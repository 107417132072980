import {
  Alert,
  Button,
  Calendar,
  Card,
  Col,
  Form,
  Input,
  Row,
  Select,
  message,
} from 'antd';
import {
  CheckCircleTwoTone,
  ClockCircleTwoTone,
  StopOutlined,
} from '@ant-design/icons';
import moment, { Moment } from 'moment';
import {
  ReactElement,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';
import { TFunction, withTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import UserContext from '../../../../contexts/user';
import { useRequest } from '../../../../hooks/useRequest';
import ScheduleRulesController from '../../../../structures/controllers/ScheduleRules';
import TruckLoadController from '../../../../structures/controllers/TruckLoad';
import {
  IGetPickUpLocations,
  IGetSeedTypes,
} from '../../../../structures/interfaces/ScheduleRules';
import {
  IGetCadences,
  IGetVehicles,
  IStepOneData,
} from '../../../../structures/interfaces/TruckLoad';
import { scrollOptions } from '../../../../utils/formOptions';
import { IErrorMessage } from '../../../../utils/interfaces';
import TruckLoadFooter from '../../components/TruckLoadFooter';
import InformWhiteSeedsDay from './InformWhiteSeedsDay';
import { DefaultOptionType } from 'antd/lib/select';
import { spawn } from 'child_process';

const { Option } = Select;

function StepOne({
  t,
  nextStep,
  setStepOneData,
}: {
  t: TFunction;
  nextStep: () => void;
  setStepOneData: (values: IStepOneData) => void;
}): ReactElement<unknown> {
  const [form] = Form.useForm();

  const history = useHistory();

  const baseString = 'pages.truckLoads.createLoad';

  const [fetchVehicles] = useRequest(TruckLoadController.getVehicles);
  const [fetchSeedType] = useRequest(ScheduleRulesController.getCulture);
  const [fetchPickupLocation, loadingPickupLocation] = useRequest(
    ScheduleRulesController.getPickUpLocations
  );
  const [fetchCadences, loadingCadences] = useRequest(
    TruckLoadController.getCadences
  );
  const [createPreSchedule, preScheduleRequesting] = useRequest(
    TruckLoadController.createPreSchedule
  );

  const { userData, customerData } = useContext(UserContext);

  const [vehicles, setVehicles] = useState<IGetVehicles[]>([]);
  const [seedTypes, setSeedTypes] = useState<DefaultOptionType[]>();
  const [seeds, setSeeds] = useState<IGetSeedTypes[]>([]);
  const [pickUpLocations, setPickUpLocations] = useState<IGetPickUpLocations[]>(
    []
  );
  const [locations, setLocations] = useState<DefaultOptionType[]>([]);
  const [cadences, setCadences] = useState<IGetCadences[]>([]);
  const [defaultMonthCalendar, setDefaultMonthCalendar] = useState<Moment>();

  const [calendarValue, setCalendarValue] = useState<Moment>();
  const [selectedCadence, setSelectedCadence] = useState<IGetCadences>();

  const [cadencesFetchedOnce, setCadencesFetchedOnce] = useState(false);
  const [locationsFetchedOnce, setLocationsFetchedOnce] = useState(false);

  const [visibleWhiteSeedsModal, setVisibleWhiteSeedsModal] = useState(false);
  const [firstdayWithTSISeeds, setFirstdayWithTSISeeds] =
    useState<IGetCadences>();

  useEffect(() => {
    const organizationId = userData
      ? userData.organization.id
      : customerData?.organizationId;
    if (organizationId) {
      fetchVehicles({
        organizationId: organizationId,
        takeCommonVehicles: true,
      }).then(setVehicles);
      fetchSeedType({}).then(res => {
        const seeds = res.map(item => ({
          value: item.nameWithoutAccent,
          label: item.name,
        }));
        setSeeds(res);
        setSeedTypes(seeds);
      });
    }
  }, []);

  const renderDefaultCalendarCell = (
    momentDate: Moment,
    children: ReactNode,
    disabled: boolean
  ) => {
    const highlight = momentDate.isSame(calendarValue, 'day');

    const disabledStyle = {
      background: '#888',
      color: '#eee',
    };

    return (
      <div
        className={`ant-picker-cell-inner ant-picker-calendar-date ${
          highlight && 'ant-picker-calendar-date-today'
        }`}
        style={{
          margin: 8,
          borderRadius: 10,
          ...(disabled && disabledStyle),
        }}
      >
        <div className="ant-picker-calendar-date-value">
          {momentDate.date()}
        </div>
        <div
          className="ant-picker-calendar-date-content"
          style={{ padding: '0', overflow: 'hidden' }}
        >
          {children}
        </div>
      </div>
    );
  };

  // const handleSeedTypeSelect = (event: string) => {
  //   setLocations([]);
  //   setCadences([]);
  //   form.resetFields(['pickupLocation']);
  //   if (userData || customerData) {
  //     fetchPickupLocation({
  //       seedTypeId: event,
  //       organizationId: userData?.organization.id,
  //     }).then(data => {
  //       const locations = data.map(item => ({
  //         value: item.id,
  //         label: item.title,
  //       }));
  //       setLocations(locations);
  //       setPickUpLocations(data);
  //       setLocationsFetchedOnce(true);
  //       handleFormChange('');
  //     });
  //   }
  // };

  useEffect(() => {
    if (userData || customerData) {
      const orgId = userData?.organization.id || customerData?.organizationId;
      fetchPickupLocation({
        seedTypeId: 'Soja',
        organizationId: orgId,
      }).then(data => {
        const locations = data.map(item => ({
          value: item.id,
          label: item.title,
        }));
        setLocations(locations);
        setPickUpLocations(data);
        setLocationsFetchedOnce(true);
      });
    }
  }, [userData, customerData]);

  const handleUnitySelect = () => {
    setCadences([]);
    form.resetFields(['unityWeight']);
    handleFormChange('');
  };

  const getFirstAndLastDayInCadence = (cadencesArray: IGetCadences[]) => {
    let firstDay = cadencesArray[0];
    let lastDay = cadencesArray[0];
    cadencesArray.forEach(cadence => {
      if (lastDay.startTime.isBefore(cadence.startTime, 'day')) {
        lastDay = cadence;
      }
      if (firstDay.startTime.isAfter(cadence.startTime, 'day')) {
        firstDay = cadence;
      }
    });

    return [firstDay, lastDay];
  };

  const sortCadenceByDate = (cadenceArray: IGetCadences[]) => {
    return cadenceArray.sort((a, b) =>
      moment(a.startTime, 'DD-MM-YYYY').diff(moment(b.startTime, 'DD-MM-YYYY'))
    );
  };

  const isDateEnabled = (value: Moment) => {
    if (!cadences.length) return false;
    const [, lastDay] = getFirstAndLastDayInCadence(cadences);
    return value.isBetween(moment(), lastDay.startTime, 'day', '[]');
  };

  const dayRenderer = (val: Moment) => {
    const cadence = cadences.find(item => val.isSame(item.startTime, 'days'));
    const { unity } = form.getFieldsValue();

    // const availableText = unity === 'BAG' ? 'bags' : 'sacas';
    const availableText = 'bags';
    if (cadence?.availableUnits === 1) {
      availableText.slice(0, -1);
    }

    if (cadence && isDateEnabled(val)) {
      const iconsLayout = {
        md: 8,
        lg: 6,
        xl: 3,
        xxl: 3,
      };
      const labelsLayout = {
        span: 0,
        xl: 13,
        xxl: 10,
        style: {
          fontSize: 13,
        },
      };
      const valuesLayout = {
        span: 8,
        xxl: 11,
      };

      return (
        <div onClick={() => setSelectedCadence(cadence)}>
          {renderDefaultCalendarCell(
            val,
            <>
              <Row>
                <Col {...iconsLayout}>
                  <CheckCircleTwoTone twoToneColor="#006AEC" />
                </Col>
                <Col {...labelsLayout}>
                  <span>
                    {`${t(
                      `${baseString}.stepOne.calendarCell.availability`
                    )}: `}
                  </span>
                </Col>
                <Col {...valuesLayout}>
                  <strong>{`${cadence.availableUnits} ${availableText}`}</strong>
                </Col>
              </Row>

              <Row>
                <Col {...iconsLayout}>
                  <ClockCircleTwoTone twoToneColor="#52c41a" />
                </Col>
                <Col {...labelsLayout}>
                  <span>
                    {`${t(`${baseString}.stepOne.calendarCell.start`)}: `}
                  </span>
                </Col>
                <Col {...valuesLayout} style={{ color: '#999' }}>
                  {moment(cadence.startTime).format('HH:mm')}
                </Col>
              </Row>

              <Row>
                <Col {...iconsLayout}>
                  <ClockCircleTwoTone twoToneColor="#eb2f41" />
                </Col>
                <Col {...labelsLayout}>
                  <span>
                    {`${t(`${baseString}.stepOne.calendarCell.end`)}: `}
                  </span>
                </Col>
                <Col {...valuesLayout} style={{ color: '#999' }}>
                  {moment(cadence.endTime).format('HH:mm')}
                </Col>
              </Row>
            </>,
            false
          )}
        </div>
      );
    }

    return renderDefaultCalendarCell(
      val,
      <div
        style={{
          width: '100%',
          height: 'calc(100% - 24px)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          color: '#eee',
        }}
      >
        <StopOutlined />
        {t(`${baseString}.stepOne.calendarCell.unavailable`)}
      </div>,
      true
    );
  };

  const handleCalendarSelect = (selectedDate: Moment) => {
    if (!cadences.length || !isDateEnabled(selectedDate)) return;
    setCalendarValue(selectedDate);
    const index = cadences.findIndex(cadence =>
      cadence.startTime.isSame(selectedDate, 'd')
    );
    if (cadences.length && cadences[index]?.whiteSeedOnly) {
      setVisibleWhiteSeedsModal(true);
    }
  };

  const isFormValid = () => {
    const { seedType, pickupLocation, unity } = form.getFieldsValue();

    return seedType && pickupLocation && unity;
  };

  const handleFormChange = (changedField: string) => {
    if (['name', 'unity', 'seedType'].includes(changedField)) {
      return;
    }

    // if (isFormValid()) {
    const { seedType, pickupLocation, unity } = form.getFieldsValue();
    fetchCadences({
      seedType,
      pickupLocation,
      unity,
    })
      .then(response => {
        setCadences(response);

        const data = response[0].startTime;
        const defaultValue = moment(data).startOf('month');
        setDefaultMonthCalendar(defaultValue);

        if (response.length) {
          const sortedCadence = sortCadenceByDate(response);
          const firstEnabledDay = sortedCadence.find(item =>
            isDateEnabled(item.startTime)
          );
          if (firstEnabledDay) {
            setCalendarValue(firstEnabledDay.startTime);
            setSelectedCadence(firstEnabledDay);
          }

          const dayWithTsiSeeds = response.find(
            item => item.whiteSeedOnly === false
          );
          setFirstdayWithTSISeeds(dayWithTsiSeeds);
        }

        if (!cadencesFetchedOnce) {
          setCadencesFetchedOnce(true);
        }
      })
      .catch(() => {
        setCadencesFetchedOnce(true);
        setCadences([]);
      });
    // }
  };

  const showError = (error: IErrorMessage) => {
    message.error({
      content: t(error.message),
      style: { marginTop: '2rem' },
    });
  };

  const handleSubmit = (values: {
    name: string;
    vehicle: string;
    pickupLocation: string;
    seedType: string;
    unity: string;
    unityWeight: number;
    farmId: string;
  }) => {
    const preScheduleAuthor = customerData ? customerData.id : userData?.id;
    message.config({ top: 64 });
    if (selectedCadence && preScheduleAuthor) {
      createPreSchedule({
        ...values,
        cadenceId: selectedCadence.id,
        createdBy: preScheduleAuthor,
        pickupLocation: '1c6e3d45-2fb4-44d2-a9a7-f96f5fc21e5b',
      })
        .then(response => {
          message.success({
            content: t(`${baseString}.messages.preSchedule`),
            duration: 5,
          });

          const vehicleById = vehicles.reduce((prev, curr) => {
            return {
              ...prev,
              [curr.id]: curr,
            };
          }, {});

          setStepOneData({
            ...values,
            preScheduleId: response.id,
            vehicle: vehicleById[values.vehicle as keyof typeof vehicleById],
            vehicles,
            pickupLocation: {
              id: '1c6e3d45-2fb4-44d2-a9a7-f96f5fc21e5b',
              organizationId: response.pickUpLocation.organizationId,
              title: 'FAZENDA BAHIA',
            },
            selectedDate: calendarValue,
            cancel: response,
            organizationId: response.pickUpLocation.organizationId,
            cadence: {
              whiteSeedOnly: selectedCadence.whiteSeedOnly,
              rule: {
                capacityPerCompany: response.cadence.rule.capacityPerCompany,
              },
              availableUnits: selectedCadence.availableUnits,
              endTime: selectedCadence.endTime,
              startTime: selectedCadence.startTime,
              id: selectedCadence.id,
            },
            seedTypes: seeds,
            pickupLocations: pickUpLocations,
            seedType: 'Soja',
            unity: 'BAG',
          });

          nextStep();
        })
        .catch(error => showError(error));
    }
  };

  let text = '';
  if (locations.length <= 0) {
    if (customerData) text = 'Nenhum local de embarque encontrado!';
    if (userData)
      text =
        'Nenhum local de embarque encontrado, crie uma regra de agendamento para prosseguir!';
  } else {
    text = '1c6e3d45-2fb4-44d2-a9a7-f96f5fc21e5b';
  }

  const camposForm = () => (
    <Row gutter={24}>
      <Col xs={24} sm={12} md={8} lg={6} xl={4}>
        <Form.Item
          name="name"
          label={t(`${baseString}.stepOne.labels.loadName`)}
        >
          <Input
            placeholder={t(`${baseString}.stepOne.placeholders.loadName`)}
          />
        </Form.Item>
      </Col>
      <Col xs={24} sm={12} md={8} lg={6} xl={4}>
        <Form.Item
          name="vehicle"
          label={t(`${baseString}.stepOne.labels.vehicle`)}
          required
        >
          <Select
            placeholder={t(`${baseString}.stepOne.placeholders.vehicle`)}
            onSelect={handleUnitySelect}
          >
            {vehicles.map(vehicle => (
              <Option key={vehicle.id} value={vehicle.id}>
                {vehicle.vehicleModel}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </Col>
      <Col xs={24} sm={12} md={8} lg={6} xl={4}>
        <Form.Item
          name="seedType"
          label={t(`${baseString}.stepOne.labels.seedType`)}
          required
        >
          <Select
            disabled
            // onSelect={handleSeedTypeSelect}
            placeholder={t(`${baseString}.stepOne.placeholders.seedType`)}
            options={seedTypes}
            defaultValue={'Soja'}
          />
        </Form.Item>
      </Col>
      <Col xs={24} sm={12} md={8} lg={6} xl={4}>
        <Form.Item
          name="pickupLocation"
          label={t(`${baseString}.stepOne.labels.location`)}
          required
        >
          {locations.length <= 0 ? (
            <span>{text}</span>
          ) : (
            <Select
              disabled
              loading={loadingPickupLocation}
              placeholder={t(`${baseString}.stepOne.placeholders.location`)}
              defaultValue={text}
              options={locations}
            />
          )}
        </Form.Item>
      </Col>
      <Col xs={24} sm={12} md={8} lg={6} xl={4}>
        <Form.Item
          name="unity"
          label={t(`${baseString}.stepOne.labels.unity`)}
          required
        >
          <Select
            onSelect={handleUnitySelect}
            placeholder={t(`${baseString}.stepOne.placeholders.unity`)}
            defaultValue={'BAG'}
            disabled
          >
            <Option value="BAG">
              {t(`${baseString}.stepOne.unityFilter.bags`)}
            </Option>
            <Option value="SMALL_BAG">
              {t(`${baseString}.stepOne.unityFilter.smallBags`)}
            </Option>
          </Select>
        </Form.Item>
      </Col>
    </Row>
  );

  return (
    <>
      <Col span={24}>
        <Card
          bordered={false}
          title={t(`${baseString}.stepOne.cardTitles.inputs`)}
        >
          <Form
            scrollToFirstError={scrollOptions}
            name="dadosBasicos"
            layout="vertical"
            form={form}
            onFieldsChange={(changedField: any) => {
              if (!changedField.length) return;
              handleFormChange(changedField[0].name[0]);
            }}
            onFinish={handleSubmit}
          >
            {camposForm}
          </Form>
        </Card>
      </Col>
      {!cadences.length && cadencesFetchedOnce && (
        <Col span={24}>
          <Alert
            type="warning"
            message={t(`${baseString}.warnings.noCadences`)}
          />
        </Col>
      )}
      {!locations.length && locationsFetchedOnce && (
        <Col span={24}>
          <Alert
            type="warning"
            message={t(`${baseString}.warnings.noLocation`)}
          />
        </Col>
      )}
      <Col span={24}>
        <Card
          bordered={false}
          title={t(`${baseString}.stepOne.cardTitles.calendar`)}
          loading={loadingCadences}
        >
          <Calendar
            key={defaultMonthCalendar?.toISOString()}
            defaultValue={defaultMonthCalendar}
            mode="month"
            value={calendarValue}
            onPanelChange={newValue => setCalendarValue(newValue)}
            onSelect={handleCalendarSelect}
            dateFullCellRender={
              cadences.length
                ? dayRenderer
                : date => renderDefaultCalendarCell(date, null, false)
            }
          />
        </Card>
      </Col>

      <TruckLoadFooter>
        <Button
          style={{ margin: 24 }}
          danger
          htmlType="reset"
          onClick={() => {
            history.push('/cargas');
          }}
        >
          {t(`${baseString}.footer.cancelBtn`)}
        </Button>
        <Button
          style={{ margin: '24px 0' }}
          type="primary"
          htmlType="submit"
          loading={preScheduleRequesting}
          disabled={/* !isFormValid() || */ !calendarValue || !selectedCadence}
          onClick={() => {
            form.submit();
          }}
        >
          {t(`${baseString}.footer.confirmBtn`)}
        </Button>
      </TruckLoadFooter>
      <InformWhiteSeedsDay
        visible={visibleWhiteSeedsModal}
        onClose={() => setVisibleWhiteSeedsModal(false)}
        onConfirm={() => setVisibleWhiteSeedsModal(false)}
        treatmentDay={firstdayWithTSISeeds}
      />
    </>
  );
}

export default withTranslation()(StepOne);
