import {
  AuditOutlined,
  FileSyncOutlined,
  FundProjectionScreenOutlined,
  HomeOutlined,
  MenuFoldOutlined,
  QuestionCircleOutlined,
  SettingOutlined,
  ShopOutlined,
  SolutionOutlined,
  TeamOutlined,
} from '@ant-design/icons';
import type { MenuProps } from 'antd';
import { Layout, Menu } from 'antd';
import React, { ReactElement, useContext } from 'react';
import { Link } from 'react-router-dom';
import TruckOutlined from '../../assets/IconComponents/TruckOutlined';
import menuLogo from '../../assets/logotipo.svg';
import smallMenuLogo from '../../assets/small-logotipo.svg';
import UserContext from '../../contexts/user';
import { t } from '../../i18n/Translator';
import { layoutStyles } from '../../utils/constants';
import './sidebar.scss';

type MenuItem = Required<MenuProps>['items'][number];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[]
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
  } as MenuItem;
}

const css: React.CSSProperties = {
  color: 'rgba(255, 255, 255, 0.65)',
};

interface ISidebar {
  collapsed: boolean;
  layoutColor?: string;
  setCollapsed: React.Dispatch<React.SetStateAction<boolean>>;
  menuKey: string;
}

const Sidebar = ({
  layoutColor,
  collapsed,
  setCollapsed,
  menuKey,
}: ISidebar): ReactElement => {
  const { customerData, userData, getPermissions } = useContext(UserContext);

  const items: MenuItem[] = [
    getItem(
      t('sidebar.options.home'),
      'HOME',
      <Link to="/home">
        <HomeOutlined />
      </Link>
    ),
    getItem(
      t('sidebar.options.contracts'),
      'CONTRATO',
      <Link to="/contratos">
        <AuditOutlined />
      </Link>
    ),

    getItem(
      t('sidebar.options.clients'),
      'CLIENTE',
      <SolutionOutlined style={css} />,
      [
        getItem(<Link to="/clientes/fazendas" />, 'farms', 'Fazendas'),
        getItem(<Link to="/clientes/contatos" />, 'customers', 'Contatos'),
      ]
    ),

    // getItem(
    //   t('sidebar.options.clients'),
    //   'CLIENTE',
    //   <Link to="/clientes">
    //     <SolutionOutlined />
    //   </Link>
    // ),
    getItem(
      t('sidebar.options.users'),
      'USUARIOS',
      <Link to="/usuarios">
        <TeamOutlined />
      </Link>
    ),
    getItem(
      t('sidebar.options.truckLoads'),
      'CARGAS',
      <Link to="/cargas">
        <TruckOutlined />
      </Link>
    ),
    getItem(
      t('sidebar.options.myCompany'),
      'MINHA_EMPRESA',
      <Link to={`/empresas/${userData?.organization.id}`}>
        <ShopOutlined />
      </Link>
    ),
    getItem(
      t('sidebar.options.settings'),
      'REGRAS',
      <SettingOutlined style={css} />,
      [
        getItem(
          <Link to="/regras/cadastro" />,
          'scheduleRules',
          t('sidebar.options.scheduleRules')
        ),
        getItem(
          <Link to="/regras/configuracoes" />,
          'ruleSettings',
          t('sidebar.options.ruleSettings')
        ),
      ]
    ),
    getItem(
      t('sidebar.options.tutorials'),
      'tutorials',
      <Link to="/tutoriais">
        <FundProjectionScreenOutlined />
      </Link>
    ),
    getItem(
      t('sidebar.options.reports'),
      'reports',
      <Link to="relatorios">
        <FileSyncOutlined />
      </Link>
    ),
  ];

  const customerItems: MenuItem[] = [
    getItem(
      t('sidebar.options.home'),
      'HOME',
      <Link to="/home">
        <HomeOutlined />
      </Link>
    ),
    getItem(
      t('sidebar.options.contracts'),
      'CONTRATO',
      <Link to="/contratos">
        <AuditOutlined />
      </Link>
    ),
    getItem(
      t('sidebar.options.truckLoads'),
      'CARGAS',
      <Link to="/cargas">
        <TruckOutlined />
      </Link>
    ),
    getItem(
      t('sidebar.options.tutorials'),
      'tutorials',
      <Link to="/tutoriais">
        <QuestionCircleOutlined />
      </Link>
    ),
    getItem(
      t('sidebar.options.reports'),
      'reports',
      <Link to="relatorios">
        <FileSyncOutlined />
      </Link>
    ),
  ];

  const permittedRoutes = items.map(route => {
    const permissions = getPermissions?.find(
      permission =>
        permission?.userPermissions.module === route?.key ||
        route?.key === 'tutorials' ||
        route?.key === 'reports'
    );
    if (permissions) {
      return route;
    } else {
      return null;
    }
  });

  const styles: Record<string, React.CSSProperties> = {
    sider: {
      position: 'fixed',
      height: '100%',
      zIndex: 1032,
      background: layoutColor,
    },
    selectedOption: {
      backgroundColor: layoutStyles.menuSelectedOption,
    },
  };

  const { Sider } = Layout;

  const openDropdown =
    menuKey === 'ruleSettings' || menuKey === 'scheduleRules'
      ? ['REGRAS']
      : [''];

  const openCustomerDropdown =
    menuKey === 'customers' || menuKey === 'farms' ? ['CLIENTE'] : [''];

  menuKey = menuKey === 'IMPORTAR_CLIENTE' ? 'CLIENTE' : menuKey;
  menuKey = menuKey === 'CRIAR_USUARIO' ? 'USUARIOS' : menuKey;
  menuKey = menuKey === 'VER_CARGA' ? 'CARGAS' : menuKey;
  menuKey = menuKey === 'CRIAR_CARGA' ? 'CARGAS' : menuKey;

  return (
    <Sider
      collapsible
      trigger={null}
      collapsed={collapsed}
      style={styles.sider}
      onCollapse={value => setCollapsed(!value)}
    >
      <div className="menu-logo_div">
        <img
          style={{
            transition: '0.2s all',
            animationTimingFunction: 'ease-out',
          }}
          src={collapsed ? smallMenuLogo : menuLogo}
          alt="Menu Logo"
          className={collapsed ? 'sidebar_logo-decreased' : ''}
        />
      </div>
      <Menu
        theme="dark"
        defaultSelectedKeys={[menuKey]}
        selectedKeys={[menuKey]}
        defaultOpenKeys={openDropdown || openCustomerDropdown}
        mode="inline"
        items={customerData ? customerItems : permittedRoutes}
        disabled={menuKey === 'customerConfigPage'}
      />

      <div
        onClick={() => {
          setCollapsed(!collapsed);
        }}
      >
        <MenuFoldOutlined
          style={{
            position: 'absolute',
            bottom: '2%',
            paddingLeft: 30,
            paddingRight: 30,
            textAlign: 'left',
            textTransform: 'none',
            overflow: 'hidden',
            fontSize: 20,
            color: '#FFF',
            cursor: 'pointer',
            transform: `rotateY(${collapsed ? '180deg' : '0deg'})`,
            transition: 'transform .2s',
          }}
        />
      </div>
    </Sider>
  );
};

export default Sidebar;
