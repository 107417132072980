import {
  Checkbox,
  Col,
  DatePicker,
  Form,
  FormInstance,
  Input,
  Select,
} from 'antd';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

const { Option } = Select;
const { RangePicker } = DatePicker;

const FiltroCarga = ({
  cultivations,
  isCustomOrdering,
  setIsCustomOrdering,
  form,
}: {
  cultivations: ReactNode;
  isCustomOrdering?: boolean;
  setIsCustomOrdering?: (v: boolean) => void;
  form: FormInstance;
}) => {
  const { t } = useTranslation();

  return (
    <>
      <Col
        span={24}
        md={12}
        lg={6}
        xl={5}
        xxl={4}
        style={{ display: 'flex', alignItems: 'end' }}
      >
        <Form.Item name="search" style={{ width: '100%' }}>
          <Input.Search
            enterButton
            onSearch={() => form.submit()}
            placeholder={t('pages.truckLoads.createLoad.placeholders.search')}
          />
        </Form.Item>
      </Col>
      <Col span={24} md={12} lg={6} xl={5} xxl={4}>
        <Form.Item
          name="cultivation"
          label={t('pages.truckLoads.createLoad.labels.cultivation')}
          initialValue=""
        >
          <Select allowClear>
            <Option value="">
              {t('pages.truckLoads.createLoad.dropdown.all')}
            </Option>
            {cultivations}
          </Select>
        </Form.Item>
      </Col>
      <Col span={24} md={12} lg={6} xl={5} xxl={4}>
        <Form.Item
          name="contractDate"
          label={t('pages.truckLoads.createLoad.labels.contractDate')}
          initialValue=""
        >
          <RangePicker
            format={'DD/MM/YYYY'}
            style={{ width: '100%' }}
            allowClear={false}
          />
        </Form.Item>
      </Col>
      <Col span={24} md={12} lg={6} xl={5} xxl={4}>
        <Form.Item label=" ">
          <Checkbox
            onChange={v => setIsCustomOrdering?.(v.target.checked)}
            checked={isCustomOrdering}
          >
            Quero ordenar a descarga
          </Checkbox>
        </Form.Item>
      </Col>
    </>
  );
};

export default FiltroCarga;
