import {
  Alert,
  Button,
  Card,
  Col,
  Divider,
  Form,
  Popover,
  Table,
  Tooltip,
  Row,
  Select,
  Tag,
  InputNumber,
} from 'antd';
import React, { CSSProperties, useContext, useEffect, useState } from 'react';
import { TFunction, Trans, withTranslation } from 'react-i18next';
import { scrollOptions } from '../../../utils/formOptions';
import {
  IFilter,
  IGetContractItems,
} from '../../../structures/interfaces/TruckLoad';
import moment from 'moment';
import FiltroCarga from '../CreateLoad/createLoadSteps/FiltroCarga';
import UserContext from '../../../contexts/user';
import Formatter from '../../../classes/Formatter';
import { ColumnsType } from 'antd/lib/table';
import { DefaultRecordType } from 'rc-table/lib/interface';

const { Option } = Select;

const colors = [
  '#C97A6D',
  '#7AC97D',
  '#7A6DC9',
  '#C9BC7A',
  '#C97AA6',
  '#A17AC9',
  '#7AC9A1',
  '#7A9BC9',
  '#C96D7A',
  '#7AC9BD',
  '#C97A68',
  '#7A91B3',
  '#B38A7A',
  '#7AB3B0',
];

function ContractItemsTable({
  t,
  colorsAssigned,
  contractItems,
  setContractItems,
  addedItemsWeight,
  addedItemsQtd,
  maxWeight,
  maxBagsCapacity,
  onLoadAddition,
  unity,
  capacityPerCompany,
  loading,
}: {
  t: TFunction;
  colorsAssigned: Record<string, string>;
  contractItems: IGetContractItems[];
  setContractItems: (items: IGetContractItems[]) => void;
  addedItemsWeight: number;
  addedItemsQtd: number;
  maxWeight: number;
  maxBagsCapacity: number;
  onLoadAddition: (newLoads: IGetContractItems[]) => void;
  unity: string;
  capacityPerCompany: number;
  loading: boolean;
}) {
  const baseString = 'pages.truckLoads.createLoad';
  const baseStringToPopover =
    'pages.truckLoads.createLoad.stepTwo.customerLimitExceeded';

  const { userData, customerData } = useContext(UserContext);

  const [formFilter] = Form.useForm();
  const [formAmounts] = Form.useForm();

  const [filter, setFilter] = useState<IFilter>({});
  const [customerRemainingAmount, setCustomerRemainingAmount] =
    useState<number>(0);
  const [itemsToAdd, setItemstoAdd] = useState<{
    [key: string]: { index: number; value: number; data: IGetContractItems };
  }>({});
  const [enableAddItems, setEnableAddItems] = useState(false);
  const [customerNameOverCapacity, setCustomerNameOverCapacity] =
    useState<string>();

  const renderPickupAmountInput = (data: IGetContractItems) => {
    return (
      <Form.Item noStyle name={data.id} initialValue={0}>
        <InputNumber
          onBlur={() => {
            const inputValue = formAmounts.getFieldValue([data.id]);
            if (!inputValue) {
              const res = { ...itemsToAdd };
              delete res[data.id];
              setItemstoAdd(res);
              formAmounts.resetFields([data.id]);
            }
          }}
          className="input-number-visible-controls"
          placeholder="QTD"
          min={0}
          max={data.remainingAmount}
          controls={true}
          onChange={val => {
            if (val !== null && !Number.isNaN(val) && val > 0) {
              const newItem = {
                ...itemsToAdd,
                [data.id]: {
                  index: data.index,
                  value: val,
                  data,
                },
              };
              setItemstoAdd(newItem);
            } else {
              const res = { ...itemsToAdd };
              delete res[data.id];
              setItemstoAdd(res);
            }
          }}
        />
      </Form.Item>
    );
  };

  const renderRemainingAmount = (amount: number, data: IGetContractItems) => {
    const result = amount - (itemsToAdd[data.id]?.value || 0);
    return result;
  };

  const renderCultivationTags = (cultivation: string, id: string) => {
    const color =
      colorsAssigned[id as keyof typeof colorsAssigned] || colors[0];

    return <Tag color={color}>{cultivation}</Tag>;
  };

  const quantityStyle: CSSProperties = {
    background: '#F0F0F0',
  };

  const quantityColumnCustomer = [
    {
      title: t(`${baseString}.contractItems.columns.contractAmount`),
      key: 'totalAmount',
      onHeaderCell: () => ({
        style: quantityStyle,
      }),
      dataIndex: 'totalAmount',
      sorter: (a: IGetContractItems, b: IGetContractItems) =>
        a.totalAmount - b.totalAmount,
      fixed: 'right',
    },
    {
      title: t(`${baseString}.contractItems.columns.pickupAmount`),
      key: 'remainingAmount',
      onHeaderCell: () => ({
        style: quantityStyle,
      }),
      dataIndex: 'remainingAmount',
      render: (amount: number, data: IGetContractItems) =>
        renderRemainingAmount(amount, data),
      sorter: (a: IGetContractItems, b: IGetContractItems) =>
        a.remainingAmount - b.remainingAmount,
      fixed: 'right',
    },
  ];
  const quantityColumnUser = [
    {
      title: t(`${baseString}.contractItems.columns.contractAmount`),
      key: 'totalAmount',
      onHeaderCell: () => ({
        style: quantityStyle,
      }),
      dataIndex: 'totalAmount',
      sorter: (a: IGetContractItems, b: IGetContractItems) =>
        a.totalAmount - b.totalAmount,
      fixed: 'right',
    },
    {
      title: t(`${baseString}.contractItems.columns.pickupAmount`),
      key: 'remainingAmount',
      onHeaderCell: () => ({
        style: quantityStyle,
      }),
      dataIndex: 'remainingAmount',
      render: (amount: number, data: IGetContractItems) =>
        renderRemainingAmount(amount, data),
      sorter: (a: IGetContractItems, b: IGetContractItems) =>
        a.remainingAmount - b.remainingAmount,
      fixed: 'right',
    },
    {
      title: t(`${baseString}.stepTwo.customerRemainingAmount`),
      key: 'customerRemainingAmount',
      onHeaderCell: () => ({
        style: quantityStyle,
      }),
      dataIndex: ['customerCapacity', 'remainingAmount'],
      render: (customerRemainingAmount: number) => {
        if (customerRemainingAmount) {
          if (customerRemainingAmount < 0) return 0;
          else return customerRemainingAmount;
        }
        return customerRemainingAmount;
      },
      fixed: 'right',
    },
  ];
  const tableQuantityChildrenColumn = customerData
    ? quantityColumnCustomer
    : quantityColumnUser;

  const contractItensColumns = [
    {
      title: t(`${baseString}.contractItems.columns.client`),
      key: 'client',
      dataIndex: 'client',
    },
    {
      title: t(`${baseString}.contractItems.columns.stateRegister`),
      key: 'stateRegistration',
      dataIndex: 'stateRegistration',
    },
    {
      title: t(`${baseString}.contractItems.columns.contract`),
      key: 'contractNumber',
      dataIndex: 'contractNumber',
    },
    {
      title: t(`${baseString}.contractItems.columns.date`),
      key: 'contractDate',
      dataIndex: ['contract', 'contractDate'],
      render: (date: string) => Formatter.formatDate(date),
      // sorter: (a: IGetContractItems, b: IGetContractItems) =>
      //   new Date(a.contract.contractDate).valueOf() -
      //   new Date(b.contract.contractDate).valueOf(),
    },
    {
      title: t(`${baseString}.contractItems.columns.id`),
      key: 'referenceNumber',
      dataIndex: 'referenceNumber',
    },
    {
      title: t(`${baseString}.contractItems.columns.cultivation`),
      key: 'cultivation',
      dataIndex: 'cultivation',
      render: (cultivation: string, { id }: { id: string }) =>
        renderCultivationTags(cultivation, id),
    },
    {
      title: t(`${baseString}.contractItems.columns.sieve`),
      key: 'sieve',
      dataIndex: 'sieve',
    },
    {
      title: t(`${baseString}.contractItems.columns.category`),
      key: 'category',
      dataIndex: 'category',
    },
    {
      title: t(`${baseString}.contractItems.columns.package`),
      key: 'packing',
      dataIndex: 'packing',
    },
    {
      title: t(`${baseString}.contractItems.columns.tsi`),
      key: 'tsi',
      dataIndex: 'tsi',
      render: (tsi: boolean) => (tsi ? 'Sim' : 'Não'),
    },
    {
      title: t(`${baseString}.contractItems.columns.weight`),
      key: 'estimatedWeight',
      dataIndex: 'estimatedWeight',
      // sorter: (a: IGetContractItems, b: IGetContractItems) =>
      //   a.estimatedWeight - b.estimatedWeight,
    },
    {
      title: t(`${baseString}.contractItems.quantity`),
      onHeaderCell: () => ({
        style: quantityStyle,
      }),
      children: tableQuantityChildrenColumn,
    },
    {
      title: t(`${baseString}.contractItems.columns.pickup`),
      key: '',
      dataIndex: '',
      render: (_: string, data: IGetContractItems) => {
        return renderPickupAmountInput(data);
      },
      fixed: 'right',
    },
  ];

  const filteredColumns = contractItensColumns.filter(column => {
    if (customerData) {
      return column.key !== 'category' && column.key !== 'sieve';
    } else {
      return column;
    }
  });

  const applyFilter = (values: IGetContractItems[]) => {
    const { contractDate, search, cultivation } = filter;
    const filteredValues = values.filter(item => {
      let filteredState = true;
      if (search) {
        const fields = [
          item.client,
          item.stateRegistration,
          item.contractNumber,
          item.seedType,
          item.referenceNumber,
          item.cultivation,
          item.sieve,
          item.category,
          item.packing,
          item.tsi,
          item.estimatedWeight,
          item.totalAmount,
          item.remainingAmount,
        ];

        filteredState = !!fields.filter(
          field =>
            field &&
            field
              .toString()
              .toLocaleLowerCase()
              .includes(search.toLocaleLowerCase())
        ).length;
      }

      if (cultivation) {
        filteredState = filteredState && cultivation === item.cultivation;
      }

      if (contractDate && contractDate.length) {
        filteredState =
          filteredState &&
          moment(item.contract.contractDate).isBetween(
            contractDate[0],
            contractDate[1],
            'day',
            '[]'
          );
      }
      return filteredState;
    });
    return filteredValues;
  };

  const getCultivations = () => {
    const cultivations = contractItems.map(item => item.cultivation);
    const uniqueCultivations = cultivations.filter(
      (value, index) => cultivations.indexOf(value) === index
    );
    return (
      <>
        {uniqueCultivations.map(cultivation => (
          <Option key={cultivation} value={cultivation}>
            {cultivation}
          </Option>
        ))}
      </>
    );
  };

  const customerExtraLimitInfo = (
    <span style={{ fontSize: 16 }}>
      {t(`${baseString}.stepTwo.extraButtons.maximumCapacity`)}{' '}
      <Tag style={{ borderRadius: 12, fontWeight: 'bold' }} color="#0080ff">
        {customerRemainingAmount}
      </Tag>
    </span>
  );
  const userExtraLimitInfo = (
    <span style={{ fontSize: 16 }}>
      {t(`${baseString}.stepTwo.extraButtons.maximumCapacityPerCompany`)}{' '}
      <Tag style={{ borderRadius: 12, fontWeight: 'bold' }} color="#0080ff">
        {capacityPerCompany}
      </Tag>
    </span>
  );

  const itemsToAddEmpty = () => {
    return !Object.values(itemsToAdd).length;
  };

  const isWeightOverTheLimit = () => {
    const values = Object.values(itemsToAdd);

    let weightToAdd = values.reduce((prev, curr) => {
      return prev + curr.data.estimatedWeight * curr.value;
    }, 0);

    weightToAdd += addedItemsWeight;

    return weightToAdd > maxWeight;
  };

  const isBagsOverTheLimit = () => {
    const values = Object.values(itemsToAdd);
    const bagsToAdd =
      values.reduce((prev, curr) => {
        const sum = prev + curr.value;
        return sum;
      }, 0) + addedItemsQtd;

    if (addedItemsQtd >= maxBagsCapacity) {
      return true;
    } else {
      return bagsToAdd > maxBagsCapacity;
    }
  };

  const isCustomerWeightOverTheLimit = () => {
    if (customerRemainingAmount) {
      const currentAmount = Object.entries(itemsToAdd).reduce(
        (prev, curr) => prev + curr[1].value,
        0
      );
      return currentAmount > customerRemainingAmount;
    }
  };

  const isRuleWeightOverTheLimit = () => {
    if (addedItemsWeight >= maxWeight) {
      return true;
    } else {
      if (capacityPerCompany) {
        const currentAmount = Object.entries(itemsToAdd).reduce(
          (prev, curr) => prev + curr[1].value,
          0
        );
        return currentAmount > capacityPerCompany;
      }
    }
  };

  const weightTooltipVisible = customerData
    ? isWeightOverTheLimit() || isCustomerWeightOverTheLimit()
    : isWeightOverTheLimit() || isRuleWeightOverTheLimit();

  const bagsTooltipVisible = isBagsOverTheLimit();

  useEffect(() => {
    contractItems.find(({ customerCapacity, id, customerId }) => {
      const values = Object.values(itemsToAdd);
      const custCapacity = customerCapacity?.remainingAmount;
      const itemsToAddValue = values.reduce((prev, curr) => {
        return prev + curr.value;
      }, 0);
      const itemToAdd = itemsToAdd[id];
      if (custCapacity) {
        setCustomerRemainingAmount(custCapacity);
        if (itemToAdd?.data?.customerId === customerId) {
          setCustomerNameOverCapacity(itemToAdd.data.client);
          if (itemsToAddValue > custCapacity && !weightTooltipVisible) {
            setEnableAddItems(true);
          }
          if (custCapacity > itemsToAddValue || weightTooltipVisible) {
            setEnableAddItems(false);
          }
        }
      }
    });
  }, [contractItems, itemsToAdd]);

  const disableAddItems = () =>
    enableAddItems ||
    itemsToAddEmpty() ||
    isWeightOverTheLimit() ||
    isBagsOverTheLimit() ||
    isRuleWeightOverTheLimit() ||
    isCustomerWeightOverTheLimit();

  const handleLoadAddition = () => {
    let newLoads: IGetContractItems[] = [];

    if (unity === 'BAG') {
      newLoads = Object.values(itemsToAdd).flatMap(item => {
        const formattedItem = {
          ...item.data,
          index: item.index,
          allocatedAmount: 1,
          extraItem: true,
        };
        const addedItemsRows = Array(item.value).fill(formattedItem);
        return addedItemsRows;
      });
    } else {
      newLoads = Object.values(itemsToAdd).flatMap(item => {
        const formattedItem = {
          ...item.data,
          index: item.index,
          allocatedAmount: item.value,
          extraItem: true,
        };
        return formattedItem;
      });
    }

    onLoadAddition(newLoads);
    formAmounts.resetFields();

    const updatedContractItems = [...contractItems];
    newLoads.forEach(item => {
      if (typeof item.index !== 'undefined' && item.allocatedAmount) {
        updatedContractItems[item.index].remainingAmount -=
          item.allocatedAmount;
      }
    });

    setContractItems(updatedContractItems);
    setItemstoAdd({});
  };

  return (
    <>
      <Col span={24}>
        <Card>
          <Form
            scrollToFirstError={scrollOptions}
            form={formFilter}
            layout="vertical"
            onFinish={setFilter}
          >
            <Row gutter={[12, 0]}>
              <FiltroCarga cultivations={getCultivations()} form={formFilter} />
              <Col
                style={{
                  marginLeft: 'auto',
                  display: 'flex',
                  alignItems: 'end',
                }}
              >
                <Form.Item>
                  <Button
                    style={{ marginRight: 8 }}
                    onClick={() => formFilter.resetFields()}
                  >
                    {t(`${baseString}.buttons.clearFilter`)}
                  </Button>
                  <Button type="primary" htmlType="submit">
                    {t(`${baseString}.buttons.filter`)}
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Card>
      </Col>
      <Col span={24}>
        <Alert
          type="warning"
          message={
            <Trans i18nKey={`${baseString}.stepTwo.warnings.contractItems`} />
          }
        />
      </Col>
      <Col span={24}>
        <Card
          title={t(`${baseString}.cardTitles.contractItems`)}
          extra={userData ? userExtraLimitInfo : customerExtraLimitInfo}
        >
          <Form scrollToFirstError={scrollOptions} form={formAmounts}>
            <Table
              loading={loading}
              dataSource={applyFilter(contractItems)}
              scroll={{ x: true }}
              columns={filteredColumns as ColumnsType<DefaultRecordType>}
              size="small"
            />
          </Form>
          <Divider />
          <Popover
            overlayStyle={{ width: 400 }}
            title={t(`${baseStringToPopover}.warning`)}
            content={
              <>
                <p>
                  {t(`${baseStringToPopover}.exceededWeight`)}{' '}
                  <strong>{customerNameOverCapacity}</strong>. <br />
                  {t(`${baseStringToPopover}.continue`)}
                </p>
                <Button
                  onClick={() => {
                    setEnableAddItems(false);
                    handleLoadAddition();
                  }}
                >
                  {t(`${baseString}.stepTwo.customerLimitExceeded.confirm`)}
                </Button>

                <Button
                  style={{ marginLeft: 24 }}
                  danger
                  onClick={() => {
                    setEnableAddItems(false);
                  }}
                >
                  {t(`${baseString}.stepTwo.customerLimitExceeded.cancel`)}
                </Button>
              </>
            }
            open={enableAddItems}
          >
            <Button
              type="primary"
              style={{ float: 'right' }}
              onClick={handleLoadAddition}
              disabled={disableAddItems()}
            >
              <Tooltip
                overlayStyle={{ zIndex: 2 }}
                open={weightTooltipVisible || bagsTooltipVisible}
                title={t(
                  `${baseString}.stepTwo.filter.${
                    weightTooltipVisible ? 'weight' : 'bags'
                  }OverTheLimit`
                )}
              >
                {t(`${baseString}.buttons.addItems`)}
              </Tooltip>
            </Button>
          </Popover>
        </Card>
      </Col>
    </>
  );
}

export default withTranslation()(ContractItemsTable);
