import { QuestionCircleOutlined } from '@ant-design/icons';
import {
  Descriptions,
  Layout,
  Modal,
  Table,
  Tag,
  Tooltip,
  Typography,
} from 'antd';
import { ReactElement, useContext, useEffect, useState } from 'react';

import { TFunction, withTranslation } from 'react-i18next';
import { useRequest } from '../../hooks/useRequest';
import ContractController from '../../structures/controllers/Contract';
import {
  IContractItems,
  IGetContract,
} from '../../structures/interfaces/Contract';
import UserContext from '../../contexts/user';

const { Title } = Typography;

const ViewContract = ({
  t,
  visible,
  onClose,
  data,
}: {
  t: TFunction;
  visible: boolean;
  onClose: () => void;
  data: IGetContract;
}): ReactElement<unknown> => {
  const [getContractItemsRequest, isGetContractRequesting] = useRequest(
    ContractController.getContractItems
  );
  const { customerData } = useContext(UserContext);
  const [contractItems, setContractItems] = useState<IContractItems[]>();
  const [pageSize, setPageSize] = useState(10);

  useEffect(() => {
    if (data) {
      getContractItemsRequest(data.id).then(items => {
        setContractItems(items);
      });
    }
  }, [data]);

  const fullAmountMessage = <div>{t('pages.contracts.fullAmountMessage')}</div>;
  const remainingAmountMessage = (
    <div>{t('pages.contracts.remainingAmountMessage')}</div>
  );

  const tableColumns = [
    {
      title: t('pages.contracts.seedType'),
      dataIndex: ['seedType', 'name'],
      key: 'seedTypeName',
    },
    {
      title: t('pages.contracts.itemID'),
      dataIndex: ['referenceNumber'],
      key: 'itemID',
      render: (referenceNumber: string) => <Tag>{referenceNumber}</Tag>,
    },
    {
      title: t('pages.contracts.cultivate'),
      dataIndex: ['cultivation'],
      key: 'cultivation',
    },
    {
      title: t('pages.contracts.sieve'),
      dataIndex: ['sieve'],
      key: 'sieve',
    },
    {
      title: t('pages.contracts.category'),
      dataIndex: ['category'],
      key: 'category',
    },
    {
      title: t('pages.contracts.packing'),
      dataIndex: ['packing'],
      key: 'packing',
    },
    {
      title: t('pages.contracts.tsi'),
      dataIndex: ['tsi'],
      key: 'tsi',
      render: (tsi: unknown) => {
        return tsi ? (
          <Tag color="blue">{t('pages.contracts.yesTsi')}</Tag>
        ) : (
          <Tag color="volcano">{t('pages.contracts.noTsi')}</Tag>
        );
      },
    },
    {
      title: t('pages.contracts.estimatedWeight'),
      dataIndex: ['estimatedWeight'],
      key: 'estimatedWeight',
    },
    {
      title: () => (
        <>
          {t('pages.contracts.remainingAmount')}{' '}
          <Tooltip title={remainingAmountMessage}>
            <QuestionCircleOutlined />
          </Tooltip>
        </>
      ),
      dataIndex: ['remainingAmount'],
      key: 'remainingAmount',
    },
    {
      title: () => (
        <>
          {t('pages.contracts.fullAmount')}{' '}
          <Tooltip title={fullAmountMessage}>
            <QuestionCircleOutlined />
          </Tooltip>
        </>
      ),
      dataIndex: ['fullAmount'],
      key: 'fullAmount',
    },
  ];

  const filteredColumns = tableColumns.filter(column => {
    if (customerData) {
      return column.key !== 'category' && column.key !== 'sieve';
    } else {
      return column;
    }
  });

  const scheduleStatus = (status: string) => {
    switch (status) {
      case 'WAITING_SCHEDULE':
        return <Tag color="orange">{t('pages.contracts.waitingSchedule')}</Tag>;
      case 'WAITING_RELEASE':
        return <Tag color="blue">{t('pages.contracts.waitingRelease')}</Tag>;
      case 'SCHEDULE_FINISHED':
        return <Tag color="green">{t('pages.contracts.scheduleFinished')}</Tag>;
    }
  };

  return (
    <Layout>
      <Modal width={'70%'} open={visible} onCancel={onClose} footer={null}>
        <Descriptions
          title={t('pages.contracts.contracts')}
          column={{ xxl: 4, xl: 3, lg: 2, md: 1, sm: 1, xs: 1 }}
        >
          <Descriptions.Item
            label={<strong>{t('pages.contracts.clients')}</strong>}
          >
            {data ? data.farm.name : '---'}
          </Descriptions.Item>
          <Descriptions.Item
            label={<strong>{t('pages.contracts.stateRegistration')}</strong>}
          >
            {data ? data.farm.stateRegistration : '---'}
          </Descriptions.Item>
          <Descriptions.Item
            label={<strong>{t('pages.contracts.contracts')}</strong>}
          >
            {data ? data.referenceNumber : '---'}
          </Descriptions.Item>
          <Descriptions.Item
            label={<strong>{t('pages.contracts.status')}</strong>}
          >
            {data ? <> {scheduleStatus(data.status)} </> : '---'}
          </Descriptions.Item>
          <Descriptions.Item
            label={<strong>{t('pages.contracts.date')}</strong>}
          >
            {data ? data.contractDateString : '---'}
          </Descriptions.Item>
          <Descriptions.Item
            label={<strong>{t('pages.contracts.email')}</strong>}
          >
            {data ? data.farm.email : '---'}
          </Descriptions.Item>
          <Descriptions.Item
            label={<strong>{t('pages.contracts.phoneNumber')}</strong>}
          >
            {data ? data.farm.customer.phoneNumber : '---'}
          </Descriptions.Item>
          <Descriptions.Item
            label={<strong>{t('pages.contracts.salesmanName')}</strong>}
          >
            {data ? data.salesman?.name : '---'}
          </Descriptions.Item>
          <Descriptions.Item
            label={
              <strong>{t('pages.contracts.salesmanDocumentNumberCpf')}</strong>
            }
          >
            {data ? data.salesman?.documentNumberCpfMask : '---'}
          </Descriptions.Item>
        </Descriptions>

        {contractItems && (
          <Table
            rowKey={contractItems => contractItems.id}
            title={() => (
              <Title level={5}>{t('pages.contracts.listingCardTitle')}</Title>
            )}
            size="small"
            columns={filteredColumns}
            dataSource={contractItems}
            loading={isGetContractRequesting}
            pagination={{
              hideOnSinglePage: true,
              pageSize: pageSize,
              onChange(_, size) {
                setPageSize(size);
              },
            }}
          />
        )}
      </Modal>
    </Layout>
  );
};

export default withTranslation()(ViewContract);
