import { EyeOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import {
  Alert,
  Button,
  Card,
  Col,
  DatePicker,
  Descriptions,
  Form,
  Image,
  Input,
  Layout,
  Row,
  Select,
  Statistic,
  Table,
  Tag,
  Tooltip,
} from 'antd';
import { ColumnsType } from 'antd/lib/table';
import moment from 'moment';
import { ReactElement, useContext, useEffect, useState } from 'react';
import { TFunction, withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { v4 as uuidv4 } from 'uuid';
import menuLogo from '../../assets/logmetrics-black.svg';
import UserContext from '../../contexts/user';
import { useRequest } from '../../hooks/useRequest';
import ContractController from '../../structures/controllers/Contract';
import HomeController from '../../structures/controllers/Home';
import {
  IDashboardContractTable,
  IGetContract,
  IGetCustomerContractsResponse,
} from '../../structures/interfaces/Contract';
import { IGetOrganizationPendencies } from '../../structures/interfaces/Home';
import { scrollOptions } from '../../utils/formOptions';
import ViewContract from '../contracts/ViewContract';

const Home = ({ t }: { t: TFunction }): ReactElement<unknown> => {
  const {
    customerData,
    userData,
    filterByCreate,
    settingsPermissions,
    userPermissions,
    clientPermissions,
    contractPermissions,
    truckLoadPermissions,
    changingCropFlag,
  } = useContext(UserContext);
  const [form] = Form.useForm();
  const [getContractsRequest, isGetContractRequesting] = useRequest(
    ContractController.getContractsById
  );
  const [getCustomerContractsRequest, isGetCustomerContractRequesting] =
    useRequest(ContractController.getCustomerContracts);

  const [countTotalLoads] = useRequest(HomeController.getTotalLoads);
  const [countAuthorizedLoads] = useRequest(HomeController.getAuthorizedLoads);
  const [countWaitingDriverLoads] = useRequest(
    HomeController.getWaitingForDriverLoads
  );
  const [countLoadedLoads] = useRequest(HomeController.getLoadedLoads);
  const [countFinalizedContracts] = useRequest(
    HomeController.getFinalizedContracts
  );
  const [countCanceledLoads] = useRequest(HomeController.getCanceledLoads);
  const [countPendingContracts] = useRequest(
    HomeController.getPendingContracts
  );
  const [totalTruckLoads, setTotalTruckLoads] = useState<number>();
  const [loadedTruckLoads, setLoadedTruckLoads] = useState<number>();
  const [pendingContractsValue, setPendingContractsValue] = useState<number>();
  const [authorizedTruckLoads, setAuthorizedTruckLoads] = useState<number>();
  const [finalizedContractsValue, setFinalizedContractsValue] =
    useState<number>();
  const [canceledTruckLoads, setCanceledTruckLoads] = useState<number>();
  const [waitindDriverTruckLoads, setWaitingDriverTruckLoads] =
    useState<number>();

  const [filteredContracts, setFilteredContracts] =
    useState<IDashboardContractTable[]>();
  const [contractData, setContractData] = useState<IGetContract>();
  const [contractModalVisible, setContractModalVisible] = useState(false);
  const [contracts, setContracts] = useState<IDashboardContractTable[]>();

  const [getPendenciesRequest] = useRequest(
    HomeController.getOrganizationPendencies
  );
  const [pendencies, setPendencies] = useState<IGetOrganizationPendencies>();

  useEffect(() => {
    if (userData) {
      getPendenciesRequest(userData?.organization.id).then(value =>
        setPendencies(value)
      );
    }
  }, []);

  const { Search } = Input;
  const { Option } = Select;
  const { RangePicker } = DatePicker;

  const onReset = () => {
    return form.resetFields();
  };
  const statusMessage = (
    <div>
      {t('pages.home.promptText')}
      <p style={{ paddingTop: 24 }}>{t('pages.home.waitingSchedule')}</p>
      <p>{t('pages.home.scheduleFinished')}</p>
      <p>{t('pages.home.waitingRelease')}</p>
    </div>
  );
  const columnsContract: ColumnsType<IDashboardContractTable> = [
    {
      title: t('pages.home.clients'),
      dataIndex: ['farm', 'name'],
      key: 'farmId',
      sorter: (a, b) => a.farm.name.localeCompare(b.farm.name),
    },
    {
      title: t('pages.home.stateRegistration'),
      dataIndex: ['farm', 'stateRegistration'],
      key: 'stateRegistration',
    },
    {
      title: t('pages.home.contract'),
      dataIndex: ['referenceNumber'],
      key: 'referenceNumber',
      render: contractNumber => <Tag>{contractNumber}</Tag>,
    },
    {
      title: () => (
        <>
          {t('pages.home.status')}{' '}
          <Tooltip title={statusMessage}>
            <QuestionCircleOutlined />
          </Tooltip>
        </>
      ),
      dataIndex: ['status'],
      key: 'status',
      render: status => {
        if (status == 'WAITING_SCHEDULE') {
          return <Tag color="orange">{t('pages.home.waitingSchedule')}</Tag>;
        } else if (status == 'SCHEDULE_FINISHED') {
          return <Tag color="green">{t('pages.home.scheduleFinished')}</Tag>;
        } else if (status == 'WAITING_RELEASE') {
          return <Tag color="blue">{t('pages.home.waitingRelease')}</Tag>;
        }
      },
    },
    {
      title: t('pages.home.contractDate'),
      dataIndex: ['contractDateString'],
      sorter: {
        compare: (a, b) => {
          return moment(a.contractDate).unix() - moment(b.contractDate).unix();
        },
        multiple: 1,
      },
      sortDirections: ['descend'],
      defaultSortOrder: 'descend',
      key: 'date',
    },
    {
      title: t('pages.home.items'),
      dataIndex: ['contractItems'],
      key: 'itens',
      render: itens => <div>{itens.length}</div>,
    },
    {
      title: t('pages.home.action'),
      key: 'action',
      render: index => (
        <Button
          icon={<EyeOutlined />}
          type="link"
          onClick={() => {
            setContractModalVisible(true);
            setContractData(index);
          }}
        >
          {t('pages.home.view')}
        </Button>
      ),
    },
  ];
  const createTruckLoadPermission = filterByCreate?.find(
    permission => permission?.userPermissions.module === 'CARGAS'
  );
  const readSettings = settingsPermissions?.find(
    item => item?.userPermissions.operation === 'READ'
  );
  const readUsers = userPermissions?.find(
    item => item?.userPermissions.operation === 'READ'
  );
  const readCustomers = clientPermissions?.find(
    item => item?.userPermissions.operation === 'READ'
  );
  const readContracts = contractPermissions?.find(
    item => item?.userPermissions.operation === 'READ'
  );
  const readTruckLoads = truckLoadPermissions?.find(
    item => item?.userPermissions.operation === 'READ'
  );

  const homeContent =
    readContracts ||
    readCustomers ||
    readSettings ||
    readTruckLoads ||
    readUsers ||
    customerData;

  const buildSlug = (
    contracts: IGetContract[] | IGetCustomerContractsResponse[]
  ) =>
    contracts?.map(contract => ({
      ...contract,
      slug: `${contract.id} ${contract.contractDate} ${contract.farm.name} ${contract.farm.stateRegistration} ${contract.farm.customer.documentNumberCpf} ${contract.farm.customer.documentNumberCnpj} ${contract.referenceNumber} ${contract.status} ${contract.farm.email} ${contract.farm.customer.phoneNumber} ${contract.contractItems}`.toLowerCase(),
    }));

  const filterContractsByStatus = (
    contracts: IDashboardContractTable[],
    status?: string
  ) =>
    status != undefined
      ? contracts.filter(contract => contract.status === status)
      : contracts;

  const filterContractsByDate = (
    contracts: IDashboardContractTable[],
    date?: [moment.Moment, moment.Moment]
  ) =>
    date != undefined
      ? contracts.filter(contract => {
          return moment(contract.contractDate).isBetween(
            date[0],
            date[1],
            'day',
            '[]'
          );
        })
      : contracts;

  const getContracts = () => {
    if (userData) {
      getContractsRequest({
        organization_id: userData.organization.id,
        subordinates_filter: null,
      })
        .then(contracts => {
          const contractTableValues = buildSlug(contracts);
          setContracts(contractTableValues);
          setFilteredContracts(contractTableValues);
        })
        .catch(() => {
          <Alert
            message={t('errors.400.message')}
            description={t('errors.400.description')}
          />;
        });
    } else if (customerData) {
      getCustomerContractsRequest(customerData.id)
        .then(contracts => {
          const contractTableValues = buildSlug(contracts);
          setContracts(contractTableValues);
          setFilteredContracts(contractTableValues);
        })
        .catch(() => {
          <Alert
            message={t('errors.400.message')}
            description={t('errors.400.description')}
          />;
        });
    }
  };
  useEffect(() => {
    getContracts();
  }, [customerData, userData, changingCropFlag]);

  const onFinish = ({
    search = '',
    status,
    contractDate,
  }: {
    search?: string;
    status?: string;
    contractDate?: [moment.Moment, moment.Moment];
  }) => {
    if (contracts) {
      setFilteredContracts(() =>
        filterContractsByDate(
          filterContractsByStatus(
            contracts.filter(contract =>
              contract.slug?.includes(search.toLowerCase().trim())
            ),
            status
          ),
          contractDate
        )
      );
    }
  };
  // NOTE: Este é o filtro que libera o calendário para filtrar apenas periodos em que a regra existe
  // const disabledDate: RangePickerProps['disabledDate'] = current => {
  //   const utcDate = (date?: string) => moment(date).utc();
  //   const latestContractDate = contracts?.reduce((a, b) => {
  //     return utcDate(a.contractDate).isAfter(utcDate(b.contractDate)) ? a : b;
  //   });
  //   return (
  //     current &&
  //     current > utcDate(latestContractDate?.contractDate).endOf('day')
  //   );
  // };

  const totalLoads = () => {
    if (userData || customerData) {
      countTotalLoads(userData ? userData.organization.id : uuidv4()).then(
        (value: number) => setTotalTruckLoads(value)
      );
    }
  };
  const authorizedLoads = () => {
    if (userData || customerData) {
      countAuthorizedLoads(userData ? userData.organization.id : uuidv4()).then(
        (value: number) => setAuthorizedTruckLoads(value)
      );
    }
  };
  const waitingDriverLoads = () => {
    if (userData || customerData) {
      countWaitingDriverLoads(
        userData ? userData.organization.id : uuidv4()
      ).then((value: number) => setWaitingDriverTruckLoads(value));
    }
  };
  const loadedLoads = () => {
    if (userData || customerData) {
      countLoadedLoads(userData ? userData.organization.id : uuidv4()).then(
        (value: number) => setLoadedTruckLoads(value)
      );
    }
  };
  const finalizedContracts = () => {
    if (userData || customerData) {
      countFinalizedContracts(
        userData ? userData.organization.id : uuidv4()
      ).then((value: number) => setFinalizedContractsValue(value));
    }
  };
  const canceledLoads = () => {
    if (userData || customerData) {
      countCanceledLoads(userData ? userData.organization.id : uuidv4()).then(
        (value: number) => setCanceledTruckLoads(value)
      );
    }
  };

  const pendingContracts = () => {
    if (userData || customerData) {
      countPendingContracts(
        userData ? userData.organization.id : uuidv4()
      ).then((value: number) => setPendingContractsValue(value));
    }
  };

  useEffect(() => {
    totalLoads();
    authorizedLoads();
    waitingDriverLoads();
    loadedLoads();
    finalizedContracts();
    canceledLoads();
    pendingContracts();
  }, [userData]);

  const conditionalViewPendenciesCard = () => {
    if (customerData) false;
    if (userData) {
      if (!(pendencies?.myCompany && pendencies?.rules)) {
        return true;
      } else {
        return false;
      }
    }
  };

  return (
    <>
      {homeContent ? (
        <>
          <Layout
            style={{
              paddingTop: 24,
              paddingLeft: 24,
            }}
          >
            <Row>
              {(readTruckLoads || customerData) && (
                <Col xs={24} sm={24} md={24} lg={6} xl={4} xxl={4}>
                  <Card
                    style={{
                      marginRight: 24,
                      height: '100%',
                      width: 'auto',
                    }}
                  >
                    <Statistic
                      value={totalTruckLoads}
                      title={t('pages.home.totalLoads')}
                      groupSeparator="."
                    />
                    {(createTruckLoadPermission || customerData) && (
                      <Link to={'/cargas/formar-carga'}>
                        <Button size="small" type="primary">
                          {t('pages.home.formLoadBtn')}
                        </Button>
                      </Link>
                    )}
                  </Card>
                </Col>
              )}
              <Col
                style={{ display: 'flex', alignItems: 'stretch' }}
                xs={24}
                sm={24}
                md={24}
                lg={18}
                xl={20}
                xxl={20}
              >
                <Row>
                  {(readTruckLoads || customerData) && (
                    <Col xs={24} sm={24} md={6} lg={6} xl={4} xxl={4}>
                      <Card
                        style={{
                          marginRight: 24,
                          height: '100%',
                          width: 'auto',
                        }}
                      >
                        <Statistic
                          style={{ marginRight: 24 }}
                          title={t('pages.home.loadsWaitingForDriver')}
                          value={waitindDriverTruckLoads}
                          groupSeparator="."
                        />
                      </Card>
                    </Col>
                  )}

                  {(readTruckLoads || customerData) && (
                    <Col xs={24} sm={24} md={6} lg={6} xl={4} xxl={4}>
                      <Card
                        style={{
                          marginRight: 24,
                          height: '100%',
                          width: 'auto',
                        }}
                      >
                        <Statistic
                          style={{ marginRight: 24, wordSpacing: '9999px' }}
                          title={t('pages.home.loadsReleased')}
                          value={authorizedTruckLoads}
                          groupSeparator="."
                        />
                      </Card>
                    </Col>
                  )}

                  {(readTruckLoads || customerData) && (
                    <Col xs={24} sm={24} md={6} lg={6} xl={4} xxl={4}>
                      <Card
                        style={{
                          marginRight: 24,
                          height: '100%',
                          width: 'auto',
                        }}
                      >
                        <Statistic
                          style={{
                            marginRight: 24,
                            wordSpacing: '9999px',
                          }}
                          title={t('pages.home.loadedLoads')}
                          value={loadedTruckLoads}
                          groupSeparator="."
                        />
                      </Card>
                    </Col>
                  )}

                  {(readTruckLoads || customerData) && (
                    <Col xs={24} sm={24} md={6} lg={6} xl={4} xxl={4}>
                      <Card
                        style={{
                          marginRight: 24,
                          height: '100%',
                          width: 'auto',
                        }}
                      >
                        <Statistic
                          style={{ marginRight: 24, wordSpacing: '9999px' }}
                          title={t('pages.home.canceledLoads')}
                          value={canceledTruckLoads}
                          groupSeparator="."
                        />
                      </Card>
                    </Col>
                  )}

                  {(readContracts || customerData) && (
                    <Col xs={24} sm={24} md={6} lg={6} xl={4} xxl={4}>
                      <Card
                        style={{
                          marginRight: 24,
                          height: '100%',
                          width: 'auto',
                        }}
                      >
                        <Statistic
                          style={{ marginRight: 24, wordSpacing: '9999px' }}
                          title={t('pages.home.pendingContracts')}
                          value={pendingContractsValue}
                          groupSeparator="."
                        />
                      </Card>
                    </Col>
                  )}

                  {(readContracts || customerData) && (
                    <Col xs={24} sm={24} md={6} lg={6} xl={4} xxl={4}>
                      <Card
                        style={{
                          marginRight: 24,
                          height: '100%',
                          width: 'auto',
                        }}
                      >
                        <Statistic
                          style={{ marginRight: 24, wordSpacing: '9999px' }}
                          title={t('pages.home.finishedContracts')}
                          value={finalizedContractsValue}
                          groupSeparator="."
                        />
                      </Card>
                    </Col>
                  )}
                </Row>
              </Col>
            </Row>

            {conditionalViewPendenciesCard() && (
              <Row>
                <Card
                  title={t('pages.home.pendencies')}
                  style={{ marginTop: 24, marginRight: 24 }}
                >
                  <Descriptions
                    column={{ xxl: 5, xl: 4, lg: 3, md: 2, sm: 1, xs: 1 }}
                  >
                    <Descriptions.Item
                      label={t('pages.home.companyData')}
                      contentStyle={{ color: 'blue' }}
                    >
                      <Link to={`/empresas/${userData?.organization.id}`}>
                        {t('pages.home.pendencieLinkMyOrganization')}
                      </Link>
                    </Descriptions.Item>

                    <Descriptions.Item
                      label={t('pages.home.users')}
                      contentStyle={{ color: 'blue' }}
                    >
                      <Link to={`/usuarios`}>
                        {t('pages.home.pendencieLinkMyUsers')}
                      </Link>
                    </Descriptions.Item>

                    <Descriptions.Item
                      label={t('pages.home.clients')}
                      contentStyle={{ color: 'blue' }}
                    >
                      <Link to={`/clientes`}>
                        {t('pages.home.pendencieLinkMyClients')}
                      </Link>
                    </Descriptions.Item>

                    <Descriptions.Item
                      label={t('pages.home.schedulingRules')}
                      contentStyle={{ color: 'blue' }}
                    >
                      <Link to={`/regras/cadastro`}>
                        {t('pages.home.pendencieLinkScheduleRules')}
                      </Link>
                    </Descriptions.Item>

                    <Descriptions.Item
                      label={t('pages.home.contracts')}
                      contentStyle={{ color: 'blue' }}
                    >
                      <Link to={`/contratos`}>
                        {t('pages.home.pendencieLinkMyContracts')}
                      </Link>
                    </Descriptions.Item>
                  </Descriptions>
                </Card>
              </Row>
            )}

            {(readContracts || customerData) && (
              <Card style={{ marginRight: 24, marginTop: 24 }}>
                <Form
                  scrollToFirstError={scrollOptions}
                  form={form}
                  layout="vertical"
                  onFinish={onFinish}
                >
                  <Row gutter={[24, 0]}>
                    <Col xs={24} sm={24} md={24} lg={8} xl={6} xxl={4}>
                      <Form.Item name="search" label={' '}>
                        <Search
                          enterButton
                          onSearch={() => form.submit()}
                          size="small"
                          placeholder={t('pages.home.searchItens')}
                        />
                      </Form.Item>
                    </Col>

                    <Col xs={24} sm={24} md={12} lg={8} xl={5} xxl={4}>
                      <Form.Item
                        name="status"
                        label={t('pages.home.statusLabel')}
                      >
                        <Select
                          size="small"
                          allowClear
                          placeholder={t('pages.home.selectStatus')}
                        >
                          <Option value="WAITING_SCHEDULE">
                            {t('pages.home.waitingSchedule')}
                          </Option>
                          <Option value="SCHEDULE_FINISHED">
                            {t('pages.home.scheduleFinished')}
                          </Option>
                          <Option value="WAITING_RELEASE">
                            {t('pages.home.waitingRelease')}
                          </Option>
                        </Select>
                      </Form.Item>
                    </Col>

                    <Col xs={24} sm={24} md={12} lg={8} xl={6} xxl={4}>
                      <Form.Item
                        name="contractDate"
                        label={t('pages.home.contractDateLabel')}
                      >
                        <RangePicker
                          size="small"
                          picker="date"
                          format="DD/MM/YYYY"
                          style={{ display: 'flex' }}
                          allowClear
                        />
                      </Form.Item>
                    </Col>

                    <Col
                      xs={24}
                      sm={24}
                      md={24}
                      lg={24}
                      xl={7}
                      xxl={12}
                      style={{
                        display: 'flex',
                        width: '100%',
                        justifyContent: 'end',
                        alignItems: 'end',
                      }}
                    >
                      <Form.Item style={{ marginRight: 8 }}>
                        <Button size="small" onClick={onReset}>
                          {t('pages.home.cleanFilter')}
                        </Button>
                      </Form.Item>

                      <Form.Item>
                        <Button size="small" type="primary" htmlType="submit">
                          {t('pages.home.filter')}
                        </Button>
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </Card>
            )}

            {(readContracts || customerData) && (
              <Card
                title={t('pages.home.contracts')}
                style={{ marginTop: 24, marginRight: 24, marginBottom: 24 }}
              >
                <Table
                  rowKey={contract => contract.id}
                  columns={columnsContract}
                  dataSource={filteredContracts}
                  loading={
                    isGetCustomerContractRequesting || isGetContractRequesting
                  }
                  scroll={{ x: 1300 }}
                  size="small"
                />
              </Card>
            )}
          </Layout>
          {contractData && (
            <ViewContract
              visible={contractModalVisible}
              onClose={() => setContractModalVisible(false)}
              data={contractData}
            />
          )}
        </>
      ) : (
        <div
          style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-around',
            textAlign: 'center',
          }}
        >
          <Image
            style={{ width: 500, height: 200, opacity: 0.2 }}
            src={menuLogo}
            preview={false}
          />
        </div>
      )}
    </>
  );
};

export default withTranslation()(Home);
