import { ReactElement, SVGProps } from 'react';

const TruckOutlined = (props: SVGProps<SVGSVGElement>): ReactElement => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" {...props}>
    <g data-name="Grupo 17009">
      <path
        fill="rgba(255,255,255, 0.65)"
        d="M10.818 11.155a1.031 1.031 0 1 0-.955-1.028.992.992 0 0 0 .955 1.028m.955-6.169h-1.591V6.7h2.838l-1.247-1.713m-8.591 6.168a1.031 1.031 0 0 0 0-2.056 1.031 1.031 0 0 0 0 2.056m8.909-7.2L14 6.7v3.427h-1.273a1.984 1.984 0 0 1-1.909 2.056 1.984 1.984 0 0 1-1.909-2.056H5.091a1.984 1.984 0 0 1-1.909 2.056 1.984 1.984 0 0 1-1.909-2.056H0V2.588a1.32 1.32 0 0 1 1.273-1.371h8.909v2.742h1.909M1.273 2.588v6.169h.484a1.825 1.825 0 0 1 2.851 0h4.3V2.588Z"
      />
    </g>
  </svg>
);

export default TruckOutlined;
