import { Button, Modal } from 'antd';
import { TFunction } from 'i18next';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import { IGetCadences } from '../../../../structures/interfaces/TruckLoad';

interface InformWhiteSeedsModal {
  t: TFunction;
  visible: boolean;
  onClose: () => void;
  onConfirm: () => void;
  treatmentDay: IGetCadences | undefined;
}

const InformWhiteSeedsDay = ({
  visible,
  onClose,
  t,
  treatmentDay,
}: InformWhiteSeedsModal) => {
  const baseString =
    'pages.truckLoads.createLoad.stepOne.whiteSeedsSelectedDayModal';
  const footerModal = [
    <Button onClick={onClose} danger>
      {t(`${baseString}.cancelBtn`)}
    </Button>,
    <Button onClick={onClose} type="primary">
      {t(`${baseString}.confirmBtn`)}
    </Button>,
  ];

  return (
    <Modal
      style={{ marginTop: '10%' }}
      title={t(`${baseString}.title`)}
      width={'40%'}
      open={visible}
      onCancel={onClose}
      footer={footerModal}
    >
      {t(`${baseString}.content`)}
      {moment(treatmentDay?.startTime).format('D [de] MMMM')}
    </Modal>
  );
};

export default withTranslation()(InformWhiteSeedsDay);
