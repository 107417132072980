import { PlusOutlined, TableOutlined } from '@ant-design/icons';
import {
  Alert,
  Button,
  Card,
  Col,
  Descriptions,
  Divider,
  Form,
  InputNumber,
  Layout,
  Modal,
  PageHeader,
  Row,
  Table,
  Tag,
  Tooltip,
  message,
} from 'antd';
import { ColumnsType } from 'antd/lib/table';
import moment from 'moment';
import { DefaultRecordType } from 'rc-table/lib/interface';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { TFunction, Trans, withTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import Formatter from '../../classes/Formatter';
import Normalizer from '../../classes/Normalizer';
import UserContext from '../../contexts/user';
import { useRequest } from '../../hooks/useRequest';
import BreadCrumb from '../../structures/controllers/Breadcrumb';
import TruckLoadController from '../../structures/controllers/TruckLoad';
import {
  INewShippingCompany,
  IRegisterShippingCompany,
  IUpdateShippingCompany,
} from '../../structures/interfaces/Company/Company';
import {
  IGetContractItems,
  IGetVehicles,
  IUpdateTruckLoadInput,
  IViewTruckLoad,
  IViewTruckLoadDriver,
  IViewTruckLoadItem,
} from '../../structures/interfaces/TruckLoad';
import { scrollOptions } from '../../utils/formOptions';
import { IErrorMessage } from '../../utils/interfaces';
import AuthorizationCard from './components/AuthorizationCard';
import DriverFields from './components/DriverFields';
import SynchronizationCard from './components/SynchronizationCard';
import TruckImage from './components/TruckImage';
import TruckLoadDescription from './components/TruckLoadDescription';
import TruckLoadEdition from './components/TruckLoadEdition';

const { Item } = Descriptions;

const colors = [
  '#C97A6D',
  '#7AC97D',
  '#7A6DC9',
  '#C9BC7A',
  '#C97AA6',
  '#A17AC9',
  '#7AC9A1',
  '#7A9BC9',
  '#C96D7A',
  '#7AC9BD',
  '#C97A68',
  '#7A91B3',
  '#B38A7A',
  '#7AB3B0',
];

function ViewLoad({
  t,
  ...otherProps
}: {
  t: TFunction;
  match: { params: { id: string } };
}) {
  const baseString = 'pages.truckLoads';

  const encodedCachedLoadedamount = localStorage.getItem('loadedAmount');
  const cachedLoadedAmount = encodedCachedLoadedamount
    ? JSON.parse(window.atob(encodedCachedLoadedamount))
    : null;

  const [formDriver] = Form.useForm();
  const [formLoadedAmount] = Form.useForm();

  const editItemsContainerRef = useRef<HTMLDivElement>(null);
  const authorizeContainerRef = useRef<HTMLDivElement>(null);
  const driverContainerRef = useRef<HTMLDivElement>(null);

  const [refToScroll, setRefToScroll] =
    useState<React.RefObject<HTMLDivElement>>();

  const { userData, customerData } = useContext(UserContext);

  const { state = {} }: { state: { truckLoadId?: string; status?: string } } =
    useLocation();
  const { status: truckLoadPassedStatus } = state;
  const truckLoadId = state.truckLoadId || otherProps.match.params.id;

  const [fethTruckLoad, fetchingTruckLoad] = useRequest(
    TruckLoadController.getTruckLoad
  );
  const [updateTruckLoad, isUpdateTruckLoadRequesting] = useRequest(
    TruckLoadController.updateTruckLoad
  );
  const [fetchVehicles] = useRequest(TruckLoadController.getVehicles);
  const [fetchAvailableDates] = useRequest(
    TruckLoadController.getTruckLoadAvailableDates
  );
  const [authorizeTruckLoad] = useRequest(
    TruckLoadController.authorizeTruckLoad
  );
  const [updateTruckLoadWithTruckLoadItems, isUpdatingItems] = useRequest(
    TruckLoadController.UpdateTruckLoadWithTruckLoadItems
  );

  const history = useHistory();

  const [vehicles, setVehicles] = useState<IGetVehicles[]>([]);
  const [shippingCompanies, setShippingCompanies] = useState<
    INewShippingCompany[]
  >([]);
  const [truckLoadData, setTruckLoadData] = useState<IViewTruckLoad>();
  const [truckLoadItems, setTruckLoadItems] = useState<IViewTruckLoadItem[]>(
    []
  );
  const [loadAll, setLoadAll] = useState(false);
  const [colorsAssigned, setColorsAssigned] = useState({});
  const [driverEditing, setDriverEditing] = useState(false);
  const [openRegShippingCompany, setOpenRegShippingCompany] = useState(false);
  const [itemsEditing, setItemsEditing] = useState(false);
  const [editItemsContainerFixed, setEditItemsContainerFixed] = useState(false);
  const [hoveredBoxIndex, setHoveredBoxIndex] = useState<number>();
  const [hoveredRowIndex, setHoveredRowIndex] = useState<number>();
  const [availableDates, setAvailableDates] = useState<moment.Moment[]>([]);
  const [pickupDateUpdating, setPickupDateUpdating] = useState(false);
  const [driverUpdating, setDriverUpdating] = useState(false);
  const [hasScrolled, setHasScrolled] = useState(false);
  const [driverInitialValues, setDriverInitialValues] = useState<
    IViewTruckLoadDriver | undefined
  >();
  const [isSynchronizingTruckLoad, setIsSynchronizingTruckLoad] =
    useState(false);
  const [createShippingCompany, setCreateShippingCompany] =
    useState<IRegisterShippingCompany>();
  const [updateShippingCompany, setUpdateShippingCompany] =
    useState<IUpdateShippingCompany>();

  const [loadingCargoInTruck, setLoadingCargoInTruck] = useState(
    !!cachedLoadedAmount
  );
  const [selectedDriverVehicle, setSelectedDriverVehicle] = useState<string>();
  const [isWrongVehicle, setIsWrongVehicle] = useState(false);

  const isManager = truckLoadData?.manager === customerData?.id || !!userData;

  const canUpdateTruckLoadItems =
    !!truckLoadData &&
    !['LOADED', 'CANCELED', 'COMPLETED', 'EXPIRED'].includes(
      truckLoadData.status
    ) &&
    isManager &&
    !(
      // isManager &&
      (customerData && !['WAITING_DRIVER'].includes(truckLoadData.status))
    );

  const Flag = false;

  const selectedVehicleData = vehicles.find(
    vehicle => vehicle.id === selectedDriverVehicle
  );

  const allowPerms = {
    updateScheduleDate:
      !!truckLoadData &&
      ['WAITING_DRIVER', 'WAITING_RELEASE', 'WAITING_ACTIONS'].includes(
        truckLoadData.status
      ) &&
      isManager &&
      !(
        // isManager &&
        (customerData && !['WAITING_DRIVER'].includes(truckLoadData.status))
      ),
    createDriver:
      !!truckLoadData && truckLoadData.status === 'WAITING_DRIVER' && isManager,
    updateDriver:
      !!truckLoadData &&
      !['LOADED', 'CANCELED', 'COMPLETED'].includes(truckLoadData.status) &&
      isManager &&
      !(
        // isManager &&
        (customerData && !['WAITING_DRIVER'].includes(truckLoadData.status))
      ),
    authorizeLoad:
      !customerData &&
      !!truckLoadData &&
      truckLoadData.status === 'WAITING_RELEASE' &&
      isManager,
    authorizedLoad:
      !customerData &&
      !!truckLoadData &&
      [
        'LOADED',
        'LOADING',
        'COMPLETED',
        'AUTHORIZED_LOAD',
        'CANCELED',
        'WAITING_ACTIONS',
      ].includes(truckLoadData.status),
    updateTruckLoadItems: canUpdateTruckLoadItems,
    removeTruckLoadItems:
      canUpdateTruckLoadItems &&
      (!!userData ||
        Flag ||
        (!!selectedVehicleData &&
          truckLoadData.description.estimatedWeight >
            selectedVehicleData?.weightCapacity)),
    addTruckLoadItems:
      canUpdateTruckLoadItems &&
      (!!userData ||
        Flag ||
        (!!selectedVehicleData &&
          truckLoadData.description.estimatedWeight <
            selectedVehicleData?.weightCapacity)),
    synchronizeTruckLoad:
      truckLoadData &&
      isManager &&
      !customerData &&
      [
        'LOADED',
        'LOADING',
        'COMPLETED',
        'AUTHORIZED_LOAD',
        'CANCELED',
        'WAITING_ACTIONS',
      ].includes(truckLoadData.status),
  };

  const getVehicleData = () => {
    return vehicles.find(vhcl => vhcl.id === selectedDriverVehicle);
  };

  const isLoadOverVehicleCapacity = () => {
    const vehicleData = getVehicleData();
    if (vehicleData) {
      return (
        !!truckLoadData?.description.estimatedWeight &&
        truckLoadData?.description.estimatedWeight > vehicleData.weightCapacity
      );
    }
    return false;
  };

  const isLoadUnderCapacity = () => {
    const vehicleData = getVehicleData();
    if (vehicleData) {
      return (
        !!truckLoadData?.description.estimatedWeight &&
        truckLoadData?.description.estimatedWeight < vehicleData.weightCapacity
      );
    }
    return false;
  };

  const renderCultivationTags = (cultivation: string, id: string) => {
    const color =
      colorsAssigned[id as keyof typeof colorsAssigned] || colors[0];

    return <Tag color={color}>{cultivation}</Tag>;
  };

  const filteredTruckLoadItems = () => {
    if (customerData) {
      return truckLoadItems.filter(tli => tli.customerId === customerData?.id);
    } else {
      return truckLoadItems;
    }
  };

  const renderPickupAmountInput = (data: IViewTruckLoadItem) => {
    if (truckLoadData?.status === 'LOADED') return data.loadedAmount;

    const initialValue = cachedLoadedAmount
      ? cachedLoadedAmount[data.id]
      : data.loadedAmount;

    return (
      <Form.Item noStyle name={data.id} initialValue={initialValue}>
        <InputNumber
          onBlur={() => {
            const inputValue = formLoadedAmount.getFieldValue([data.id]);
            if (!inputValue) formLoadedAmount.resetFields([data.id]);
          }}
          className="input-number-visible-controls"
          placeholder={t(`${baseString}.createLoad.placeholders.quantity`)}
          min={0}
          max={data.allocatedAmount}
          controls={true}
          style={{
            pointerEvents: allowPerms.updateTruckLoadItems ? 'all' : 'none',
          }}
          onChange={val => {
            if (val !== null && !Number.isNaN(val)) {
              const encryptedData = window.btoa(
                JSON.stringify(formLoadedAmount.getFieldsValue())
              );
              localStorage.setItem('loadedAmount', encryptedData);
              setLoadingCargoInTruck(true);
            }
          }}
        />
      </Form.Item>
    );
  };

  const columnsTruckLoadItems = [
    {
      title: t(`${baseString}.createLoad.createdLoads.columns.order`),
      key: 'order',
      dataIndex: 'order',
      render: (_: string, __: string, index: number) =>
        `${filteredTruckLoadItems().length - index}ª desc`,
    },
    {
      title: t(`${baseString}.createLoad.contractItems.columns.client`),
      key: 'client',
      dataIndex: 'client',
    },
    {
      title: t(`${baseString}.createLoad.contractItems.columns.stateRegister`),
      key: 'stateRegistration',
      dataIndex: 'stateRegistration',
    },
    {
      title: t(`${baseString}.createLoad.contractItems.columns.contract`),
      key: 'contractNumber',
      dataIndex: 'contractNumber',
    },
    {
      title: t(`${baseString}.createLoad.contractItems.columns.date`),
      key: 'contractDate',
      dataIndex: 'contractDate',
      render: (date: string) => Formatter.formatDate(date),
      sorter: (a: IViewTruckLoadItem, b: IViewTruckLoadItem) =>
        new Date(a.contractDate).valueOf() - new Date(b.contractDate).valueOf(),
    },
    {
      title: t(`${baseString}.createLoad.contractItems.columns.id`),
      key: 'referenceNumber',
      dataIndex: 'referenceNumber',
    },
    {
      title: t(`${baseString}.createLoad.contractItems.columns.cultivation`),
      key: 'cultivation',
      dataIndex: 'cultivation',
      render: (
        cultivation: string,
        { contractItemId }: { contractItemId: string }
      ) => renderCultivationTags(cultivation, contractItemId),
    },
    {
      title: t(`${baseString}.createLoad.contractItems.columns.sieve`),
      key: 'sieve',
      dataIndex: 'sieve',
    },
    {
      title: t(`${baseString}.createLoad.contractItems.columns.category`),
      key: 'category',
      dataIndex: 'category',
    },
    {
      title: t(`${baseString}.createLoad.contractItems.columns.package`),
      key: 'packing',
      dataIndex: 'packing',
    },
    {
      title: t(`${baseString}.createLoad.contractItems.columns.tsi`),
      key: 'tsi',
      dataIndex: 'tsi',
      render: (tsi: boolean) => (tsi ? 'Sim' : 'Não'),
    },
    {
      title: t(`${baseString}.createLoad.contractItems.columns.weight`),
      key: 'estimatedWeight',
      dataIndex: 'estimatedWeight',
      sorter: (a: IViewTruckLoadItem, b: IViewTruckLoadItem) =>
        a.estimatedWeight - b.estimatedWeight,
    },
    {
      title: t(`${baseString}.createLoad.createdLoads.columns.allocated`),
      key: 'allocatedAmount',
      dataIndex: 'allocatedAmount',
    },
    {
      title: t(`${baseString}.createLoad.createdLoads.columns.loaded`),
      key: 'loadTruck',
      dataIndex: '',
      render: (_: string, data: IViewTruckLoadItem) => {
        if (loadAll) {
          formLoadedAmount.setFieldsValue({ [data.id]: 1 });
          const encryptedData = window.btoa(
            JSON.stringify(formLoadedAmount.getFieldsValue())
          );
          localStorage.setItem('loadedAmount', encryptedData);
          setLoadAll(false);
          setLoadingCargoInTruck(true);
        }
        return renderPickupAmountInput(data);
      },
      fixed: 'right',
    },
  ];

  const filteredColumns = columnsTruckLoadItems.filter(column => {
    if (customerData) {
      return (
        column.key !== 'category' &&
        column.key !== 'sieve' &&
        column.key !== 'loadTruck' &&
        column.key !== 'org' &&
        column.key !== 'tsi' &&
        column.key !== 'estimatedWeight' &&
        column.key !== 'referenceNumber' &&
        column.key !== 'packing' &&
        column.key !== 'category'
      );
    } else {
      return (
        column.key !== 'tsi' &&
        column.key !== 'estimatedWeight' &&
        column.key !== 'referenceNumber' &&
        column.key !== 'packing' &&
        column.key !== 'category'
      );
    }
  });

  const showError = (error: IErrorMessage) => {
    message.error({
      content: t(error.message),
      style: { marginTop: 64 },
    });
  };

  const doTruckLoadUpdate = (
    input: IUpdateTruckLoadInput,
    callback: () => void = () => null
  ) => {
    if (truckLoadId && (userData || customerData)) {
      updateTruckLoad({
        truckLoadId: truckLoadId,
        input: input,
      })
        .then(() => {
          message.success({
            content: t(`${baseString}.viewLoad.updateSuccess`),
            duration: 5,
            style: {
              marginTop: 64,
            },
          });
          callback();
          setLoadingCargoInTruck(false);
        })
        .catch(err => {
          showError(err);
        })
        .finally(() => {
          setPickupDateUpdating(false);
          setItemsEditing(false);
          setDriverUpdating(false);
          setDriverEditing(false);
        });
    }
  };

  const cancelItemsChange = () => {
    if (truckLoadData) {
      setTruckLoadItems(truckLoadData.items);
      formLoadedAmount.resetFields();
    }
    setLoadingCargoInTruck(false);
  };

  const handleItemsChanged = (addedItems: IGetContractItems[]) => {
    const itemsInputFormat = addedItems.map((item, index) => ({
      where: { truck_load_item_id: item.id },
      input: {
        load_sort: index,
        loaded_amount:
          formLoadedAmount.getFieldsValue()[item.id as keyof typeof item],
      },
    }));

    doTruckLoadUpdate(
      {
        update_truck_load_items: itemsInputFormat,
      },
      () => {
        localStorage.removeItem('loadedAmount');
        setItemsEditing(false);
        doTruckLoadRequest();
      }
    );
  };

  const handleDriverSubmit = () => {
    if (!driverEditing && !allowPerms.createDriver) {
      setDriverEditing(true);
      return;
    }

    formDriver.validateFields().then(values => {
      const {
        driver,
        driverDocument,
        vehicle,
        licensePlate,
        phoneNumber,
        email,
        driverObservation,
        shippingCompanyId,
        shippingCompanyName,
        shippingCompanyCnpj,
        shippingCompanyEmail,
        shippingCompanyPhoneNumber,
        shippingCompanyTradeName,
      } = values;

      const driverInput = {
        name: driver,
        document_number_cpf: Normalizer.onlyNumbers(driverDocument),
        vehicle_id: vehicle.value ? vehicle.value : vehicle,
        vehicle_license_plate: licensePlate.toUpperCase(),
        phone_number: Normalizer.onlyNumbers(phoneNumber),
        email: email,
        note: driverObservation,
      };

      const shippingCompanyInput = {
        company_name: shippingCompanyName,
        document_number_cnpj: Normalizer.onlyNumbers(shippingCompanyCnpj),
        email: shippingCompanyEmail,
        phone_number: Normalizer.onlyNumbers(shippingCompanyPhoneNumber),
        trade_name: shippingCompanyTradeName,
      };

      setDriverUpdating(true);

      if (allowPerms.createDriver && truckLoadId) {
        doTruckLoadUpdate(
          {
            shipping_company_id: shippingCompanyId,
            create_driver: driverInput,
            shipping_company_data: shippingCompanyInput,
          },
          () => {
            doTruckLoadRequest();
            formDriver.resetFields();
            setSelectedDriverVehicle(undefined);
          }
        );
      } else if (
        allowPerms.updateDriver &&
        truckLoadId &&
        (userData?.id || customerData)
      ) {
        doTruckLoadUpdate(
          {
            update_driver: driverInput,
            shipping_company_id: shippingCompanyId,
            shipping_company_data: shippingCompanyInput,
          },
          () => setSelectedDriverVehicle(undefined)
        );
      }
    });
  };

  const handleDateChange = (date: moment.Moment) => {
    setPickupDateUpdating(true);
    doTruckLoadUpdate({
      pick_up_date: date.format('YYYY-MM-DD'),
    });
  };

  const doTruckLoadRequest = () => {
    if (truckLoadId) {
      fethTruckLoad({ truckLoadId }).then(resp => {
        setTruckLoadData(resp);
        setDriverInitialValues({
          cpf: resp.driver?.cpf,
          email: resp.driver?.email,
          licensePlate: resp.driver?.licensePlate,
          name: resp.driver?.name,
          note: resp.driver?.note,
          phoneNumber: resp.driver?.phoneNumber,
          vehicleId: resp.driver?.vehicleId,
          vehicleModel: resp.driver?.vehicleModel,
          shippingCompany: resp.shippingCompany,
        });

        if (resp.status === 'WAITING_DRIVER') {
          setDriverEditing(true);
        }

        setTruckLoadItems(resp.items);

        const formattedItems = resp.items.map(item => ({
          ...item,
          cultivation: `${item.cultivation}`,
        }));
        const newColors: { [key: string]: string } = {};

        formattedItems.forEach(
          ({ contractItemId }: { contractItemId: string }) => {
            if (!newColors[contractItemId as keyof typeof colorsAssigned]) {
              let colorIndex = Object.keys(newColors).length;

              if (colorIndex > colors.length - 1) {
                colorIndex -=
                  Math.floor(colorIndex / colors.length) * colors.length;
              }

              newColors[contractItemId] = colors[colorIndex];
            }
          }
        );

        setColorsAssigned(newColors);

        fetchAvailableDates({ preScheduleId: resp.preScheduleId })
          .then(setAvailableDates)
          .catch(err => showError(err));
      });
    }
  };

  useEffect(() => {
    const organizationId = userData
      ? userData.organization.id
      : customerData?.organizationId;
    if (organizationId) {
      fetchVehicles({
        organizationId: organizationId,
        takeCommonVehicles: true,
      }).then(setVehicles);
      doTruckLoadRequest();
    }
  }, []);

  useEffect(() => {
    doTruckLoadRequest();
  }, [driverEditing]);

  const handleBoxHover = (index: number) => {
    if (index === -1) {
      setHoveredBoxIndex(undefined);
    } else {
      setHoveredBoxIndex(index);
    }
  };

  const scrollTo = (ref: React.RefObject<HTMLDivElement>) => {
    if (ref.current) {
      ref.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
      setHasScrolled(true);
    }
  };

  useEffect(() => {
    if (truckLoadItems.length && !hasScrolled) {
      if (truckLoadPassedStatus === 'WAITING_RELEASE') {
        setRefToScroll(authorizeContainerRef);
      } else if (truckLoadPassedStatus === 'WAITING_DRIVER') {
        setRefToScroll(driverContainerRef);
      }
    }
  }, [truckLoadItems, authorizeContainerRef, driverContainerRef]);

  useEffect(() => {
    refToScroll && scrollTo(refToScroll);
  }, [refToScroll]);

  const handleScroll = () => {
    if (itemsEditing && editItemsContainerRef.current?.previousElementSibling) {
      const isPinned =
        editItemsContainerRef.current.offsetTop >=
        editItemsContainerRef.current.previousElementSibling.clientHeight;
      if (isPinned !== editItemsContainerFixed) {
        setEditItemsContainerFixed(isPinned);
      }
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [editItemsContainerRef, editItemsContainerFixed, loadingCargoInTruck]);

  const handleAuthorization = () => {
    if (truckLoadId) {
      authorizeTruckLoad({ truckLoadId })
        .then(() => {
          doTruckLoadRequest();
        })
        .catch(err => showError(err));
    }
  };

  const handleModalCancel = () => {
    setItemsEditing(false);
  };

  const handleModalOk = (
    addedItems: IGetContractItems[],
    removedItems: string[]
  ) => {
    updateTruckLoadWithTruckLoadItems({
      truckLoadId,
      truckLoadItems: addedItems,
      removedItems,
    })
      .then(() => {
        setItemsEditing(false);
        message.success({
          content: t(`${baseString}.viewLoad.updateSuccess`),
          style: { marginTop: 64 },
        });
        doTruckLoadRequest();
      })
      .catch(showError);
  };

  const renderDriverFieldButton = () => {
    if (allowPerms.createDriver) {
      return 'Adicionar';
    }

    return driverEditing ? 'Salvar' : 'Editar';
  };

  const onRowHover = (record: DefaultRecordType, index?: number) => {
    if (index === -1) {
      setHoveredRowIndex(undefined);
    } else {
      if (index !== undefined) {
        setHoveredRowIndex(filteredTruckLoadItems().length - index - 1);
      }
    }
  };

  useEffect(() => {
    if (selectedDriverVehicle) {
      selectedDriverVehicle === truckLoadData?.description.vehicleId
        ? setIsWrongVehicle(false)
        : setIsWrongVehicle(true);

      // if (isLoadUnderCapacity()) {
      //   Modal.confirm({
      //     title: 'Carga abaixo do peso máximo',
      //     content:
      //       'O veículo selecionado suporta mais itens de carga. Deseja adicionar mais itens?',
      //     onOk: () => {
      //       setItemsEditing(true);
      //     },
      //     okText: 'Sim',
      //     cancelText: 'Não',
      //   });
      // } else if (isLoadOverVehicleCapacity()) {
      //   Modal.warning({
      //     title: 'Carga acima do peso máximo',
      //     content:
      //       'O veículo selecionado suporta menos itens de carga. Remova items para continuar.',
      //     onOk: () => {
      //       setItemsEditing(true);
      //     },
      //     okText: 'Ok',
      //   });
      // }
    }
  }, [selectedDriverVehicle, truckLoadData]);

  const getVehicleWeightCapacity = () => {
    if (selectedVehicleData) return selectedVehicleData.weightCapacity;

    if (truckLoadData?.driver) {
      const driverVehicle = vehicles.find(
        vehicle => vehicle.id === truckLoadData.driver?.vehicleId
      );
      if (driverVehicle) return driverVehicle?.weightCapacity;
    }

    return truckLoadData?.description.vehicleWeightCapacity;
  };

  const getVehicleBagsCapacity = () => {
    if (selectedVehicleData) return selectedVehicleData.bagsCapacity;

    if (truckLoadData?.driver) {
      const driverVehicle = vehicles.find(
        vehicle => vehicle.id === truckLoadData.driver?.vehicleId
      );
      if (driverVehicle) return driverVehicle?.bagsCapacity;
    }

    return truckLoadData?.description.vehicleBagsCapacity;
  };

  const renderLoadAllButton =
    truckLoadData?.status === 'WAITING_DRIVER' ||
    truckLoadData?.status === 'AUTHORIZED_LOAD' ||
    truckLoadData?.status === 'LOADED' ? (
      <Button
        type="primary"
        icon={<TableOutlined />}
        onClick={() => setLoadAll(true)}
        children={'Carregar tudo'}
        disabled={truckLoadData?.status === 'LOADED'}
      />
    ) : null;

  return (
    <Layout style={{ minHeight: '100%' }}>
      <PageHeader
        className="site-page-header"
        title={t(`${baseString}.viewLoad.title`)}
        subTitle={t(`${baseString}.viewLoad.subtitle`)}
        ghost={false}
        breadcrumb={<BreadCrumb lastName={'Detalhes'} />}
      />
      <Row gutter={[24, 24]} style={{ padding: '24px 12px', margin: 0 }}>
        <Col span={24}>
          <Card
            loading={fetchingTruckLoad}
            title={t(`${baseString}.viewLoad.cardTitles.descriptions`)}
          >
            {truckLoadData?.description && (
              <TruckLoadDescription
                alocatedItems={truckLoadItems.length}
                items={truckLoadData.description}
                isEdition
                onDateChange={handleDateChange}
                availableDates={
                  allowPerms.updateScheduleDate && !isSynchronizingTruckLoad
                    ? availableDates
                    : []
                }
                pickupDateUpdating={pickupDateUpdating}
                isUpdateAvailable={allowPerms.updateScheduleDate}
              />
            )}
          </Card>
        </Col>
        {allowPerms.updateDriver &&
          allowPerms.updateScheduleDate &&
          allowPerms.updateTruckLoadItems && (
            <Col span={24}>
              <Alert
                type="warning"
                message={
                  <Trans
                    i18nKey={`${baseString}.createLoad.warnings.createdLoad`}
                  />
                }
              />
            </Col>
          )}
        <Col span={24}>
          <Card
            title={t(`${baseString}.viewLoad.cardTitles.load`)}
            loading={fetchingTruckLoad || isSynchronizingTruckLoad}
            extra={renderLoadAllButton}
            // extra={
            //   allowPerms.updateTruckLoadItems && (
            //     <Button
            //       onClick={() => setItemsEditing(true)}
            //       loading={isSynchronizingTruckLoad}
            //       disabled={isSynchronizingTruckLoad}
            //     >
            //       Editar
            //     </Button>
            //   )
            // }
          >
            <Row>
              <Col span={3} xs={0} sm={0} md={0} lg={3}>
                <TruckImage
                  colorsAssigned={colorsAssigned}
                  truckLoadItems={filteredTruckLoadItems()}
                  onBoxHover={handleBoxHover}
                  hoveredRow={hoveredRowIndex}
                />
              </Col>
              <Col span={21} xs={24} sm={24} md={24} lg={21}>
                <Form
                  scrollToFirstError={scrollOptions}
                  form={formLoadedAmount}
                >
                  <Table
                    pagination={false}
                    dataSource={filteredTruckLoadItems()}
                    columns={filteredColumns as ColumnsType<DefaultRecordType>}
                    scroll={{ x: true }}
                    size="small"
                    rowClassName={(record, index: number) => {
                      if (index === hoveredBoxIndex)
                        return 'truck-load-item-row hovered-box';
                      return 'truck-load-item-row';
                    }}
                    onRow={(record, index) => {
                      const attr = {
                        index,
                        onMouseLeave: () =>
                          onRowHover ? onRowHover(record, -1) : null,
                        onMouseEnter: () =>
                          onRowHover ? onRowHover(record, index) : null,
                      };
                      return attr;
                    }}
                  />
                </Form>
                {loadingCargoInTruck && (
                  <div
                    style={{
                      width: '100%',
                      background: '#fff',
                      height: 60,
                      padding: 12,
                      position: 'sticky',
                      bottom: 0,
                      boxShadow: editItemsContainerFixed
                        ? 'none'
                        : 'rgba(0, 0, 0, 0.15) 0px -8px 8px -4px',
                      transition: 'all .2s ease',
                      zIndex: 2,
                    }}
                    ref={editItemsContainerRef}
                  >
                    <div style={{ float: 'right' }}>
                      <Button
                        style={{ marginRight: 12 }}
                        onClick={cancelItemsChange}
                        loading={
                          isSynchronizingTruckLoad ||
                          isUpdateTruckLoadRequesting
                        }
                      >
                        {t(`${baseString}.createLoad.buttons.cancel`)}
                      </Button>
                      <Button
                        type="primary"
                        loading={
                          isSynchronizingTruckLoad ||
                          isUpdateTruckLoadRequesting
                        }
                        onClick={() =>
                          handleItemsChanged(
                            truckLoadItems as unknown as IGetContractItems[]
                          )
                        }
                      >
                        {t(`${baseString}.createLoad.buttons.saveChanges`)}
                      </Button>
                    </div>
                  </div>
                )}
              </Col>
            </Row>
          </Card>
        </Col>
        <Col span={24} ref={driverContainerRef}>
          <Card
            loading={fetchingTruckLoad}
            title={t(`${baseString}.viewLoad.cardTitles.driver`)}
            style={{
              boxShadow:
                truckLoadPassedStatus === 'WAITING_DRIVER' &&
                !truckLoadData?.driver &&
                allowPerms.updateDriver
                  ? '0 0 15px #1890ff77'
                  : 'none',
            }}
            extra={
              driverEditing ? (
                <Button
                  onClick={() => {
                    setOpenRegShippingCompany(true);
                  }}
                  type="primary"
                >
                  <PlusOutlined /> Cadastrar nova transportada
                </Button>
              ) : null
            }
          >
            <Form
              scrollToFirstError={scrollOptions}
              form={formDriver}
              layout="vertical"
              onFinish={handleDriverSubmit}
            >
              <Row gutter={[24, 0]}>
                {allowPerms.createDriver ||
                (driverEditing && allowPerms.updateDriver) ? (
                  <DriverFields
                    isDriverEditing={driverEditing}
                    openModal={openRegShippingCompany}
                    setOpenModal={setOpenRegShippingCompany}
                    setSelectedDriverVehicle={setSelectedDriverVehicle}
                    shippingCompanies={shippingCompanies}
                    vehicles={vehicles}
                    form={formDriver}
                    initialValues={driverInitialValues}
                    setCreateShippingCompany={setCreateShippingCompany}
                    setUpdateShippingCompany={setUpdateShippingCompany}
                    setInitialValues={setDriverInitialValues}
                  />
                ) : (
                  <Col>
                    <Descriptions>
                      <Item label="CNPJ da transportadora">
                        {driverInitialValues?.shippingCompany
                          ?.documentNumberCnpj
                          ? Formatter.formatCNPJ(
                              driverInitialValues.shippingCompany
                                .documentNumberCnpj
                            )
                          : '---'}
                      </Item>
                      <Item label="Nome da transportadora">
                        {driverInitialValues?.shippingCompany?.companyName ||
                          '---'}
                      </Item>
                      <Item label="Nome fantasia">
                        {driverInitialValues?.shippingCompany?.tradeName ||
                          '---'}
                      </Item>
                      <Item label="Email">
                        {driverInitialValues?.shippingCompany?.email || '---'}
                      </Item>
                      <Item label="Telefone">
                        {driverInitialValues?.shippingCompany?.phoneNumber
                          ? Formatter.formatCellphone(
                              driverInitialValues.shippingCompany.phoneNumber
                            )
                          : '---'}
                      </Item>
                    </Descriptions>
                    <Divider />
                    <Descriptions>
                      <Item label={t(`${baseString}.createLoad.labels.driver`)}>
                        {driverInitialValues?.name || '---'}
                      </Item>
                      <Item
                        label={t(`${baseString}.createLoad.labels.driverCpf`)}
                      >
                        {driverInitialValues?.cpf
                          ? Formatter.formatCPF(driverInitialValues.cpf)
                          : '---'}
                      </Item>
                      <Item
                        label={t(
                          `${baseString}.createLoad.labels.driverCellphone`
                        )}
                      >
                        {driverInitialValues?.phoneNumber
                          ? Formatter.formatCellphone(
                              driverInitialValues.phoneNumber
                            )
                          : '---'}
                      </Item>
                      <Item
                        label={t(
                          `${baseString}.createLoad.labels.vehicleModel`
                        )}
                      >
                        {driverInitialValues?.vehicleModel || '---'}
                      </Item>
                      <Item
                        label={t(
                          `${baseString}.createLoad.labels.vehicleLicensePlate`
                        )}
                      >
                        {driverInitialValues?.licensePlate || '---'}
                      </Item>
                      <Item label={t(`${baseString}.createLoad.labels.notes`)}>
                        {driverInitialValues?.note || '---'}
                      </Item>
                    </Descriptions>
                  </Col>
                )}
                {(allowPerms.createDriver || allowPerms.updateDriver) && (
                  <Col
                    style={{
                      marginLeft: 'auto',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <Row>
                      {driverEditing && (
                        <Col>
                          <Button
                            style={{ marginRight: 12 }}
                            onClick={() => {
                              formDriver.resetFields();
                              setSelectedDriverVehicle(undefined);
                              setDriverEditing(false);
                            }}
                            loading={isSynchronizingTruckLoad}
                          >
                            {t(`${baseString}.createLoad.viewLoad.cancelBtn`)}
                          </Button>
                        </Col>
                      )}
                      {truckLoadData?.status !== 'EXPIRED' && (
                        <Col>
                          <Button
                            type="primary"
                            htmlType="submit"
                            loading={driverUpdating || isSynchronizingTruckLoad}
                            disabled={isLoadOverVehicleCapacity()}
                          >
                            <Tooltip
                              open={isLoadOverVehicleCapacity()}
                              title="Carga total acima da capacidade do veículo" // Implementar i18n
                              getPopupContainer={triggerNode =>
                                triggerNode.parentNode as HTMLElement
                              }
                              overlayStyle={{
                                maxWidth: 200,
                              }}
                              overlayInnerStyle={{
                                whiteSpace: 'break-spaces',
                                textAlign: 'center',
                              }}
                              zIndex={999}
                            >
                              {renderDriverFieldButton()}
                            </Tooltip>
                          </Button>
                        </Col>
                      )}
                    </Row>
                  </Col>
                )}
              </Row>
              <Row>
                <Col>
                  {isWrongVehicle && (
                    <Alert
                      type="warning"
                      message={t(
                        `pages.truckLoads.createLoad.stepThree.wrongVehicleWarning`
                      )}
                    />
                  )}
                </Col>
              </Row>
            </Form>
          </Card>
        </Col>
        {(allowPerms.authorizedLoad || allowPerms.authorizeLoad) &&
          truckLoadData && (
            <Col span={24} ref={authorizeContainerRef}>
              <Card
                loading={fetchingTruckLoad}
                title={t(
                  `${baseString}.viewLoad.cardTitles.${
                    allowPerms.authorizedLoad ? 'authorization' : 'authorize'
                  }`
                )}
                style={{
                  boxShadow:
                    truckLoadPassedStatus === 'WAITING_RELEASE' &&
                    !truckLoadData.authorization
                      ? '0 0 15px #1890ff77'
                      : 'none',
                }}
              >
                <AuthorizationCard
                  authorizationData={truckLoadData.authorization}
                  loadAuthorized={allowPerms.authorizedLoad}
                  handleAuthorization={handleAuthorization}
                  isLoading={isSynchronizingTruckLoad}
                />
              </Card>
            </Col>
          )}

        {allowPerms.synchronizeTruckLoad && truckLoadData && (
          <Col span={24}>
            <SynchronizationCard
              truckLoadData={truckLoadData}
              isSyncDisabled={itemsEditing || driverEditing}
              isFetchingTruckLoad={fetchingTruckLoad}
              setIsSynchronizingTruckLoad={setIsSynchronizingTruckLoad}
            />
          </Col>
        )}

        <Button
          style={{ margin: '0px 12px' }}
          type="primary"
          onClick={() => {
            history.push('/cargas');
          }}
          loading={isSynchronizingTruckLoad}
        >
          {t(`${baseString}.createLoad.viewLoad.backToListing`)}
        </Button>
      </Row>
      {!!truckLoadData && (
        <Modal
          open={itemsEditing}
          onCancel={handleModalCancel}
          title={'Editar agendamento'} // Implementar i18n
          width={1440}
          footer={null}
          destroyOnClose
        >
          <TruckLoadEdition
            vehicleWeightCapacity={getVehicleWeightCapacity() || 0}
            vehicleBagsCapacity={getVehicleBagsCapacity() || 0}
            showContractItems={allowPerms.addTruckLoadItems}
            showDeleteButton={allowPerms.removeTruckLoadItems}
            truckLoadItems={truckLoadItems}
            truckLoadData={truckLoadData}
            onCancel={handleModalCancel}
            onOk={handleModalOk}
            buttonLoading={isUpdatingItems}
          />
        </Modal>
      )}
    </Layout>
  );
}

export default withTranslation()(ViewLoad);
