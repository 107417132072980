import { QuestionCircleOutlined } from '@ant-design/icons';
import {
  Alert,
  Button,
  Calendar,
  Card,
  Checkbox,
  Col,
  DatePicker,
  Divider,
  Form,
  FormInstance,
  InputNumber,
  Layout,
  message,
  PageHeader,
  Popover,
  Row,
  Select,
  Space,
  Switch,
  Table,
  TimePicker,
  Tooltip,
  Typography,
} from 'antd';
import { useForm, useWatch } from 'antd/es/form/Form';
import { RangePickerProps } from 'antd/lib/date-picker';
import { Rule, RuleObject } from 'antd/lib/form';
import { valueType } from 'antd/lib/statistic/utils';
import { ColumnsType } from 'antd/lib/table';
import { TooltipPlacement } from 'antd/lib/tooltip';
import { rejects } from 'assert';
import moment, { Moment } from 'moment';
import {
  CSSProperties,
  Dispatch,
  ReactElement,
  SetStateAction,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { TFunction, useTranslation, withTranslation } from 'react-i18next';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import Converter from '../../../../classes/Converter';
import Footer from '../../../../components/layout/footer';
import UserContext from '../../../../contexts/user';
import { useRequest } from '../../../../hooks/useRequest';
import AddressController from '../../../../structures/controllers/Address';
import BreadCrumb from '../../../../structures/controllers/Breadcrumb';
import ClientsController from '../../../../structures/controllers/Clients';
import ScheduleRulesController from '../../../../structures/controllers/ScheduleRules';
import { Farm } from '../../../../structures/interfaces/Clients';
import {
  ICalendarDate,
  ICreateCadencesWeekDayForm,
  ICreateCustomerRule,
  ICreateCustomerRuleFormValues,
  IEditCustomerRule,
  IEditRuleForm,
  IFillCalendar,
  IFindRules,
  IGetPickUpLocations,
  IGetSeedTypes,
  IRenderCalendarDates,
} from '../../../../structures/interfaces/ScheduleRules';
import { scrollOptions } from '../../../../utils/formOptions';
import { IErrorMessage } from '../../../../utils/interfaces';
import DeleteCustomerRule from '../customerRulesModals/deleteCustomerRule';
import ConfirmRuleEdition from '../modals/confirmRuleEdition';
import CustomerRuleEdition from '../modals/editCustomerRule';
import '../styles/scheduleRuleStyles.scss';

const sizes = { xs: 24, sm: 24, md: 12, lg: 8, xl: 7, xxl: 6 };
const colSize = { xs: 24, sm: 24, md: 12, lg: 8, xl: 6, xxl: 5 };

// recursos antd
const { Option } = Select;
const { RangePicker } = DatePicker;
const { Text } = Typography;

// interfaces
interface stateType {
  organizationId: string;
  ruleId: string;
}
interface IFormEditCalendarProps {
  formCalendarEdit: FormInstance;
  setShowEditModal: Dispatch<SetStateAction<boolean>>;
  setCalendarCellDate: Dispatch<any>;
  initialValues: ICreateCadencesWeekDayForm;
  setEditingCalendarDateId: () => void;
  validValueRule: any;
  enableConfirm: boolean;
}
interface ICreateCalendarCadence {
  form: FormInstance;
  onFinish: (values: ICreateCadencesWeekDayForm) => void;
  rules: Rule[];
  showModal: (value: SetStateAction<boolean>) => void;
}

const EditScheduleRule = ({ t }: { t: TFunction }): ReactElement => {
  const { userData } = useContext(UserContext);

  const history = useHistory();

  const { state }: { state: stateType } = useLocation();

  const [weekendFormView, setWeekendFormView] = useState(false);
  const [clientFormView, setClientFormView] = useState(false);
  const [deleteCRModalVisible, setDeleteCRModalVisible] = useState(false); // CR = Customer Rules
  const [updateCalendar, setUpdateCalendar] = useState(false);
  const [confirmBtn, setConfirmBtn] = useState(false);
  const [onOk, setOnOk] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [disabledMaxCapacity, setDisabledMaxCapacity] = useState(true);
  const [custRuleVisible, setCustRuleVisible] = useState(false);
  const [confirmCustRule, setConfirmCustRule] = useState(false);
  const [enableConfirm, setEnableConfirm] = useState(true);

  const [selectedDay, setSelectedDay] = useState(moment());

  const [calendarId, setCalendarId] = useState<Moment>();
  const [unityType, setUnityType] = useState<string>();
  const [calendarCellDate, setCalendarCellDate] = useState<any>(null);
  const [isTooltipVisible, setIsVisibleTooltip] = useState<Moment | null>(null);
  const [clients, setClients] = useState<Farm[]>([]);
  const [culture, setCulture] = useState<IGetSeedTypes[]>([]);
  const [locales, setLocales] = useState<IGetPickUpLocations[]>([]);
  const [selectedRule, setSelectedRule] = useState<IFindRules>();
  const [ruleCadences, setRuleCadences] = useState<IFillCalendar[]>([]);
  const [customerRules, setCustomerRules] = useState<ICreateCustomerRule[]>([]);
  const [calendarData, setCalendarData] = useState<IRenderCalendarDates[]>([]);
  const [disabledCadences, setDisabledCadences] = useState<
    IRenderCalendarDates[]
  >([]);
  const [initialMonthAppear, setInitialMomentAppear] = useState<Moment>();

  const [pageSize, setPageSize] = useState(10);
  const [sunday, setSunday] = useState<IFillCalendar>();
  const [saturday, setSaturday] = useState<IFillCalendar>();
  const [generalWeek, setGeneralWeek] = useState<IFillCalendar>();
  const [maxCapacityContent, setMaxCapacityContent] =
    useState<valueType | null>();
  const [selectedCustomerRule, setSelectedCustomerRule] =
    useState<IEditCustomerRule>();

  const [cultureRequest] = useRequest(ScheduleRulesController.getCulture);
  const [localesRequest] = useRequest(AddressController.findPickUpLocations);
  const [findRulesRequest] = useRequest(ScheduleRulesController.findRule);
  const [editRuleRequest, isLoading] = useRequest(
    ScheduleRulesController.editRules
  );
  const [getClientsRequest] = useRequest(ClientsController.getFarms);
  const [createCadencesRequest] = useRequest(
    ScheduleRulesController.createCadence
  );
  const [deleteCadencesRequest] = useRequest(
    ScheduleRulesController.deleteCadence
  );
  const [customerRuleRequest] = useRequest(
    ScheduleRulesController.createCustomerRule
  );

  const [form] = useForm();
  const [formCadences] = useForm();
  const [formCalendarEdit] = Form.useForm();
  const [formCustomerRule] = useForm();
  const [formCreateCalendar] = Form.useForm(); //Create cadence

  const formRef = useRef(formCalendarEdit);
  const editingCadenceDateId = useRef<string | null>(null);

  const [enableSubmit, setEnableSubmit] = useState(false);

  const generalCapacity = useWatch('generalCapacity', form);
  const generalHours = useWatch('generalHours', form);
  const saturdayCapacity = useWatch(['saturday', 'quantity'], formCadences);
  const saturdayHours = useWatch(['saturday', 'hours'], formCadences);
  const sundayHours = useWatch(['sunday', 'hours'], formCadences);
  const sundayCapacity = useWatch(['sunday', 'quantity'], formCadences);
  const releaseSubmissionDate = useWatch('releaseSubmissionDate', form);
  const startSchedulingnDate = useWatch('schedulingStart', form);
  const startDateValue = useWatch('startDate', form);
  const endDateValue = useWatch('endDate', form);
  const capacityPerCompany = useWatch('capacityPerCompany', form);

  // requisições iniciais
  const findRule = () => {
    findRulesRequest({ ruleId: state.ruleId })
      .then(rule => {
        setUnityType(rule.unity);
        setSelectedRule(rule);
      })
      .catch(() => {
        rejects;
      });
  };
  const getClients = () => {
    if (userData) {
      getClientsRequest(userData.organization.id)
        .then(cust => {
          setClients(cust);
        })
        .catch(() => {
          <Alert
            message={t('errors.900.message')}
            description={t('errors.900.description')}
          />;
        });
    }
  };
  const getCultures = () => {
    cultureRequest({}).then(seeds => {
      setCulture(seeds);
    });
  };
  const getLocales = () => {
    if (userData) {
      localesRequest({ organizationId: userData.organization.id }).then(
        locations => {
          setLocales(locations);
        }
      );
    }
  };

  // regras e validadores do formulário
  const requiredRule = {
    required: true,
    message: t('pages.settings.pickUpLocations.requiredRuleMessage'),
  };
  const validValueRule = {
    validator(rule: RuleObject, value: number) {
      if (value === null && !rule.required) {
        setEnableConfirm(true);
        return Promise.resolve();
      }
      if (value <= 0) {
        setEnableConfirm(false);
        return Promise.reject(t('pages.scheduleRules.insertAValidValue'));
      }

      return Promise.resolve();
    },
  };
  const hoursRequired = () => {
    if (weekendFormView) {
      return {};
    }
    return {
      required: !weekendFormView,
      message: t('pages.settings.pickUpLocations.requiredRuleMessage'),
    };
  };
  const weekendHoursRequired = (
    saturdayValue?: number,
    sundayValue?: number
  ) => {
    if (saturdayValue) {
      return {
        required: weekendFormView,
        message: t('pages.settings.pickUpLocations.requiredRuleMessage'),
      };
    }
    if (sundayValue) {
      return {
        required: weekendFormView,
        message: t('pages.settings.pickUpLocations.requiredRuleMessage'),
      };
    }
    return {} as Rule;
  };

  // feedback do usuário
  const info = () => {
    message.success({
      content: t('pages.scheduleRules.editRuleSuccess'),
      className: 'custom-class',
      style: {
        marginTop: 60,
      },
    });
  };
  const infoError = (statusCode: IErrorMessage) => {
    message.error({
      content: t(`pages.scheduleRules.errors.${statusCode}.message`),
      className: 'custom-class',
      style: {
        marginTop: 60,
      },
    });
  };
  const deleteCadenceError = () => {
    message.error({
      content: 'A cadência não pode ser deletada enquanto estiver sendo usada!',
      className: 'custom-class',
      style: {
        marginTop: 60,
      },
    });
  };
  const createCadenceError = (error: IErrorMessage) => {
    // 'Nao foi possível criar a(s) cadência(s)! Tente novamente'
    message.error({
      content: t(error.message),
      className: 'custom-class',
      style: {
        marginTop: 60,
      },
    });
  };

  // recursos antd
  const tableColumns: ColumnsType<ICreateCustomerRule> = [
    {
      title: t('pages.scheduleRules.newRules.cadencesTable.organization'),
      render: customer => {
        const stateId = clients.find(id => customer.customerId === id.id);
        return stateId ? stateId.name || stateId.tradeName : '---';
      },
      key: 'customerId',
    },
    {
      title: () => <>{t('pages.scheduleRules.rulePeriodDate')}</>,
      render: (_, record) => {
        return (
          <Space size="middle">
            {moment(record.startDate).format('DD/MM/YYYY')}
            {'-'}
            {moment(record.endDate).format('DD/MM/YYYY')}
          </Space>
        );
      },
      key: 'rulePeriod',
    },
    {
      title: t('pages.scheduleRules.newRules.cadencesTable.capacity'),
      dataIndex: ['capacity'],
      key: 'capacity',
    },
    {
      title: t('pages.scheduleRules.newRules.cadencesTable.actions'),
      key: 'action',
      render: (index: IEditCustomerRule) => {
        return (
          <Space>
            <Typography.Link
              style={{ marginRight: 12 }}
              onClick={() => {
                if (index) {
                  setCustRuleVisible(true);
                  setSelectedCustomerRule(index);
                }
              }}
            >
              {t('pages.settings.actionEdit')}
            </Typography.Link>
            <Typography.Link
              style={{ color: 'red' }}
              onClick={() => {
                setSelectedCustomerRule(index);
                setDeleteCRModalVisible(true);
              }}
            >
              {t('pages.settings.actionDelete')}
            </Typography.Link>
          </Space>
        );
      },
    },
  ];

  // manipulação de calendário
  const disableCustomerRuleDates: RangePickerProps['disabledDate'] =
    current => {
      const start = selectedRule?.startDate;
      const end = selectedRule?.endDate;
      const isDateIntoRule = !current.isBetween(start, end, 'days', '[]');

      return isDateIntoRule;
    };
  const buildFillCalendar = (
    capacity: number,
    hours: [Moment, Moment]
  ): IFillCalendar => {
    const [startTime, endTime] = hours;
    return {
      endTime: endTime,
      startTime: startTime,
      capacityPerDay: Number(capacity),
    };
  };
  const disableCadence = (dateId: string) => {
    const cadenceDisable = (arr: IRenderCalendarDates[]) => {
      const daysDisabled = arr.filter(cadence =>
        moment(cadence.date).isSame(moment(dateId), 'day')
      );
      const remainingDays = arr.filter(
        cadence => !moment(cadence.date).isSame(moment(dateId), 'day')
      );
      setDisabledCadences(daysDisabled);
      return { daysDisabled, remainingDays };
    };
    setCalendarData(calendarData => cadenceDisable(calendarData).remainingDays);
    formCalendarEdit.resetFields();
  };

  // manipulações de formulário
  const onFinish = (values: IEditRuleForm) => {
    editRule(values);
  };

  const onFinishCalendar = (
    values: Record<string, ICreateCadencesWeekDayForm>
  ) => {
    if (editingCadenceDateId.current) {
      const formValues = values[editingCadenceDateId.current];
      if (formValues.enableCadence) {
        const calendarDate = calendarData.find(({ date }) =>
          moment(formValues.id).isSame(date, 'day')
        );
        if (calendarDate && formValues.hours) {
          const startTime = formValues.hours[0].date(
            moment(calendarDate.fillCalendar.startTime).date()
          );
          const endTime = formValues.hours[1].date(
            moment(calendarDate.fillCalendar.endTime).date()
          );

          startTime.date(selectedDay.date());
          startTime.month(selectedDay.month());
          startTime.year(selectedDay.year());

          endTime.date(selectedDay.date());
          endTime.month(selectedDay.month());
          endTime.year(selectedDay.year());

          const listData = formValues.quantity
            ? [
                {
                  title: `${t(
                    'pages.scheduleRules.maxTitleDateCellContent'
                  )}: ${formValues.quantity}`,
                },
                {
                  title: `${startTime.format('HH:mm')} - ${endTime.format(
                    'HH:mm'
                  )} `,
                },
              ]
            : [];

          Object.assign(calendarDate, {
            calendarCell: listData,
            fillCalendar: {
              availableUnits: formValues.quantity,
              capacityPerDay: formValues.quantity,
              startTime: startTime,
              endTime: endTime,
            },
            date: calendarDate.date,
          });

          setCalendarData([...calendarData]);
        }
      } else {
        disableCadence(editingCadenceDateId.current);
      }
    }
  };
  const onFinishCustomerRule = ({
    ...values
  }: ICreateCustomerRuleFormValues) => {
    if (selectedRule) {
      const [start, end] = values.rulePeriod;
      const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
      setCustomerRules(customerData => [
        ...customerData,
        {
          ...values,
          id: uuidv4(),
          startDate: start.startOf('day').utc().toISOString(),
          endDate: end.clone().endOf('day').utc().toISOString(),
          timezone: tz,
        },
      ]);
      formCustomerRule.resetFields();
    }
  };
  const onFinishCreateCalendar = (values: ICreateCadencesWeekDayForm) => {
    if (calendarId) {
      const calendarDisabledDate = disabledCadences.find(data =>
        calendarId.isSame(moment(data.date), 'day')
      );

      if (values.hours && values.quantity) {
        const startTime = values.hours[0].set('date', calendarId.date());
        const endTime = values.hours[1].set('date', calendarId.date());

        startTime.date(calendarId.date());
        startTime.month(calendarId.month());
        startTime.year(calendarId.year());

        endTime.date(calendarId.date());
        endTime.month(calendarId.month());
        endTime.year(calendarId.year());

        const listData = values.quantity
          ? [
              {
                title: `${t('pages.scheduleRules.maxTitleDateCellContent')}: ${
                  values.quantity
                }`,
              },
              {
                title: `${startTime.format('HH:mm')} - ${endTime.format(
                  'HH:mm'
                )} `,
              },
            ]
          : [];

        if (calendarDisabledDate) {
          const aux = {
            calendarCell: listData,
            fillCalendar: {
              availableUnits: values.quantity,
              capacityPerDay: values.quantity,
              startTime: startTime.clone(),
              endTime: endTime.clone(),
            },
            date: calendarDisabledDate.date,
          };
          setCalendarData(calendarData => [...calendarData, aux]);
        } else {
          const aux = {
            calendarCell: listData,
            fillCalendar: {
              availableUnits: values.quantity,
              capacityPerDay: values.quantity,
              startTime: startTime,
              endTime: endTime,
            },
            date: calendarId.utc(),
          };
          setCalendarData(calendarData => [...calendarData, aux]);
        }
      }

      formCreateCalendar.resetFields();
    }
  };

  // requisições de envio
  const createCadences = () => {
    const cadencesToAdd = calendarData.filter(
      cadence =>
        !selectedRule?.cadences?.some(cad =>
          moment(cad.startTime).isSame(moment(cadence.date), 'day')
        )
    );
    const newCadences = cadencesToAdd
      .map(({ fillCalendar }) => fillCalendar)
      .filter(cad => cad);

    if (selectedRule && cadencesToAdd.length > 0 && newCadences.length > 0) {
      createCadencesRequest({
        ruleId: selectedRule.id,
        cadences: newCadences,
      })
        .then(() => {
          setCalendarData([...calendarData]);
        })
        .catch(error => {
          createCadenceError(error);
        });
    }
  };
  const deleteCadence = (deletedCadences?: IRenderCalendarDates[]) => {
    const weekendRemovedSuccess = () => {
      message.success({
        content: 'Finais de semana removidos com sucesso!',
        className: 'custom-class',
        style: {
          marginTop: 60,
        },
      });
    };
    if (deletedCadences && deletedCadences.length > 0) {
      const weekendCadencesIds = deletedCadences.map(({ fillCalendar }) => {
        return fillCalendar.id || '';
      });
      if (weekendCadencesIds && weekendCadencesIds.length > 0) {
        deleteCadencesRequest({ cadence_ids: weekendCadencesIds })
          .then(() => {
            weekendRemovedSuccess();
            const remainingCadences = calendarData.filter((cadence, index) => {
              // O trecho está comentado pois a solução de usar o date em si pode nao ser a melhor
              // return cadence.fillCalendar.id !== weekendCadencesIds[index];
              return (
                moment(cadence.date).isoWeekday() !== 6 &&
                moment(cadence.date).isoWeekday() !== 7
              );
            });
            setCalendarData(remainingCadences);
          })
          .catch(() => {
            deleteCadenceError();
          });
      }
    } else {
      const cadencesToDelete = selectedRule?.cadences
        ?.filter(
          cadence =>
            !calendarData.some(cad =>
              moment(cad.date).isSame(moment(cadence.startTime), 'day')
            )
        )
        .map(cad => cad.id);

      if (cadencesToDelete && cadencesToDelete.length > 0) {
        deleteCadencesRequest({ cadence_ids: cadencesToDelete })
          .then(() => {
            setCalendarData([...calendarData]);
          })
          .catch(() => {
            deleteCadenceError();
          });
      }
    }
  };
  const editRule = (formValues: IEditRuleForm) => {
    if (selectedRule) {
      const initialRule = Object.entries(selectedRule);

      formValues.releaseSubmissionDate;
      formValues.schedulingStart;

      formValues.driverReportTimeout = formValues.driverReportTimeout
        ? Converter.convertHoursInMinutes(formValues.driverReportTimeout)
        : undefined;
      formValues.loadReportTimeout = formValues.loadReportTimeout
        ? formValues.loadReportTimeout
        : undefined;
      formValues.whiteSeedSchedulingTime = formValues.whiteSeedSchedulingTime
        ? Number(formValues.whiteSeedSchedulingTime)
        : undefined;
      formValues.tsiSeedSchedulingTime = formValues.tsiSeedSchedulingTime
        ? Number(formValues.tsiSeedSchedulingTime)
        : undefined;

      const editRulesInput = {} as IEditRuleForm;

      initialRule.forEach(([key, value]) => {
        if (key in formValues) {
          const index = key as keyof IEditRuleForm;
          if (value !== formValues[index]) {
            Object.assign(editRulesInput, { [index]: formValues[index] });
          }
        }
      });

      const filteredCadences = calendarData.reduce(
        (finalCadences, cadences) => {
          const initialCadence = selectedRule.cadences?.find(cadence => {
            if (
              moment(cadence.startTime).isSame(
                cadences.fillCalendar.startTime,
                'D'
              )
            ) {
              return cadence;
            }
          });

          if (initialCadence) {
            if (
              initialCadence.capacityPerDay !==
              cadences.fillCalendar.capacityPerDay
            ) {
              finalCadences.push({
                ...cadences,
                fillCalendar: {
                  ...cadences.fillCalendar,
                  id: initialCadence.id,
                },
              });
            } else if (
              initialCadence.startTime !== cadences.fillCalendar.startTime
            ) {
              finalCadences.push({
                ...cadences,
                fillCalendar: {
                  ...cadences.fillCalendar,
                  id: initialCadence.id,
                },
              });
            } else if (
              initialCadence.endTime !== cadences.fillCalendar.endTime
            ) {
              finalCadences.push({
                ...cadences,
                fillCalendar: {
                  ...cadences.fillCalendar,
                  id: initialCadence.id,
                },
              });
            }
          }
          return finalCadences;
        },
        [] as IRenderCalendarDates[]
      );
      editRuleRequest({
        ruleId: selectedRule.id,
        rulesInput: {
          ...editRulesInput,
          generalCapacityPerDay: generalCapacity,
          generalSaturdayCapacity: saturdayCapacity,
          generalSundayCapacity: sundayCapacity,
          updateCadences: filteredCadences.map(item => ({
            where: {
              id: item.fillCalendar.id,
            },
            input: {
              availableUnits: item.fillCalendar.availableUnits
                ? item.fillCalendar.availableUnits
                : undefined,
              capacityPerDay: item.fillCalendar.capacityPerDay,
              endTime: moment(item.fillCalendar.endTime).toISOString(),
              startTime: moment(item.fillCalendar.startTime).toISOString(),
            },
          })),
        },
      })
        .then(() => {
          info();
          history.push(`/regras/cadastro`);
        })
        .catch(err => {
          infoError(err);
        });
    }
  };
  const fillCalendarData = (value: Moment) => {
    // eh aqui
    const calendarDate = calendarData.find(({ date }) =>
      value.isSame(date, 'day')
    );

    const dateId = moment(calendarDate?.date).toISOString();
    const setEditingCalendarDateId = () =>
      (editingCadenceDateId.current = dateId);

    const initialValues = {
      id: dateId,
      quantity: calendarDate?.fillCalendar.capacityPerDay,
      enableCadence: true,
      hours: [
        moment(calendarDate?.fillCalendar.startTime),
        moment(calendarDate?.fillCalendar.endTime),
      ] as [Moment, Moment],
    };
    const selectedDay = value.isSame(moment(calendarCellDate), 'day');

    if (calendarData.length > 0) {
      const firstDate = startDateValue;
      const lastDate = endDateValue;
      const disabledFirstDate = disabledCadences[0]?.date;
      const disabledLastDate =
        disabledCadences[disabledCadences.length - 1]?.date;

      const isIntoCadenceDays =
        moment(calendarCellDate).isBetween(firstDate, lastDate, 'days', '[]') &&
        calendarDate?.fillCalendar !== undefined;

      const isDisabledCadenceDay =
        calendarId?.isBetween(
          disabledFirstDate,
          disabledLastDate,
          'days',
          '[]'
        ) && !calendarDate?.fillCalendar;

      const popoverView =
        isIntoCadenceDays && !isDisabledCadenceDay ? (
          <FormEditCalendar
            formCalendarEdit={formCalendarEdit}
            setShowEditModal={setShowEditModal}
            setCalendarCellDate={setCalendarCellDate}
            initialValues={initialValues}
            setEditingCalendarDateId={setEditingCalendarDateId}
            validValueRule={validValueRule}
            enableConfirm={enableConfirm}
          />
        ) : (
          <CreateCadenceInCalendar
            form={formCreateCalendar}
            onFinish={onFinishCreateCalendar}
            rules={[validValueRule]}
            showModal={setShowEditModal}
          />
        );

      let placementType: TooltipPlacement;

      if (value?.isoWeekday() === 6) {
        placementType = 'topRight';
      } else if (value?.isoWeekday() === 7) {
        placementType = 'topLeft';
      } else {
        placementType = 'top';
      }

      return (
        <>
          <div
            style={{
              position: 'absolute',
              width: '100%',
              height: '100%',
              left: 0,
              top: 0,
            }}
            onMouseOver={() => setIsVisibleTooltip(value)}
            onMouseOut={() => setIsVisibleTooltip(null)}
          >
            {moment(isTooltipVisible).isSame(moment(value), 'day') &&
              isDateDisabled(value) && (
                <div
                  style={{
                    borderRadius: 5,
                    backgroundColor: '#000000',
                    color: '#fff',
                    textAlign: 'left',
                    padding: '6px 8px',
                    position: 'relative',
                    top: '-90px',
                    left: '0',
                    minWidth: 32,
                    minHeight: 32,
                  }}
                >
                  {t('pages.scheduleRules.editRules.disableCadencesTooltip')}
                </div>
              )}
          </div>
          <Popover
            placement={placementType}
            open={showEditModal && selectedDay}
            content={<>{popoverView}</>}
          >
            <ul className="calendar-cell-content">
              {calendarDate?.calendarCell.map(item => (
                <li key={item.title}>{item.title}</li>
              ))}
            </ul>
          </Popover>
        </>
      );
    }
  };
  const isDateDisabled = (value: Moment) => {
    const day = calendarData.find(day => moment(day.date).isSame(value, 'day'));
    const preScheduleId =
      day?.fillCalendar.preSchedules?.length &&
      day.fillCalendar.preSchedules.length > 0;
    return preScheduleId ? true : false;
  };
  const rulePeriodDates = (
    dateRange: [Moment, Moment],
    includes: [boolean, boolean] = [true, true]
  ) => {
    const startDate = moment(dateRange[0]).clone();
    const endDate = moment(dateRange[1]).clone();

    const dateDiff = endDate.diff(startDate, 'day');

    if (dateDiff > 0) {
      const datesInRange = new Array(dateDiff)
        .fill('')
        .map((_, index) => startDate.clone().add(index + 1, 'day'));

      if (includes[0]) {
        datesInRange.unshift(startDate.clone());
      }

      if (!includes[1]) {
        datesInRange.pop();
      }

      return datesInRange;
    }
    return [];
  };
  const getListData = (value: Moment) => {
    const days = (value: Moment) => {
      switch (value.isoWeekday()) {
        case 1:
        case 2:
        case 3:
        case 4:
        case 5:
          return generalWeek;
        case 6:
          return saturday;
        case 7:
          return sunday;
      }
    };

    const fillCalendar = days(value);
    if (fillCalendar) {
      const startTime = fillCalendar.startTime.clone();
      const endTime = fillCalendar.endTime.clone();

      if (startTime && endTime) {
        startTime.date(value.date());
        startTime.month(value.month());
        startTime.year(value.year());

        endTime.date(value.date());
        endTime.month(value.month());
        endTime.year(value.year());

        const cadenceId = ruleCadences?.find(cadence => {
          return moment(cadence.startTime).isSame(startTime, 'day');
        });

        const newFillCalendar = {
          ...fillCalendar,
          id: cadenceId?.id,
          endTime,
          startTime,
        };

        const listData = fillCalendar.capacityPerDay
          ? [
              {
                title: `Max: ${fillCalendar.capacityPerDay}`,
              },
              {
                title: `${fillCalendar.startTime.format(
                  'HH:mm'
                )} - ${fillCalendar.endTime.format('HH:mm')} `,
              },
            ]
          : [];

        return { listData, fillCalendar: newFillCalendar };
      }
    }
  };
  const createCustomerRule = () => {
    const customerRulesToAdd = customerRules.filter(
      customerRule =>
        !selectedRule?.customerRules.some(
          existRule => existRule.id === customerRule.id
        )
    );
    if (selectedRule && customerRulesToAdd) {
      customerRuleRequest({
        customerRules: customerRulesToAdd,
        referenceRuleId: selectedRule.id,
      });
    }
  };

  // componentes de formulário
  const capacityPerCompanyValidator = (value: number) => {
    if (generalCapacity) {
      return value <= generalCapacity
        ? Promise.resolve()
        : Promise.reject(
            new Error(t('pages.scheduleRules.maxCapacityAlertError'))
          );
    }
    if (saturdayCapacity) {
      return value <= saturdayCapacity
        ? Promise.resolve()
        : Promise.reject(
            new Error(t('pages.scheduleRules.maxCapacityAlertError'))
          );
    }
    if (sundayCapacity) {
      return value <= sundayCapacity
        ? Promise.resolve()
        : Promise.reject(
            new Error(t('pages.scheduleRules.maxCapacityAlertError'))
          );
    }
  };
  const rulesForm = (
    <Form
      scrollToFirstError={scrollOptions}
      layout="vertical"
      form={form}
      onFinish={values => onFinish(values)}
    >
      <Card
        title={t('pages.scheduleRules.editRules.basicInformation.title')}
        style={{ marginBottom: 24 }}
      >
        <Row gutter={24}>
          <Col {...sizes}>
            <Form.Item
              name="seedTypeId"
              label={t(
                'pages.scheduleRules.newRules.basicInformation.cultureLabel'
              )}
              rules={[{ ...requiredRule }]}
            >
              <Select
                placeholder={t(
                  'pages.scheduleRules.newRules.defaultValueSelect'
                )}
              >
                {culture?.map(item => (
                  <Option
                    key={item.nameWithoutAccent}
                    value={item.nameWithoutAccent}
                  >
                    {item.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col {...sizes}>
            <Form.Item
              name="pickUpLocationId"
              label={t(
                'pages.scheduleRules.newRules.basicInformation.locationLabel'
              )}
              rules={[{ ...requiredRule }]}
            >
              <Select
                disabled
                placeholder={t(
                  'pages.scheduleRules.newRules.defaultValueSelect'
                )}
              >
                {locales?.map(item => (
                  <Option key={item.id} value={item.id}>
                    {item.title}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Card>

      <Card title={t('pages.scheduleRules.seedsTreatment')}>
        <Row gutter={24}>
          <Col {...sizes}>
            <Form.Item
              name="whiteSeedSchedulingTime"
              label={
                <Tooltip
                  title={t('pages.scheduleRules.newRules.hoursInputMessages')}
                >
                  {t(
                    'pages.scheduleRules.newRules.unityInformation.whiteSeedsTreatment'
                  )}{' '}
                  <QuestionCircleOutlined />
                </Tooltip>
              }
              rules={[{ ...requiredRule }]}
            >
              <InputNumber
                style={{ width: '100%' }}
                disabled
                placeholder={t('pages.scheduleRules.newRules.quantityInDays')}
                addonAfter={t('pages.scheduleRules.newRules.addonAfterDays')}
              />
            </Form.Item>
          </Col>

          <Col {...sizes}>
            <Form.Item
              name="tsiSeedSchedulingTime"
              label={
                <Tooltip
                  title={t('pages.scheduleRules.newRules.hoursInputMessages')}
                >
                  {t(
                    'pages.scheduleRules.newRules.unityInformation.tsiSeedsTreatment'
                  )}{' '}
                  <QuestionCircleOutlined />
                </Tooltip>
              }
              rules={[{ ...requiredRule }]}
            >
              <InputNumber
                style={{ width: '100%' }}
                disabled
                placeholder={t('pages.scheduleRules.newRules.quantityInDays')}
                addonAfter={t('pages.scheduleRules.newRules.addonAfterDays')}
              />
            </Form.Item>
          </Col>

          <Col {...sizes}>
            <Form.Item
              validateFirst
              name="releaseSubmissionDate"
              label={
                <>
                  <Tooltip
                    title={t('pages.scheduleRules.releaseSubmissionDateInfo')}
                  >
                    {t(
                      'pages.scheduleRules.newRules.basicInformation.dateReleaseSentLabel'
                    )}{' '}
                    <QuestionCircleOutlined />
                  </Tooltip>
                </>
              }
              rules={[{ ...requiredRule }]}
            >
              <DatePicker
                allowClear={false}
                format={'DD/MM/YYYY'}
                style={{ width: '100%' }}
                disabled
              />
            </Form.Item>
          </Col>

          <Col {...sizes}>
            <Form.Item
              validateFirst
              name="schedulingStart"
              label={
                <>
                  <Tooltip title={t('pages.scheduleRules.schedulingStartInfo')}>
                    {t(
                      'pages.scheduleRules.newRules.basicInformation.startSchedulingLabel'
                    )}{' '}
                    <QuestionCircleOutlined />
                  </Tooltip>
                </>
              }
              rules={[
                { ...requiredRule },
                {
                  validator: (_, value) =>
                    moment(value) >= releaseSubmissionDate
                      ? Promise.resolve()
                      : Promise.reject(
                          new Error(
                            t(
                              'pages.scheduleRules.newRules.schedulingStartDateError'
                            )
                          )
                        ),
                },
              ]}
            >
              <DatePicker
                allowClear={false}
                format={'DD/MM/YYYY'}
                style={{ width: '100%' }}
                disabled
              />
            </Form.Item>
          </Col>

          <Col {...sizes}>
            <Form.Item
              dependencies={['endDate']}
              validateFirst
              name="startDate"
              label={
                <>
                  <Tooltip title={t('pages.scheduleRules.startShipmentInfo')}>
                    {t(
                      'pages.scheduleRules.newRules.basicInformation.startShipmentLabel'
                    )}{' '}
                    <QuestionCircleOutlined />
                  </Tooltip>
                </>
              }
              rules={[
                { ...requiredRule },
                {
                  validator: (_, value) =>
                    moment(value) >= startSchedulingnDate
                      ? Promise.resolve()
                      : Promise.reject(
                          new Error(
                            t('pages.scheduleRules.newRules.startDateError')
                          )
                        ),
                },
              ]}
            >
              <DatePicker
                allowClear={false}
                format={'DD/MM/YYYY'}
                style={{ width: '100%' }}
                disabled
              />
            </Form.Item>
          </Col>

          <Col {...sizes}>
            <Form.Item
              dependencies={['startDate']}
              validateFirst
              name="endDate"
              label={
                <>
                  <Tooltip title={t('pages.scheduleRules.endShipmentInfo')}>
                    {t(
                      'pages.scheduleRules.newRules.basicInformation.endShipmentLabel'
                    )}{' '}
                    <QuestionCircleOutlined />
                  </Tooltip>
                </>
              }
              rules={[
                { ...requiredRule },
                {
                  validator: (_, value) =>
                    moment(value) >= startDateValue
                      ? Promise.resolve()
                      : Promise.reject(
                          new Error(
                            t('pages.scheduleRules.newRules.endDateError')
                          )
                        ),
                },
              ]}
            >
              <DatePicker
                allowClear={false}
                format={'DD/MM/YYYY'}
                style={{ width: '100%' }}
                disabled
              />
            </Form.Item>
          </Col>

          <Col {...sizes}>
            <Form.Item
              name="loadReportTimeout"
              label={
                <>
                  <Tooltip
                    title={
                      <>
                        {t(
                          'pages.scheduleRules.editRules.basicInformation.limitTimeToInformLoadMessage'
                        )}
                        <Divider />
                        {t('pages.scheduleRules.editRules.hoursInputMessages')}
                      </>
                    }
                  >
                    {t(
                      'pages.scheduleRules.editRules.basicInformation.limitTimeToInformLoadLabel'
                    )}{' '}
                    <QuestionCircleOutlined />
                  </Tooltip>
                </>
              }
              rules={[{ ...requiredRule }, () => validValueRule]}
            >
              <InputNumber
                placeholder={t('Insira o tempo para formar cargas')}
                addonAfter={'Minutos'}
                style={{ width: '100%' }}
              />
            </Form.Item>
          </Col>

          <Col {...sizes}>
            <Form.Item
              name="driverReportTimeout"
              label={
                <>
                  <Tooltip
                    title={
                      <>
                        {t(
                          'pages.scheduleRules.editRules.basicInformation.limitTimeToInformDriverMessage'
                        )}
                        <Divider />
                        {t('pages.scheduleRules.editRules.hoursInputMessages')}
                      </>
                    }
                  >
                    {t(
                      'pages.scheduleRules.editRules.basicInformation.limitTimeToInformDriverLabel'
                    )}{' '}
                    <QuestionCircleOutlined />
                  </Tooltip>
                </>
              }
              rules={[{ ...requiredRule }, () => validValueRule]}
            >
              <InputNumber
                placeholder={t(
                  'pages.scheduleRules.editRules.hoursQuantityPlaceholder'
                )}
                addonAfter={t('pages.scheduleRules.editRules.addonAfterHours')}
                style={{ width: '100%' }}
              />
            </Form.Item>
          </Col>
        </Row>
      </Card>

      <Alert
        style={{ marginBottom: 24, marginTop: 24 }}
        message={t('pages.scheduleRules.alertMessageToUnities')}
        type="warning"
      />

      <Row gutter={24} style={{ marginBottom: 24 }}>
        <Col span={24} style={{ height: '100%' }}>
          <Card
            style={{ height: '100%' }}
            title={t(
              'pages.scheduleRules.newRules.unityInformation.unityTitle'
            )}
          >
            <Row gutter={24}>
              <Col>
                <Form.Item
                  name="unity"
                  label={t(
                    'pages.scheduleRules.newRules.unityInformation.measurementUnityLabel'
                  )}
                >
                  <Select onChange={(value: string) => setUnityType(value)}>
                    <Option value="BAG">
                      {t(
                        'pages.scheduleRules.newRules.unityInformation.bagsOption'
                      )}
                    </Option>
                    <Option value="SMALL_BAG">
                      {t(
                        'pages.scheduleRules.newRules.unityInformation.smallBagOption'
                      )}
                    </Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>

      <Alert
        style={{ marginBottom: 24 }}
        message={t('pages.scheduleRules.alertMessageToCapacities')}
        type="warning"
      />

      <Card
        title={t('pages.scheduleRules.newRules.unityInformation.capacityTitle')}
        bordered={false}
      >
        <Row gutter={24}>
          <Col>
            <Form.Item
              name="generalCapacity"
              label={
                <Tooltip
                  title={t(
                    'pages.scheduleRules.newRules.unityInformation.tooltipGeneralCapacity'
                  )}
                >
                  {t(
                    'pages.scheduleRules.newRules.unityInformation.maximumCapacityForDayLabel'
                  )}{' '}
                  <QuestionCircleOutlined />
                </Tooltip>
              }
              rules={[() => validValueRule]}
            >
              <InputNumber
                placeholder={t(
                  'pages.scheduleRules.newRules.cadences.quantityPlaceholder'
                )}
                addonAfter={
                  unityType === 'BAG'
                    ? t(
                        'pages.scheduleRules.newRules.unityInformation.addonAfterBag'
                      )
                    : t(
                        'pages.scheduleRules.newRules.unityInformation.addonAfterSmallBag'
                      )
                }
                style={{ width: '100%' }}
                onChange={value => {
                  setMaxCapacityContent(value);
                  setEnableSubmit(true);
                }}
              />
            </Form.Item>
          </Col>

          <Col>
            <Form.Item
              name="generalHours"
              label={t(
                'pages.scheduleRules.newRules.unityInformation.generalHours'
              )}
              // rules={[hoursRequired()]}
            >
              <TimePicker.RangePicker
                format={'HH:mm'}
                style={{ width: '100%' }}
                placeholder={[
                  t('pages.scheduleRules.editRules.startTime'),
                  t('pages.scheduleRules.editRules.endTime'),
                ]}
              />
            </Form.Item>
          </Col>

          <Col>
            <Form.Item
              validateFirst
              name="capacityPerCompany"
              label={
                <>
                  <Tooltip
                    title={t(
                      'pages.scheduleRules.newRules.capacityPerCompanyMessage'
                    )}
                  >
                    {t(
                      'pages.scheduleRules.newRules.unityInformation.maximumCapacityForOrganizationLabel'
                    )}{' '}
                    <QuestionCircleOutlined />
                  </Tooltip>
                </>
              }
              rules={[
                { ...requiredRule },
                () => validValueRule,
                { validator: (_, value) => capacityPerCompanyValidator(value) },
              ]}
            >
              <InputNumber
                disabled={disabledMaxCapacity}
                placeholder={t(
                  'pages.scheduleRules.newRules.cadences.quantityPlaceholder'
                )}
                onChange={() => setEnableSubmit(true)}
                addonAfter={
                  unityType === 'BAG'
                    ? t(
                        'pages.scheduleRules.newRules.unityInformation.addonAfterBag'
                      )
                    : t(
                        'pages.scheduleRules.newRules.unityInformation.addonAfterSmallBag'
                      )
                }
                style={{ width: '100%' }}
              />
            </Form.Item>
          </Col>
        </Row>
      </Card>
    </Form>
  );
  const cadencesForm = (
    <Form
      scrollToFirstError={scrollOptions}
      style={{ marginBottom: 24 }}
      layout="vertical"
      form={formCadences}
    >
      <Card bordered={false}>
        <Form.Item>
          <Checkbox
            name="weekDetails"
            checked={weekendFormView}
            onChange={value => {
              setWeekendFormView(value.target.checked);
              if (value.target.checked === false) formCadences.resetFields();
            }}
          >
            {t('pages.scheduleRules.weekDetails')}
          </Checkbox>
        </Form.Item>
        {weekendFormView && (
          <>
            <div>
              <Text>{t('pages.scheduleRules.newRules.cadences.saturday')}</Text>
              <Row style={{ marginTop: 12 }} gutter={24}>
                <Col {...sizes}>
                  <Form.Item
                    name={['saturday', 'quantity']}
                    label={t('pages.scheduleRules.newRules.cadences.quantity')}
                    rules={[
                      () => validValueRule,
                      {
                        validator: (_, value) => {
                          if (capacityPerCompany && value) {
                            return value > capacityPerCompany
                              ? Promise.resolve()
                              : Promise.reject(
                                  new Error(
                                    t(
                                      'pages.scheduleRules.maxCapacityPerDayError'
                                    )
                                  )
                                );
                          }
                        },
                      },
                    ]}
                  >
                    <InputNumber
                      style={{ width: '100%' }}
                      placeholder={t(
                        'pages.scheduleRules.newRules.cadences.quantityPlaceholder'
                      )}
                      onChange={() => setEnableSubmit(true)}
                    />
                  </Form.Item>
                </Col>

                <Col {...sizes}>
                  <Form.Item
                    name={['saturday', 'hours']}
                    label={t('pages.scheduleRules.newRules.cadences.schedule')}
                    rules={[weekendHoursRequired(saturdayCapacity)]}
                  >
                    <TimePicker.RangePicker
                      format={'HH:mm'}
                      style={{ width: '100%' }}
                      placeholder={[
                        t('pages.scheduleRules.editRules.startTime'),
                        t('pages.scheduleRules.editRules.endTime'),
                      ]}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </div>
            <div>
              <Text>{t('pages.scheduleRules.newRules.cadences.sunday')}</Text>
              <Row style={{ marginTop: 12 }} gutter={24}>
                <Col {...sizes}>
                  <Form.Item
                    name={['sunday', 'quantity']}
                    label={t('pages.scheduleRules.newRules.cadences.quantity')}
                    rules={[
                      () => validValueRule,
                      {
                        validator: (_, value) => {
                          if (capacityPerCompany && value) {
                            return value > capacityPerCompany
                              ? Promise.resolve()
                              : Promise.reject(
                                  new Error(
                                    t(
                                      'pages.scheduleRules.maxCapacityPerDayError'
                                    )
                                  )
                                );
                          }
                        },
                      },
                    ]}
                  >
                    <InputNumber
                      style={{ width: '100%' }}
                      placeholder={t(
                        'pages.scheduleRules.newRules.cadences.quantityPlaceholder'
                      )}
                      onChange={() => setEnableSubmit(true)}
                    />
                  </Form.Item>
                </Col>

                <Col {...sizes}>
                  <Form.Item
                    name={['sunday', 'hours']}
                    label={t('pages.scheduleRules.newRules.cadences.schedule')}
                    rules={[weekendHoursRequired(saturdayCapacity)]}
                  >
                    <TimePicker.RangePicker
                      format={'HH:mm'}
                      style={{ width: '100%' }}
                      placeholder={[
                        t('pages.scheduleRules.editRules.startTime'),
                        t('pages.scheduleRules.editRules.endTime'),
                      ]}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </div>
          </>
        )}

        <Alert
          style={{ marginBottom: 24, width: 'auto' }}
          message={t('pages.scheduleRules.applyCadenceMessage')}
          type="warning"
        />
        <Row>
          <Col>
            <Button
              type="primary"
              onClick={() => {
                setOnOk(true), setConfirmBtn(!confirmBtn);
              }}
            >
              {t('pages.scheduleRules.applyCadenceButton')}
            </Button>
          </Col>
        </Row>
      </Card>
    </Form>
  );
  const customerRulesFormAndCard = (
    <Card
      title={t('pages.scheduleRules.newRules.calendarTitle')}
      style={{ marginBottom: 84 }}
    >
      <Form
        scrollToFirstError={scrollOptions}
        ref={formRef}
        form={formCalendarEdit}
        onFinish={values => {
          onFinishCalendar(values);
        }}
      >
        <Calendar
          key={initialMonthAppear?.toISOString()}
          defaultValue={initialMonthAppear}
          className="edit-schedule-rule-calendar"
          disabledDate={date =>
            isDateDisabled(date) ||
            date.isBefore(startDateValue) ||
            date.isAfter(endDateValue)
          }
          dateCellRender={fillCalendarData}
          onSelect={value => {
            setSelectedDay(value);
            setCalendarId(value);
            setCalendarCellDate(value);
            setShowEditModal(true);
          }}
        />
      </Form>
      <Row>
        <Col span={8}>
          <Form.Item>
            <Checkbox
              name="userRuleView"
              checked={clientFormView}
              onChange={() => {
                setClientFormView(!clientFormView);
              }}
            >
              {t('pages.scheduleRules.newRules.checkboxLabel')}
            </Checkbox>
          </Form.Item>
        </Col>
      </Row>
      {clientFormView && (
        <>
          <Form
            scrollToFirstError={scrollOptions}
            layout="vertical"
            form={formCustomerRule}
            onFinish={onFinishCustomerRule}
          >
            <Row gutter={24}>
              <Col {...colSize}>
                <Form.Item
                  rules={[{ ...requiredRule }]}
                  name="customerId"
                  label={t('pages.scheduleRules.newRules.clientLabel')}
                >
                  <Select
                    placeholder={t(
                      'pages.scheduleRules.newRules.defaultValueSelect'
                    )}
                  >
                    {clients &&
                      clients.map(item => (
                        <Option key={item.id} value={item.id}>
                          {item.name || item.tradeName}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>

              <Col {...colSize}>
                <Form.Item
                  name="rulePeriod"
                  label={t('pages.scheduleRules.newRules.rulePeriodLabel')}
                  validateFirst
                  rules={[{ ...requiredRule }]}
                >
                  <RangePicker
                    placement="topLeft"
                    format={'DD/MM/YYYY'}
                    disabledDate={disableCustomerRuleDates}
                    defaultValue={[moment(selectedRule?.startDate), null]}
                  />
                </Form.Item>
              </Col>

              <Col {...colSize}>
                <Form.Item
                  rules={[
                    { ...requiredRule },
                    () => validValueRule,
                    {
                      validator: (_, value) => {
                        if (generalCapacity && value) {
                          return value < generalCapacity
                            ? Promise.resolve()
                            : Promise.reject(
                                new Error(
                                  t(
                                    'pages.scheduleRules.newRules.CRweightOverLimit'
                                  )
                                )
                              );
                        }
                      },
                    },
                  ]}
                  name="capacity"
                  label={t(
                    'pages.scheduleRules.newRules.maximumCapacityPerCompanyLabel'
                  )}
                >
                  <InputNumber
                    placeholder={t(
                      'pages.scheduleRules.newRules.cadences.quantityPlaceholder'
                    )}
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              </Col>

              <Col {...colSize}>
                <Form.Item label=" ">
                  <Button
                    style={{ width: '100%' }}
                    type="primary"
                    htmlType="submit"
                  >
                    {t('pages.scheduleRules.newRules.buttonContent')}
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
          <Table
            rowKey={({ id }) => id}
            size="small"
            bordered
            dataSource={customerRules}
            columns={tableColumns}
            pagination={{
              hideOnSinglePage: true,
              pageSize: pageSize,
              onChange(_, size) {
                setPageSize(size);
              },
            }}
          />
        </>
      )}
    </Card>
  );
  const footer = (
    <div style={{ textAlign: 'right' }}>
      <Link to="/regras/cadastro">
        <Button style={{ marginRight: 24 }} danger htmlType="reset">
          {t('pages.editCompany.cancelBtn')}
        </Button>
      </Link>

      <Button
        type="primary"
        htmlType="submit"
        loading={isLoading}
        onClick={() => {
          form.submit();
          formCadences.submit();
          createCustomerRule();
          createCadences();
          deleteCadence();
        }}
        disabled={enableSubmit}
      >
        <Popover
          overlayStyle={{ width: 300 }}
          placement="topRight"
          open={enableSubmit}
          content={t('pages.scheduleRules.tooltipToEnableButton')}
          getTooltipContainer={trigger => trigger.parentElement as HTMLElement}
        >
          {t('pages.editCompany.confirmBtn')}
        </Popover>
      </Button>
    </div>
  );

  useEffect(() => {
    if (!maxCapacityContent) {
      setDisabledMaxCapacity(true);
    }
  }, [maxCapacityContent]);
  useEffect(() => {
    if (generalCapacity) {
      setDisabledMaxCapacity(false);
    } else if (saturdayCapacity) {
      setDisabledMaxCapacity(false);
    } else if (sundayCapacity) {
      setDisabledMaxCapacity(false);
    }
  }, [generalCapacity, saturdayCapacity, sundayCapacity]);
  useEffect(() => {
    findRule();
    getCultures();
    getLocales();
    getClients();
  }, [userData]);
  useEffect(() => {
    if (selectedRule && selectedRule.cadences) {
      form.setFieldsValue({
        seedTypeId: selectedRule.seedTypeId,
        pickUpLocationId: selectedRule.pickUpLocationId,
        startDate: moment(selectedRule.startDate),
        endDate: moment(selectedRule.endDate),
        releaseSubmissionDate: moment(selectedRule.releaseSubmissionDate),
        schedulingStart: moment(selectedRule.schedulingStart),
        loadReportTimeout: selectedRule.loadReportTimeout,
        driverReportTimeout: Converter.convertMinInHours(
          selectedRule.driverReportTimeout
        ),
        unity: selectedRule.unity,
        whiteSeedSchedulingTime: Number(selectedRule.whiteSeedSchedulingTime),
        tsiSeedSchedulingTime: Number(selectedRule.tsiSeedSchedulingTime),
        capacityPerCompany: selectedRule.capacityPerCompany,
      });
      selectedRule.cadences.find(item => {
        switch (moment(item.startTime).isoWeekday()) {
          case 1:
          case 2:
          case 3:
          case 4:
          case 5:
            return form.setFieldsValue({
              generalCapacity: selectedRule.generalCapacityPerDay,
              generalHours: [moment(item.startTime), moment(item.endTime)],
            });
          case 6:
            return formCadences.setFieldsValue({
              saturday: {
                quantity: selectedRule.generalSaturdayCapacity,
                hours: [moment(item.startTime), moment(item.endTime)],
              },
            });
          case 7:
            return formCadences.setFieldsValue({
              sunday: {
                quantity: selectedRule.generalSundayCapacity,
                hours: [moment(item.startTime), moment(item.endTime)],
              },
            });
        }
      });
      const initialDates = selectedRule.cadences.map(cadence => {
        const calendarCell = cadence
          ? [
              {
                title: `Max: ${cadence.capacityPerDay}`,
              },
              {
                title: `${moment(cadence.startTime).format('HH:mm')} - ${moment(
                  cadence.endTime
                ).format('HH:mm')} `,
              },
            ]
          : [];
        return {
          date: cadence.startTime,
          fillCalendar: cadence,
          calendarCell,
        };
      });

      const data = selectedRule.cadences[0]?.startTime;
      const defaultValue = moment(data).startOf('month');
      setInitialMomentAppear(defaultValue);

      setCalendarData(initialDates);
      setRuleCadences(selectedRule.cadences);
      setCustomerRules(selectedRule.customerRules);
    }
  }, [selectedRule]);
  useEffect(() => {
    if (saturdayCapacity && saturdayHours) {
      setSaturday(buildFillCalendar(saturdayCapacity, saturdayHours));
    }
  }, [saturdayCapacity, saturdayHours]);
  useEffect(() => {
    if (sundayCapacity && sundayHours) {
      setSunday(buildFillCalendar(sundayCapacity, sundayHours));
    }
  }, [sundayCapacity, sundayHours]);
  useEffect(() => {
    if (generalCapacity && generalHours) {
      setGeneralWeek(buildFillCalendar(generalCapacity, generalHours));
    }
  }, [generalCapacity, generalHours]);
  useEffect(() => {
    if (selectedRule) {
      const start = moment(selectedRule.startDate);
      const end = moment(selectedRule.endDate);

      const period = rulePeriodDates([start, end]);
      const dates = period
        .map(date => {
          const listData = getListData(date);
          if (listData) {
            const { listData: calendarCell, fillCalendar } = listData;
            return {
              date,
              calendarCell,
              fillCalendar,
            };
          }
        })
        .filter(cad => cad) as ICalendarDate[];

      selectedRule.cadences
        ?.sort((a, b) => {
          return moment(a.startTime).unix() - moment(b.startTime).unix();
        })
        .forEach((value, index) => {
          const calendarCellDisabled = value
            ? [
                {
                  title: `Max: ${value.capacityPerDay}`,
                },
                {
                  title: `${moment(value.startTime).format('HH:mm')} - ${moment(
                    value.endTime
                  ).format('HH:mm')} `,
                },
              ]
            : [];

          if (value.preSchedules?.length) {
            dates[index] = {
              ...dates[index],
              calendarCell: calendarCellDisabled,
              fillCalendar: value,
            };
          }
        });

      setCalendarData(dates);
    }
  }, [updateCalendar]);
  useEffect(() => {
    if (selectedRule?.customerRules) {
      selectedRule.customerRules.length > 0
        ? setClientFormView(true)
        : setClientFormView(false);
    }
    if (selectedRule?.cadences) {
      selectedRule.cadences.map(({ startTime }) => {
        const sab = moment(startTime).isoWeekday() === 6;
        const dom = moment(startTime).isoWeekday() === 7;

        if (sab || dom) {
          setWeekendFormView(true);
        }
      });
    }
  }, [selectedRule]);
  useEffect(() => {
    setCustomerRules([...customerRules]);
  }, [custRuleVisible]);

  return (
    <>
      <Layout>
        <PageHeader
          title={t('pages.scheduleRules.editRules.title')}
          subTitle={t('pages.scheduleRules.editRules.subtitle')}
          ghost={false}
          breadcrumb={<BreadCrumb />}
        />
        <Layout style={{ margin: 24 }}>
          {rulesForm}
          {cadencesForm}
          <Alert
            style={{ marginBottom: 24 }}
            type="warning"
            message={t('pages.scheduleRules.editRules.featuredCadencesWarning')}
          />
          {customerRulesFormAndCard}
        </Layout>
      </Layout>
      <Footer>{footer}</Footer>

      <ConfirmRuleEdition
        visible={onOk}
        onOk={() => {
          setEnableSubmit(false);
          setUpdateCalendar(!updateCalendar);
          setOnOk(false);
          if (weekendFormView === false) {
            const weekendToRemove = calendarData.filter(({ date }) => {
              return (
                moment(date).isoWeekday() === 6 ||
                moment(date).isoWeekday() === 7
              );
            });
            deleteCadence(weekendToRemove);
          }
        }}
        onCancel={() => setOnOk(false)}
      />
      <CustomerRuleEdition
        customerRuleModalVisible={custRuleVisible}
        onOkCustomerRule={() => {
          setConfirmCustRule(!confirmCustRule);
          setCustRuleVisible(false);
        }}
        onCancelCustomerRule={() => setCustRuleVisible(false)}
        selectedCustomerRule={selectedCustomerRule}
        customerRules={customerRules}
        setCustomerRules={setCustomerRules}
        referenceRule={selectedRule}
      />
      <DeleteCustomerRule
        visible={deleteCRModalVisible}
        onOk={() => setDeleteCRModalVisible(false)}
        onCancel={() => setDeleteCRModalVisible(false)}
        selectedRule={selectedRule}
        selectedCustomerRule={selectedCustomerRule}
        setCustomerRules={setCustomerRules}
      />
    </>
  );
};
export default withTranslation()(EditScheduleRule);

// Formularios de edição no calendário
const FormEditCalendar = ({
  formCalendarEdit,
  setShowEditModal,
  setCalendarCellDate,
  initialValues,
  setEditingCalendarDateId,
  validValueRule,
  enableConfirm,
}: IFormEditCalendarProps) => {
  const { t } = useTranslation();
  const [isSwitchChecked, setIsSwitchChecked] = useState(
    initialValues.enableCadence
  );

  const isFilledQuantity = Form.useWatch(
    [initialValues.id, 'quantity'],
    formCalendarEdit
  );
  const isFilledHours = Form.useWatch(
    [initialValues.id, 'hours'],
    formCalendarEdit
  );
  const switchStyle: CSSProperties = !isSwitchChecked
    ? { background: '#00000040' }
    : { background: '#00CB0A' };

  if (!isSwitchChecked) formCalendarEdit.resetFields();

  return (
    <>
      <Form.Item
        initialValue={initialValues.id}
        name={[initialValues.id, 'id']}
        hidden
      >
        <InputNumber style={{ width: '100%' }} />
      </Form.Item>
      <Form.Item
        initialValue={initialValues.quantity}
        name={[initialValues.id, 'quantity']}
        label="Max: "
        rules={[() => validValueRule]}
      >
        <InputNumber
          disabled={!isSwitchChecked}
          style={{ width: '100%' }}
          placeholder={t('pages.scheduleRules.editCalendarInputPlaceholder')}
        />
      </Form.Item>
      <Form.Item
        initialValue={initialValues.hours}
        name={[initialValues.id, 'hours']}
        label={t('pages.scheduleRules.editCalendarLabelPlaceholder')}
      >
        <TimePicker.RangePicker disabled={!isSwitchChecked} format={'HH:mm'} />
      </Form.Item>
      <Form.Item
        initialValue={isSwitchChecked}
        name={[initialValues.id, 'enableCadence']}
      >
        <Switch
          style={switchStyle}
          onChange={() => setIsSwitchChecked(!isSwitchChecked)}
          checked={isSwitchChecked}
          unCheckedChildren="Desabilitado"
          checkedChildren="Habilitado"
        />
      </Form.Item>
      <Form.Item style={{ textAlign: 'right' }}>
        <Button
          style={{ marginRight: 24 }}
          danger
          onClick={e => {
            e.stopPropagation();
            setCalendarCellDate(null);
            formCalendarEdit.resetFields();
            setShowEditModal(false);
          }}
        >
          {t('pages.settings.deleteBranchCompany.cancelButton')}
        </Button>

        <Button
          type="primary"
          htmlType="submit"
          onClick={e => {
            e.stopPropagation();
            setCalendarCellDate(null);
            setEditingCalendarDateId();
            formCalendarEdit.submit();
            setShowEditModal(false);
          }}
          disabled={
            !enableConfirm ||
            (!isSwitchChecked && !(isFilledHours && isFilledQuantity))
          }
        >
          {t('pages.scheduleRules.confirmChangeBtn')}
        </Button>
      </Form.Item>
    </>
  );
};
const CreateCadenceInCalendar = ({
  form,
  onFinish,
  rules,
  showModal,
}: ICreateCalendarCadence) => {
  const { t } = useTranslation();
  return (
    <Form form={form} onFinish={onFinish}>
      <Form.Item name="quantity" label="Max: " rules={rules}>
        <InputNumber
          style={{ width: '100%' }}
          placeholder={t('pages.scheduleRules.editCalendarInputPlaceholder')}
        />
      </Form.Item>
      <Form.Item
        name="hours"
        label={t('pages.scheduleRules.editCalendarLabelPlaceholder')}
      >
        <TimePicker.RangePicker
          placeholder={[
            t('pages.scheduleRules.editRules.startTime'),
            t('pages.scheduleRules.editRules.endTime'),
          ]}
          format={'HH:mm'}
          allowClear={false}
        />
      </Form.Item>

      <Form.Item>
        <Form.Item style={{ textAlign: 'right' }}>
          <Button
            style={{ marginRight: 24 }}
            danger
            onClick={e => {
              e.stopPropagation();
              showModal(false);
            }}
          >
            {t('pages.settings.deleteBranchCompany.cancelButton')}
          </Button>

          <Button
            type="primary"
            htmlType="submit"
            onClick={e => {
              e.stopPropagation();
              showModal(false);
            }}
          >
            {t('pages.scheduleRules.createCadenceBtn')}
          </Button>
        </Form.Item>
      </Form.Item>
    </Form>
  );
};
