import {
  Alert,
  Button,
  Card,
  Col,
  Dropdown,
  Form,
  Input,
  Layout,
  MenuProps,
  PageHeader,
  Row,
  Select,
  Space,
  Table,
  Tag,
  message,
} from 'antd';
import { ColumnsType } from 'antd/lib/table';
import {
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import ImportCustomerModal from './importCustomers/modal';
import ViewCard from './viewCard';

import {
  CheckCircleOutlined,
  ClockCircleOutlined,
  CloseCircleOutlined,
  DownOutlined,
  EditFilled,
  EllipsisOutlined,
  EyeOutlined,
  LockOutlined,
  MailOutlined,
  PlusOutlined,
  SendOutlined,
} from '@ant-design/icons';
import { TableRowSelection } from 'antd/lib/table/interface';
import { useTranslation } from 'react-i18next';
import UserContext from '../../contexts/user';
import { useRequest } from '../../hooks/useRequest';
import ClientsController from '../../structures/controllers/Clients';
import {
  IGetClient,
  IUpdateCustomerFields,
} from '../../structures/interfaces/Clients';
import { IOptions, RegStatusEnum } from '../../structures/interfaces/User';
import { scrollOptions } from '../../utils/formOptions';
import { fetchSyncUrl, screenModule } from '../../utils/sync';
import NewCustomer from './NewCustomer.modal';
import ResetCustomerPassword from './ResetCustomersPass.modal';
import UpdateCustomers, {
  IUpdateCustomersProps,
} from './UpdateCustomers.modal';
import RegularizeCustomers from './regularizeCustomers';

const CustomersPage = (): ReactElement => {
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);
  const [isCreateCustomer, setIsCreateCustomer] = useState(false);
  const [fetchCust, setFetchCust] = useState(false);

  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [updateData, setUpdateData] = useState<IUpdateCustomerFields>();
  const [openForm, setOpenForm] = useState(false);
  const [openResetPass, setOpenResetPass] = useState(false);
  const [custData, setCustData] = useState<IGetClient>();

  const [importModalVisible, setImportModalVisible] = useState(false);
  const { Search } = Input;
  const [form] = Form.useForm();
  const [clients, setClients] = useState<IGetClient[]>();
  const [selectedClient, setSelectedClient] = useState<IGetClient>();
  const [filteredClients, setFilteredClients] = useState<IGetClient[]>();
  const {
    userData,
    filterByCreate,
    settings,
    integrationMod,
    changingCropFlag,
  } = useContext(UserContext);
  const [getClientsRequest, isGetClientsRequesting] = useRequest(
    ClientsController.getClients
  );
  const [sendNewAccess] = useRequest(ClientsController.sendNewCustomerAccess);

  const customerStatus: IOptions[] = [
    {
      label: t('enums.customerStatus.NOT_ACTIVATED'),
      value: 'NOT_ACTIVATED',
    },
    {
      label: t('enums.customerStatus.NOTIFIED'),
      value: 'NOTIFIED',
    },
    {
      label: t('enums.customerStatus.WAITING_ACTIVATION'),
      value: 'WAITING_ACTIVATION',
    },
    {
      label: t('enums.customerStatus.ACTIVATED'),
      value: 'ACTIVATED',
    },
  ];

  const onReset = () => {
    form.resetFields();
  };

  const handleClientView = (id: string) => {
    const client = clients?.find(client => client.id === id);
    if (client) {
      setSelectedClient(client);
    }
  };

  const openViewCustomerModal = (client: IGetClient) => {
    if (userData && clients) {
      getClientsRequest(userData.organization.id)
        .then(() => {
          setVisible(true);
          handleClientView(client.id);
        })
        .catch(() => {
          <Alert
            message={t('errors.900.message')}
            description={t('errors.900.description')}
          />;
        });
    }
  };
  const [modalView, setModalView] = useState(false);
  const openModalToRegularize = (client: IGetClient) => {
    handleClientView(client.id);
    setModalView(true);
  };

  const columns: ColumnsType<IGetClient> = [
    {
      title: 'Contato',
      dataIndex: ['name'],
      key: 'name',
      render: (_, record) => {
        return record.name;
      },
      sorter: (a, b) => {
        if (a.companyName && b.companyName) {
          const nameA = a.companyName || '';
          const nameB = b.companyName || '';
          return nameA.localeCompare(nameB);
        }
        if (a.tradeName && b.tradeName) {
          const nameA = a.tradeName || '';
          const nameB = b.tradeName || '';
          return nameA.localeCompare(nameB);
        } else {
          const nameA = a.name || '';
          const nameB = b.name || '';
          return nameA.localeCompare(nameB);
        }
      },
    },
    {
      title: t('pages.clients.table.companyName'),
      dataIndex: ['companyName'],
      render: (_, record) => {
        return record.companyName || '---';
      },
      key: 'companyName',
    },
    {
      title: t('pages.clients.table.documentNumberCpf'),
      dataIndex: ['documentNumberCpfMask'],
      key: 'documentNumberCpf',
      render: data => {
        return data ? data : '---';
      },
    },
    {
      title: t('pages.clients.table.documentNumberCnpj'),
      dataIndex: ['documentNumberCnpjMask'],
      key: 'documentNumberCnpj',
      render: data => {
        return data ? data : '---';
      },
    },
    {
      title: t('pages.clients.table.phoneNumber'),
      dataIndex: ['phoneNumberMask'],
      render: phone => {
        return phone ? phone : '---';
      },
      key: 'phoneNumber',
    },
    {
      title: t('pages.clients.table.email'),
      dataIndex: ['email'],
      key: 'email',
    },
    {
      title: 'Status',
      dataIndex: ['status'],
      render: status => {
        switch (status) {
          case 'NOT_ACTIVATED':
            return (
              <Tag icon={<CloseCircleOutlined />} color="volcano">
                <b>{RegStatusEnum.NOT_ACTIVATED}</b>
              </Tag>
            );
          case 'NOTIFIED':
            return (
              <Tag icon={<MailOutlined />} color="processing">
                <b>{RegStatusEnum.NOTIFIED}</b>
              </Tag>
            );
          case 'WAITING_ACTIVATION':
            return (
              <Tag icon={<ClockCircleOutlined />} color="default">
                <b>{RegStatusEnum.WAITING_ACTIVATION}</b>
              </Tag>
            );
          case 'ACTIVATED':
            return (
              <Tag icon={<CheckCircleOutlined />} color="success">
                <b>{RegStatusEnum.ACTIVATED}</b>
              </Tag>
            );
        }
      },
      key: 'email',
    },
    {
      title: t('pages.settings.actions'),
      key: 'action',
      render: (_, record) => (
        <Dropdown
          menu={{
            items: [
              {
                label: 'Visualizar',
                key: 'view',
                icon: <EyeOutlined />,
                onClick: () => openViewCustomerModal(record),
              },
              {
                label: 'Resetar Senha',
                key: 'reset',
                icon: <LockOutlined />,
                onClick: () => {
                  setCustData(record);
                  setOpenResetPass(true);
                },
              },
              {
                label: 'Editar',
                key: 'update',
                icon: <EditFilled />,
                onClick: () => {
                  setOpenForm(true);
                  setUpdateData({
                    ...record,
                    documentNumberCnpj: record.documentNumberCnpj,
                    documentNumberCpf: record.documentNumberCpf,
                  });
                },
              },
            ],
          }}
          trigger={['click']}
          placement="top"
        >
          <EllipsisOutlined style={{ cursor: 'pointer', fontSize: 30 }} />
        </Dropdown>
      ),
    },
  ];

  const buildSlug = (clients: IGetClient[]) =>
    clients?.map(clients => ({
      ...clients,
      slug: `${clients.name} ${clients.id} ${clients.tradeName} ${clients.email} ${clients.companyName} ${clients.documentNumberCnpj} ${clients.documentNumberCpf} ${clients.documentNumberCpfMask} ${clients.documentNumberCnpjMask} ${clients.phoneNumber} ${clients.phoneNumberMask} ${clients.farms}`.toLowerCase(),
    }));

  const filterCustomersByStatus = (
    customers: IGetClient[],
    customerStatus?: string
  ) =>
    customerStatus != undefined
      ? customers.filter(customer => customer.status === customerStatus)
      : customers;

  const getCustomer = useCallback(() => {
    if (userData) {
      getClientsRequest(userData.organization.id)
        .then(client => {
          const filteredClients = buildSlug(client);
          setClients(filteredClients);
          setFilteredClients(filteredClients);
        })
        .catch(() => {
          <Alert
            message={t('errors.900.message')}
            description={t('errors.900.description')}
          />;
        });
    }
  }, [modalView, userData, changingCropFlag, openForm, fetchCust]);

  useEffect(() => {
    if (userData && modalView === false) {
      getClientsRequest(userData.organization.id)
        .then(client => {
          const filteredClients = buildSlug(client);
          setClients(filteredClients);
          setFilteredClients(filteredClients);
        })
        .catch(() => {
          <Alert
            message={t('errors.900.message')}
            description={t('errors.900.description')}
          />;
        });
    }
  }, [userData, modalView, changingCropFlag, openForm, fetchCust]);

  const onFinish = ({
    search = '',
    customerStatus,
  }: {
    search?: string;
    customerStatus?: string;
  }) => {
    if (clients) {
      const filteredCustomers = clients.filter(client =>
        client.slug?.includes(search.toLowerCase().trim())
      );
      setFilteredClients(
        filterCustomersByStatus(filteredCustomers, customerStatus)
      );
    }
  };

  const createCustomerPermission = filterByCreate?.find(
    permission => permission?.userPermissions.module === 'CLIENTE'
  );

  const syncCustomersSuccess = () => {
    message.success(t('pages.sync.success.syncCustomersSuccess'));
  };

  const syncCustomersError = () => {
    message.error(t('pages.sync.errors.syncAllError'));
  };

  const syncClients = () => {
    const mod = screenModule({ intMod: integrationMod, mod: 'CUSTOMERS' });
    if (settings && mod) {
      return (
        <Button
          onClick={() =>
            fetchSyncUrl({
              url: settings.integrationUrl,
              mod,
              syncError: syncCustomersError,
              syncSuccess: syncCustomersSuccess,
            })
          }
          size="small"
        >
          {t('pages.sync.labels.syncCustomers')}
        </Button>
      );
    }
  };

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection: TableRowSelection<IGetClient> = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const hasSelected = selectedRowKeys.length > 0;

  const items: MenuProps['items'] = [
    {
      label: 'Disparar e-mails de primeiro acesso',
      key: 'first-access',
      icon: <SendOutlined />,
      disabled: !hasSelected,
      onClick: () => {
        const keys = selectedRowKeys as string[];
        sendNewAccess({ ids: keys })
          .then(() => message.success('E-mails enviados com sucesso'))
          .catch(() => message.error('Não foi possível enviar os e-mails'));
      },
    },
    {
      label: 'Adicionar cliente',
      key: 'new-customer',
      icon: <PlusOutlined />,
      onClick: () => setIsCreateCustomer(true),
    },
  ];

  const menuProps = {
    items,
  };

  const aux = (
    <Dropdown menu={menuProps} trigger={['click']}>
      <Button type="primary">
        <Space size={24}>
          Ações
          <DownOutlined />
        </Space>
      </Button>
    </Dropdown>
  );

  return (
    <>
      <Layout>
        <PageHeader
          title={t('pages.clients.title')}
          subTitle={t('pages.clients.subtitle')}
          // extra={
          //   <>
          //     {createCustomerPermission && (
          //       <Button
          //         size="small"
          //         type="primary"
          //         onClick={() => setImportModalVisible(true)}
          //       >
          //         {t('pages.clients.importClientsBtn')}
          //       </Button>
          //     )}
          //   </>
          // }
          ghost={false}
        />
        <Layout style={{ margin: 24 }}>
          <Card
            size="small"
            style={{ paddingRight: 12, paddingLeft: 12, marginBottom: 24 }}
          >
            <Form
              scrollToFirstError={scrollOptions}
              layout="vertical"
              form={form}
              onFinish={onFinish}
            >
              <Row gutter={[24, 0]}>
                <Col xs={24} sm={24} md={12} lg={8} xl={6} xxl={6}>
                  <Form.Item label=" " name="search">
                    <Search
                      enterButton
                      onSearch={() => form.submit()}
                      size="small"
                      placeholder={t('pages.clients.searchItemPlaceholder')}
                    />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={24} md={12} lg={8} xl={6} xxl={6}>
                  <Form.Item
                    name="customerStatus"
                    label={t('pages.clients.customerStatusLabel')}
                  >
                    <Select
                      size="small"
                      options={customerStatus}
                      placeholder={t('general.select')}
                      allowClear
                    />
                  </Form.Item>
                </Col>

                <Col
                  xs={24}
                  sm={24}
                  md={24}
                  lg={8}
                  xl={12}
                  xxl={12}
                  style={{ textAlign: 'right' }}
                >
                  <Form.Item label=" ">
                    <Button
                      style={{ marginRight: 24 }}
                      size="small"
                      onClick={onReset}
                    >
                      {t('pages.clients.clearFilter')}
                    </Button>
                    <Button
                      size="small"
                      type="primary"
                      onClick={() => form.submit()}
                    >
                      {t('pages.clients.filter')}
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Card>

          <Card extra={aux} title={t('pages.clients.listingCardTitle')}>
            <Table
              rowSelection={rowSelection}
              scroll={{ x: 1300 }}
              rowKey={customer => customer.id}
              columns={columns}
              dataSource={filteredClients}
              size="small"
              loading={isGetClientsRequesting}
              pagination={{ hideOnSinglePage: true }}
            />
          </Card>
        </Layout>
      </Layout>
      <ViewCard
        client={selectedClient}
        visible={visible}
        onClose={() => setVisible(false)}
      />
      <ImportCustomerModal
        visible={importModalVisible}
        onClose={() => setImportModalVisible(false)}
      />
      <RegularizeCustomers
        visible={modalView}
        onClose={() => setModalView(false)}
        onConfirm={() => {
          setModalView(false);
          getCustomer();
        }}
        data={selectedClient}
      />
      <UpdateCustomers
        data={updateData}
        open={openForm}
        setOpen={setOpenForm}
        setCust={setFetchCust}
      />
      <ResetCustomerPassword
        open={openResetPass}
        setOpen={setOpenResetPass}
        data={custData}
      />
      <NewCustomer
        setOpen={setIsCreateCustomer}
        open={isCreateCustomer}
        setFetchCust={setFetchCust}
      />
    </>
  );
};

export default CustomersPage;
