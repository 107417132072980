import { Col, Form, Input, message, Modal, Row } from 'antd';
import { AxiosRequestConfig } from 'axios';
import { Dispatch, SetStateAction, useEffect } from 'react';
import Formatter from '../../classes/Formatter';
import Normalizer from '../../classes/Normalizer';
import { IUpdateCustomerFields } from '../../structures/interfaces/Clients';
import { useRequest } from '../../hooks/useRequest';
import ClientsController from '../../structures/controllers/Clients';

export interface IUpdateCustomersProps {
  id: string;
  name: string;
  companyName: string;
  documentCpf: string;
  documentCnpj: string;
  phoneNumber: string;
  email: string;
}

interface IUpdateClientProps {
  data?: IUpdateCustomerFields;
  open: boolean;
  setOpen: (value: boolean) => void;
  setCust: Dispatch<SetStateAction<boolean>>;
}

const UpdateCustomers = ({
  data,
  open,
  setOpen,
  setCust,
}: IUpdateClientProps) => {
  const [form] = Form.useForm<IUpdateCustomerFields>();
  const [updateCustomer, isLoading] = useRequest(
    ClientsController.updateCustomer
  );

  const handleSubmit = (formValues: IUpdateCustomerFields) => {
    if (!data?.id) return null;

    const treatedValues = {
      ...formValues,
      documentNumberCnpj:
        formValues.documentNumberCnpj && formValues.documentNumberCnpj !== ''
          ? Normalizer.onlyNumbers(formValues.documentNumberCnpj)
          : null,
      documentNumberCpf:
        formValues.documentNumberCpf && formValues.documentNumberCpf !== ''
          ? Normalizer.onlyNumbers(formValues.documentNumberCpf)
          : null,
      phoneNumber:
        formValues.phoneNumber && formValues.phoneNumber !== ''
          ? Normalizer.onlyNumbers(formValues.phoneNumber)
          : null,
    } as IUpdateCustomerFields;

    updateCustomer({ customerData: treatedValues, id: data.id })
      .then(() => {
        message.success('Cliente atualizado com sucesso!');
        setCust(v => !v);
      })
      .catch(() => message.error('Não foi possível atualizar o cliente'));
  };

  useEffect(() => {
    form.setFieldsValue({
      ...data,
      documentNumberCnpj: Formatter.formatCNPJ(data?.documentNumberCnpj),
      documentNumberCpf: Formatter.formatCPF(data?.documentNumberCpf),
      phoneNumber: Formatter.formatCellphone(data?.phoneNumber),
    });
  }, [data]);

  return (
    <Modal
      title="Atualizar cliente"
      open={open}
      width={1000}
      onCancel={() => setOpen(false)}
      onOk={() => {
        form.submit();
        setOpen(false);
      }}
      closeIcon={false}
      okButtonProps={{ loading: isLoading }}
    >
      <Form form={form} layout="vertical" onFinish={v => handleSubmit(v)}>
        <Form.Item hidden={true} name={'id'}>
          <Input />
        </Form.Item>

        <Row gutter={[24, 24]}>
          <Col span={12}>
            <Form.Item label="Nome" name={'name'}>
              <Input />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item label="Razão social" name={'companyName'}>
              <Input />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              normalize={Formatter.formatCPF}
              label="CPF"
              name={'documentNumberCpf'}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              normalize={Formatter.formatCNPJ}
              label="CNPJ"
              name={'documentNumberCnpj'}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              normalize={Formatter.formatCellphone}
              label="Telefone"
              name={'phoneNumber'}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item label="E-mail" name={'email'}>
              <Input />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default UpdateCustomers;
