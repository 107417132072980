import { Card, Form, Row, Col, Select, Button, Input, DatePicker } from 'antd';
import { scrollOptions } from '../../utils/formOptions';
import { ReactElement, useContext } from 'react';
import UserContext from '../../contexts/user';
import { useTranslation } from 'react-i18next';
import {
  IDashboardContractTable,
  IGetContractWhere,
} from '../../structures/interfaces/Contract';
import { useRequest } from '../../hooks/useRequest';
import ContractController from '../../structures/controllers/Contract';
import {
  BuildContracts,
  BuildSlug,
  FilterContractsByDate,
  FilterContractsByStatus,
} from './Contracts.utils';
import { Moment } from 'moment';

const { Search } = Input;
const { RangePicker } = DatePicker;

const colSize = { xs: 24, sm: 24, md: 12, lg: 12, xl: 6, xxl: 5 };
const buttonsSize = { xs: 24, sm: 24, md: 24, lg: 24, xl: 24, xxl: 4 };

interface IContractsFilterProps {
  contracts: IDashboardContractTable[] | undefined;
  filteredContracts: IDashboardContractTable[] | undefined;
  setContracts: (contracts: IDashboardContractTable[] | undefined) => void;
  setFilteredContracts: (
    contracts: IDashboardContractTable[] | undefined
  ) => void;
}
const ContractsFilter = ({
  contracts,
  setFilteredContracts,
  filteredContracts,
  setContracts,
}: IContractsFilterProps): ReactElement => {
  const [form] = Form.useForm();
  const { userData, customerData } = useContext(UserContext);
  const { t } = useTranslation();

  const [fetchContracts, isLoading] = useRequest(
    ContractController.getContractsById
  );

  const onFinish = ({
    search = '',
    status,
    contractDate,
    salesmen,
  }: {
    search?: string;
    status?: string;
    contractDate?: [Moment, Moment];
    salesmen?: string;
  }) => {
    if (contracts) {
      if (userData) {
        let filters: IGetContractWhere = {
          organization_id: userData.organization.id,
          subordinates_filter: null,
        };

        if (salesmen === 'subordinatesContracts') {
          filters = {
            organization_id: userData.organization.id,
            subordinates_filter: {
              subordinates_only: true,
            },
          };
        } else if (salesmen === 'myContracts') {
          filters = {
            organization_id: userData.organization.id,
            subordinates_filter: {
              ignore_subordinates: true,
            },
          };
        }
        fetchContracts(filters)
          .then(contracts => {
            const contractTableValues = BuildSlug(BuildContracts(contracts));
            setFilteredContracts(
              FilterContractsByDate(
                FilterContractsByStatus(
                  contractTableValues.filter(contract =>
                    contract.slug?.includes(search.toLowerCase().trim())
                  ),
                  status
                ),
                contractDate
              )
            );
          })
          .catch(() => null);
      } else if (customerData) {
        setFilteredContracts(
          FilterContractsByDate(
            FilterContractsByStatus(
              contracts.filter(contract =>
                contract.slug?.includes(search.toLowerCase().trim())
              ),
              status
            ),
            contractDate
          )
        );
      }
    }
  };

  const statusOptions = [
    { label: t('pages.home.waitingSchedule'), value: 'WAITING_SCHEDULE' },
    { label: t('pages.home.scheduleFinished'), value: 'SCHEDULE_FINISHED' },
    { label: t('pages.home.waitingRelease'), value: 'WAITING_RELEASE' },
  ];
  const salesmanOptions = [
    {
      label: t('pages.contracts.allContracts'),
      value: 'allContracts',
    },
    {
      label: t('pages.contracts.subordinatesContracts'),
      value: 'subordinatesContracts',
    },
    {
      label: t('pages.contracts.myContracts'),
      value: 'myContracts',
    },
  ];

  return (
    <Card
      size="small"
      style={{ paddingRight: 12, paddingLeft: 12, marginBottom: 24 }}
    >
      <Form
        scrollToFirstError={scrollOptions}
        layout="vertical"
        form={form}
        onFinish={onFinish}
      >
        <Row gutter={[24, 0]} style={{ alignItems: 'end' }}>
          <Col {...colSize}>
            <Form.Item name="search">
              <Search
                enterButton
                onSearch={() => form.submit()}
                size="small"
                placeholder={t('pages.contracts.searchItemPlaceholder')}
              />
            </Form.Item>
          </Col>
          <Col {...colSize}>
            <Form.Item name="status" label={t('pages.contracts.statusLabel')}>
              <Select
                allowClear
                size="small"
                placeholder={t('pages.home.selectStatus')}
                options={statusOptions}
              />
            </Form.Item>
          </Col>
          <Col {...colSize}>
            <Form.Item
              name="contractDate"
              label={t('pages.contracts.contractDateLabel')}
            >
              <RangePicker
                size="small"
                picker="date"
                format="DD/MM/YYYY"
                style={{ display: 'flex' }}
                allowClear
              />
            </Form.Item>
          </Col>

          {userData && (
            <Col {...colSize}>
              <Form.Item name="salesmen" label={t('pages.contracts.salesmen')}>
                <Select
                  size="small"
                  allowClear
                  placeholder={t('pages.contracts.phSalesmen')}
                  options={salesmanOptions}
                />
              </Form.Item>
            </Col>
          )}

          <Col {...buttonsSize} style={{ textAlign: 'right' }}>
            <Form.Item>
              <Button
                style={{ marginRight: 24 }}
                size="small"
                htmlType="reset"
                onClick={() => {
                  form.resetFields();
                  setFilteredContracts(contracts);
                }}
              >
                {t('pages.contracts.clearFilter')}
              </Button>
              <Button
                size="small"
                type="primary"
                htmlType="submit"
                loading={isLoading}
              >
                {t('pages.contracts.filter')}
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};

export default ContractsFilter;
