import {
  CheckCircleOutlined,
  ClockCircleOutlined,
  CloseCircleOutlined,
  MailOutlined,
} from '@ant-design/icons';
import {
  Alert,
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Input,
  Layout,
  PageHeader,
  Row,
  Select,
  Space,
  Table,
  Tag,
  Tooltip,
  Typography,
  message,
} from 'antd';
import { ColumnsType } from 'antd/lib/table';
import moment from 'moment';
import { ReactElement, useContext, useEffect, useState } from 'react';
import { TFunction, withTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import UserContext from '../../contexts/user';
import { useRequest } from '../../hooks/useRequest';
import UserController from '../../structures/controllers/User';
import {
  IGetUser,
  IGetUserUpdate,
  IOptions,
  RegStatusEnum,
} from '../../structures/interfaces/User';
import DeleteUser from './modals/deleteUser';
import RegularizeUser from './modals/regularizeUser';
import UpdateUser from './modals/updateUser';

import { scrollOptions } from '../../utils/formOptions';
import { fetchSyncUrl, screenModule } from '../../utils/sync';

const UsersPage = ({ t }: { t: TFunction }): ReactElement<unknown> => {
  // hooks
  const { RangePicker } = DatePicker;
  const { Search } = Input;
  const [pageSize, setPageSize] = useState(10);
  const {
    userData,
    customerData,
    filterByCreate,
    filterByUpdate,
    filterByDelete,
    settings,
    integrationMod,
    changingCropFlag,
  } = useContext(UserContext);

  const [form] = Form.useForm();

  const { state } = useLocation();

  const [deleteUserModalVisible, setDeleteUserModalVisible] = useState(false);
  const [updateUserModalVisible, setUpdateUserModalVisible] = useState(false);
  const [users, setUsers] = useState<IGetUser[]>([]);
  const [userTypes, setUserTypes] = useState<IOptions[]>([]);
  const [filteredUsers, setFilteredUsers] = useState<IGetUser[]>([]);
  const [updateUserData, setUpdateUserData] = useState<any>();
  const [regularizeUserData, setRegularizeUserData] = useState<any>();
  const [userDataForDelete, setUserDataForDelete] = useState<IGetUser>();

  const [userRequesting] = useRequest(UserController.getUser);
  const [getUsersRequest, isGetUsersRequesting] = useRequest(
    UserController.getUserByOrganizationId
  );
  const [getUsersPermissionsRequest] = useRequest(
    UserController.getUserPermissions
  );

  const userStatus: IOptions[] = [
    {
      label: t('enums.userStatus.NOT_ACTIVATED'),
      value: 'NOT_ACTIVATED',
    },
    {
      label: t('enums.userStatus.NOTIFIED'),
      value: 'NOTIFIED',
    },
    {
      label: t('enums.userStatus.WAITING_ACTIVATION'),
      value: 'WAITING_ACTIVATION',
    },
    {
      label: t('enums.userStatus.ACTIVATED'),
      value: 'ACTIVATED',
    },
  ];

  // get permissions
  const createUserPermission = filterByCreate?.find(
    permission => permission?.userPermissions.module === 'USUARIOS'
  );
  const updateUserPermission = filterByUpdate?.find(
    permission => permission?.userPermissions.module === 'USUARIOS'
  );
  const deleteUserPermission = filterByDelete?.find(
    permission => permission?.userPermissions.module === 'USUARIOS'
  );

  const openModalToEditUser = (record: IGetUser) => {
    setUpdateUserModalVisible(true);
    if (record)
      userRequesting(record.id).then(data => {
        data.userPermissionsData = data.userHasPermissions.map(
          item => item.userPermissions
        );
        if ({ ...data } && record.organization) {
          setUpdateUserData({
            ...data,
            organization: record.organization,
            userType: record.userType,
            receiveNotifications: data.receiveNotifications,
          });
        }
      });
  };
  const openModalToDeleteUser = (record: IGetUser) => {
    setUserDataForDelete(record);
    setDeleteUserModalVisible(true);
  };

  const [modalView, setModalView] = useState(false);
  const openModalToRegularize = (record: IGetUser) => {
    setModalView(true);
    if (record)
      userRequesting(record.id).then(data => {
        data.userPermissionsData = data.userHasPermissions.map(
          item => item.userPermissions
        );
        if (data && record.organization) {
          setRegularizeUserData({
            ...data,
            organization: record.organization,
          });
        }
      });
  };
  const columnsUsers: ColumnsType<IGetUser> = [
    {
      title: t('pages.users.userType'),
      dataIndex: ['userType', 'id'],
      key: 'userType',
      render: (text, record) => {
        return (
          <Tooltip key={record.id} title={record?.userType?.typeDescription}>
            {text}
          </Tooltip>
        );
      },
      sorter: {
        compare: (a, b) => {
          return moment(a.updatedAt).unix() - moment(b.updatedAt).unix();
        },
        multiple: 1,
      },
      sortDirections: ['descend'],
      defaultSortOrder: 'descend',
    },
    {
      title: t('pages.users.creationDate'),
      dataIndex: ['createdAtString'],
      key: 'creationDate',
    },
    {
      title: t('pages.users.userName'),
      dataIndex: ['name'],
      key: 'name',
      sorter: (a, b) => {
        const nameA = a.name || '';
        const nameB = b.name || '';
        return nameA.localeCompare(nameB);
      },
    },
    {
      title: t('pages.users.documentNumberCpf'),
      dataIndex: ['documentNumberCpfMask'],
      key: 'documentNumberCpf',
    },
    {
      title: t('pages.users.company'),
      dataIndex: ['organization', 'tradeName'],
      key: 'companyName',
    },
    {
      title: t('pages.users.cellPhone'),
      dataIndex: ['phoneNumberMask'],
      key: 'phoneNumber',
    },
    {
      title: t('pages.users.email'),
      dataIndex: ['email'],
      key: 'email',
    },
    {
      title: 'Status',
      dataIndex: ['status'],
      render: status => {
        switch (status) {
          case 'NOT_ACTIVATED':
            return (
              <Tag icon={<CloseCircleOutlined />} color="volcano">
                <b>{RegStatusEnum.NOT_ACTIVATED}</b>
              </Tag>
            );
          case 'NOTIFIED':
            return (
              <Tag icon={<MailOutlined />} color="processing">
                <b>{RegStatusEnum.NOTIFIED}</b>
              </Tag>
            );
          case 'WAITING_ACTIVATION':
            return (
              <Tag icon={<ClockCircleOutlined />} color="default">
                <b>{RegStatusEnum.WAITING_ACTIVATION}</b>
              </Tag>
            );
          case 'ACTIVATED':
            return (
              <Tag icon={<CheckCircleOutlined />} color="success">
                <b>{RegStatusEnum.ACTIVATED}</b>
              </Tag>
            );
        }
      },
      key: 'status',
    },
    {
      title: t('pages.users.actions'),
      key: 'action',
      render: (_, record) => {
        if (record.status == 'ACTIVATED') {
          return (
            <Space size="middle">
              {updateUserPermission && (
                <Typography.Link onClick={() => openModalToEditUser(record)}>
                  {t('pages.settings.actionEdit')}
                </Typography.Link>
              )}

              {deleteUserPermission && (
                <Typography.Link
                  type="danger"
                  onClick={() => openModalToDeleteUser(record)}
                >
                  {t('pages.settings.actionDelete')}
                </Typography.Link>
              )}
            </Space>
          );
        }
        if (record.status == 'NOT_ACTIVATED') {
          return (
            <Space size="middle">
              <Typography.Link
                type="danger"
                onClick={() => openModalToRegularize(record)}
              >
                {t('pages.users.regularizeUser.regActions.activate')}
              </Typography.Link>
            </Space>
          );
        }
        if (record.status == 'WAITING_ACTIVATION') {
          return (
            <Space size="middle">
              <Typography.Link disabled color="default">
                {t('pages.users.regularizeUser.regActions.waitingAccess')}
              </Typography.Link>
            </Space>
          );
        }
        if (record.status == 'NOTIFIED') {
          return (
            <Space size="middle">
              <Typography.Link disabled color="default">
                Aguardando acesso...
              </Typography.Link>
            </Space>
          );
        }
      },
    },
  ];
  const filteredColumns = columnsUsers.filter(column => {
    if (!updateUserPermission && !deleteUserPermission) {
      return column.key != 'action';
    } else {
      return column;
    }
  });

  // manipulate functions
  const onReset = () => {
    form.resetFields();
  };
  const newUserBtn = (
    <>
      {createUserPermission && (
        <Link to={'/usuarios/novo'}>
          <Button type="primary" size="small">
            {t('pages.users.newUserBtn')}
          </Button>
        </Link>
      )}
    </>
  );
  useEffect(() => {
    if (userData) {
      getUsersRequest(userData.organization.id).then(users => {
        const loggedUser = users.find(user => user.id === userData.id);
        const visibleUsers = users.filter(user => user.id !== loggedUser?.id);
        setUsers(visibleUsers);
        setFilteredUsers(visibleUsers);
      });
    }
  }, [customerData, userData, state, changingCropFlag]);

  const onFinish = ({
    search = '',
    userType,
    userDate,
    userStatus,
  }: {
    search?: string;
    userType?: string;
    userDate?: [moment.Moment, moment.Moment];
    userStatus?: string;
  }) => {
    if (users) {
      const filters = UserController.filterUsers;
      setFilteredUsers(() =>
        filters(users, {
          search,
          userType,
          userDate,
          userStatus,
        })
      );
    }
  };
  // NOTE: essa função permite que o usuário filtre apenas dias anteriores a hoje
  // const disabledDate: RangePickerProps['disabledDate'] = current => {
  //   return current && current > moment().endOf('day');
  // };

  const colSize = {
    xs: 24,
    sm: 24,
    md: 12,
    lg: 12,
    xl: 12,
    xxl: 6,
  };

  // screen components
  const filterCard = () => {
    return (
      <Card
        size="small"
        style={{ paddingRight: 12, paddingLeft: 12, marginBottom: 24 }}
      >
        <Form
          scrollToFirstError={scrollOptions}
          layout="vertical"
          form={form}
          onFinish={onFinish}
        >
          <Row gutter={[24, 0]} style={{ alignItems: 'end' }}>
            <Col {...colSize}>
              <Form.Item name="search">
                <Search
                  enterButton
                  onSearch={() => form.submit()}
                  size="small"
                  placeholder={t('pages.users.searchItemPlaceholder')}
                />
              </Form.Item>
            </Col>
            <Col {...colSize}>
              <Form.Item name="userType" label={t('pages.users.userType')}>
                <Select
                  size="small"
                  options={userTypes}
                  placeholder={t('pages.users.phUserType')}
                  allowClear
                />
              </Form.Item>
            </Col>
            <Col {...colSize}>
              <Form.Item name="userStatus" label={t('pages.users.userStatus')}>
                <Select
                  size="small"
                  options={userStatus}
                  placeholder={t('general.select')}
                  allowClear
                />
              </Form.Item>
            </Col>
            <Col {...colSize}>
              <Form.Item name="userDate" label={t('pages.users.creationDate')}>
                <RangePicker
                  size="small"
                  picker="date"
                  format="DD/MM/YYYY"
                  style={{ display: 'flex' }}
                  allowClear
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={24}
              xl={24}
              xxl={24}
              style={{ textAlign: 'right' }}
            >
              <Form.Item>
                <Button
                  style={{ marginRight: 24 }}
                  size="small"
                  onClick={onReset}
                >
                  {t('pages.users.clearFilter')}
                </Button>
                <Button
                  size="small"
                  type="primary"
                  onClick={() => form.submit()}
                >
                  {t('pages.users.filter')}
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Card>
    );
  };
  const listingCard = () => {
    return (
      <Card
        title={t('pages.users.listingCardTitle')}
        extra={
          <>
            {syncUsers()}
            {newUserBtn}
          </>
        }
      >
        <Table
          rowKey={user => user.id}
          scroll={{ x: 1300 }}
          columns={filteredColumns}
          size="small"
          dataSource={filteredUsers}
          loading={isGetUsersRequesting}
          pagination={{
            hideOnSinglePage: true,
            pageSize: pageSize,
            onChange(_, size) {
              setPageSize(size);
            },
          }}
        />
      </Card>
    );
  };

  const updateModal = () => {
    return (
      <UpdateUser
        visible={updateUserModalVisible}
        onClose={() => setUpdateUserModalVisible(false)}
        data={updateUserData}
        onConfirm={() => {
          if (userData) {
            getUsersRequest(userData.organization.id).then(users => {
              const loggedUser = users.find(user => user.id === userData.id);
              const visibleUsers = users.filter(
                user => user.id !== loggedUser?.id
              );
              setUsers(visibleUsers);
              setFilteredUsers(visibleUsers);
            });

            getUsersPermissionsRequest({})
              .then(permission => {
                const type = permission.map(usrType => {
                  return {
                    label: usrType.userType.id,
                    value: usrType.userType.id,
                  };
                });
                setUserTypes(type);
              })
              .catch(() => {
                <Alert
                  message={t('error.100.message')}
                  description={t('error.100.description')}
                />;
              });
          }
          setUpdateUserModalVisible(false);
        }}
      />
    );
  };
  const regularizeModal = () => {
    return (
      <>
        <RegularizeUser
          data={regularizeUserData}
          visible={modalView}
          onClose={() => setModalView(false)}
          onConfirm={() => {
            if (userData) {
              getUsersRequest(userData.organization.id).then(users => {
                const loggedUser = users.find(user => user.id === userData.id);
                const visibleUsers = users.filter(
                  user => user.id !== loggedUser?.id
                );
                setUsers(visibleUsers);
                setFilteredUsers(visibleUsers);
              });
            }
            setModalView(false);
          }}
        />
      </>
    );
  };
  const deleteModal = (deletePermission: IGetUser | undefined) => {
    if (deletePermission) {
      return (
        <DeleteUser
          visible={deleteUserModalVisible}
          onConfirm={() => {
            if (filteredUsers) {
              const newData = [...filteredUsers];
              const index = filteredUsers.findIndex(
                item => deletePermission.id === item.id
              );
              newData.splice(index, 1);
              setFilteredUsers(newData);
            }

            setDeleteUserModalVisible(false);
          }}
          deleteData={deletePermission}
          onClose={() => setDeleteUserModalVisible(false)}
        />
      );
    }
  };

  useEffect(() => {
    if (userData) {
      getUsersRequest(userData.organization.id).then(users => {
        const loggedUser = users.find(user => user.id === userData.id);
        const visibleUsers = users.filter(user => user.id !== loggedUser?.id);
        setUsers(visibleUsers);
        setFilteredUsers(visibleUsers);
      });

      getUsersPermissionsRequest({}).then(permission => {
        const type = permission.map(usrType => {
          return { label: usrType.userType.id, value: usrType.userType.id };
        });
        setUserTypes(type);
      });
    }
  }, [customerData, userData, state]);

  const syncUsersSuccess = () => {
    message.success(t('pages.sync.success.syncUsersSuccess'));
  };

  const syncUsersError = () => {
    message.error(t('pages.sync.errors.syncAllError'));
  };

  const syncUsers = () => {
    const mod = screenModule({ intMod: integrationMod, mod: 'USERS' });
    if (settings && mod) {
      return (
        <Button
          onClick={() =>
            fetchSyncUrl({
              url: settings.integrationUrl,
              mod,
              syncError: syncUsersError,
              syncSuccess: syncUsersSuccess,
            })
          }
          style={{ marginRight: 24 }}
          size="small"
        >
          {t('pages.sync.labels.syncUsers')}
        </Button>
      );
    }
  };

  return (
    <>
      <Layout>
        <PageHeader
          title={t('pages.users.title')}
          subTitle={t('pages.users.subtitle')}
          ghost={false}
        />
        <Layout style={{ margin: 24 }}>
          {filterCard()}
          {listingCard()}
        </Layout>
      </Layout>
      {updateModal()}
      {deleteModal(userDataForDelete)}
      {regularizeModal()}
    </>
  );
};

export default withTranslation()(UsersPage);
