import { Row } from 'antd';
import React from 'react';
import Caminhao from '../../../assets/caminhao.svg';
import './TruckLoad.scss';

export default function TruckImage({
  colorsAssigned,
  truckLoadItems,
  onBoxHover,
  hoveredRow,
}: {
  colorsAssigned: Record<string, string>;
  truckLoadItems: {
    id?: string;
    contractItemId?: string;
    allocatedAmount: number;
  }[];
  onBoxHover: (index: number) => void;
  hoveredRow?: number;
}): JSX.Element {
  const colors = [
    '#C97A6D',
    '#7AC97D',
    '#7A6DC9',
    '#C9BC7A',
    '#C97AA6',
    '#A17AC9',
    '#7AC9A1',
    '#7A9BC9',
    '#C96D7A',
    '#7AC9BD',
    '#C97A68',
    '#7A91B3',
    '#B38A7A',
    '#7AB3B0',
  ];
  const boxDetailCommonStyle = {
    position: 'absolute',
    width: 4,
    height: 8,
    background: '#F5A714',
    boxSizing: 'border-box',
    border: 'solid 1px #000',
  } as React.CSSProperties;

  const renderBox = (background: string, index: number) => (
    <div
      key={index}
      style={{
        width: 32,
        height: 32,
        boxSizing: 'border-box',
        border: 'solid 1px #000',
        borderRadius: 4,
        background: background,
        transition: 'all .2s ease',
        filter: `brightness(${index === hoveredRow ? 1.4 : 1})`,
        transform: `scale(${index === hoveredRow ? 1.2 : 1})`,
      }}
      onMouseOver={() => onBoxHover(index)}
    >
      <div
        style={{
          ...boxDetailCommonStyle,
          transform: 'rotate(-45deg)',
          marginLeft: 2,
        }}
      />

      <div
        style={{
          ...boxDetailCommonStyle,
          transform: 'rotate(45deg)',
          marginLeft: 25,
        }}
      />
      <div
        style={{
          ...boxDetailCommonStyle,
          transform: 'rotate(-135deg)',
          marginLeft: 2,
          marginTop: 22,
        }}
      />
      <div
        style={{
          ...boxDetailCommonStyle,
          transform: 'rotate(135deg)',
          marginTop: 22,
          marginLeft: 25,
        }}
      />
      <div
        style={{
          fontWeight: 'bold',
          color: '#fff',
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          pointerEvents: 'none',
        }}
      >
        {index + 1}
      </div>
    </div>
  );

  return (
    <div
      style={{
        marginTop: 16,
        height: 'fit-content',
        position: 'sticky',
        top: 70,
        overflow: 'hidden',
        paddingLeft: 'calc(50% - 48px)',
      }}
    >
      <img src={Caminhao} />
      <Row
        style={{
          padding: 15,
          marginLeft: 1,
          marginTop: -235,
          display: 'flex',
          gap: '1px 3px',
          width: 98,
        }}
        onMouseLeave={() => onBoxHover(-1)}
      >
        {truckLoadItems.map((item, index) =>
          renderBox(
            colorsAssigned[
              (item.contractItemId || item.id) as keyof typeof colorsAssigned
            ] || colors[0],
            truckLoadItems.length - index - 1
          )
        )}
      </Row>
    </div>
  );
}
