import { MenuOutlined } from '@ant-design/icons';
import {
  Alert,
  Button,
  Card,
  Col,
  Divider,
  Form,
  InputNumber,
  Popover,
  Progress,
  Row,
  Select,
  Statistic,
  Table,
  Tag,
  Tooltip,
} from 'antd';
import moment, { Moment } from 'moment';
import { CSSProperties, useContext, useEffect, useState } from 'react';
import { TFunction, Trans, withTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Formatter from '../../../../classes/Formatter';
import DraggingTable from '../../../../components/table/DraggingTable';
import { useRequest } from '../../../../hooks/useRequest';
import TruckLoadController from '../../../../structures/controllers/TruckLoad';
import {
  IFilter,
  IGetContractItems,
  IStepOneData,
  IStepTwoData,
} from '../../../../structures/interfaces/TruckLoad';

import { ColumnsType } from 'antd/lib/table';
import { DefaultRecordType } from 'rc-table/lib/interface';
import UserContext from '../../../../contexts/user';
import { scrollOptions } from '../../../../utils/formOptions';
import TruckImage from '../../components/TruckImage';
import TruckLoadDescription from '../../components/TruckLoadDescription';
import TruckLoadFooter from '../../components/TruckLoadFooter';
import NonExistingContractItems from '../nonExistingContractItemsModal';
import FiltroCarga from './FiltroCarga';

const { Option } = Select;

function StepTwo({
  t,
  stepOneData,
  stepTwoData,
  setStepTwoData,
  nextStep,
  cancelPreSchedule,
  colors,
  isCustomOrdering,
  setIsCustomOrdering,
}: {
  t: TFunction;
  stepOneData: IStepOneData;
  stepTwoData?: IStepTwoData;
  cancelPreSchedule: (id: string, timeout?: boolean) => void;
  setStepTwoData: (values: IStepTwoData) => void;
  nextStep: () => void;
  colors: string[];
  isCustomOrdering: boolean;
  setIsCustomOrdering: (value: boolean) => void;
}) {
  const history = useHistory();

  const [formFilter] = Form.useForm();
  const [formAmounts] = Form.useForm();

  const { customerData, userData } = useContext(UserContext);

  const {
    name,
    vehicle,
    selectedDate,
    pickupLocation,
    cadence,
    seedType,
    cancel,
    unity,
    unityWeight,
    organizationId,
  } = stepOneData;

  const maxWeight = vehicle.weightCapacity || 0;
  const maxCapacity = vehicle.bagsCapacity || 0;

  const [fetchContractItems, contractItemsRequesting] = useRequest(
    TruckLoadController.getContractItems
  );

  const [filter, setFilter] = useState<IFilter>({});
  const [contractItems, setContractItems] = useState<IGetContractItems[]>(
    stepTwoData?.contractItems || []
  );
  const [customerRemainingAmount, setCustomerRemainingAmount] =
    useState<number>(0);
  const [itemsToAdd, setItemstoAdd] = useState<{
    [key: string]: { index: number; value: number; data: IGetContractItems };
  }>({});
  const [addedItems, setAddedItems] = useState<IGetContractItems[]>(
    stepTwoData?.addedItems || []
  );
  const [timeout, defineTimeout] = useState<Moment | string>();
  const [colorsAssigned, setColorsAssigned] = useState(
    stepTwoData?.colorsAssigned || {}
  );
  const [hoveredBoxIndex, setHoveredBoxIndex] = useState<number>();
  const [hoveredRowIndex, setHoveredRowIndex] = useState<number>();

  const [contractItemsModalOpen, setContractItemsModalOpen] = useState(false);

  const [ruleCapacityPerCompany, setRuleCapacityPerCompany] =
    useState<number>();

  useEffect(() => {
    setRuleCapacityPerCompany(stepOneData.cadence.rule.capacityPerCompany);
  }, [stepOneData]);

  const baseString = 'pages.truckLoads.createLoad';
  const baseStringToPopover =
    'pages.truckLoads.createLoad.stepTwo.customerLimitExceeded';

  const renderPickupAmountInput = (data: IGetContractItems) => {
    return (
      <Form.Item noStyle name={data.id} initialValue={0}>
        <InputNumber
          onBlur={() => {
            const inputValue = formAmounts.getFieldValue([data.id]);
            if (!inputValue) {
              const res = { ...itemsToAdd };
              delete res[data.id];
              setItemstoAdd(res);
              formAmounts.resetFields([data.id]);
            }
          }}
          className="input-number-visible-controls"
          placeholder="QTD"
          min={0}
          max={data.remainingAmount}
          controls={true}
          onChange={val => {
            if (val !== null && val < 0) {
              return formAmounts.setFieldValue([data.id], 0);
            }
            if (val !== null && !Number.isNaN(val)) {
              if (val > 0) {
                const newItem = {
                  ...itemsToAdd,
                  [data.id]: {
                    index: data.index,
                    value: val,
                    data,
                  },
                };
                setItemstoAdd(newItem);
              } else {
                const res = { ...itemsToAdd };
                delete res[data.id];
                setItemstoAdd(res);
              }
            }
          }}
        />
      </Form.Item>
    );
  };

  const renderRemainingAmount = (amount: number, data: IGetContractItems) => {
    const result = amount - (itemsToAdd[data.id]?.value || 0);
    return result < 0 ? 0 : result;
  };

  const renderCultivationTags = (cultivation: string, id: string) => {
    const color =
      colorsAssigned[id as keyof typeof colorsAssigned] || colors[0];

    return <Tag color={color}>{cultivation}</Tag>;
  };

  const quantityStyle: CSSProperties = {
    background: '#F0F0F0',
  };

  const quantityChildrenColumns = [
    {
      title: t(`${baseString}.contractItems.columns.contractAmount`),
      key: 'totalAmount',
      onHeaderCell: () => ({
        style: quantityStyle,
      }),
      dataIndex: 'totalAmount',
      render: (value: any) => (value < 0 ? 0 : value),
      sorter: (a: IGetContractItems, b: IGetContractItems) =>
        a.totalAmount - b.totalAmount,
      fixed: 'right',
    },
    {
      title: t(`${baseString}.contractItems.columns.pickupAmount`),
      key: 'remainingAmount',
      onHeaderCell: () => ({
        style: quantityStyle,
      }),
      dataIndex: 'remainingAmount',
      render: (amount: number, data: IGetContractItems) =>
        renderRemainingAmount(amount, data),
      sorter: (a: IGetContractItems, b: IGetContractItems) =>
        a.remainingAmount - b.remainingAmount,
      fixed: 'right',
    },
    {
      title: t(`${baseString}.stepTwo.customerRemainingAmount`),
      key: 'customerRemainingAmount',
      onHeaderCell: () => ({
        style: quantityStyle,
      }),
      dataIndex: ['customerCapacity', 'remainingAmount'],
      render: (customerRemainingAmount: number) => {
        if (customerRemainingAmount) {
          if (customerRemainingAmount < 0) return 0;
          else return customerRemainingAmount;
        }
        return customerRemainingAmount;
      },
      fixed: 'right',
    },
  ];

  const renderQuantityColumns = () => {
    if (customerData) {
      const renderColumns = quantityChildrenColumns.filter(column => {
        return column.key !== 'customerRemainingAmount';
      });
      return renderColumns;
    } else return quantityChildrenColumns;
  };

  const contractItensColumns = [
    {
      title: t(`${baseString}.contractItems.columns.client`),
      key: 'client',
      dataIndex: 'client',
    },
    {
      title: t(`${baseString}.contractItems.columns.stateRegister`),
      key: 'stateRegistration',
      dataIndex: 'stateRegistration',
    },
    {
      title: t(`${baseString}.contractItems.columns.contract`),
      key: 'contractNumber',
      dataIndex: 'contractNumber',
    },
    {
      title: t(`${baseString}.contractItems.columns.date`),
      key: 'contractDate',
      dataIndex: ['contract', 'contractDate'],
      render: (date: string) => Formatter.formatDate(date),
    },
    {
      title: t(`${baseString}.contractItems.columns.id`),
      key: 'referenceNumber',
      dataIndex: 'referenceNumber',
    },
    {
      title: t(`${baseString}.contractItems.columns.cultivation`),
      key: 'cultivation',
      dataIndex: 'cultivation',
      render: (cultivation: string, { id }: { id: string }) =>
        renderCultivationTags(cultivation, id),
    },
    {
      title: t(`${baseString}.contractItems.columns.sieve`),
      key: 'sieve',
      dataIndex: 'sieve',
    },
    {
      title: t(`${baseString}.contractItems.columns.category`),
      key: 'category',
      dataIndex: 'category',
    },
    {
      title: t(`${baseString}.contractItems.columns.package`),
      key: 'packing',
      dataIndex: 'packing',
    },
    {
      title: t(`${baseString}.contractItems.columns.tsi`),
      key: 'tsi',
      dataIndex: 'tsi',
      render: (tsi: boolean) => (tsi ? 'Sim' : 'Não'),
    },
    {
      title: t(`${baseString}.contractItems.columns.weight`),
      key: 'estimatedWeight',
      dataIndex: 'estimatedWeight',
    },
    {
      title: t(`${baseString}.contractItems.quantity`),
      onHeaderCell: () => ({
        style: quantityStyle,
      }),
      children: renderQuantityColumns(),
    },
    {
      title: t(`${baseString}.contractItems.columns.pickup`),
      key: '',
      dataIndex: '',
      render: (_: string, data: IGetContractItems) => {
        return renderPickupAmountInput(data);
      },
      fixed: 'right',
    },
  ];

  const filteredColumns = contractItensColumns.filter(column => {
    if (customerData) {
      return column.key !== 'category' && column.key !== 'sieve';
    } else {
      return column;
    }
  });

  const removeLoaded = (values: IGetContractItems, index: number) => {
    if (typeof values.index !== 'undefined') {
      const updatedContractItems = contractItems;
      updatedContractItems[values.index].remainingAmount +=
        values.allocatedAmount || 0;
      setContractItems(updatedContractItems);
      setAddedItems(
        addedItems.filter(
          (item: IGetContractItems, itemIndex: number) => itemIndex !== index
        )
      );
    }
  };

  const removeAllLoads = () => {
    addedItems.forEach(removeLoaded);
    setAddedItems([]);
  };

  const renderRemoveButton = (values: IGetContractItems, index: number) => (
    <Button type="link" danger onClick={() => removeLoaded(values, index)}>
      {t(`${baseString}.stepTwo.renderRemoveButton`)}
    </Button>
  );

  const renderRemoveAllButton = () => (
    <Button danger onClick={removeAllLoads}>
      {t(`${baseString}.stepTwo.renderRemoveAllButton`)}
    </Button>
  );

  const columnsComplement = customerData
    ? filteredColumns.slice(0, 9)
    : contractItensColumns.slice(0, 11);

  const createdLoadColumns = [
    {
      title: t(`${baseString}.createdLoads.columns.organization`),
      key: 'org',
      dataIndex: '',
      render: () => <MenuOutlined style={{ color: '#999' }} />,
    },
    {
      title: t(`${baseString}.createdLoads.columns.order`),
      key: 'order',
      dataIndex: 'order',
      render: (_: string, __: string, index: number) =>
        `${addedItems.length - index}ª desc`,
    },
    ...columnsComplement,
    {
      title: t(`${baseString}.createdLoads.columns.allocated`),
      key: 'allocatedAmount',
      dataIndex: 'allocatedAmount',
    },
    {
      title: t(`${baseString}.createdLoads.columns.actions`),
      render: (values: IGetContractItems, _: string, index: number) =>
        renderRemoveButton(values, index),
    },
  ];

  useEffect(() => {
    if (!stepTwoData) {
      fetchContractItems({
        unity: 'BAG',
        seedType: 'Soja',
        organizationId: organizationId,
        prescheduleId: cancel.id,
        excludeTsi: stepOneData.cadence.whiteSeedOnly,
      })
        .then(response => {
          const onlyRemainingItems = response.contractItems.filter(
            ci => ci.remainingAmount > 0
          );

          const formattedItems = onlyRemainingItems.map((item, index) => {
            return {
              ...item,
              cultivation: `${item.cultivation}`,
              index,
            };
          });
          const newColors: { [key: string]: string } = {};

          formattedItems.forEach(({ id }: { id: string }) => {
            if (!newColors[id as keyof typeof colorsAssigned]) {
              let colorIndex = Object.keys(newColors).length;

              if (colorIndex > colors.length - 1) {
                colorIndex -=
                  Math.floor(colorIndex / colors.length) * colors.length;
              }

              newColors[id] = colors[colorIndex];
            }
          });
          setColorsAssigned(newColors);
          setContractItems(formattedItems);
          if (!onlyRemainingItems.length) {
            setContractItemsModalOpen(true);
          }
        })
        .catch(() => setContractItemsModalOpen(true));
    }
  }, []);

  const handleCancel = () => {
    setContractItemsModalOpen(false);
  };

  const handleOk = () => {
    setContractItemsModalOpen(false);
    history.push('/cargas');
  };

  const handleLoadAddition = () => {
    let newLoads: IGetContractItems[] = [];

    if (unity === 'BAG') {
      newLoads = Object.values(itemsToAdd).flatMap(item => {
        const formattedItem = {
          ...item.data,
          index: item.index,
          allocatedAmount: 1,
        };
        const addedItemsRows = Array(item.value).fill(formattedItem);
        return addedItemsRows;
      });
    } else {
      newLoads = Object.values(itemsToAdd).flatMap(item => {
        const formattedItem = {
          ...item.data,
          index: item.index,
          allocatedAmount: item.value,
        };
        return formattedItem;
      });
    }

    setAddedItems([...addedItems, ...newLoads]);
    formAmounts.resetFields();

    const updatedContractItems = [...contractItems];
    newLoads.forEach(item => {
      if (typeof item.index !== 'undefined' && item.allocatedAmount) {
        updatedContractItems[item.index].remainingAmount -=
          item.allocatedAmount;
      }
    });

    setContractItems(updatedContractItems);
    setItemstoAdd({});
  };

  const getUnitsAmount = () => {
    if (unity === 'BAG') return cancel.unitsAmount;
    return ((cancel.unitsAmount * (unityWeight || 1)) / 1000).toFixed(2);
  };

  const getTotalWeight = () =>
    addedItems.reduce(
      (prev: number, curr: IGetContractItems) =>
        prev + Number(curr.estimatedWeight) * (curr.allocatedAmount || 1),
      0
    );

  const timeText = () => (
    <Trans
      i18nKey={`${baseString}.stepTwo.warnings.timeout`}
      values={{ timeout: timeout }}
    >
      Com não finalize a formação de carga em menos de <strong>0</strong> sua
      reserva será liberada.
    </Trans>
  );

  const getWarningText = () => {
    return (
      <>
        <Trans
          i18nKey={`${baseString}.stepTwo.warnings.${
            unity === 'BAG' ? 'bag' : 'smallBag'
          }`}
          values={{ amount: getUnitsAmount() }}
        >
          Com base no veículo selecionado foram reservadas <strong>0</strong> da
          cadência do dia selecionado.
        </Trans>{' '}
        {timeText()}
      </>
    );
  };

  const getDescriptions = () => ({
    name,
    vehicleModel: vehicle.vehicleModel,
    selectedDate,
    availableUnits: cadence.availableUnits,
    pickupLocation: pickupLocation?.title,
    seedType: seedType,
    unity: unity,
    vehicleBagsCapacity: vehicle.bagsCapacity,
    vehicleWeightCapacity: vehicle.weightCapacity,
    estimatedWeight: getTotalWeight(),
  });

  const applyFilter = (values: IGetContractItems[]) => {
    const { contractDate, search, cultivation } = filter;
    const filteredValues = values.filter(item => {
      let filteredState = true;
      if (search) {
        const fields = [
          item.client,
          item.stateRegistration,
          item.contractNumber,
          item.seedType,
          item.referenceNumber,
          item.cultivation,
          item.sieve,
          item.category,
          item.packing,
          item.tsi,
          item.estimatedWeight,
          item.totalAmount,
          item.remainingAmount,
        ];

        filteredState = !!fields.filter(
          field =>
            field &&
            field
              .toString()
              .toLocaleLowerCase()
              .includes(search.toLocaleLowerCase())
        ).length;
      }

      if (cultivation) {
        filteredState = filteredState && cultivation === item.cultivation;
      }

      if (contractDate && contractDate.length) {
        filteredState =
          filteredState &&
          moment(item.contract.contractDate).isBetween(
            contractDate[0],
            contractDate[1],
            'day',
            '[]'
          );
      }

      return filteredState;
    });
    return filteredValues;
  };

  const itemsToAddEmpty = () => {
    return !Object.values(itemsToAdd).length;
  };

  const isWeightOverTheLimit = () => {
    const values = Object.values(itemsToAdd);

    let weightToAdd = values.reduce((prev, curr) => {
      return prev + curr.data.estimatedWeight * curr.value;
    }, 0);

    weightToAdd += addedItems.reduce((prev, curr) => {
      return prev + (curr.allocatedAmount || 1) * curr.estimatedWeight;
    }, 0);

    return weightToAdd > maxWeight;
  };

  const isBagsOverTheLimit = () => {
    const values = Object.values(itemsToAdd);

    let bagsToAdd = values.reduce((prev, curr) => {
      return prev + curr.value;
    }, 0);

    bagsToAdd += addedItems.reduce((prev, curr) => {
      return prev + (curr.allocatedAmount || 1);
    }, 0);

    return bagsToAdd > maxCapacity;
  };

  const isCustomerWeightOverTheLimit = () => {
    if (customerRemainingAmount) {
      const currentAmount = Object.entries(itemsToAdd).reduce(
        (prev, curr) => prev + curr[1].value,
        0
      );
      return currentAmount > customerRemainingAmount;
    }
  };

  const isRuleWeightOverTheLimit = () => {
    if (ruleCapacityPerCompany) {
      const currentAmount = Object.entries(itemsToAdd).reduce(
        (prev, curr) => prev + curr[1].value,
        0
      );
      return currentAmount > ruleCapacityPerCompany;
    }
  };

  const roundTo2 = (num: number) => Math.ceil(num * 100) / 100;

  const handleBoxHover = (index: number) => {
    if (index === -1) {
      setHoveredBoxIndex(undefined);
    } else {
      setHoveredBoxIndex(addedItems.length - index - 1);
    }
  };

  const handleFinish = () => {
    const totalWeight = getTotalWeight();

    setStepTwoData({
      addedItems,
      createdLoadColumns,
      totalWeight,
      colorsAssigned,
      contractItems,
    });

    nextStep();
  };

  const getCultivations = () => {
    const cultivations = contractItems.map(item => item.cultivation);
    const uniqueCultivations = cultivations.filter(
      (value, index) => cultivations.indexOf(value) === index
    );
    return (
      <>
        {uniqueCultivations.map(cultivation => (
          <Option key={cultivation} value={cultivation}>
            {cultivation}
          </Option>
        ))}
      </>
    );
  };

  const getUnityPercentage = () => {
    const unityText = t(`${baseString}.stepTwo.percentages.bags`);

    let value = addedItems.length;
    let totalOfUnity = 0;
    totalOfUnity = addedItems.reduce((prev, curr) => {
      return prev + (curr.allocatedAmount || 0);
    }, 0);

    if (vehicle.bagsCapacity) {
      value = (100 / vehicle.bagsCapacity) * totalOfUnity;
    }

    return (
      <>
        <Statistic
          valueStyle={{ fontSize: 32, fontWeight: 'bold' }}
          title={t(`${baseString}.cardTitles.loadInBags`)}
          value={`${roundTo2(value)}%`}
        />
        <Progress
          strokeColor="#59C7C9"
          showInfo={false}
          percent={roundTo2(value)}
        />
        <Divider style={{ margin: '18 0' }} />
        <span>{`${totalOfUnity} / ${maxCapacity} ${unityText}`}</span>
      </>
    );
  };
  const getTonsPercentage = () => {
    let value = 0;
    let totalWeight = 0;
    if (vehicle.weightCapacity) {
      totalWeight = addedItems.reduce(
        (prev: number, curr: IGetContractItems) =>
          prev + Number(curr.estimatedWeight) * (curr.allocatedAmount || 1),
        0
      );
      value = (100 / (vehicle.weightCapacity / 1000)) * (totalWeight / 1000);
    }

    return (
      <>
        <Statistic
          valueStyle={{ fontSize: 32, fontWeight: 'bold' }}
          title={t(`${baseString}.cardTitles.loadInTons`)}
          value={`${roundTo2(value)}%`}
        />
        <Progress
          strokeColor="#59C7C9"
          showInfo={false}
          percent={roundTo2(value)}
        />
        <Divider style={{ margin: '18 0' }} />
        <span>{`${totalWeight / 1000} / ${maxWeight / 1000} ${t(
          `${baseString}.stepTwo.percentages.tons`
        )}`}</span>
      </>
    );
  };

  const customerExtraLimitInfo = (
    <span style={{ fontSize: 16 }}>
      {t(`${baseString}.stepTwo.extraButtons.maximumCapacity`)}{' '}
      <Tag style={{ borderRadius: 12, fontWeight: 'bold' }} color="#0080ff">
        {customerRemainingAmount > 0 ? customerRemainingAmount : 0}
      </Tag>
    </span>
  );
  const userExtraLimitInfo = (
    <span style={{ fontSize: 16 }}>
      {t(`${baseString}.stepTwo.extraButtons.maximumCapacityPerCompany`)}{' '}
      <Tag style={{ borderRadius: 12, fontWeight: 'bold' }} color="#0080ff">
        {ruleCapacityPerCompany}
      </Tag>
    </span>
  );

  const weightTooltipVisible = customerData
    ? isWeightOverTheLimit() || isCustomerWeightOverTheLimit()
    : isWeightOverTheLimit() || isRuleWeightOverTheLimit();

  const bagsTooltipVisible = isBagsOverTheLimit();

  const [enableAddItems, setEnableAddItems] = useState(false);
  const [customerNameOverCapacity, setCustomerNameOverCapacity] =
    useState<string>();
  useEffect(() => {
    contractItems.find(({ customerCapacity, id, customerId }) => {
      const values = Object.values(itemsToAdd);
      const custCapacity = customerCapacity?.remainingAmount;
      const itemsToAddValue = values.reduce((prev, curr) => {
        return prev + curr.value;
      }, 0);
      const itemToAdd = itemsToAdd[id];
      if (custCapacity) {
        setCustomerRemainingAmount(custCapacity);
        if (itemToAdd?.data?.customerId === customerId) {
          setCustomerNameOverCapacity(itemToAdd.data.client);
          if (
            itemsToAddValue > custCapacity &&
            !weightTooltipVisible &&
            !bagsTooltipVisible
          ) {
            setEnableAddItems(true);
          }
          if (
            custCapacity > itemsToAddValue ||
            weightTooltipVisible ||
            bagsTooltipVisible
          ) {
            setEnableAddItems(false);
          }
        }
      }
    });
  }, [contractItems, itemsToAdd]);

  const disableAddItems = () =>
    enableAddItems ||
    itemsToAddEmpty() ||
    isWeightOverTheLimit() ||
    isRuleWeightOverTheLimit() ||
    isCustomerWeightOverTheLimit() ||
    isBagsOverTheLimit();

  return (
    <>
      <Col span={24}>
        <Alert type="warning" message={getWarningText()} />
      </Col>
      <Col span={24}>
        <Card
          title={t(`${baseString}.cardTitles.descriptions`)}
          bordered={false}
        >
          <TruckLoadDescription items={getDescriptions()} />
        </Card>
      </Col>
      <Col span={24}>
        <Card>
          <Form
            scrollToFirstError={scrollOptions}
            form={formFilter}
            layout="vertical"
            onFinish={setFilter}
          >
            <Row gutter={[12, 0]}>
              <FiltroCarga
                cultivations={getCultivations()}
                isCustomOrdering={isCustomOrdering}
                setIsCustomOrdering={setIsCustomOrdering}
                form={formFilter}
              />
              <Col
                style={{
                  marginLeft: 'auto',
                  display: 'flex',
                  alignItems: 'end',
                }}
              >
                <Form.Item>
                  <Button
                    style={{ marginRight: 8 }}
                    onClick={() => formFilter.resetFields()}
                  >
                    {t(`${baseString}.buttons.clearFilter`)}
                  </Button>
                  <Button type="primary" htmlType="submit">
                    {t(`${baseString}.buttons.filter`)}
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Card>
      </Col>
      <Col span={24}>
        <Alert
          type="warning"
          message={
            <Trans i18nKey={`${baseString}.stepTwo.warnings.contractItems`} />
          }
        />
      </Col>
      <Col span={24}>
        <Card
          title={t(`${baseString}.cardTitles.contractItems`)}
          bordered={false}
          extra={userData ? userExtraLimitInfo : customerExtraLimitInfo}
        >
          <Form scrollToFirstError={scrollOptions} form={formAmounts}>
            <Table
              loading={contractItemsRequesting}
              dataSource={applyFilter(contractItems)}
              scroll={{ x: true }}
              columns={filteredColumns as ColumnsType<DefaultRecordType>}
              size="small"
            />
          </Form>
          <Divider />
          <Popover
            overlayStyle={{ width: 400 }}
            title={t(`${baseStringToPopover}.warning`)}
            content={
              <>
                <p>
                  {t(`${baseStringToPopover}.exceededWeight`)}{' '}
                  <strong>{customerNameOverCapacity}</strong>. <br />
                  {t(`${baseStringToPopover}.continue`)}
                </p>
                <Button
                  onClick={() => {
                    setEnableAddItems(false);
                    handleLoadAddition();
                  }}
                >
                  {t(`${baseString}.stepTwo.customerLimitExceeded.confirm`)}
                </Button>

                <Button
                  style={{ marginLeft: 24 }}
                  danger
                  onClick={() => {
                    setEnableAddItems(false);
                  }}
                >
                  {t(`${baseString}.stepTwo.customerLimitExceeded.cancel`)}
                </Button>
              </>
            }
            open={enableAddItems}
          >
            <Button
              type="primary"
              style={{ float: 'right' }}
              onClick={handleLoadAddition}
              disabled={disableAddItems()}
            >
              <Tooltip
                overlayStyle={{ zIndex: 2 }}
                open={weightTooltipVisible || bagsTooltipVisible}
                title={t(
                  `${baseString}.stepTwo.filter.${
                    weightTooltipVisible ? 'weight' : 'bags'
                  }OverTheLimit`
                )}
              >
                {t(`${baseString}.buttons.addItems`)}
              </Tooltip>
            </Button>
          </Popover>
        </Card>
      </Col>
      <Col span={24}>
        <Alert
          type="warning"
          message={t(`${baseString}.stepTwo.warnings.maxCapacity`)}
        />
      </Col>
      {unity === 'BAG' && (
        <Col span={24} style={{ display: 'flex' }}>
          <Card style={{ flexGrow: 1 }}>{getUnityPercentage()}</Card>
        </Col>
      )}
      {unity !== 'BAG' && (
        <Col span={12}>
          <Card>{getTonsPercentage()}</Card>
        </Col>
      )}

      {isCustomOrdering && (
        <>
          <Col span={24}>
            <Alert
              type="warning"
              message={<Trans i18nKey={`${baseString}.warnings.createdLoad`} />}
            />
          </Col>

          <Col span={24}>
            <Card
              title={t(`${baseString}.cardTitles.loads`)}
              extra={renderRemoveAllButton()}
            >
              <Row>
                <Col span={3} xs={0} sm={0} md={0} lg={3}>
                  <TruckImage
                    colorsAssigned={colorsAssigned}
                    truckLoadItems={addedItems}
                    onBoxHover={handleBoxHover}
                    hoveredRow={hoveredRowIndex}
                  />
                </Col>
                <Col span={21} xs={24} sm={24} md={24} lg={21}>
                  <DraggingTable
                    data={addedItems}
                    setData={
                      setAddedItems as (values: DefaultRecordType[]) => void
                    }
                    columns={
                      createdLoadColumns as ColumnsType<DefaultRecordType>
                    }
                    size="small"
                    tableProps={{
                      rowClassName: (record, index: number) => {
                        const reverseIndex = addedItems.length - index - 1;
                        if (reverseIndex === hoveredBoxIndex)
                          return 'truck-load-item-row hovered-box';
                        return 'truck-load-item-row';
                      },
                    }}
                    onRowHover={(record, index) => {
                      if (index === -1) {
                        setHoveredRowIndex(undefined);
                      } else {
                        if (index !== undefined) {
                          setHoveredRowIndex(addedItems.length - index - 1);
                        }
                      }
                    }}
                  />
                </Col>
              </Row>
            </Card>
          </Col>
        </>
      )}

      <TruckLoadFooter>
        <Statistic.Countdown
          title={t(`pages.truckLoads.createLoad.footer.countdown`)}
          value={cancel.expiresIn}
          style={{ marginTop: '12px' }}
          valueStyle={{ lineHeight: '20px' }}
          onFinish={() => {
            cancelPreSchedule(cancel.id, true);
          }}
          onChange={value =>
            value ? defineTimeout(Formatter.formatDuration(value)) : {}
          }
        />
        <Button
          style={{ margin: 24 }}
          danger
          htmlType="reset"
          onClick={() => {
            history.push('/cargas');
          }}
        >
          {t(`pages.truckLoads.createLoad.footer.cancelBtn`)}
        </Button>
        <Button
          disabled={!addedItems.length}
          style={{ margin: '24px 0' }}
          type="primary"
          htmlType="submit"
          onClick={handleFinish}
        >
          {t(`pages.truckLoads.createLoad.footer.confirmBtn`)}
        </Button>
      </TruckLoadFooter>
      <NonExistingContractItems
        open={contractItemsModalOpen}
        onCancel={handleCancel}
        onOk={handleOk}
      />
    </>
  );
}

export default withTranslation()(StepTwo);
