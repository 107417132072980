import { DownloadOutlined, InboxOutlined } from '@ant-design/icons';
import {
  Alert,
  Button,
  Col,
  Form,
  message,
  Modal,
  Row,
  Typography,
  Upload,
} from 'antd';
import { UploadFile } from 'antd/lib/upload/interface';
import { TFunction } from 'i18next';
import { useWatch } from 'rc-field-form';
import { ReactElement, useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { read, utils } from 'xlsx';
import Normalizer from '../../../classes/Normalizer';
import { IFile, ITableData } from '../../../structures/interfaces/Clients';
import { scrollOptions } from '../../../utils/formOptions';

const { Title } = Typography;

const ImportContractsModal = ({
  t,
  visible,
  onClose,
}: {
  t: TFunction;
  visible: boolean;
  onClose: () => void;
}): ReactElement<unknown> => {
  const [error, setError] = useState<{
    message: string;
    type: 'error';
    description?: string;
  } | null>(null);
  const [tableData, setTableData] = useState<ITableData[][]>();
  const [isLoading, setIsLoading] = useState(false);

  const [form] = Form.useForm();
  const history = useHistory();
  const maxPreviewLines = 15;
  const maxCellCharAmount = 20;

  const supportedFileType = [
    'text/csv',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.template',
  ];

  const normFile = (e: { file: UploadFile; fileList: UploadFile[] }) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const generateTable = () => {
    const uploadFile = form.getFieldValue('uploadFile');

    try {
      const reader = new FileReader();

      reader.addEventListener('loadend', () => {
        const wb = read(reader.result, { type: 'array' });
        const fw = wb.Sheets[wb.SheetNames[0]];

        const tableData = utils
          .sheet_to_json(fw, { header: 1 })
          .slice(0, maxPreviewLines)
          .map((lineArray: any) =>
            lineArray.map((item: string) => ({
              value: Normalizer.sliceWithEllipsis(item, maxCellCharAmount),
            }))
          );

        setTableData(tableData);
      });

      reader.readAsArrayBuffer(uploadFile[0].originFileObj);
      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    }
  };

  const table_ = useWatch('uploadFile', form);

  useEffect(() => {
    if (table_ && table_.length !== 0) {
      generateTable();
    }
  }, [table_]);

  const onFinish = (values: IFile) => {
    if (!values.uploadFile || values.uploadFile.length === 0) {
      message.error(t('pages.contracts.importModal.rmHasFile'));
    } else if (
      values.uploadFile &&
      values.uploadFile[0].type &&
      supportedFileType.includes(values.uploadFile[0].type)
    ) {
      form.validateFields(['uploadFile']).then(() => {
        const { uploadFile } = values;
        const uploadedFile = uploadFile[0];

        message.info(t('pages.contracts.importModal.infoConfigTable'), 4);
        history.push('/contratos/importar', {
          table: tableData,
          file: uploadedFile,
        });
      });
    } else {
      message.error(t('pages.contracts.importModal.rmFileType'));
    }
  };

  const formImportFile = (
    <Form
      scrollToFirstError={scrollOptions}
      name="basic"
      layout="vertical"
      onFinish={onFinish}
      form={form}
    >
      {error && (
        <Alert
          key={error.message}
          message={error.message}
          description={error.description}
          type={error.type}
          closable
          style={{ marginBottom: 24 }}
        />
      )}
      <Row>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
          <Form.Item
            name="uploadFile"
            valuePropName="fileList"
            initialValue={undefined}
            getValueFromEvent={normFile}
          >
            <Upload.Dragger
              name="file"
              beforeUpload={file => {
                if (!supportedFileType.includes(file.type)) {
                  message.error(t('pages.contracts.importModal.rmFileType'));
                  return true;
                } else {
                  return false;
                }
              }}
              accept={supportedFileType.join()}
              maxCount={1}
              customRequest={() => true}
            >
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">
                {t('pages.contracts.importModal.dragFile')}
              </p>
              <p className="ant-upload-hint">
                {t('pages.contracts.importModal.acceptedFiles')}
              </p>
              <p className="ant-upload-hint">
                {t('pages.contracts.importModal.filesType')}
              </p>
            </Upload.Dragger>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );

  const footerModal = [
    <Button
      loading={isLoading}
      onClick={() => {
        setIsLoading(true);
        setTimeout(() => setIsLoading(false), 1500);
      }}
      href="https://logmetrics-dev.s3.us-east-2.amazonaws.com/templates/template-contrato.xlsx"
      icon={<DownloadOutlined />}
    >
      {t('pages.clients.importConfig.templateWorksheet')}
    </Button>,
    <Button key="back" danger onClick={onClose}>
      {t('pages.contracts.importModal.cancelButton')}
    </Button>,
    <Button
      key="confirm"
      type="primary"
      htmlType="submit"
      onClick={() => {
        form.submit();
        setError(null);
      }}
    >
      {t('pages.contracts.importModal.confirmButton')}
    </Button>,
  ];

  return (
    <Modal width={'40%'} open={visible} onCancel={onClose} footer={footerModal}>
      <Title level={5}>{t('pages.contracts.importModal.title')}</Title>
      {formImportFile}
    </Modal>
  );
};

export default withTranslation()(ImportContractsModal);
