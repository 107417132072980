import { Col, Form, Input, Modal, Row, message } from 'antd';
import { Rule } from 'antd/lib/form';
import { t } from 'i18next';
import { Dispatch, SetStateAction } from 'react';
import Formatter from '../../../classes/Formatter';
import Validator from '../../../classes/Validator';
import { useRequest } from '../../../hooks/useRequest';
import CompanyController from '../../../structures/controllers/Company';
import { INewShippingCompany } from '../../../structures/interfaces/Company/Company';

interface CollectionCreateFormProps {
  open: boolean;
  hasAdded?: boolean;
  setNewShippingCompany?: Dispatch<
    SetStateAction<INewShippingCompany | undefined>
  >;
  setHasAdded?: Dispatch<SetStateAction<boolean>>;
  setOpenModal?: Dispatch<boolean>;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const RegisterShippingCompany = ({
  open,
  hasAdded,
  setHasAdded,
  setOpenModal,
  setNewShippingCompany,
}: CollectionCreateFormProps) => {
  const [form] = Form.useForm();

  const [registerShippingCompany] = useRequest(
    CompanyController.registerShippingCompany
  );

  const validPhoneNumber = (rule: Rule, value: string) => {
    if (!value) return Promise.resolve();
    if (Validator.validateCellphoneWithoutDDI(value)) return Promise.resolve();
    return Promise.reject(new Error('Número Inválido'));
  };
  const validEmail = (rule: Rule, value: string) => {
    if (!value) return Promise.resolve();
    if (!value || Validator.validateEmail(value)) return Promise.resolve();
    return Promise.reject(new Error('Email inválido'));
  };
  const validCNPJ = (_: Rule, value: string) => {
    if (Validator.validateCNPJ(value)) return Promise.resolve();
    return Promise.reject(new Error('CNPJ inválido'));
  };

  const requiredRule = {
    required: true,
    message: t('pages.settings.pickUpLocations.requiredRuleMessage'),
  };

  const showError = () => {
    message.error({
      content: 'Não foi possível fazer o registro',
      style: { marginTop: '4rem' },
    });
  };

  return (
    <Modal
      open={open}
      title="Cadastrar Transportadora"
      okText="Confirmar"
      cancelButtonProps={{ danger: true }}
      cancelText="Cancelar"
      onCancel={() => {
        setOpenModal?.(false);
        form.resetFields();
      }}
      onOk={() => {
        form
          .validateFields()
          .then(values => {
            values.shippingCompanyPhoneNumber =
              values.shippingCompanyPhoneNumber.replace(/[^0-9]/g, '');
            values.shippingCompanyCnpj = values.shippingCompanyCnpj.replace(
              /[^0-9]/g,
              ''
            );
            registerShippingCompany(values)
              .then(newShippingCompany => {
                setHasAdded?.(!hasAdded);
                setOpenModal?.(false);
                setNewShippingCompany?.(newShippingCompany);
                message.success({
                  content: 'Transportadora criada',
                  duration: 5,
                  style: {
                    marginTop: 60,
                  },
                });
                form.resetFields();
              })
              .catch(() => {
                showError();
              });
          })
          .catch(showError);
      }}
    >
      <Form
        form={form}
        layout="vertical"
        name="form_in_modal"
        initialValues={{ modifier: 'public' }}
      >
        <Row gutter={24}>
          <Col span={24}>
            <Form.Item
              name="shippingCompanyName"
              label="Nome da transporadora"
              rules={[requiredRule]}
            >
              <Input placeholder="Informe o nome da transportadora" />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item
              name="shippingCompanyTradeName"
              label="Nome Fantasia"
              rules={[requiredRule]}
            >
              <Input placeholder="Informe o nome fantasia" />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item
              name="shippingCompanyEmail"
              label="Email"
              rules={[{ validator: validEmail }, requiredRule]}
            >
              <Input placeholder="Informe o email" />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              name="shippingCompanyCnpj"
              label="CNPJ"
              rules={[requiredRule, { validator: validCNPJ }]}
              required
              normalize={Formatter.formatCNPJ}
            >
              <Input placeholder="Informe o CNPJ" />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              name="shippingCompanyPhoneNumber"
              label="Telefone"
              normalize={Formatter.formatPhoneWithoutDDI}
              rules={[{ validator: validPhoneNumber }, requiredRule]}
            >
              <Input placeholder="Informe o telefone" />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default RegisterShippingCompany;
