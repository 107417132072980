import {
  Alert,
  Button,
  Col,
  Form,
  Layout,
  message,
  PageHeader,
  Popover,
  Row,
  Space,
} from 'antd';
import { RuleObject } from 'antd/lib/form';
import { useWatch } from 'antd/lib/form/Form';
import { TooltipPlacement } from 'antd/lib/tooltip';
import moment, { Moment } from 'moment';
import { ReactElement, useContext, useEffect, useState } from 'react';
import { TFunction, withTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import Converter from '../../../../classes/Converter';
import Footer from '../../../../components/layout/footer';
import UserContext from '../../../../contexts/user';
import { useRequest } from '../../../../hooks/useRequest';
import AddressController from '../../../../structures/controllers/Address';
import BreadCrumb from '../../../../structures/controllers/Breadcrumb';
import ClientsController from '../../../../structures/controllers/Clients';
import HomeController from '../../../../structures/controllers/Home';
import ScheduleRulesController from '../../../../structures/controllers/ScheduleRules';
import SettingsController from '../../../../structures/controllers/Settings';
import { Farm } from '../../../../structures/interfaces/Clients';
import {
  ICalendarDate,
  ICreateCadencesWeekDayForm,
  ICreateCustomerRule,
  ICreateCustomerRuleFormValues,
  ICreateRule,
  ICreateRuleFormValues,
  IEditCustomerRule,
  IFillCalendar,
  IGetPickUpLocations,
  IGetSeedTypes,
} from '../../../../structures/interfaces/ScheduleRules';
import { scrollOptions } from '../../../../utils/formOptions';
import { IErrorMessage } from '../../../../utils/interfaces';
import DeleteCustomerRule from '../customerRulesModals/deleteCustomerRule';
import CustomerRuleEdition from '../modals/editCustomerRule';
import WeekendCard from './components/cadenceFormCards/WeekendCard';
import CustomerForm from './components/customerRuleCards/CustomerForm';
import BasicInformationCard from './components/rulesFormCards/BasicInformation';
import CapacityInformationCard from './components/rulesFormCards/CapacityInformation';
import SeedsTreatmentCard from './components/rulesFormCards/SeedsTreatment';
import UnityInformationCard from './components/rulesFormCards/UnityInformation';
import CreateCadenceInCalendar from './forms/createCadencesOnCalendar';
import EditCadenceInCalendar from './forms/editCadenceInCalendar';

const colSize = { xs: 24, sm: 24, md: 12, lg: 8, xl: 6, xxl: 5 };
const cadencesColSize = { xs: 24, sm: 24, md: 12, lg: 8, xl: 5, xxl: 4 };
const inputsColSize = {
  xs: 24,
  sm: 24,
  md: 12,
  lg: 8,
  xl: 7,
  xxl: 6,
};

const NewScheduleRule = ({ t }: { t: TFunction }): ReactElement => {
  const { userData, settings } = useContext(UserContext);
  const history = useHistory();

  const [form] = Form.useForm();
  const [formCadences] = Form.useForm();
  const [formCustomerRule] = Form.useForm();
  const [formEditCalendar] = Form.useForm();
  const [formCreateCalendar] = Form.useForm(); //Create cadences

  const [isWeekend, setIsWeekend] = useState(false);
  const [custRuleVisible, setCustRuleVisible] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [clientFormView, setClientFormView] = useState(false);
  const [weekendFormView, setWeekendFormView] = useState(false);
  const [confirmBtn, setConfirmBtn] = useState(false);
  const [clickConfirmBtn, setClickConfirmBtn] = useState(false);
  const [confirmCustRule, setConfirmCustRule] = useState(false);
  const [deleteCRModalVisible, setDeleteCRModalVisible] = useState(false); // CR = Customer Rules
  const [disabledMaxCapacity, setDisabledMaxCapacity] = useState(true);
  const [enableConfirm, setEnableConfirm] = useState(true);

  const [pageSize, setPageSize] = useState(10);
  const [calendarId, setCalendarId] = useState<Moment>();
  const [customerRuleDates, setCustomerRuleDates] = useState<any>([]);
  const [customerRules, setCustomerRules] = useState<ICreateCustomerRule[]>([]);
  const [clients, setClients] = useState<Farm[]>([]);
  const [cultures, setCultures] = useState<IGetSeedTypes[]>();
  const [locales, setLocales] = useState<IGetPickUpLocations[]>();
  const [scheduleRule, setScheduleRule] = useState<ICreateRule[]>();
  const [initialMonthAppear, setInitialMomentAppear] = useState<Moment>();
  const [calendarDates, setCalendarDates] = useState<ICalendarDate[]>([]);
  const [unityType, setUnityType] = useState<string>('BAG');
  const [sunday, setSunday] = useState<IFillCalendar>();
  const [saturday, setSaturday] = useState<IFillCalendar>();
  const [generalWeek, setGeneralWeek] = useState<IFillCalendar>();
  const [disabledCadences, setDisabledCadences] = useState<
    Array<ICalendarDate>
  >([]);

  const [selectedCustomerRule, setSelectedCustomerRule] =
    useState<IEditCustomerRule>();

  const generalCapacity: number = useWatch('generalCapacity', form);
  const sundayCapacity: number = useWatch(['sunday', 'quantity'], formCadences);
  const startDateValue = useWatch('startDate', form);
  const endDateValue = useWatch('endDate', form);
  const generalHours: [Moment, Moment] = useWatch('generalHours', form);
  const capacityPerCompany = useWatch('capacityPerCompany', form);
  const isFilledQuantity = useWatch('quantity', formEditCalendar);
  const isFilledHours = useWatch('hours', formEditCalendar);
  const isSwitchChecked = useWatch('enableCadence', formEditCalendar);
  const sundayHours: [Moment, Moment] = useWatch(
    ['sunday', 'hours'],
    formCadences
  );
  const saturdayCapacity: number = useWatch(
    ['saturday', 'quantity'],
    formCadences
  );
  const saturdayHours: [Moment, Moment] = useWatch(
    ['saturday', 'hours'],
    formCadences
  );

  const [getSettingsRequest] = useRequest(SettingsController.getSettings);
  const [cultureRequest] = useRequest(ScheduleRulesController.getCulture);
  const [createRuleRequest, ruleLoading] = useRequest(
    ScheduleRulesController.createRule
  );
  const [getClientsRequest] = useRequest(ClientsController.getFarms);
  const [localesRequest] = useRequest(AddressController.findPickUpLocations);
  const [customerRuleRequest] = useRequest(
    ScheduleRulesController.createCustomerRule
  );
  const [updatePendenciesRequest] = useRequest(
    HomeController.updateOrganizationPendencies
  );

  // manipulação de formulários
  const onFinishEditCalendar = (values: ICreateCadencesWeekDayForm) => {
    setCalendarId((calendarId: any) => {
      const calendarDate = calendarDates.find(({ date }) =>
        date.isSame(calendarId, 'day')
      );
      const index = calendarDates.findIndex(item =>
        item.date.isSame(calendarId, 'day')
      );

      if (calendarDate && values.hours && values.quantity) {
        const [startTime, endTime] = values.hours;
        startTime.date(calendarDate.date.date());
        endTime.date(calendarDate.date.date());

        const listData = values.quantity
          ? [
              {
                title: `${t('pages.scheduleRules.maxTitleDateCellContent')}: ${
                  values.quantity
                }`,
              },
              {
                title: `${startTime.format('HH:mm')} - ${endTime.format(
                  'HH:mm'
                )}`,
              },
            ]
          : [];

        calendarDates[index] = {
          calendarCell: listData,
          fillCalendar: {
            capacityPerDay: values.quantity,
            startTime,
            endTime,
          },
          date: calendarDate.date,
        };
        setCalendarDates([...calendarDates]);
      }
      return undefined;
    });
    formEditCalendar.resetFields();
  };
  const onFinishCreateCalendar = (values: ICreateCadencesWeekDayForm) => {
    setCalendarId((calendarId: any) => {
      if (values.hours && values.quantity) {
        const [startTime, endTime] = values.hours;

        startTime.date(calendarId.date());
        startTime.month(calendarId.month());
        startTime.year(calendarId.year());

        endTime.date(calendarId.date());
        endTime.month(calendarId.month());
        endTime.year(calendarId.year());

        const listData = values.quantity
          ? [
              {
                title: `${t('pages.scheduleRules.maxTitleDateCellContent')}: ${
                  values.quantity
                }`,
              },
              {
                title: `${startTime.format('HH:mm')} 
                - ${endTime.format('HH:mm')}`,
              },
            ]
          : [];

        const createdCadence = {
          calendarCell: listData,
          fillCalendar: {
            capacityPerDay: values.quantity,
            startTime: startTime.clone(),
            endTime: endTime.clone(),
          },
          date: calendarId,
        };
        setCalendarDates(filteredCalendarDates => [
          ...filteredCalendarDates,
          createdCadence,
        ]);
      }

      return undefined;
    });
    formCreateCalendar.resetFields();
  };

  const onFinishCustomerRule = (values: ICreateCustomerRuleFormValues) => {
    const [start, end] = values.rulePeriod;
    const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
    setCustomerRules(customerData => [
      ...customerData,
      {
        ...values,
        id: uuidv4(),
        startDate: start.clone().startOf('day').toISOString(),
        endDate: end.clone().endOf('day').toISOString(),
        timezone: tz,
      },
    ]);
    formCustomerRule.resetFields();
  };
  const onFinish = ({ ...values }: ICreateRuleFormValues) => {
    createRule(values);
  };

  // recursos de componentes
  const requiredRule = {
    required: true,
    message: t('pages.settings.pickUpLocations.requiredRuleMessage'),
  };
  const validValueRule = {
    validator(rule: RuleObject, value: number) {
      if (value === null && !rule.required) {
        setEnableConfirm(true);
        return Promise.resolve();
      }
      if (value <= 0) {
        setEnableConfirm(false);
        return Promise.reject(t('pages.scheduleRules.insertAValidValue'));
      }

      return Promise.resolve();
    },
  };

  // feedbacks de usuário
  const info = () => {
    message.success({
      content: t('pages.scheduleRules.ruleSuccess'),
      className: 'custom-class',
      style: {
        marginTop: 60,
      },
    });
  };
  const infoError = (statusCode: IErrorMessage) => {
    message.error({
      content: t(`${statusCode.message}`),
      className: 'custom-class',
      style: {
        marginTop: 60,
      },
    });
  };

  // requisições iniciais
  const getCultures = () => {
    cultureRequest({}).then(seeds => {
      setCultures(seeds);
    });
  };
  const getLocales = () => {
    if (userData) {
      localesRequest({ organizationId: userData.organization.id }).then(
        locations => {
          setLocales(locations);
        }
      );
    }
  };
  const getClients = () => {
    if (userData) {
      getClientsRequest(userData.organization.id)
        .then(cust => {
          setClients(cust);
        })
        .catch(() => {
          <Alert
            message={t('errors.900.message')}
            description={t('errors.900.description')}
          />;
        });
    }
  };
  const createCustomerRule = () => {
    if (scheduleRule && scheduleRule.length > 0) {
      scheduleRule.map(rule => {
        if (rule.id) {
          customerRuleRequest({
            customerRules: customerRules,
            referenceRuleId: rule.id,
          });
        }
      });
    }
  };

  // requisições de envio
  const createRule = (values: ICreateRuleFormValues) => {
    const cadences: IFillCalendar[] = calendarDates
      .map(cadence => {
        return {
          ...cadence.fillCalendar,
          startTime: moment(cadence.fillCalendar.startTime).clone(),
          endTime: moment(cadence.fillCalendar.endTime).clone(),
        };
      })
      .filter(cad => cad);
    const ruleData: ICreateRule = {
      ...values,
      pickUpLocationIds: values.pickUpLocationIds.map(locale => ({
        id: locale,
      })),
      schedulingStart: moment(values.schedulingStart)
        .clone()
        .startOf('day')
        .toISOString(),
      releaseSubmissionDate: moment(values.releaseSubmissionDate)
        .clone()
        .startOf('day')
        .toISOString(),
      startDate: moment(values.startDate).clone().startOf('day').toISOString(),
      endDate: moment(values.endDate).clone().endOf('day').toISOString(),

      loadReportTimeout: Number(values.loadReportTimeout),
      driverReportTimeout: Number(
        Converter.convertHoursInMinutes(values.driverReportTimeout)
      ),
      whiteSeedSchedulingTime: Number(values.whiteSeedSchedulingTime),
      tsiSeedSchedulingTime: Number(values.tsiSeedSchedulingTime),
      capacityPerCompany: Number(values.capacityPerCompany),
      generalCapacityPerDay: Number(generalCapacity),
      generalSaturdayCapacity: Number(saturdayCapacity),
      generalSundayCapacity: Number(sundayCapacity),
      createCadences: {
        cadences: cadences.map(cadence => ({
          available_units: cadence.capacityPerDay,
          capacity_per_day: cadence.capacityPerDay,
          end_time: cadence.endTime,
          start_time: cadence.startTime,
        })),
      },
    };
    createRuleRequest({ createRule: ruleData })
      .then(rule => {
        info();
        setScheduleRule(rule);
        history.push(`/regras/cadastro`, {
          updateList: scheduleRule,
        });
        if (userData) {
          updatePendenciesRequest({
            id: userData.organization.id,
            pendenciesInput: { rules: true },
          });
        }
      })
      .catch(err => {
        infoError(err);
      });
  };

  // controle do calendário
  function ordemCrescente(a: ICalendarDate, b: ICalendarDate) {
    return moment(a.date).unix() - moment(b.date).unix();
  }
  calendarDates.sort(ordemCrescente);
  const fillCalendarData = (value?: Moment) => {
    const calendarDate = calendarDates.find(({ date }) =>
      moment(date).isSame(value, 'day')
    );

    const disabledFirstDate = disabledCadences[0];
    const disabledLastDate = disabledCadences[disabledCadences.length - 1];

    const isIntoCadenceDays = calendarId?.isBetween(
      startDateValue,
      endDateValue,
      'days',
      '[]'
    );
    const isDisabledCadenceDay =
      calendarId?.isBetween(
        disabledFirstDate?.fillCalendar?.startTime,
        disabledLastDate?.fillCalendar?.startTime,
        'days',
        '[]'
      ) && !calendarDate?.fillCalendar;

    const isValidCadence = calendarDate?.fillCalendar ? true : false;

    const popoverView =
      isIntoCadenceDays && !isDisabledCadenceDay && isValidCadence ? (
        <EditCadenceInCalendar
          form={formEditCalendar}
          enableConfirm={enableConfirm}
          isSwitchChecked={isSwitchChecked}
          onFinish={onFinishEditCalendar}
          setShowModal={setShowEditModal}
          disableCadence={disableCadence}
          validatorRule={validValueRule}
          isFilledHours={isFilledHours}
          isFilledQuantity={isFilledQuantity}
        />
      ) : (
        <CreateCadenceInCalendar
          form={formCreateCalendar}
          onFinish={onFinishCreateCalendar}
          setShowModal={setShowEditModal}
        />
      );

    let placementType: TooltipPlacement;

    if (value?.isoWeekday() === 6) {
      placementType = 'topRight';
    } else if (value?.isoWeekday() === 7) {
      placementType = 'topLeft';
    } else {
      placementType = 'top';
    }
    return (
      <Popover
        placement={placementType}
        open={showEditModal && value?.isSame(calendarId, 'day')}
        content={<>{popoverView}</>}
      >
        <ul>
          {calendarDate?.calendarCell.map(item => (
            <li key={item.title}>{item.title}</li>
          ))}
        </ul>
      </Popover>
    );
  };

  const disableCadence = () => {
    if (calendarId) {
      const dayToDisable = calendarDates.find(calendarDate =>
        moment(calendarDate.date).isSame(calendarId, 'day')
      );
      if (dayToDisable) {
        setDisabledCadences(disabledCadences => [
          ...disabledCadences,
          dayToDisable,
        ]);
        formEditCalendar.resetFields();
      }
    }
  };
  const buildFillCalendar = (
    capacity: number,
    hours: [Moment, Moment]
  ): IFillCalendar => {
    const [startTime, endTime] = hours;
    return {
      endTime,
      startTime,
      capacityPerDay: Number(capacity),
    };
  };
  const rulePeriodDates = (
    dateRange: [Moment, Moment],
    includes: [boolean, boolean] = [true, true]
  ) => {
    const startDate = moment(dateRange[0]).clone();
    const endDate = moment(dateRange[1]).clone();

    const dateDiff = endDate.diff(startDate, 'day');

    if (dateDiff > 0) {
      const datesInRange = new Array(dateDiff)
        .fill('')
        .map((_, index) => startDate.clone().add(index + 1, 'day'));

      if (includes[0]) {
        datesInRange.unshift(startDate.clone());
      }

      if (!includes[1]) {
        datesInRange.pop();
      }

      return datesInRange;
    }
    return [];
  };
  const getListData = (value: Moment) => {
    const days = (value: Moment) => {
      switch (value.isoWeekday()) {
        case 1:
        case 2:
        case 3:
        case 4:
        case 5:
          return generalWeek;
        case 6:
          return saturday;
        case 7:
          return sunday;
      }
    };
    const fillCalendar = days(value);

    if (fillCalendar) {
      const startTime = fillCalendar.startTime.clone();
      const endTime = fillCalendar.endTime.clone();
      if (startTime && endTime) {
        startTime.set({
          date: value.date(),
          month: value.month(),
          year: value.year(),
        });

        endTime.set({
          date: value.date(),
          month: value.month(),
          year: value.year(),
        });

        const newFillCalendar = {
          ...fillCalendar,
          endTime,
          startTime,
        };

        const listData = fillCalendar.capacityPerDay
          ? [
              {
                title: `Max: ${fillCalendar.capacityPerDay}`,
              },
              {
                title: `${fillCalendar.startTime.format(
                  'HH:mm'
                )} - ${fillCalendar.endTime.format('HH:mm')} `,
              },
            ]
          : [];

        return { listData, fillCalendar: newFillCalendar };
      }
    }
  };

  // componentes de formulário
  useEffect(() => {
    if (settings) {
      form.setFieldsValue({
        whiteSeedSchedulingTime: settings.commonWhiteSeedSchedulingTime,
        tsiSeedSchedulingTime: settings.commonTsiSeedSchedulingTime,
      });
    }
  }, [settings]);

  const rulesForm = (
    <Form
      scrollToFirstError={scrollOptions}
      layout="vertical"
      form={form}
      onFinish={onFinish}
    >
      <BasicInformationCard
        locales={locales}
        requiredRule={requiredRule}
        inputsColSize={inputsColSize}
        cultures={cultures}
        form={form}
      />

      <Alert
        style={{ marginBottom: 24 }}
        message="O tempo de tratamento de sementes, assim como a data de envio de liberação são informações que afetam a regra, e caso sejam alterados os dados terão que ser preenchidos novamente"
        type="warning"
      />

      <SeedsTreatmentCard
        form={form}
        requiredRule={requiredRule}
        inputsColSize={inputsColSize}
        validValueRule={validValueRule}
      />

      <Alert
        style={{ marginBottom: 24 }}
        message={t('pages.scheduleRules.alertMessageToUnities')}
        type="warning"
      />

      <Row gutter={24} style={{ marginBottom: 24 }}>
        <Col span={24} style={{ height: '100%' }}>
          <UnityInformationCard setUnityType={setUnityType} />
        </Col>
      </Row>

      <Alert
        style={{ marginBottom: 24 }}
        message={t('pages.scheduleRules.alertMessageToCapacities')}
        type="warning"
      />

      <CapacityInformationCard
        isWeekend={isWeekend}
        disabledMaxCapacity={disabledMaxCapacity}
        unityType={unityType}
        generalCapacity={generalCapacity}
        requiredRule={requiredRule}
        validValueRule={validValueRule}
        colSize={colSize}
        saturdayCapacity={saturdayCapacity}
        sundayCapacity={sundayCapacity}
      />
    </Form>
  );
  const cadencesForm = (
    <Form
      scrollToFirstError={scrollOptions}
      style={{ marginBottom: 24 }}
      layout="vertical"
      form={formCadences}
    >
      <WeekendCard
        form={formCadences}
        formCadences={formCadences}
        weekendFormView={weekendFormView}
        setWeekendFormView={setWeekendFormView}
        setConfirmBtn={setConfirmBtn}
        clickConfirmBtn={clickConfirmBtn}
        setClickConfirmBtn={setClickConfirmBtn}
        capacityPerCompany={capacityPerCompany}
        validValueRule={validValueRule}
        cadencesColSize={cadencesColSize}
        generalCapacity={generalCapacity}
        saturdayCapacity={saturdayCapacity}
        sundayCapacity={sundayCapacity}
      />
    </Form>
  );
  const customerRulesFormAndCard = (
    <>
      <Alert
        style={{ marginBottom: 24 }}
        type="warning"
        message={t('pages.scheduleRules.newRules.alertOfDisableDates')}
      />
      <CustomerForm
        formCustomerRule={formCustomerRule}
        formEditCalendar={formEditCalendar}
        setCustRuleVisible={setCustRuleVisible}
        showEditModal={showEditModal}
        setShowEditModal={setShowEditModal}
        clientFormView={clientFormView}
        setClientFormView={setClientFormView}
        setDeleteCRModalVisible={setDeleteCRModalVisible}
        pageSize={pageSize}
        setPageSize={setPageSize}
        setCalendarId={setCalendarId}
        customerRuleDates={customerRuleDates}
        setCustomerRuleDates={setCustomerRuleDates}
        customerRules={customerRules}
        clients={clients}
        initialMonthAppear={initialMonthAppear}
        setSelectedCustomerRule={setSelectedCustomerRule}
        startDate={startDateValue}
        endDate={endDateValue}
        fillCalendarData={fillCalendarData}
        onFinishCustomerRule={onFinishCustomerRule}
        colSize={colSize}
        requiredRule={requiredRule}
        validValueRule={validValueRule}
        generalCapacity={generalCapacity}
      />
    </>
  );
  const footer = (
    <Space>
      <Button
        danger
        children={t('pages.editCompany.cancelBtn')}
        onClick={() => {
          form.resetFields();
          history.push('/regras/cadastro');
        }}
      />

      <Button
        type="primary"
        onClick={() => form.submit()}
        disabled={calendarDates.length <= 0}
        loading={ruleLoading}
        children={t('pages.editCompany.confirmBtn')}
      />
    </Space>
  );

  useEffect(() => {
    const filteredDates = calendarDates.filter(
      calendarDate =>
        !disabledCadences.some(disableCadence =>
          disableCadence.fillCalendar.startTime.isSame(calendarDate.date, 'day')
        )
    );
    setCalendarDates(filteredDates);
  }, [disabledCadences]);
  useEffect(() => {
    if (generalCapacity) {
      setDisabledMaxCapacity(false);
    } else if (saturdayCapacity) {
      setDisabledMaxCapacity(false);
    } else if (sundayCapacity) {
      setDisabledMaxCapacity(false);
    }
  }, [generalCapacity, saturdayCapacity, sundayCapacity]);
  useEffect(() => {
    getCultures();
    getLocales();
    getClients();
  }, [userData]);
  useEffect(() => {
    if (scheduleRule) {
      formCadences.submit();
      createCustomerRule();
    }
  }, [scheduleRule]);
  useEffect(() => {
    const period = rulePeriodDates([startDateValue, endDateValue]);

    if (confirmBtn) {
      const isDayOfWeekend = period.every(date => {
        const dayOfWeek = date.day();
        return dayOfWeek === 6 || dayOfWeek === 0;
      });

      isDayOfWeekend ? setIsWeekend(true) : setIsWeekend(false);
    }

    const dates = period
      .map(date => {
        const listData = getListData(date);
        if (listData) {
          const { listData: calendarCell, fillCalendar } = listData;
          return {
            date,
            calendarCell,
            fillCalendar,
          };
        }
      })
      .filter(cad => {
        const weekDay = (date?: Moment) => moment(date).isoWeekday();
        if (weekendFormView) {
          return cad;
        } else {
          if (weekDay(cad?.date) !== 6 && weekDay(cad?.date) !== 7) {
            return cad;
          }
        }
      }) as ICalendarDate[];

    setCalendarDates(dates);

    if (dates.length > 0) {
      const data = dates[0].date.clone();
      const defaultValue = moment(data).startOf('month');
      setInitialMomentAppear(defaultValue);
    }
  }, [clickConfirmBtn]);
  useEffect(() => {
    if (saturdayCapacity && saturdayHours) {
      setSaturday(buildFillCalendar(saturdayCapacity, saturdayHours));
    }
  }, [saturdayCapacity, saturdayHours]);
  useEffect(() => {
    if (sundayCapacity && sundayHours) {
      setSunday(buildFillCalendar(sundayCapacity, sundayHours));
    }
  }, [sundayCapacity, sundayHours]);
  useEffect(() => {
    if (generalCapacity && generalHours) {
      setGeneralWeek(buildFillCalendar(generalCapacity, generalHours));
    }
  }, [generalCapacity, generalHours]);
  useEffect(() => {
    if (weekendFormView === false) {
      formCadences.setFieldsValue(undefined);
    }
  }, [weekendFormView]);
  useEffect(() => {
    if (userData) {
      getSettingsRequest({ organizationId: userData.organization.id }).then(
        value => {
          form.setFieldsValue({
            whiteSeedSchedulingTime: value.commonWhiteSeedSchedulingTime,
            tsiSeedSchedulingTime: value.commonTsiSeedSchedulingTime,
          });
        }
      );
    }
  }, []);

  return (
    <>
      <Layout>
        <PageHeader
          title={t('pages.scheduleRules.newRules.title')}
          subTitle={t('pages.scheduleRules.newRules.subtitle')}
          ghost={false}
          breadcrumb={<BreadCrumb />}
        />
        <Layout style={{ margin: 24 }}>
          {rulesForm}
          {cadencesForm}
          {customerRulesFormAndCard}
        </Layout>
      </Layout>
      <Footer>{footer}</Footer>
      <DeleteCustomerRule
        visible={deleteCRModalVisible}
        onOk={() => setDeleteCRModalVisible(false)}
        onCancel={() => setDeleteCRModalVisible(false)}
        selectedCustomerRule={selectedCustomerRule}
        setCustomerRules={setCustomerRules}
      />
      <CustomerRuleEdition
        customerRuleModalVisible={custRuleVisible}
        onOkCustomerRule={() => {
          setConfirmCustRule(!confirmCustRule);
          setCustRuleVisible(false);
        }}
        onCancelCustomerRule={() => setCustRuleVisible(false)}
        selectedCustomerRule={selectedCustomerRule}
        customerRules={customerRules}
        setCustomerRules={setCustomerRules}
      />
    </>
  );
};

export default withTranslation()(NewScheduleRule);
