import { Button, Card, Col, message, Row, Table, Tooltip } from 'antd';
import React, { Dispatch, useState } from 'react';
import { TFunction, withTranslation } from 'react-i18next';
import { useRequest } from '../../../hooks/useRequest';
import TruckLoadController from '../../../structures/controllers/TruckLoad';
import { IViewTruckLoad } from '../../../structures/interfaces/TruckLoad';

const baseString = 'pages.truckLoads.viewLoad';

function SynchronizationCard({
  t,
  truckLoadData,
  isSyncDisabled = false,
  isFetchingTruckLoad = false,
  setIsSynchronizingTruckLoad,
}: {
  t: TFunction;
  truckLoadData?: IViewTruckLoad;
  isSyncDisabled: boolean;
  isFetchingTruckLoad: boolean;
  setIsSynchronizingTruckLoad: Dispatch<React.SetStateAction<boolean>>;
}) {
  const [pageSize, setPageSize] = useState(10);

  const [synchronizeTruckLoadRequest, isSynchronizeTruckLoadRequesting] =
    useRequest(TruckLoadController.synchronizeTruckLoad);

  const columnsTruckLoadRefuses = [
    {
      title: t(`${baseString}.cancellationReasonsTable.reasons`),
      key: 'reason',
      dataIndex: 'message',
    },
  ];

  const handleSynchronizeTruckLoad = () => {
    if (truckLoadData) {
      setIsSynchronizingTruckLoad(true);
      synchronizeTruckLoadRequest({
        truckLoadId: truckLoadData.id,
      })
        .then(({ success }) => {
          if (success) {
            message.info({
              content: t(`${baseString}.synchronizing`),
              style: { marginTop: '4rem' },
              duration: 4,
            });
          }
        })
        .catch(err =>
          message.error({
            content: t(err.message),
            style: { marginTop: '4rem' },
          })
        )
        .finally(() => {
          setIsSynchronizingTruckLoad(false);
        });
    }
  };

  return (
    <Card
      loading={isFetchingTruckLoad}
      title={t(`${baseString}.cardTitles.synchronization`)}
    >
      <Table
        columns={columnsTruckLoadRefuses}
        dataSource={truckLoadData?.cancellationReasons}
        pagination={{
          hideOnSinglePage: true,
          pageSize: pageSize,
          onChange(_, size) {
            setPageSize(size);
          },
        }}
      />

      <Row>
        <Col style={{ marginLeft: 'auto' }}>
          <Tooltip
            title={isSyncDisabled ? t(`${baseString}.mustFinishUpdate`) : ''}
          >
            <Button
              style={{ float: 'right', marginLeft: 12, marginTop: 12 }}
              type="primary"
              onClick={handleSynchronizeTruckLoad}
              loading={isSynchronizeTruckLoadRequesting}
              disabled={isSyncDisabled}
            >
              {t(`${baseString}.synchronizeTruckLoadBtn`)}
            </Button>
          </Tooltip>
        </Col>
      </Row>
    </Card>
  );
}
export default withTranslation()(SynchronizationCard);
