import client from '../../api/urql/customClient';
import Formatter from '../../classes/Formatter';
import {
  IContractItems,
  IGetContract,
  IGetContractWhere,
  IGetCustomerContractsResponse,
  IViewContracts,
} from '../interfaces/Contract';
import queries from '../queries/Contract';

class ContractController {
  static getContractsById = (
    filters: IGetContractWhere
  ): Promise<IGetContract[]> =>
    new Promise((resolve, reject) =>
      client
        .query(queries.getContracts(), {
          where: {
            organization_id: filters.organization_id,
            subordinates_filter:
              filters.subordinates_filter === null
                ? null
                : {
                    ignore_subordinates:
                      filters.subordinates_filter.ignore_subordinates,
                    subordinates_only:
                      filters.subordinates_filter.subordinates_only,
                    exclude_subordinates:
                      filters.subordinates_filter.exclude_subordinates,
                    include_subordinates:
                      filters.subordinates_filter.include_subordinates,
                    take_only: filters.subordinates_filter.take_only,
                  },
          },
        })
        .then(({ getContracts }) => {
          const contracts: IGetContract[] = getContracts.map(contract => {
            if (!contract.farm.name) contract.farm.name = '---';
            if (!contract.farm.email) contract.farm.email = '---';

            contract.farm.customer.documentNumberCnpj = contract.farm.customer
              .documentNumberCnpj
              ? Formatter.formatCNPJ(contract.farm.customer.documentNumberCnpj)
              : '---';
            contract.farm.customer.documentNumberCpf = contract.farm.customer
              .documentNumberCpf
              ? Formatter.formatCPF(contract.farm.customer.documentNumberCpf)
              : '---';
            contract.salesman.documentNumberCpfMask = contract.salesman
              .documentNumberCpf
              ? Formatter.formatCPF(contract.salesman.documentNumberCpf)
              : '---';
            contract.farm.customer.phoneNumber = contract.farm.customer
              .phoneNumber
              ? Formatter.formatCellphone(contract.farm.customer.phoneNumber)
              : '---';
            contract.contractDateString = Formatter.formatDate(
              contract.contractDate
            );

            return contract;
          });
          resolve(contracts);
        })
        .catch(err => reject(err))
    );

  static getViewContracts = (
    organizationId: string
  ): Promise<IViewContracts[]> =>
    new Promise((resolve, reject) =>
      client
        .query(queries.getViewContracts(), {
          where: { organization_id: organizationId },
        })
        .then(({ viewContracts }) => {
          const views: IViewContracts[] = viewContracts.map(
            ({ ...viewContracts }) => {
              return {
                ...viewContracts,
                farm: {
                  ...viewContracts.farm,
                  customer: {
                    ...viewContracts.farm.customer,
                    phoneNumberMask: Formatter.formatCellphone(
                      viewContracts.farm.customer.phoneNumber
                    ),
                  },
                },
                contractDateMask: Formatter.formatDate(
                  viewContracts.contractDate
                ),
              };
            }
          );
          resolve(views);
        })
        .catch(err => reject(err))
    );

  static getContractItems = (id: string): Promise<IContractItems[]> =>
    new Promise((resolve, reject) =>
      client
        .query(queries.getContractItems(), { where: { contract_id: id } })
        .then(({ getContractItems }) => {
          resolve(getContractItems);
        })
        .catch(err => reject(err))
    );

  static getCustomerContracts = (
    customerId: string
  ): Promise<IGetCustomerContractsResponse[]> =>
    new Promise((resolve, reject) =>
      client
        .query(queries.getCustomerContracts(), {
          where: { customer_id: customerId },
        })
        .then(({ getContracts }) => {
          const contracts: IGetCustomerContractsResponse[] = getContracts.map(
            contract => {
              if (!contract.farm.name) contract.farm.name = '---';
              if (!contract.farm.email) contract.farm.email = '---';
              contract.farm.customer.documentNumberCnpj = contract.farm.customer
                .documentNumberCnpj
                ? Formatter.formatCNPJ(
                    contract.farm.customer.documentNumberCnpj
                  )
                : '---';
              contract.salesman.documentNumberCpf = Formatter.formatCPF(
                contract.salesman.documentNumberCpf
              );
              contract.farm.customer.documentNumberCpf = contract.farm.customer
                .documentNumberCpf
                ? Formatter.formatCPF(contract.farm.customer.documentNumberCpf)
                : '---';
              contract.salesman.documentNumberCpfMask = contract.salesman
                .documentNumberCpf
                ? Formatter.formatCPF(contract.salesman.documentNumberCpf)
                : '---';
              contract.farm.customer.phoneNumber = contract.farm.customer
                .phoneNumber
                ? Formatter.formatCellphone(contract.farm.customer.phoneNumber)
                : '---';
              contract.contractDateString = Formatter.formatDate(
                contract.contractDate
              );

              return contract;
            }
          );
          resolve(contracts);
        })
        .catch(err => reject(err))
    );
}

export default ContractController;
