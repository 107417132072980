import { object } from 'moove-tools-js';
import {
  IDashboardContractTable,
  IGetContract,
  IGetCustomerContractsResponse,
} from '../../structures/interfaces/Contract';
import moment from 'moment';

export const BuildSlug = (contracts: IGetContract[]): IGetContract[] =>
  contracts?.map(contract => ({
    ...contract,
    slug: `${contract.id} ${contract.contractDate} ${contract.contractDateString} ${contract.farm.name} ${contract.farm.stateRegistration} ${contract.farm.customer.tradeName} ${contract.farm.customer.documentNumberCpf} ${contract.farm.customer.documentNumberCpfMask} ${contract.farm.customer.documentNumberCnpj} ${contract.farm.customer.documentNumberCnpjMask} ${contract.referenceNumber} ${contract.status} ${contract.farm.email} ${contract.farm.customer.phoneNumber} ${contract.farm.customer.phoneNumberMask} ${contract.contractItems}`.toLowerCase(),
  }));
export const BuildContracts = (
  contracts: IGetContract[] | IGetCustomerContractsResponse[]
): Array<IGetContract & { contract_item_id: string }> => {
  const contractItems: any = [];
  contracts?.forEach(contract => {
    const contrato = object.pick(
      contract as unknown as Record<string, unknown>,
      [
        'id',
        'farm',
        'status',
        'contractDate',
        'contractDateString',
        'referenceNumber',
        'organization_id',
        'farm_id',
        'salesman_id',
        'salesman',
      ]
    );
    contract.contractItems.forEach(contractItem => {
      contractItems.push({
        ...contrato,
        ...object.pick(contractItem, [
          ['contract_item_id', 'id'],
          ['contract_item_reference_number', 'referenceNumber'],
          ['contract_item_cultivation', 'cultivation'],
          ['contract_item_packing', 'packing'],
          ['contract_item_sieve', 'sieve'],
          ['contract_item_category', 'category'],
          ['contract_item_tsi', 'tsi'],
          ['contract_item_estimated_weight', 'estimatedWeight'],
          ['contract_item_seed_type', 'seedType'],
          ['contract_item_full_amount', 'fullAmount'],
          ['contract_item_remaining_amount', 'remainingAmount'],
        ]),
      });
    });
  });
  return contractItems;
};
export const FilterContractsByStatus = (
  contracts: IDashboardContractTable[],
  status?: string
): IDashboardContractTable[] => {
  return status != undefined
    ? contracts.filter(contract => contract.status === status)
    : contracts;
};

export const FilterContractsByDate = (
  contracts: IDashboardContractTable[],
  date?: [moment.Moment, moment.Moment]
): IDashboardContractTable[] =>
  date != undefined
    ? contracts.filter(contract => {
        return moment(contract.contractDate).isBetween(
          date[0],
          date[1],
          'day',
          '[]'
        );
      })
    : contracts;
