import { MenuOutlined } from '@ant-design/icons';
import {
  Button,
  Card,
  Col,
  Divider,
  Progress,
  Row,
  Statistic,
  Tag,
  message,
} from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { DefaultRecordType } from 'rc-table/lib/interface';
import { useContext, useEffect, useRef, useState } from 'react';
import { TFunction, withTranslation } from 'react-i18next';
import Formatter from '../../../classes/Formatter';
import DraggingTable from '../../../components/table/DraggingTable';
import UserContext from '../../../contexts/user';
import { useRequest } from '../../../hooks/useRequest';
import TruckLoadController from '../../../structures/controllers/TruckLoad';
import {
  IGetContractItems,
  IViewTruckLoad,
  IViewTruckLoadItem,
} from '../../../structures/interfaces/TruckLoad';
import { IErrorMessage } from '../../../utils/interfaces';
import ContractItemsTable from './ContractItemsTable';
import TruckImage from './TruckImage';

const colors = [
  '#C97A6D',
  '#7AC97D',
  '#7A6DC9',
  '#C9BC7A',
  '#C97AA6',
  '#A17AC9',
  '#7AC9A1',
  '#7A9BC9',
  '#C96D7A',
  '#7AC9BD',
  '#C97A68',
  '#7A91B3',
  '#B38A7A',
  '#7AB3B0',
];

function TruckLoadEdition({
  t,
  showContractItems,
  showDeleteButton,
  truckLoadItems,
  truckLoadData,
  vehicleWeightCapacity,
  vehicleBagsCapacity,
  onOk,
  onCancel,
  buttonLoading,
}: {
  t: TFunction;
  showContractItems: boolean;
  showDeleteButton: boolean;
  truckLoadItems: IViewTruckLoadItem[];
  truckLoadData: IViewTruckLoad;
  vehicleWeightCapacity: number;
  vehicleBagsCapacity: number;
  onOk: (newItems: IGetContractItems[], removedItems: string[]) => void;
  onCancel: () => void;
  buttonLoading: boolean;
}) {
  const baseString = 'pages.truckLoads';

  const { userData, customerData } = useContext(UserContext);

  const editItemsContainerRef = useRef<HTMLDivElement>(null);

  const [fetchContractItems, contractItemsRequesting] = useRequest(
    TruckLoadController.getContractItems
  );

  const [hoveredBoxIndex, setHoveredBoxIndex] = useState<number>();
  const [hoveredRowIndex, setHoveredRowIndex] = useState<number>();
  const [editItemsContainerFixed, setEditItemsContainerFixed] = useState(false);
  const [colorsAssigned, setColorsAssigned] = useState({});
  const [contractItems, setContractItems] = useState<IGetContractItems[]>([]);
  const [addedItems, setAddedItems] = useState<IGetContractItems[]>(
    truckLoadItems as unknown as IGetContractItems[]
  );
  const [removedItems, setRemovedItems] = useState<string[]>([]);

  const renderCultivationTags = (cultivation: string, id: string) => {
    const color =
      colorsAssigned[id as keyof typeof colorsAssigned] || colors[0];

    return <Tag color={color}>{cultivation}</Tag>;
  };

  const removeLoaded = (values: IGetContractItems, index: number) => {
    if (showDeleteButton && !showContractItems) {
      setAddedItems(
        addedItems.filter(
          (item: IGetContractItems, itemIndex: number) => itemIndex !== index
        )
      );
      if (!values.extraItem) {
        setRemovedItems(prevRemovedItemds => [...prevRemovedItemds, values.id]);
      }
    } else if (typeof values.index !== 'undefined' && values.index !== -1) {
      const updatedContractItems = [...contractItems];
      updatedContractItems[values.index].remainingAmount +=
        values.allocatedAmount || 0;
      setContractItems(updatedContractItems);
      setAddedItems(
        addedItems.filter(
          (item: IGetContractItems, itemIndex: number) => itemIndex !== index
        )
      );
      if (!values.extraItem) {
        setRemovedItems(prevRemovedItemds => [...prevRemovedItemds, values.id]);
      }
    }
  };

  const renderRemoveButton = (values: IGetContractItems, index: number) => (
    <Button type="link" danger onClick={() => removeLoaded(values, index)}>
      {t(`${baseString}.createLoad.stepTwo.renderRemoveButton`)}
    </Button>
  );

  const columnsTruckLoadItems = [
    {
      title: t(`${baseString}.createLoad.createdLoads.columns.organization`),
      key: 'org',
      dataIndex: '',
      render: () => <MenuOutlined style={{ color: '#999' }} />,
    },
    {
      title: t(`${baseString}.createLoad.createdLoads.columns.order`),
      key: 'order',
      dataIndex: 'order',
      render: (_: string, __: string, index: number) =>
        `${addedItems.length - index}ª desc`,
    },
    {
      title: t(`${baseString}.createLoad.contractItems.columns.client`),
      key: 'client',
      dataIndex: 'client',
    },
    {
      title: t(`${baseString}.createLoad.contractItems.columns.stateRegister`),
      key: 'stateRegistration',
      dataIndex: 'stateRegistration',
    },
    {
      title: t(`${baseString}.createLoad.contractItems.columns.contract`),
      key: 'contractNumber',
      dataIndex: 'contractNumber',
    },
    {
      title: t(`${baseString}.createLoad.contractItems.columns.date`),
      key: 'contractDate',
      dataIndex: 'contractDate',
      render: (date: string) => Formatter.formatDate(date),
      sorter: (a: IViewTruckLoadItem, b: IViewTruckLoadItem) =>
        new Date(a.contractDate).valueOf() - new Date(b.contractDate).valueOf(),
    },
    {
      title: t(`${baseString}.createLoad.contractItems.columns.id`),
      key: 'referenceNumber',
      dataIndex: 'referenceNumber',
    },
    {
      title: t(`${baseString}.createLoad.contractItems.columns.cultivation`),
      key: 'cultivation',
      dataIndex: 'cultivation',
      render: (
        cultivation: string,
        { contractItemId }: { contractItemId: string }
      ) => renderCultivationTags(cultivation, contractItemId),
    },
    {
      title: t(`${baseString}.createLoad.contractItems.columns.sieve`),
      key: 'sieve',
      dataIndex: 'sieve',
    },
    {
      title: t(`${baseString}.createLoad.contractItems.columns.category`),
      key: 'category',
      dataIndex: 'category',
    },
    {
      title: t(`${baseString}.createLoad.contractItems.columns.package`),
      key: 'packing',
      dataIndex: 'packing',
    },
    {
      title: t(`${baseString}.createLoad.contractItems.columns.tsi`),
      key: 'tsi',
      dataIndex: 'tsi',
      render: (tsi: boolean) => (tsi ? 'Sim' : 'Não'),
    },
    {
      title: t(`${baseString}.createLoad.contractItems.columns.weight`),
      key: 'estimatedWeight',
      dataIndex: 'estimatedWeight',
      sorter: (a: IViewTruckLoadItem, b: IViewTruckLoadItem) =>
        a.estimatedWeight - b.estimatedWeight,
    },
    {
      title: t(`${baseString}.createLoad.createdLoads.columns.allocated`),
      key: 'allocatedAmount',
      dataIndex: 'allocatedAmount',
    },
    {
      title: t(`${baseString}.createLoad.createdLoads.columns.actions`),
      render: (values: IGetContractItems, _: string, index: number) =>
        renderRemoveButton(values, index),
      align: 'center',
    },
  ];

  if (!showDeleteButton) {
    columnsTruckLoadItems.pop();
  }

  const filteredColumns = columnsTruckLoadItems.filter(column => {
    if (customerData) {
      return column.key !== 'category' && column.key !== 'sieve';
    } else {
      return column;
    }
  });

  const handleSubmit = () => {
    onOk(addedItems, removedItems);
  };

  const handleLoadAddition = (newLoads: IGetContractItems[]) => {
    setAddedItems([...addedItems, ...newLoads]);
  };

  const handleRowMove = (newItemsOrder: DefaultRecordType[]) => {
    setAddedItems(newItemsOrder as IGetContractItems[]);
  };

  const handleBoxHover = (index: number) => {
    if (index === -1) {
      setHoveredBoxIndex(undefined);
    } else {
      setHoveredBoxIndex(index);
    }
  };

  const onRowHover = (record: DefaultRecordType, index?: number) => {
    if (index === -1) {
      setHoveredRowIndex(undefined);
    } else {
      if (index !== undefined) {
        const reverseIndex = addedItems.length - index - 1;
        setHoveredRowIndex(reverseIndex);
      }
    }
  };

  const handleScroll = () => {
    if (editItemsContainerRef.current?.previousElementSibling) {
      const isPinned =
        editItemsContainerRef.current.offsetTop >=
        editItemsContainerRef.current.previousElementSibling.clientHeight;
      if (isPinned !== editItemsContainerFixed) {
        setEditItemsContainerFixed(isPinned);
      }
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [editItemsContainerRef, editItemsContainerFixed]);

  const showError = (error: IErrorMessage) => {
    message.error({
      content: t(error.message),
      style: { marginTop: '4rem' },
    });
  };

  const getAddedItemsWeight = () =>
    addedItems.reduce((prev, curr) => {
      return prev + (curr.allocatedAmount || 1) * curr.estimatedWeight;
    }, 0);

  const roundTo2 = (num: number) => Math.ceil(num * 100) / 100;
  const maxWeight = vehicleWeightCapacity || 0;
  const maxCapacity = vehicleBagsCapacity || 0;
  const getUnityPercentage = () => {
    const unityText = t(`pages.truckLoads.createLoad.stepTwo.percentages.bags`);

    let value = addedItems.length;
    let totalOfUnity = 0;
    totalOfUnity = addedItems.reduce((prev, curr) => {
      return prev + (curr.allocatedAmount || 0);
    }, 0);

    if (vehicleBagsCapacity) {
      value = (100 / vehicleBagsCapacity) * totalOfUnity;
    }

    return (
      <>
        <Statistic
          valueStyle={{ fontSize: 32, fontWeight: 'bold' }}
          title={t(`pages.truckLoads.createLoad.cardTitles.loadInBags`)}
          value={`${roundTo2(value)}%`}
        />
        <Progress
          strokeColor="#59C7C9"
          showInfo={false}
          percent={roundTo2(value)}
        />
        <Divider style={{ margin: '18 0' }} />
        <span>{`${totalOfUnity} / ${maxCapacity} ${unityText}`}</span>
      </>
    );
  };
  const getTonsPercentage = () => {
    let value = 0;
    let totalWeight = 0;
    if (vehicleWeightCapacity) {
      totalWeight = addedItems.reduce(
        (prev: number, curr: IGetContractItems) =>
          prev + Number(curr.estimatedWeight) * (curr.allocatedAmount || 1),
        0
      );
      value = (100 / (vehicleWeightCapacity / 1000)) * (totalWeight / 1000);
    }

    return (
      <>
        <Statistic
          valueStyle={{ fontSize: 32, fontWeight: 'bold' }}
          title={t(`pages.truckLoads.createLoad.cardTitles.loadInTons`)}
          value={`${roundTo2(value)}%`}
        />
        <Progress
          strokeColor="#59C7C9"
          showInfo={false}
          percent={roundTo2(value)}
        />
        <Divider style={{ margin: '18 0' }} />
        <span>{`${totalWeight / 1000} / ${maxWeight / 1000} ${t(
          `pages.truckLoads.createLoad.stepTwo.percentages.tons`
        )}`}</span>
      </>
    );
  };

  useEffect(() => {
    if (showContractItems) {
      const organizationId = userData
        ? userData.organization.id
        : customerData?.organizationId;

      fetchContractItems({
        unity: truckLoadData.description.unity as string,
        seedType: truckLoadData.description.seedType as string,
        organizationId: organizationId,
        prescheduleId: truckLoadData.preScheduleId,
        excludeTsi: false,
      })
        .then(response => {
          const formattedItems = response.contractItems.map((item, index) => {
            return {
              ...item,
              contractItemId: item.id,
              cultivation: `${item.cultivation}`,
              index,
            };
          });
          const newColors: { [key: string]: string } = {};

          formattedItems.forEach(({ id }: { id: string }) => {
            if (!newColors[id as keyof typeof colorsAssigned]) {
              let colorIndex = Object.keys(newColors).length;

              if (colorIndex > colors.length - 1) {
                colorIndex -=
                  Math.floor(colorIndex / colors.length) * colors.length;
              }

              newColors[id] = colors[colorIndex];
            }
          });
          setColorsAssigned(newColors);
          setContractItems(formattedItems);

          if (addedItems.length) {
            setAddedItems(
              truckLoadItems.map(item => {
                const contractItemIndex = formattedItems.findIndex(
                  ({ contractItemId }) => contractItemId === item.contractItemId
                );
                return {
                  ...(item as unknown as IGetContractItems),
                  index: contractItemIndex,
                };
              })
            );
          }
        })
        .catch(err => {
          showError(err);
          onCancel();
        });
    }
  }, []);

  return (
    <Row gutter={[24, 24]}>
      {showContractItems && (
        <>
          <ContractItemsTable
            contractItems={contractItems}
            setContractItems={setContractItems}
            colorsAssigned={colorsAssigned}
            addedItemsWeight={getAddedItemsWeight()}
            loading={contractItemsRequesting}
            maxWeight={vehicleWeightCapacity}
            maxBagsCapacity={vehicleBagsCapacity}
            unity={truckLoadData.description.unity as string}
            onLoadAddition={handleLoadAddition}
            addedItemsQtd={truckLoadData.items.length || 0}
            capacityPerCompany={
              truckLoadData.description.capacityPerCompany || 0
            }
          />

          <Col span={12} style={{ display: 'flex' }}>
            <Card style={{ flexGrow: 1 }}>{getUnityPercentage()}</Card>
          </Col>

          <Col span={12}>
            <Card>{getTonsPercentage()}</Card>
          </Col>
        </>
      )}
      <Col span={24}>
        <Card>
          <Row>
            <Col span={3} xs={0} sm={0} md={0} lg={3}>
              <TruckImage
                colorsAssigned={colorsAssigned}
                truckLoadItems={addedItems}
                onBoxHover={handleBoxHover}
                hoveredRow={hoveredRowIndex}
              />
            </Col>
            <Col span={21} xs={24} sm={24} md={24} lg={21}>
              <DraggingTable
                data={addedItems || []}
                setData={handleRowMove}
                columns={filteredColumns as ColumnsType<DefaultRecordType>}
                size="small"
                tableProps={{
                  rowClassName: (record, index: number) => {
                    if (index === hoveredBoxIndex)
                      return 'truck-load-item-row hovered-box';
                    return 'truck-load-item-row';
                  },
                }}
                onRowHover={onRowHover}
              />
              <div
                style={{
                  width: '100%',
                  background: '#fff',
                  height: 60,
                  padding: 12,
                  position: 'sticky',
                  bottom: 0,
                  boxShadow: editItemsContainerFixed
                    ? 'none'
                    : 'rgba(0, 0, 0, 0.15) 0px -8px 8px -4px',
                  transition: 'all .2s ease',
                  zIndex: 2,
                }}
                ref={editItemsContainerRef}
              >
                <div style={{ float: 'right' }}>
                  <Button
                    style={{ marginRight: 12 }}
                    disabled={buttonLoading}
                    onClick={onCancel}
                  >
                    {t(`${baseString}.createLoad.buttons.cancel`)}
                  </Button>
                  <Button
                    type="primary"
                    loading={buttonLoading}
                    onClick={handleSubmit}
                  >
                    {t(`${baseString}.createLoad.buttons.saveChanges`)}
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
        </Card>
      </Col>
    </Row>
  );
}

export default withTranslation()(TruckLoadEdition);
