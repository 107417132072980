import { Table, TableProps } from 'antd';
import { SizeType } from 'antd/es/config-provider/SizeContext';
import { ColumnGroupType, ColumnsType } from 'antd/lib/table';
import update from 'immutability-helper';
import { DefaultRecordType } from 'rc-table/lib/interface';
import React, { useCallback, useRef } from 'react';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import './DraggingTable.scss';

interface DraggableBodyRowProps
  extends React.HTMLAttributes<HTMLTableRowElement> {
  index: number;
  moveRow: (dragIndex: number, hoverIndex: number) => void;
}

const type = 'DraggableBodyRow';

const DraggableBodyRow = ({
  index,
  moveRow,
  className,
  style,
  ...restProps
}: DraggableBodyRowProps) => {
  const ref = useRef<HTMLTableRowElement>(null);
  const [{ isOver, dropClassName }, drop] = useDrop({
    accept: type,
    collect: monitor => {
      const { index: dragIndex } = monitor.getItem() || {};
      if (dragIndex === index) {
        return {};
      }
      return {
        isOver: monitor.isOver(),
        dropClassName:
          dragIndex < index ? ' drop-over-downward' : ' drop-over-upward',
      };
    },
    drop: (item: { index: number }) => {
      moveRow(item.index, index);
    },
  });
  const [, drag] = useDrag({
    type,
    item: { index },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  });
  drop(drag(ref));

  return (
    <tr
      ref={ref}
      className={`${className}${isOver ? dropClassName : ''}`}
      style={{ cursor: 'move', ...style }}
      {...restProps}
    />
  );
};

const DraggingTable = ({
  data,
  setData,
  columns,
  size,
  tableProps,
  onRowHover,
}: {
  data: DefaultRecordType[];
  setData: (values: DefaultRecordType[]) => void;
  columns: ColumnGroupType<DefaultRecordType> | ColumnsType<DefaultRecordType>;
  size: SizeType;
  tableProps?: TableProps<DefaultRecordType>;
  onRowHover?: (record: DefaultRecordType, index?: number) => void;
}): JSX.Element => {
  const components = {
    body: {
      row: DraggableBodyRow,
    },
  };

  const moveRow = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      const dragRow = data[dragIndex];
      setData(
        update(data, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragRow],
          ],
        })
      );
    },
    [data]
  );

  return (
    <DndProvider backend={HTML5Backend}>
      <Table
        key="id"
        columns={columns as ColumnsType<DefaultRecordType>}
        dataSource={data}
        components={components}
        pagination={false}
        onRow={(record, index) => {
          const attr = {
            index,
            moveRow,
            onMouseLeave: () => (onRowHover ? onRowHover(record, -1) : null),
            onMouseEnter: () => (onRowHover ? onRowHover(record, index) : null),
          };
          return attr;
        }}
        scroll={{ x: true }}
        size={size}
        {...tableProps}
      />
    </DndProvider>
  );
};
export default DraggingTable;
