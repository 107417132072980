import {
  Card,
  Col,
  Layout,
  message,
  Modal,
  PageHeader,
  Row,
  Steps,
} from 'antd';
import { ReactElement, useContext, useEffect, useState } from 'react';
import { TFunction, withTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import UserContext from '../../../contexts/user';
import { useRequest } from '../../../hooks/useRequest';
import BreadCrumb from '../../../structures/controllers/Breadcrumb';
import TruckLoadController from '../../../structures/controllers/TruckLoad';
import {
  IStepOneData,
  IStepTwoData,
} from '../../../structures/interfaces/TruckLoad';
import StepOne from './createLoadSteps/stepOne';
import StepThree from './createLoadSteps/stepThree';
import StepTwo from './createLoadSteps/stepTwo';

const { Step } = Steps;

function CreateLoad({ t }: { t: TFunction }): ReactElement<unknown> {
  const history = useHistory();
  const [currentStep, setCurrentStep] = useState(0);
  const [stepOneData, setStepOneData] = useState<IStepOneData>();
  const [stepTwoData, setStepTwoData] = useState<IStepTwoData>();
  const [createdTruckLoadId, setCreatedTruckLoadId] = useState('');

  const [isCustomOrdering, setIsCustomOrdering] = useState(false);

  const { userData, customerData } = useContext(UserContext);

  const [cancelPreSchedule] = useRequest(TruckLoadController.cancelPreSchedule);
  const [getPreScheduleByCreator] = useRequest(
    TruckLoadController.getPreScheduleByCreator
  );

  const baseString = 'pages.truckLoads.createLoad';

  const colors = [
    '#C97A6D',
    '#7AC97D',
    '#7A6DC9',
    '#C9BC7A',
    '#C97AA6',
    '#A17AC9',
    '#7AC9A1',
    '#7A9BC9',
    '#C96D7A',
    '#7AC9BD',
    '#C97A68',
    '#7A91B3',
    '#B38A7A',
    '#7AB3B0',
  ];
  const timeoutModal = () => {
    Modal.warning({
      title: t('pages.truckLoads.createLoad.timeout'),
      content: '',
      closable: false,
      centered: true,
      onOk: () => {
        history.push('/cargas');
      },
    });
  };

  const handlePreScheduleCancel = (id: string, isTimeout = false) => {
    cancelPreSchedule({ id: id })
      .then(() => {
        message.warning({
          content: t(`${baseString}.messages.preScheduleCancel`),
        });
        setCreatedTruckLoadId('-1');
      })
      .catch(err => {
        message.config({ top: 64 });
        message.error({ content: t(err.message), duration: 5 });
      });

    if (isTimeout) timeoutModal();
  };

  useEffect(() => {
    const preScheduleAuthor = userData ? userData.id : customerData?.id;
    if (preScheduleAuthor) {
      getPreScheduleByCreator({ creatorId: preScheduleAuthor }).then(values => {
        values.forEach(preSchedule => {
          handlePreScheduleCancel(preSchedule.id);
        });
      });
    }
  }, []);

  useEffect(() => {
    if (createdTruckLoadId && createdTruckLoadId !== '-1') {
      message.config({ top: 64 });
      message.success({
        content: t(`${baseString}.messages.formedLoad`),
        duration: 5,
      });
      history.push('/cargas');
    }

    return () => {
      if (stepOneData && !createdTruckLoadId) {
        const pathname = window.location.pathname.split('/');
        const lastIndex = pathname.length - 1;
        if (pathname[lastIndex] !== 'formar-carga') {
          handlePreScheduleCancel(stepOneData.cancel.id);
        }
      }
    };
  }, [stepOneData, createdTruckLoadId]);

  const next = () => {
    window.scrollTo(0, 0);
    setCurrentStep(currentStep + 1);
  };

  const previous = () => {
    setCurrentStep(currentStep - 1);
  };

  const steps = [
    {
      title: t('pages.truckLoads.createLoad.steps.one'),
      content: <StepOne nextStep={next} setStepOneData={setStepOneData} />,
    },
    {
      title: t('pages.truckLoads.createLoad.steps.two'),
      content: stepOneData && (
        <StepTwo
          stepOneData={stepOneData}
          stepTwoData={stepTwoData}
          setStepTwoData={setStepTwoData}
          cancelPreSchedule={handlePreScheduleCancel}
          nextStep={next}
          colors={colors}
          isCustomOrdering={isCustomOrdering}
          setIsCustomOrdering={setIsCustomOrdering}
        />
      ),
    },
    {
      title: t('pages.truckLoads.createLoad.steps.three'),
      content: stepOneData && stepTwoData && (
        <StepThree
          previous={previous}
          stepOneData={stepOneData}
          stepTwoData={stepTwoData}
          setStepTwoData={setStepTwoData}
          setCreatedTruckLoadId={setCreatedTruckLoadId}
          cancelPreSchedule={handlePreScheduleCancel}
          isCustomOrdering={isCustomOrdering}
        />
      ),
    },
  ];

  return (
    <Layout style={{ minHeight: '100vh', paddingBottom: 84 }}>
      <PageHeader
        className="site-page-header"
        title={t('pages.truckLoads.createLoad.title')}
        subTitle={t('pages.truckLoads.createLoad.subtitle')}
        ghost={false}
        breadcrumb={<BreadCrumb />}
      />
      <Row gutter={[24, 24]} style={{ padding: '24px 12px', margin: 0 }}>
        <Col span={24}>
          <Card size="small">
            <Steps
              current={currentStep}
              style={{ maxWidth: 800, margin: '12px auto' }}
            >
              {steps.map(step => (
                <Step key={step.title} title={step.title} />
              ))}
            </Steps>
          </Card>
        </Col>
        {steps[currentStep].content}
      </Row>
    </Layout>
  );
}

export default withTranslation()(CreateLoad);
