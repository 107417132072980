import { Layout } from 'antd';

const { Footer } = Layout;

export default function TruckLoadFooter({
  children,
}: {
  children: JSX.Element[];
}): JSX.Element {
  return (
    <Footer
      style={{
        position: 'fixed',
        left: 0,
        padding: '0 24px',
        bottom: 0,
        zIndex: 2,
        width: '100%',
        backgroundColor: '#FFF',
        textAlign: 'center',
        boxShadow:
          '0 -6px 16px -8px rgba(0,0,0,.08),0 -9px 28px 0 rgba(0,0,0,.05),0 -12px 48px 16px rgba(0,0,0,.03)',
      }}
    >
      <div
        style={{
          textAlign: 'right',
          display: 'flex',
          justifyContent: 'end',
        }}
      >
        {children}
      </div>
    </Footer>
  );
}
