import { EditTwoTone, LoadingOutlined } from '@ant-design/icons';
import {
  Checkbox,
  DatePicker,
  DatePickerProps,
  Descriptions,
  Spin,
  Tag,
} from 'antd';
import moment from 'moment';
import { TFunction, useTranslation, withTranslation } from 'react-i18next';
import Formatter from '../../../classes/Formatter';
import { IViewTruckLoadDescription } from '../../../structures/interfaces/TruckLoad';
import UserContext from '../../../contexts/user';
import { useContext } from 'react';

const { Item } = Descriptions;

function TruckLoadDescription({
  t,
  items,
  isEdition,
  availableDates,
  pickupDateUpdating,
  onDateChange,
  isUpdateAvailable,
  alocatedItems,
}: {
  t: TFunction;
  alocatedItems?: number;
  items: IViewTruckLoadDescription;
  isEdition?: boolean;
  availableDates?: moment.Moment[];
  pickupDateUpdating?: boolean;
  onDateChange?: (date: moment.Moment) => void;
  isUpdateAvailable?: boolean;
}) {
  const baseString = 'pages.truckLoads';
  const { userData, customerData } = useContext(UserContext);

  const language = useTranslation().i18n.language;

  const {
    name = '---',
    vehicleModel = '---',
    vehicleBagsCapacity = 0,
    vehicleWeightCapacity = 0,
    selectedDate = null,
    unity = null,
    availableUnits = 0,
    pickupLocation = '---',
    seedType = '---',
    status = '---',
    observation = '---',
  } = items;

  const renderStatus = () => {
    const allStatus = {
      WAITING_DRIVER: 'orange',
      WAITING_RELEASE: 'purple',
      WAITING_COMERCIAL: 'purple',
      WAITING_ACTIONS: `${userData ? 'orange' : 'purple'}`,
      LOADED: 'green',
      LOAD_RELEASED: 'blue',
      AUTHORIZED_LOAD: 'blue',
      EXPIRED: 'default',
      LOADING: 'magenta',
    };

    const showWaitingComercial = customerData && status === 'WAITING_ACTIONS';

    const color = allStatus[status as keyof typeof allStatus] || 'red';

    return (
      <Tag color={color}>
        {t(
          `${baseString}.viewLoad.status.${
            showWaitingComercial ? 'WAITING_RELEASE' : status
          }`
        )}
      </Tag>
    );
  };

  const getCapacityLabel = () => {
    if (unity === 'BAG') {
      return `${vehicleBagsCapacity} bags` || '---';
    }
    return `${(vehicleWeightCapacity / 1000).toFixed(2)} toneladas`;
  };

  const customFormat: DatePickerProps['format'] = value =>
    `${Formatter.formatDateWithMonthWrittenOut(value, language)}`;

  return (
    <Descriptions column={{ xs: 1, sm: 1, md: 2, xl: 3, xxl: 4 }}>
      <Item label={t(`${baseString}.descriptions.name`)}>{name || '---'}</Item>
      <Item label={t(`${baseString}.descriptions.date`)}>
        {isUpdateAvailable && onDateChange && availableDates?.length ? (
          <DatePicker
            bordered={false}
            size="small"
            format={customFormat}
            defaultValue={moment(selectedDate)}
            allowClear={false}
            showToday={false}
            disabledDate={date =>
              !availableDates.find(availableDate =>
                availableDate.isSame(date, 'D')
              )
            }
            placement="bottomRight"
            onChange={date => date && onDateChange(date)}
            suffixIcon={
              pickupDateUpdating ? (
                <Spin
                  indicator={
                    <LoadingOutlined style={{ marginLeft: 24 }} spin />
                  }
                />
              ) : (
                <EditTwoTone
                  style={{
                    marginLeft: 24,
                    cursor: 'pointer',
                    pointerEvents: 'all',
                  }}
                />
              )
            }
          />
        ) : (
          <>
            {selectedDate
              ? Formatter.formatDateWithMonthWrittenOut(selectedDate, language)
              : '---'}
          </>
        )}
      </Item>
      <Item label={t(`${baseString}.descriptions.vehicle`)}>
        {vehicleModel || '---'}
      </Item>
      {isEdition && (
        <Item label={t(`${baseString}.descriptions.status`)}>
          {status ? renderStatus() : '---'}
        </Item>
      )}
      <Item label={t(`${baseString}.descriptions.maxCapacity`)}>
        {getCapacityLabel()}
      </Item>
      {alocatedItems && (
        <Item label={'Quantidade alocada'}>{alocatedItems}</Item>
      )}
      {!isEdition && (
        <Item label={t(`${baseString}.descriptions.availability`)}>
          {availableUnits || 0}
        </Item>
      )}
      <Item label={t(`${baseString}.descriptions.location`)}>
        {pickupLocation || '---'}
      </Item>
      <Item label={t(`${baseString}.descriptions.seedType`)}>
        {seedType || '---'}
      </Item>
      {isEdition && (
        <Item label={t(`${baseString}.descriptions.observation`)}>
          {observation || '---'}
        </Item>
      )}
    </Descriptions>
  );
}

export default withTranslation()(TruckLoadDescription);
