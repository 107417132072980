import { Button, Modal } from 'antd';
import { t } from 'i18next';
import { useContext } from 'react';
import { withTranslation } from 'react-i18next';
import UserContext from '../../../contexts/user';

interface IContractItemsModal {
  open: boolean;
  onCancel: (() => void) | undefined;
  onOk: (() => void) | undefined;
}

const NonExistingContractItems = ({
  open,
  onCancel,
  onOk,
}: IContractItemsModal) => {
  const { customerData } = useContext(UserContext);

  const baseString = 'pages.truckLoads.createLoad.stepTwo.contractItemsModal';

  const customModal = [
    <Button danger onClick={onCancel}>
      {t('pages.settings.deleteBranchCompany.cancelButton')}
    </Button>,
    <Button type="primary" onClick={onOk}>
      {t('pages.truckLoads.createLoad.viewLoad.backToListing')}
    </Button>,
  ];

  return (
    <Modal
      width={'50%'}
      open={open}
      closable
      footer={customModal}
      title={'Atenção!'}
    >
      <p>
        {customerData
          ? t(`${baseString}.nonExistContractItemsCustomer`)
          : t(`${baseString}.nonExistContractItems`)}
      </p>
      <br />
      <p>{t('pages.truckLoads.createLoad.stepTwo.contractItemsModal.back')}</p>
    </Modal>
  );
};

export default withTranslation()(NonExistingContractItems);
