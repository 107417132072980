import { Button, Col, Descriptions, message, Row } from 'antd';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { TFunction, withTranslation } from 'react-i18next';
import Formatter from '../../../classes/Formatter';
import UserContext from '../../../contexts/user';
import { useRequest } from '../../../hooks/useRequest';
import UserController from '../../../structures/controllers/User';
import { ITruckLoadAuthorization } from '../../../structures/interfaces/TruckLoad';
import { IGetUserResponse } from '../../../structures/interfaces/User';
import { IErrorMessage } from '../../../utils/interfaces';

const { Item } = Descriptions;

function AuthorizationCard({
  t,
  authorizationData,
  loadAuthorized = false,
  handleAuthorization,
  handleAuthorizeLater,
  isLoading = false,
}: {
  t: TFunction;
  authorizationData?: ITruckLoadAuthorization;
  loadAuthorized?: boolean;
  handleAuthorizeLater?: () => void;
  handleAuthorization: () => void;
  isLoading?: boolean;
}) {
  const [fetchUser] = useRequest(UserController.getUser);

  const { userData } = useContext(UserContext);

  const [user, setUser] = useState<IGetUserResponse>();

  const baseString = 'pages.truckLoads';

  const renderDescription = (
    name = '---',
    cpf = '---',
    phone = '---',
    email = '---',
    date = '---'
  ) => (
    <>
      <Item
        label={t(
          `${baseString}.authorization.labels.${
            loadAuthorized ? 'authorizedName' : 'unauthorizedName'
          }`
        )}
      >
        {name || '---'}
      </Item>
      <Item label={t(`${baseString}.authorization.labels.document`)}>
        {Formatter.formatCPF(cpf || '---')}
      </Item>
      <Item label={t(`${baseString}.authorization.labels.phoneNumber`)}>
        {Formatter.formatPhoneWithoutDDI(phone || '---')}
      </Item>
      <Item label={t(`${baseString}.authorization.labels.email`)}>
        {email || '---'}
      </Item>
      <Item label={t(`${baseString}.authorization.labels.date`)}>
        {Formatter.formatDate(date)}
      </Item>
    </>
  );

  const showError = (error: IErrorMessage) => {
    message.error({
      content: t(error.message),
      style: { marginTop: '4rem' },
    });
  };

  useEffect(() => {
    if (userData) {
      fetchUser(userData.id)
        .then(setUser)
        .catch(err => showError(err));
    }
  }, []);

  return (
    <>
      <Descriptions
        column={{
          xxl: handleAuthorizeLater ? 2 : 5,
          xl: handleAuthorizeLater ? 2 : 4,
          lg: handleAuthorizeLater ? 2 : 3,
          md: 2,
          sm: 2,
          xs: 1,
        }}
      >
        {renderDescription(
          authorizationData?.name || user?.name,
          authorizationData?.cpf || user?.documentNumberCpf,
          authorizationData?.phone || user?.phoneNumber,
          authorizationData?.email || user?.email,
          authorizationData?.date || moment().toISOString()
        )}
      </Descriptions>

      <Row>
        <Col style={{ marginLeft: 'auto' }}>
          {!loadAuthorized && (
            <Button
              type="primary"
              onClick={handleAuthorization}
              style={{ float: 'right', marginLeft: 12 }}
              loading={isLoading}
            >
              {t(`${baseString}.authorization.button.authorize`)}
            </Button>
          )}
          {handleAuthorizeLater && (
            <Button
              onClick={() => handleAuthorizeLater()}
              style={{ float: 'right' }}
            >
              {t(`${baseString}.authorization.button.authorizeLater`)}
            </Button>
          )}
        </Col>
      </Row>
    </>
  );
}
export default withTranslation()(AuthorizationCard);
