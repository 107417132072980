import { gql, TypedDocumentNode } from 'urql';
import { IGetOrganizationPendencies } from '../interfaces/Home';

const homeQueries = {
  countTotalTruckLoads: (): TypedDocumentNode<
    {
      totalLoads: number;
    },
    { where: { organization_id: string } }
  > => gql`
    query countTotalTruckLoads($where: TilesCountInput!) {
      totalLoads: CountTotalTruckLoads(where: $where)
    }
  `,

  countAuthorizedTruckLoads: (): TypedDocumentNode<
    {
      authorizedLoads: number;
    },
    { where: { organization_id: string } }
  > => gql`
    query countAuthorizedTruckLoads($where: TilesCountInput!) {
      authorizedLoads: CountAuthorizedTruckLoads(where: $where)
    }
  `,

  countTruckLoadsWaitingForDriver: (): TypedDocumentNode<
    {
      waitingDriverLoads: number;
    },
    { where: { organization_id: string } }
  > => gql`
    query countTruckLoadsWaitingForDriver($where: TilesCountInput!) {
      waitingDriverLoads: CountTruckLoadsWaitingForDriver(where: $where)
    }
  `,

  countLoadedTruckLoads: (): TypedDocumentNode<
    {
      loadedLoads: number;
    },
    { where: { organization_id: string } }
  > => gql`
    query countLoadedTruckLoads($where: TilesCountInput!) {
      loadedLoads: CountLoadedTruckLoads(where: $where)
    }
  `,

  countCanceledTruckLoads: (): TypedDocumentNode<
    {
      canceledLoads: number;
    },
    { where: { organization_id: string } }
  > => gql`
    query countCanceledTruckLoads($where: TilesCountInput!) {
      canceledLoads: CountCanceledTruckLoads(where: $where)
    }
  `,

  countFinalizedContracts: (): TypedDocumentNode<
    {
      finalizedContracts: number;
    },
    { where: { organization_id: string } }
  > => gql`
    query countFinalizedContracts($where: TilesCountInput!) {
      finalizedContracts: CountFinalizedContracts(where: $where)
    }
  `,

  countPendingContracts: (): TypedDocumentNode<
    {
      pendingContracts: number;
    },
    { where: { organization_id: string } }
  > => gql`
    query countPendingContracts($where: TilesCountInput!) {
      pendingContracts: CountPendingContracts(where: $where)
    }
  `,

  getOrganizationPendencies: (): TypedDocumentNode<
    {
      pendencies: IGetOrganizationPendencies;
    },
    {
      where: { id: string };
    }
  > => gql`
    query getOrganizationPendencies($where: OrganizationWhereUniqueInput!) {
      pendencies: GetOrganizationPendencies(where: $where) {
        id
        organizationId: organization_id
        myCompany: my_company
        rules
        organization {
          tradeName: trade_name
        }
      }
    }
  `,

  updateOrganizationPendencies: (): TypedDocumentNode<
    {
      updatePendencies: IGetOrganizationPendencies;
    },
    {
      where: { id: string };
    }
  > => gql`
    mutation updateOrganizationPendencies(
      $where: OrganizationWhereUniqueInput!
      $input: PendenciesUncheckedUpdateInput!
    ) {
      updatePendencies: UpdateOrganizationPendencies(
        where: $where
        input: $input
      ) {
        id
        organizationId: organization_id
        myCompany: my_company
        rules
        organization {
          tradeName: trade_name
        }
      }
    }
  `,
};

export default homeQueries;
