import {
  Col,
  Form,
  Input,
  message,
  Modal,
  ModalProps,
  Row,
  Select,
} from 'antd';
import { Rule } from 'antd/lib/form';
import { DefaultOptionType } from 'antd/lib/select';
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';
import Formatter from '../../classes/Formatter';
import Normalizer from '../../classes/Normalizer';
import Validator from '../../classes/Validator';
import UserContext from '../../contexts/user';
import { useRequest } from '../../hooks/useRequest';
import ClientsController from '../../structures/controllers/Clients';

export interface IFarmResponse {
  id: string;
  name: string;
  stateRegistration: string;
  cnpj: string;
  address: string;
  slug?: string;
  customer: {
    id: string;
    cnpj: string;
    name: string;
    email: string;
  };
}

export interface IOptionType extends DefaultOptionType {
  filter: string;
}
interface INewCustomerProps extends ModalProps {
  setOpen: (v: boolean) => void;
  setFetchCust: Dispatch<SetStateAction<boolean>>;
}
interface IFormValues {
  name: string;
  email: string;
  phone: string;
  cpf: string;
  farm: string;
}

const validPhoneNumber = (rule: Rule, value: string) => {
  if (!value) return Promise.resolve();
  if (Validator.validatePhoneNumber(value)) return Promise.resolve();
  return Promise.reject(new Error('Insira um telefone válido!'));
};

const NewCustomer = ({ open, setOpen, setFetchCust }: INewCustomerProps) => {
  const { userData } = useContext(UserContext);
  const [form] = Form.useForm();
  const [farms, setFarms] = useState<IOptionType[]>([]);

  const [fetchFarms, isLoading] = useRequest(ClientsController.fetchOrgFarms);
  const [createCustomer, isLoadingCust] = useRequest(
    ClientsController.createCustomer
  );

  useEffect(() => {
    if (userData && userData.organization)
      fetchFarms({ orgId: userData.organization.id })
        .then(res => {
          const farmOptions = res.map(farm => ({
            label: (
              <Row gutter={24}>
                <Col span={8}>
                  <p>{farm.name}</p>
                </Col>

                <Col style={{ textAlign: 'center' }} span={8}>
                  <p>
                    Inscrição estadual: <b>{farm.stateRegistration}</b>
                  </p>
                </Col>

                <Col style={{ textAlign: 'center' }} span={8}>
                  <p>
                    CNPJ:{' '}
                    <b>{Formatter.formatCNPJ(farm.customer.cnpj) || '---'}</b>{' '}
                  </p>
                </Col>
              </Row>
            ),
            filter: `${farm.name} ${farm.stateRegistration} ${farm.customer.cnpj} `,
            value: farm.id,
          }));
          setFarms(farmOptions);
        })
        .catch(() => null);
  }, [open]);

  const onFinish = (values: IFormValues) => {
    if (!userData?.organization.id) return null;

    const reqValues = {
      ...values,
      phone: Normalizer.onlyNumbers(values.phone),
      cpf: Normalizer.onlyNumbers(values.cpf),
    } as IFormValues;

    createCustomer({
      ...reqValues,
      farmId: reqValues.farm,
      orgId: userData?.organization.id,
    })
      .then(() => message.success('Cliente cadastrado com sucesso!'))
      .catch(() => message.error('Erro ao cadastrar cliente!'));

    return;
  };

  return (
    <Modal
      width={1400}
      open={open}
      title="Cadastrar novo cliente"
      okText="Cadastrar"
      okButtonProps={{ loading: isLoadingCust }}
      onCancel={() => setOpen(false)}
      onOk={() => {
        form.submit();
        setOpen(false);
        setFetchCust(v => !v);
      }}
    >
      <Form layout="vertical" onFinish={onFinish} form={form}>
        <Row gutter={[24, 24]} align={'middle'}>
          <Col span={12}>
            <Form.Item label="Nome" name="name" rules={[{ required: true }]}>
              <Input placeholder="Insira o nome do cliente" />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              rules={[{ type: 'email' }, { required: true }]}
              label="E-mail"
              name="email"
            >
              <Input placeholder="Insira o e-mail do cliente" />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item normalize={Formatter.formatCPF} label="CPF" name="cpf">
              <Input placeholder="Insira o CPF do cliente" />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              normalize={Formatter.formatPhoneWithoutDDI}
              label="Telefone"
              name="phone"
              rules={[{ validator: validPhoneNumber }]}
            >
              <Input placeholder="Insira o telefone do cliente" />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item label="Fazenda" name="farm">
              <Select
                showSearch
                allowClear
                optionFilterProp={'filter'}
                placeholder="Selecione uma fazenda para seu cliente!"
                options={farms}
                loading={isLoading}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default NewCustomer;
