import { EyeOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Button, Tag, Tooltip } from 'antd';
import Table, { ColumnsType } from 'antd/lib/table';
import moment from 'moment';
import { ReactElement } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import {
  IDashboardContractTable,
  IGetContract,
} from '../../structures/interfaces/Contract';

interface IContractsTableProps {
  setOpen: (open: boolean) => void;
  setContract: (data?: IGetContract) => void;
  contracts?: Array<IDashboardContractTable & { contract_item_id: string }>;
  isLoading?: boolean;
}

const renderStatus = (
  status: string,
  t: TFunction<'translation', undefined>
) => {
  switch (status) {
    case 'WAITING_SCHEDULE':
      return <Tag color="orange">{t('pages.home.waitingSchedule')}</Tag>;
    case 'SCHEDULE_FINISHED':
      return <Tag color="green">{t('pages.home.scheduleFinished')}</Tag>;
    case 'WAITING_RELEASE':
      return <Tag color="blue">{t('pages.home.waitingRelease')}</Tag>;
  }
};
const statusMessage = (t: TFunction<'translation', undefined>) => (
  <div>
    {t('pages.home.promptText')}
    <p style={{ paddingTop: 24 }}>{t('pages.home.waitingSchedule')}</p>
    <p>{t('pages.home.scheduleFinished')}</p>
    <p>{t('pages.home.waitingRelease')}</p>
  </div>
);

const ContractItemsTable = ({
  setOpen,
  setContract,
  contracts,
  isLoading,
}: IContractsTableProps): ReactElement => {
  const { t } = useTranslation();

  const statusColumnTitle = (
    <>
      {t('pages.home.status')}{' '}
      <Tooltip title={() => statusMessage(t)}>
        <QuestionCircleOutlined />
      </Tooltip>
    </>
  );
  const columnsContract: ColumnsType<
    IDashboardContractTable & { contract_item_id: string }
  > = [
    {
      title: t('pages.home.stateRegistration'),
      dataIndex: ['farm', 'stateRegistration'],
      key: 'stateRegistration',
    },
    {
      title: t('pages.home.contract'),
      dataIndex: ['referenceNumber'],
      key: 'referenceNumber',
    },
    {
      title: statusColumnTitle,
      dataIndex: ['status'],
      key: 'status',
      width: 'auto',
      render: status => renderStatus(status, t),
    },
    {
      title: t('pages.home.contractDate'),
      dataIndex: ['contractDateString'],
      sorter: {
        compare: (a, b) => {
          const itemA = moment(a.contractDate);
          const itemB = moment(b.contractDate);
          return itemA.unix() - itemB.unix();
        },
        multiple: 1,
      },
      sortDirections: ['descend'],
      defaultSortOrder: 'descend',
      key: 'date',
    },
    {
      title: t('pages.contracts.itemID'),
      dataIndex: ['contract_item_reference_number'],
      key: 'itens',
      render: itemId => <Tag>{itemId}</Tag>,
    },
    {
      title: t('pages.contracts.estimatedWeight'),
      dataIndex: ['contract_item_estimated_weight'],
      key: 'estimatedWeight',
      render: weight => {
        if (weight > 1000) {
          return `${weight / 1000} ton`;
        } else {
          return `${weight} kg`;
        }
      },
    },
    {
      title: t('pages.contracts.tsi'),
      dataIndex: ['contract_item_tsi'],
      key: 'tsi',
      render: tsi => {
        return tsi ? (
          <Tag color="blue">{t('pages.contracts.yesTsi')}</Tag>
        ) : (
          <Tag color="volcano">{t('pages.contracts.noTsi')}</Tag>
        );
      },
    },
    {
      title: t('pages.contracts.actions'),
      key: 'action',
      render: index => (
        <Button
          icon={<EyeOutlined />}
          type="link"
          onClick={() => {
            if (index) {
              setOpen(true);
              setContract(index);
            }
          }}
        >
          {t('pages.contracts.view')}
        </Button>
      ),
    },
  ];

  return (
    <Table
      scroll={{ x: 1300 }}
      rowKey={contract => contract.contract_item_id}
      loading={isLoading}
      columns={columnsContract}
      dataSource={contracts}
      size="small"
      pagination={{
        hideOnSinglePage: true,
        defaultPageSize: 10,
      }}
    />
  );
};

export default ContractItemsTable;
