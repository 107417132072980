import {
  Alert,
  Button,
  Card,
  Checkbox,
  Col,
  Form,
  Input,
  Layout,
  message,
  PageHeader,
  Row,
  Select,
  Table,
  Tooltip,
} from 'antd';
import { Footer } from 'antd/lib/layout/layout';
import { ColumnsType } from 'antd/lib/table';
import { ReactElement, useContext, useEffect, useState } from 'react';

import { TFunction, withTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Formatter from '../../classes/Formatter';
import UserContext from '../../contexts/user';
import { useRequest } from '../../hooks/useRequest';
import BreadCrumb from '../../structures/controllers/Breadcrumb';
import CompanyController from '../../structures/controllers/Company';
import UserController from '../../structures/controllers/User';
import {
  IRegisterUserInput,
  IUserPermission,
  IUserPermissions,
  IVisibleOrganizations,
} from '../../structures/interfaces/User';
import { validateCpf } from '../../utils/inputRules';
import { IErrorMessage } from '../../utils/interfaces';
import { scrollOptions } from '../../utils/formOptions';

const RegisterNewUser = ({ t }: { t: TFunction }): ReactElement<unknown> => {
  const [userPermissionRequest, isUserPermissionRequesting] = useRequest(
    UserController.getUserPermissionOptions
  );
  const [userRegisterRequest, isUserRegisterRequesting] = useRequest(
    UserController.registerUser
  );
  const [findBranchesRequest] = useRequest(
    CompanyController.getBranchCompanies
  );
  const [pageSize, setPageSize] = useState(10);
  const [organizations, setOrganizations] = useState<IVisibleOrganizations[]>();
  const [userPermissions, setUserPermissions] = useState<IUserPermissions[]>();
  const [selectedUserPermissions, setSelectedUserPermissions] =
    useState<IUserPermissions[]>();
  const { userData, company } = useContext(UserContext);
  const [form] = Form.useForm();
  const [formCheckbox] = Form.useForm();
  const [disabledContractCheckbox, setDisabledContractCheckbox] =
    useState<boolean>();
  const [disabledTruckLoadCheckbox, setDisabledTruckLoadCheckbox] =
    useState<boolean>();
  const [disabledHomeCheckbox, setDisabledHomeCheckbox] = useState<boolean>();
  const [disabledCustomerCheckbox, setDisabledCustomerCheckbox] =
    useState<boolean>();
  const [disabledUserCheckbox, setDisabledUserCheckbox] = useState<boolean>();
  const [disabledSettingsCheckbox, setDisabledSettingsCheckbox] =
    useState<boolean>();
  const [receiveEmails, setReceiveEmails] = useState(false);

  const history = useHistory();
  const { Option } = Select;

  const info = () => {
    message.success(t('pages.users.newUser.success.message'));
  };

  const showError = (error: IErrorMessage) => {
    message.error({
      content: t(error.message),
      duration: 7,
      style: { marginTop: '4rem' },
    });
  };

  useEffect(() => {
    if (userData && company) {
      const tmpOrganizations: IVisibleOrganizations[] = [];
      tmpOrganizations.push({
        name: company.tradeName,
        id: userData.organization.id,
      });

      findBranchesRequest({ id: userData.organization.id }).then(branches => {
        branches.map(branch => {
          tmpOrganizations.push({
            name: branch.tradeName,
            id: branch.id,
          });
        });
      });
      setOrganizations(tmpOrganizations);
    }
  }, [userData, company]);

  useEffect(() => {
    if (userData) {
      userPermissionRequest({})
        .then(permissions => {
          setUserPermissions(permissions);
        })
        .catch(err => showError(err));
    }
  }, [userData]);

  const isReadChecked = (module: string) => {
    switch (module) {
      case 'HOME':
        return disabledHomeCheckbox;

      case 'CONTRATO':
        return disabledContractCheckbox;

      case 'REGRAS':
        return disabledSettingsCheckbox;

      case 'CARGAS':
        return disabledTruckLoadCheckbox;

      case 'USUARIOS':
        return disabledUserCheckbox;

      case 'CLIENTE':
        return disabledCustomerCheckbox;
    }
  };

  const columnsUsers: ColumnsType<IUserPermissions> = [
    {
      title: t('pages.users.newUser.userPermissions.pages'),
      render: value => {
        switch (value) {
          case 'HOME':
            return t('sidebar.options.home');
          case 'USUARIOS':
            return t('sidebar.options.users');
          case 'MINHA_EMPRESA':
            return t('sidebar.options.myCompany');
          case 'REGRAS':
            return t('sidebar.options.settings');
          case 'CONTRATO':
            return t('sidebar.options.contracts');
          case 'CLIENTE':
            return t('sidebar.options.clients');
          case 'CARGAS':
            return t('sidebar.options.truckLoads');
        }
      },
      dataIndex: ['module'],
      key: 'page',
    },
    {
      title: t('pages.users.newUser.userPermissions.reading'),
      dataIndex: ['READ'],
      key: 'read',
      render: (value, record) => {
        const rowData = record as IUserPermission;
        return value ? (
          <Form.Item
            name={value}
            initialValue={value}
            noStyle
            valuePropName="checked"
            validateFirst
            rules={[
              {
                validator: () => {
                  const userPermissions =
                    selectedUserPermissions as IUserPermission[];

                  const permission = userPermissions?.find(
                    userPermission => userPermission.module === rowData.module
                  );

                  const formValues = formCheckbox.getFieldsValue();

                  if (permission) {
                    if (!formValues[permission.READ]) {
                      switch (permission.module) {
                        case 'HOME':
                          setDisabledHomeCheckbox(true);
                          break;
                        case 'CONTRATO':
                          setDisabledContractCheckbox(true);
                          break;
                        case 'REGRAS':
                          setDisabledSettingsCheckbox(true);
                          break;
                        case 'CARGAS':
                          setDisabledTruckLoadCheckbox(true);
                          break;
                        case 'USUARIOS':
                          setDisabledUserCheckbox(true);
                          break;
                        case 'CLIENTE':
                          setDisabledCustomerCheckbox(true);
                          break;
                      }
                    } else {
                      switch (permission.module) {
                        case 'HOME':
                          setDisabledHomeCheckbox(false);
                          break;
                        case 'CONTRATO':
                          setDisabledContractCheckbox(false);
                          break;
                        case 'REGRAS':
                          setDisabledSettingsCheckbox(false);
                          break;
                        case 'CARGAS':
                          setDisabledTruckLoadCheckbox(false);
                          break;
                        case 'USUARIOS':
                          setDisabledUserCheckbox(false);
                          break;
                        case 'CLIENTE':
                          setDisabledCustomerCheckbox(false);
                          break;
                      }
                    }
                  }
                },
              },
            ]}
          >
            {rowData.module === 'HOME' ? (
              <Tooltip
                title={t('pages.users.errors.mustHaveHomeReadPermission')}
              >
                <Checkbox disabled checked />
              </Tooltip>
            ) : (
              <Checkbox />
            )}
          </Form.Item>
        ) : (
          <Checkbox disabled />
        );
      },
    },
    {
      title: t('pages.users.newUser.userPermissions.edit'),
      dataIndex: ['UPDATE'],
      key: 'update',
      render: (value, record) => {
        const rowData = record as IUserPermission;
        const userPermissions = selectedUserPermissions as IUserPermission[];

        const permission = userPermissions?.find(
          userPermission => userPermission.module === rowData.module
        );

        const checkboxChecked = permission
          ? isReadChecked(permission.module)
          : false;

        return value ? (
          <Form.Item
            name={value}
            initialValue={value}
            noStyle
            valuePropName={checkboxChecked ? '' : 'checked'}
          >
            <Checkbox
              key={value}
              checked={!checkboxChecked}
              disabled={checkboxChecked}
            />
          </Form.Item>
        ) : (
          <Checkbox key={value} disabled />
        );
      },
    },
    {
      title: t('pages.users.newUser.userPermissions.create'),
      dataIndex: ['CREATE'],
      key: 'create',
      render: (value, record) => {
        const rowData = record as IUserPermission;
        const userPermissions = selectedUserPermissions as IUserPermission[];

        const permission = userPermissions?.find(
          userPermission => userPermission.module === rowData.module
        );

        const checkboxChecked = permission
          ? isReadChecked(permission.module)
          : false;

        return value ? (
          <Form.Item
            name={value}
            initialValue={value}
            noStyle
            valuePropName={checkboxChecked ? '' : 'checked'}
          >
            <Checkbox checked={!checkboxChecked} disabled={checkboxChecked} />
          </Form.Item>
        ) : (
          <Checkbox disabled />
        );
      },
    },
    {
      title: t('pages.users.newUser.userPermissions.delete'),
      dataIndex: ['DELETE'],
      key: 'delete',
      render: (value, record) => {
        const rowData = record as IUserPermission;
        const userPermissions = selectedUserPermissions as IUserPermission[];

        const permission = userPermissions?.find(
          userPermission => userPermission.module === rowData.module
        );

        const checkboxChecked = permission
          ? isReadChecked(permission.module)
          : false;

        return value ? (
          <Form.Item
            name={value}
            initialValue={value}
            noStyle
            valuePropName={checkboxChecked ? '' : 'checked'}
          >
            <Checkbox
              key={value}
              checked={!checkboxChecked}
              disabled={checkboxChecked}
            />
          </Form.Item>
        ) : (
          <Checkbox key={value} disabled />
        );
      },
    },
  ];
  const colSize = { xs: 24, sm: 24, md: 12, lg: 8, xl: 7, xxl: 3 };

  const onFinish = (values: IRegisterUserInput) => {
    if (values) {
      const checkboxValues = formCheckbox.getFieldsValue();
      let allPermissions: Array<Array<string | boolean>> =
        Object.entries(checkboxValues);
      allPermissions = allPermissions.filter(item => item[1] !== false);
      const finalPermissions = allPermissions.map(item => item[0]);

      values.phoneNumber = values.phoneNumber.replace(/[^0-9]/g, '');
      values.documentNumberCpf = values.documentNumberCpf.replace(
        /[^0-9]/g,
        ''
      );
      values.receive_notifications = receiveEmails;

      const initialPermissions = selectedUserPermissions as IUserPermission[];

      initialPermissions.map(permission => {
        const existsReadPermission = finalPermissions.find(finalPermission => {
          if (finalPermission === permission.READ) return finalPermission;
        });

        if (!existsReadPermission) {
          const existsCreatePermission = finalPermissions.find(
            finalPermission => {
              if (finalPermission === permission.CREATE) return finalPermission;
            }
          );
          if (existsCreatePermission) {
            const createPos = finalPermissions.indexOf(permission.CREATE);
            finalPermissions.splice(createPos, 1);
          }

          const existsUpdatePermission = finalPermissions.find(
            finalPermission => {
              if (finalPermission === permission.UPDATE) return finalPermission;
            }
          );
          if (existsUpdatePermission) {
            const updatePos = finalPermissions.indexOf(permission.UPDATE);
            finalPermissions.splice(updatePos, 1);
          }

          const existsDeletePermission = finalPermissions.find(
            finalPermission => {
              if (finalPermission === permission.DELETE) return finalPermission;
            }
          );
          if (existsDeletePermission) {
            const deletePos = finalPermissions.indexOf(permission.DELETE);
            finalPermissions.splice(deletePos, 1);
          }
        }
      });

      values.permissions = finalPermissions;

      userRegisterRequest({
        user: values,
      })
        .then(usr => {
          info();
          history.push('/usuarios', {
            user: usr,
          });
        })
        .catch(err => showError(err));
    }
  };
  return (
    <>
      <Layout>
        <PageHeader
          title={t('pages.users.newUser.title')}
          subTitle={t('pages.users.newUser.subtitle')}
          ghost={false}
          breadcrumb={<BreadCrumb />}
        />
        <Layout style={{ margin: 24 }}>
          <Card title={t('pages.users.newUser.labels.title')}>
            <Form
              scrollToFirstError={scrollOptions}
              name="basic"
              form={form}
              layout="vertical"
              onFinish={onFinish}
            >
              <Row gutter={24}>
                <Col {...colSize}>
                  <Form.Item
                    name="userType"
                    label={t('pages.users.newUser.labels.userType')}
                    rules={[
                      {
                        required: true,
                        message: t('pages.users.newUser.rmUserType'),
                      },
                    ]}
                  >
                    <Select
                      size="small"
                      placeholder={t('pages.users.newUser.phUserType')}
                      onChange={item => {
                        const tmpObjPermission: any = userPermissions?.find(
                          permissions => permissions.type === item
                        );
                        if (tmpObjPermission) {
                          let props =
                            Object.getOwnPropertyNames(tmpObjPermission);

                          props = props.filter(item => item !== 'type');

                          const permissions: IUserPermissions[] = [];
                          props.map(item => {
                            permissions.push(tmpObjPermission[item]);
                          });

                          setSelectedUserPermissions(permissions);
                        }
                      }}
                    >
                      {userPermissions?.map(item => {
                        return (
                          <>
                            {
                              <Option key={item.type} value={item.type}>
                                {item.type}
                              </Option>
                            }
                          </>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>

                <Col {...colSize}>
                  <Form.Item
                    label={t('pages.users.newUser.labels.userName')}
                    name="name"
                    rules={[
                      {
                        required: true,
                        message: t('pages.users.newUser.rmName'),
                      },
                    ]}
                  >
                    <Input
                      size="small"
                      placeholder={t('pages.users.newUser.phUserName')}
                    />
                  </Form.Item>
                </Col>

                <Col {...colSize}>
                  <Form.Item
                    label={t('pages.users.newUser.labels.documentNumberCpf')}
                    name="documentNumberCpf"
                    normalize={Formatter.formatCPF}
                    validateFirst
                    rules={[
                      {
                        required: true,
                        message: t('pages.users.newUser.rmDocumentNumberCpf'),
                      },
                      {
                        min: 11,
                        message: t(
                          'pages.users.newUser.rmMinSizeDocumentNumberCnpj'
                        ),
                      },
                      { ...validateCpf, message: t(validateCpf.message) },
                    ]}
                  >
                    <Input
                      size="small"
                      placeholder={t('pages.users.newUser.phDocumentNumberCpf')}
                    />
                  </Form.Item>
                </Col>

                <Col {...colSize}>
                  <Form.Item
                    label={t('pages.users.newUser.labels.organizationName')}
                    name="organizationId"
                    rules={[
                      {
                        required: true,
                        message: t('pages.users.newUser.rmOrganizationName'),
                      },
                    ]}
                  >
                    <Select
                      size="small"
                      placeholder={t('pages.users.newUser.phCompany')}
                    >
                      {organizations?.map(item => {
                        return (
                          <>
                            {item && (
                              <Option key={item.id} value={item.id}>
                                {item.name}
                              </Option>
                            )}
                          </>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>

                <Col {...colSize}>
                  <Form.Item
                    label={t('pages.users.newUser.labels.phoneNumber')}
                    name="phoneNumber"
                    normalize={Formatter.formatCellphone}
                    rules={[
                      {
                        required: true,
                        message: t('pages.users.newUser.rmPhoneNumber'),
                      },
                    ]}
                  >
                    <Input
                      size="small"
                      placeholder={t('pages.users.newUser.phPhoneNumber')}
                    />
                  </Form.Item>
                </Col>

                <Col {...colSize}>
                  <Form.Item
                    label={t('pages.users.newUser.labels.email')}
                    name="email"
                    rules={[
                      {
                        type: 'email',
                        message: t('pages.users.newUser.warningValidEmail'),
                      },
                      {
                        required: true,
                        message: t('pages.users.newUser.rmEmail'),
                      },
                    ]}
                  >
                    <Input
                      size="small"
                      placeholder={t('pages.users.newUser.phEmail')}
                    />
                  </Form.Item>
                </Col>

                <Col {...colSize}>
                  <Form.Item label=" ">
                    <Checkbox
                      checked={receiveEmails}
                      onChange={v => setReceiveEmails(v.target.checked)}
                      children={'Receber e-mails'}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Card>

          <Alert
            style={{ marginTop: 24, marginBottom: 24 }}
            message={t('pages.users.errors.hasNoReadPermission')}
            type="warning"
          />

          <Card title={t('pages.users.newUser.userPermissions.title')}>
            <Form scrollToFirstError={scrollOptions} form={formCheckbox}>
              {selectedUserPermissions && (
                <Table
                  columns={columnsUsers}
                  dataSource={selectedUserPermissions}
                  loading={isUserPermissionRequesting}
                  size="small"
                  scroll={{ x: 1300 }}
                  pagination={{
                    hideOnSinglePage: true,
                    pageSize: pageSize,
                    onChange(_, size) {
                      setPageSize(size);
                    },
                  }}
                />
              )}
            </Form>
          </Card>
        </Layout>
      </Layout>
      <Footer
        style={{
          position: 'fixed',
          left: 24,
          bottom: 0,
          width: '100%',
          backgroundColor: '#FFF',
          textAlign: 'center',
          boxShadow:
            '0 -6px 16px -8px rgba(0,0,0,.08),0 -9px 28px 0 rgba(0,0,0,.05),0 -12px 48px 16px rgba(0,0,0,.03)',
        }}
      >
        <div style={{ textAlign: 'right' }}>
          <Button
            style={{ marginRight: 24 }}
            danger
            htmlType="reset"
            onClick={() => {
              history.push('/usuarios');
            }}
          >
            {t('pages.users.newUser.footer.cancelBtn')}
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            onClick={() => {
              form.submit();
            }}
            loading={isUserRegisterRequesting}
          >
            {t('pages.users.newUser.footer.confirmBtn')}
          </Button>
        </div>
      </Footer>
    </>
  );
};

export default withTranslation()(RegisterNewUser);
