import { Descriptions, Layout, Modal, Table, Typography } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { ReactElement, useState } from 'react';

import { TFunction, withTranslation } from 'react-i18next';
import { IGetClient, IGetFarm } from '../../structures/interfaces/Clients';

const ViewClientsCard = ({
  t,
  visible,
  onClose,
  client,
}: {
  t: TFunction;
  visible: boolean;
  onClose: () => void;
  client?: IGetClient;
}): ReactElement<unknown> => {
  const { Title } = Typography;
  const tableColumns: ColumnsType<IGetFarm> = [
    {
      title: t('pages.clients.table.name'),
      dataIndex: ['name'],
      key: 'name',
    },
    {
      title: t('pages.clients.table.stateRegistration'),
      dataIndex: ['stateRegistration'],
      key: 'stateRegistration',
    },
    {
      title: t('pages.clients.table.email'),
      dataIndex: ['email'],
      key: 'email',
    },
  ];
  const [pageSize, setPageSize] = useState(10);

  return (
    <Layout>
      <Layout style={{ margin: 24 }}>
        <Modal width={'70%'} open={visible} onCancel={onClose} footer={null}>
          <Descriptions
            title={t('pages.clients.clientTitle')}
            column={{ xxl: 4, xl: 2, lg: 2, md: 1, sm: 2, xs: 1 }}
          >
            <Descriptions.Item label={t('pages.clients.clientName')}>
              {client ? client.name || client.tradeName : '---'}
            </Descriptions.Item>
            {client?.documentNumberCpfMask && (
              <Descriptions.Item label={t('pages.clients.documentNumberCpf')}>
                {client ? client.documentNumberCpfMask : '---'}
              </Descriptions.Item>
            )}
            {client?.documentNumberCnpjMask && (
              <Descriptions.Item label={t('pages.clients.documentNumberCnpj')}>
                {client ? client.documentNumberCnpjMask : '---'}
              </Descriptions.Item>
            )}
            <Descriptions.Item label={t('pages.clients.clientEmail')}>
              {client ? client.email : '---'}
            </Descriptions.Item>
            <Descriptions.Item label={t('pages.clients.clientPhoneNumber')}>
              {client ? client.phoneNumberMask : '---'}
            </Descriptions.Item>
          </Descriptions>

          <Table
            rowKey={farm => farm.name}
            size="small"
            title={() => <Title level={5}>{t('pages.clients.farms')}</Title>}
            columns={tableColumns}
            dataSource={client?.farms}
            pagination={{
              hideOnSinglePage: true,
              pageSize: pageSize,
              onChange(_, size) {
                setPageSize(size);
              },
            }}
          />
        </Modal>
      </Layout>
    </Layout>
  );
};

export default withTranslation()(ViewClientsCard);
