import { CopyOutlined } from '@ant-design/icons';
import {
  Button,
  Col,
  Descriptions,
  Form,
  Input,
  message,
  Modal,
  Row,
  Typography,
} from 'antd';
import { ReactElement, useEffect, useState } from 'react';
import Formatter from '../../classes/Formatter';
import { useRequest } from '../../hooks/useRequest';
import ClientsController from '../../structures/controllers/Clients';
import { IGetClient } from '../../structures/interfaces/Clients';

const copyToClipboard = (text: string) => {
  try {
    navigator.clipboard.writeText(text);
    message.success('Copiado para área de transferência!');
  } catch (err) {
    message.error('Falha ao copiar a senha!');
  }
};

interface IResetPassProps {
  open: boolean;
  setOpen: (value: boolean) => void;
  data?: IGetClient;
}

const ResetCustomerPassword = ({
  open,
  setOpen,
  data,
}: IResetPassProps): ReactElement => {
  const [form] = Form.useForm();
  const [pass, setPass] = useState('');
  const [resetPass, isLoading] = useRequest(
    ClientsController.resetCustomerPass
  );

  useEffect(() => {
    form.resetFields();
    setPass('');
  }, [open]);

  const handleOnOk = () => {
    if (!data) return null;
    resetPass({ ids: [data.id] })
      .then(res => {
        form.setFieldsValue({ password: res[0].password });
        setPass(res[0].password);
      })
      .catch(() => {
        message.error('Não foi possível resetar a senha deste usuário!');
      });
  };

  return (
    <Modal
      closable
      open={open}
      title="Resetar senha"
      okText="Resetar"
      okButtonProps={{ loading: isLoading }}
      onOk={handleOnOk}
      onCancel={() => setOpen(false)}
    >
      <Descriptions column={1} style={{ marginBottom: 32 }}>
        <Descriptions.Item label="Nome">
          {data?.tradeName || data?.name}
        </Descriptions.Item>

        <Descriptions.Item label="CNPJ">
          {Formatter.formatCNPJ(data?.documentNumberCnpj)}
        </Descriptions.Item>
      </Descriptions>
      {pass ? (
        <Form form={form}>
          <Row gutter={12}>
            <Col span={12}>
              <Form.Item name="password">
                <Input.Password />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Button
                disabled={!pass}
                type="primary"
                icon={<CopyOutlined />}
                onClick={() => copyToClipboard(form.getFieldValue('password'))}
              />
            </Col>
          </Row>
        </Form>
      ) : (
        <Typography.Text strong>
          Clique para resetar a senha de seu cliente!
        </Typography.Text>
      )}
    </Modal>
  );
};

export default ResetCustomerPassword;
