import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Tag,
  Typography,
  message,
} from 'antd';
import { TFunction } from 'i18next';
import { ReactElement, useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { useRequest } from '../../hooks/useRequest';
import ClientsController from '../../structures/controllers/Clients';
import { IGetClient } from '../../structures/interfaces/Clients';
import CustomerNotified from './customerNotified';
import { RegStatusEnum } from '../../structures/interfaces/User';

interface IRegularizeCustomer {
  t: TFunction;
  visible: boolean;
  onClose: () => void;
  onConfirm: () => void;
  data: IGetClient | undefined;
}

const { Text } = Typography;

const RegularizeCustomers = ({
  t,
  visible,
  onClose,
  data,
  onConfirm,
}: IRegularizeCustomer): ReactElement<unknown> => {
  const [form] = Form.useForm();

  const [allowCustomerAccess] = useRequest(
    ClientsController.allowCustomerAccess
  );
  const [viewNotification, setViewNotification] = useState(false);

  const colSize = { xs: 24, sm: 24, md: 12, lg: 8, xl: 7, xxl: 6 };

  const requiredRule = {
    required: true,
    message: t('pages.users.updateUser.rmEmail'),
  };

  const regularizeModalContent = () => {
    return (
      <>
        <Text>
          {t('pages.activate.feedbackModal.contentText.partOne')}{' '}
          <b>{data?.name || data?.tradeName}</b>{' '}
          {t('pages.activate.feedbackModal.contentText.partTwo')}
          <Tag color="green">{RegStatusEnum.ACTIVATED}</Tag>
        </Text>
      </>
    );
  };

  const errorToActivate = (err: { message: string; description: string }) => {
    message.error({
      content: t(err.message),
      style: {
        marginTop: 60,
      },
    });
  };

  const onFinish = (values: { email: string }) => {
    if (data) {
      allowCustomerAccess({ email: values.email, customerId: data.id })
        .then(() => {
          onConfirm();
          setViewNotification(true);
        })
        .catch(err => errorToActivate(err));
    }
  };

  const formUpdateUser = (
    <Form layout="vertical" form={form} onFinish={onFinish}>
      <Row gutter={[24, 0]}>
        <Col {...colSize}>
          <Form.Item
            label={<b>{t('pages.users.updateUser.email')}</b>}
            name="email"
            rules={[requiredRule]}
          >
            <Input
              size="large"
              placeholder={t('pages.users.updateUser.phEmail')}
            />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );

  const footerModal = [
    <Button
      key="back"
      danger
      onClick={() => {
        onClose();
        form.resetFields();
      }}
    >
      {t('pages.users.updateUser.cancelButton')}
    </Button>,
    <Button
      key="confirm"
      type="primary"
      htmlType="submit"
      onClick={() => {
        form.submit();
      }}
    >
      {t('pages.users.updateUser.confirmButton')}
    </Button>,
  ];

  useEffect(() => {
    form.resetFields();
  }, [visible]);

  return (
    <>
      <Modal
        title={t('pages.activate.modalTitle')}
        width={'70%'}
        open={visible}
        onCancel={onClose}
        footer={footerModal}
      >
        {formUpdateUser}
      </Modal>
      <CustomerNotified
        title={t('pages.activate.feedbackModal.title')}
        visible={viewNotification}
        onClose={() => setViewNotification(false)}
        onConfirm={() => setViewNotification(false)}
        content={regularizeModalContent}
      />
    </>
  );
};

export default withTranslation()(RegularizeCustomers);
