import React, { ReactElement, useState } from 'react';

import { Layout } from 'antd';

import { layoutStyles } from '../../utils/constants';

import { Header, Sidebar } from '../layout';

const { Content } = Layout;

import './index.scss';

/**
 * @description Panel Main layout (Sidebar and header).
 * @property {Node} children Main layout children (content)
 * @example
 * <MainLayout>
 *   {children}
 * </MainLayout>
 */

interface IMainLayout {
  children: React.ReactNode;
  menuKey: string;
}

const MainLayout = ({ children, menuKey }: IMainLayout): ReactElement => {
  const [collapsed, setCollapsed] = useState(false);

  const contentSmallStyle: React.CSSProperties = {
    minHeight: 'calc(100% - 65px)',
    margin: '60px 0 0 0',
    width: '100%',
    paddingTop: '4px',
    transition: 'all 2s',
    animationTimingFunction: 'ease-out',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
  };

  const contentLargeStyle: React.CSSProperties = {
    minHeight: 'calc(100% - 65px)',
    margin: '60px 0 0 0',
    width: '100%',
    paddingTop: '4px',
    transition: 'all 2s',
    animationTimingFunction: 'ease-out',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
  };

  const { backgroundColor: layoutColor } = layoutStyles;
  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Sidebar
        layoutColor={layoutColor}
        collapsed={collapsed}
        setCollapsed={setCollapsed}
        menuKey={menuKey}
      />
      <Layout
        style={{
          marginLeft: collapsed ? 80 : 200,
          transition: `margin .2s`,
        }}
      >
        <Header
          layoutColor={layoutColor}
          collapsed={collapsed}
          setCollapsed={setCollapsed}
          menuKey={menuKey}
        />
        <Content style={collapsed ? contentSmallStyle : contentLargeStyle}>
          {children}
        </Content>
      </Layout>
    </Layout>
  );
};

export default MainLayout;
