import { Button, Card, Col, Layout, PageHeader, Row, message } from 'antd';
import { ReactElement, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import UserContext from '../../contexts/user';
import { useRequest } from '../../hooks/useRequest';
import ContractController from '../../structures/controllers/Contract';
import {
  IDashboardContractTable,
  IGetContract,
} from '../../structures/interfaces/Contract';
import { fetchSyncUrl, screenModule } from '../../utils/sync';
import ContractItemsTable from './ContractItemsTable';
import { BuildContracts, BuildSlug } from './Contracts.utils';
import ContractsFilter from './ContractsFilter';
import ViewContract from './ViewContract';
import ImportContractsModal from './importContracts/modal';

const ContractsPage = (): ReactElement => {
  const { t } = useTranslation();
  const history = useHistory();

  const {
    userData,
    customerData,
    changingCropFlag,
    filterByCreate,
    settings,
    integrationMod,
  } = useContext(UserContext);

  const [open, setOpen] = useState(false);
  const [openImportModal, setOpenImportModal] = useState(false);

  const [contractData, setContractData] = useState<IGetContract>();
  const [filteredContracts, setFilteredContracts] =
    useState<IDashboardContractTable[]>();

  const [contracts, setContracts] = useState<IDashboardContractTable[]>();

  const [fetchContracts, isLoadingContracts] = useRequest(
    ContractController.getContractsById
  );
  const [fetchCustomerContracts, isLoadingCustomerContracts] = useRequest(
    ContractController.getCustomerContracts
  );

  const handleClose = () => {
    setOpen(false);
  };

  const getContracts = () => {
    if (userData) {
      fetchContracts({
        organization_id: userData.organization.id,
        subordinates_filter: null,
      })
        .then(contract => {
          const contractTableValues = BuildSlug(BuildContracts(contract));
          setContracts(contractTableValues);
          setFilteredContracts(contractTableValues);
        })
        .catch(() => null);
    } else if (customerData) {
      fetchCustomerContracts(customerData.id)
        .then(contract => {
          const contractTableValues = BuildSlug(BuildContracts(contract));
          setContracts(contractTableValues);
          setFilteredContracts(contractTableValues);
        })
        .catch(() => null);
    }
  };

  const pageHeader = (
    <PageHeader
      title={t('pages.contracts.title')}
      subTitle={t('pages.contracts.subtitle')}
      // extra={extraPageHeader}
      ghost={false}
    />
  );

  const mod = screenModule({ intMod: integrationMod, mod: 'CONTRACTS' });
  const syncContracts = mod && settings && (
    <Button
      onClick={() =>
        fetchSyncUrl({
          url: settings.integrationUrl,
          mod,
          syncError: message.error(t('pages.sync.errors.syncAllError')),
          syncSuccess: message.success(
            t('pages.sync.success.syncContractsSuccess')
          ),
        })
      }
      size="small"
      children={t('pages.sync.labels.syncContracts')}
    />
  );

  const createTruckLoadPermission = filterByCreate?.find(
    permission => permission?.userPermissions.module === 'CARGAS'
  );
  const btnFormLoad = (createTruckLoadPermission || customerData) && (
    <Button
      type="primary"
      htmlType="submit"
      onClick={() => history.push('/cargas/formar-carga')}
    >
      {t('pages.contracts.formLoadBtn')}
    </Button>
  );

  const extraButtons = (
    <Row gutter={[24, 0]}>
      <Col>{syncContracts}</Col>
      <Col>{btnFormLoad}</Col>
    </Row>
  );

  useEffect(() => {
    getContracts();
  }, [customerData, userData, changingCropFlag]);

  return (
    <>
      <Layout>
        {pageHeader}
        <Layout style={{ margin: 24 }}>
          <ContractsFilter
            setContracts={setContracts}
            contracts={contracts}
            setFilteredContracts={setFilteredContracts}
            filteredContracts={filteredContracts}
          />
          <Card
            title={t('pages.contracts.listingCardTitle')}
            extra={extraButtons}
          >
            <ContractItemsTable
              setOpen={setOpen}
              setContract={setContractData}
              contracts={contracts}
              isLoading={isLoadingContracts || isLoadingCustomerContracts}
            />
          </Card>
        </Layout>
      </Layout>
      {contractData && (
        <ViewContract
          visible={open}
          onClose={handleClose}
          data={contractData}
        />
      )}
      <ImportContractsModal
        visible={openImportModal}
        onClose={() => setOpenImportModal(false)}
      />
    </>
  );
};

export default ContractsPage;
