import { Layout, message, PageHeader } from 'antd';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import UserContext from '../../contexts/user';
import { useRequest } from '../../hooks/useRequest';
import TruckLoadController from '../../structures/controllers/TruckLoad';
import { IGetTruckLoadsResponse } from '../../structures/interfaces/TruckLoad';
import TruckLoadsFilter from './TruckLoadsPage.filter';
import TruckLoadsTable from './TruckLoadsPage.table';

const TruckLoadsPage = () => {
  const { t } = useTranslation();
  const { userData, customerData } = useContext(UserContext);

  const [truckLoads, setTruckLoads] = useState<IGetTruckLoadsResponse[]>([]);

  const [fetchLoads, isLoadingLoads] = useRequest(
    TruckLoadController.fetchLoads
  );
  const [fetchCustomerLoads, isLoadingCustomer] = useRequest(
    TruckLoadController.getCustomerTruckLoads
  );

  const truckLoadStatus = [
    { label: t('pages.truckLoads.waitingDriver'), value: 'WAITING_DRIVER' },
    { label: t('pages.truckLoads.waitingComercial'), value: 'WAITING_RELEASE' },
    { label: t('pages.truckLoads.waitingActions'), value: 'WAITING_ACTIONS' },
    { label: t('pages.truckLoads.loaded'), value: 'LOADED' },
    { label: t('pages.truckLoads.loadReleased'), value: 'AUTHORIZED_LOAD' },
    { label: t('pages.truckLoads.canceled'), value: 'CANCELED' },
    { label: t('pages.truckLoads.expired'), value: 'EXPIRED' },
    { label: t('pages.truckLoads.loading'), value: 'LOADING' },
  ];

  useEffect(() => {
    if (userData?.organization.id) {
      fetchLoads({
        organization_id: userData?.organization.id,
        subordinates_filter: null,
      })
        .then(setTruckLoads)
        .catch(() => message.error('Não foram encontradas regras!'));
    }
  }, []);
  useEffect(() => {
    if (userData) {
      fetchLoads({
        organization_id: userData.organization.id,
        subordinates_filter: null,
      }).then(setTruckLoads);
    } else if (customerData) {
      fetchCustomerLoads({}).then(setTruckLoads);
    }
  }, [userData, customerData]);

  return (
    <Layout>
      <PageHeader
        title={t('pages.truckLoads.title')}
        subTitle={t('pages.truckLoads.subtitle')}
        ghost={false}
      />
      <Layout.Content style={{ margin: 24 }}>
        <TruckLoadsFilter
          cardProps={{ style: { marginBottom: 24 } }}
          options={truckLoadStatus}
          truckLoads={truckLoads}
          setTruckLoads={setTruckLoads}
          fetchLoads={fetchLoads}
          isLoadingLoads={isLoadingLoads || isLoadingCustomer}
          customerData={customerData}
          userData={userData}
        />
        <TruckLoadsTable
          customerData={customerData}
          userData={userData}
          truckLoadStatus={truckLoadStatus}
          cardProps={{
            loading: isLoadingLoads || isLoadingCustomer,
            style: { marginBottom: 24 },
          }}
          tableProps={{ dataSource: truckLoads }}
        />
      </Layout.Content>
    </Layout>
  );
};

export default TruckLoadsPage;
