import client from '../../api/urql/customClient';
import {
  IGetOrganizationPendencies,
  pendenciesInput,
} from '../interfaces/Home';
import homeQueries from '../queries/Home';

class HomeController {
  static getTotalLoads = (organizationId: string): Promise<number> =>
    new Promise((resolve, reject) =>
      client
        .query(homeQueries.countTotalTruckLoads(), {
          where: { organization_id: organizationId },
        })
        .then(({ totalLoads }) => {
          resolve(totalLoads);
        })
        .catch(err => reject(err))
    );

  static getAuthorizedLoads = (organizationId: string): Promise<number> =>
    new Promise((resolve, reject) =>
      client
        .query(homeQueries.countAuthorizedTruckLoads(), {
          where: { organization_id: organizationId },
        })
        .then(({ authorizedLoads }) => {
          resolve(authorizedLoads);
        })
        .catch(err => reject(err))
    );

  static getWaitingForDriverLoads = (organizationId: string): Promise<number> =>
    new Promise((resolve, reject) =>
      client
        .query(homeQueries.countTruckLoadsWaitingForDriver(), {
          where: { organization_id: organizationId },
        })
        .then(({ waitingDriverLoads }) => {
          resolve(waitingDriverLoads);
        })
        .catch(err => reject(err))
    );

  static getLoadedLoads = (organizationId: string): Promise<number> =>
    new Promise((resolve, reject) =>
      client
        .query(homeQueries.countLoadedTruckLoads(), {
          where: { organization_id: organizationId },
        })
        .then(({ loadedLoads }) => {
          resolve(loadedLoads);
        })
        .catch(err => reject(err))
    );

  static getCanceledLoads = (organizationId: string): Promise<number> =>
    new Promise((resolve, reject) =>
      client
        .query(homeQueries.countCanceledTruckLoads(), {
          where: { organization_id: organizationId },
        })
        .then(({ canceledLoads }) => {
          resolve(canceledLoads);
        })
        .catch(err => reject(err))
    );

  static getFinalizedContracts = (organizationId: string): Promise<number> =>
    new Promise((resolve, reject) =>
      client
        .query(homeQueries.countFinalizedContracts(), {
          where: { organization_id: organizationId },
        })
        .then(({ finalizedContracts }) => {
          resolve(finalizedContracts);
        })
        .catch(err => reject(err))
    );

  static getPendingContracts = (organizationId: string): Promise<number> =>
    new Promise((resolve, reject) =>
      client
        .query(homeQueries.countPendingContracts(), {
          where: { organization_id: organizationId },
        })
        .then(({ pendingContracts }) => {
          resolve(pendingContracts);
        })
        .catch(err => reject(err))
    );

  static getOrganizationPendencies = (
    id: string
  ): Promise<IGetOrganizationPendencies> =>
    new Promise((resolve, reject) =>
      client
        .query(homeQueries.getOrganizationPendencies(), {
          where: { id: id },
        })
        .then(({ pendencies }) => {
          resolve(pendencies);
        })
        .catch(err => reject(err))
    );

  static updateOrganizationPendencies = ({
    id,
    pendenciesInput,
  }: {
    id: string;
    pendenciesInput: pendenciesInput;
  }): Promise<IGetOrganizationPendencies> =>
    new Promise((resolve, reject) =>
      client
        .mutation(homeQueries.updateOrganizationPendencies(), {
          where: { id: id },
          input: {
            my_company: pendenciesInput.myCompany,
            rules: pendenciesInput.rules,
          },
        })
        .then(({ updatePendencies }) => {
          resolve(updatePendencies);
        })
        .catch(err => reject(err))
    );
}

export default HomeController;
