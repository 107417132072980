import { DownOutlined, PlusOutlined, SendOutlined } from '@ant-design/icons';
import {
  Button,
  Card,
  Col,
  Dropdown,
  Form,
  Input,
  Layout,
  MenuProps,
  message,
  PageHeader,
  Row,
  Space,
  Table,
  Tooltip,
} from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { TableRowSelection } from 'antd/lib/table/interface';
import { ReactElement, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Formatter from '../../classes/Formatter';
import UserContext from '../../contexts/user';
import { useRequest } from '../../hooks/useRequest';
import ClientsController from '../../structures/controllers/Clients';
import { scrollOptions } from '../../utils/formOptions';
import NewCustomer, { IFarmResponse } from './NewCustomer.modal';

const renderContent = (text?: string) => {
  if (text) return text;
  else return '---';
};

function removeDuplicates(array: (string | undefined)[]) {
  const unique: (string | undefined)[] = [];
  array.forEach(element => {
    if (!unique.includes(element)) {
      unique.push(element);
    }
  });
  return unique;
}

const buildSlug = (farms: IFarmResponse[]) =>
  farms?.map(farm => ({
    ...farm,
    slug: `${farm.id} ${farm.name} ${farm.stateRegistration} ${farm.customer.email} ${farm.cnpj} ${farm.address} ${farm.customer.cnpj} ${farm.customer.name}`.toLowerCase(),
  }));

const FarmsPage = (): ReactElement => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const { userData } = useContext(UserContext);
  const [farms, setFarms] = useState<IFarmResponse[]>([]);
  const [filteredFarms, setFilteredFarms] = useState<IFarmResponse[]>([]);
  const [openCustomerModal, setOpenCustomerModal] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

  const [sendNewAccess] = useRequest(ClientsController.sendNewCustomerAccess);
  const [fetchFarms, isLoading] = useRequest(ClientsController.fetchOrgFarms);

  const onFinish = ({ search }: { search?: string }) => {
    if (!search) {
      setFilteredFarms(farms);
    } else {
      const treatedSearch = search.toLowerCase().trim();
      const filteredFarmsItems: IFarmResponse[] = [];
      filteredFarms.map(client => {
        if (client.slug?.includes(treatedSearch))
          filteredFarmsItems.push(client);
      });
      setFilteredFarms(filteredFarmsItems);
    }
  };

  const filter = (
    <Form
      scrollToFirstError={scrollOptions}
      layout="vertical"
      form={form}
      onFinish={onFinish}
      size="small"
    >
      <Row gutter={24}>
        <Col span={8}>
          <Form.Item label="Pesquisa" name="search">
            <Input.Search
              placeholder="Procure itens na tabela"
              enterButton
              onSearch={() => form.submit()}
            />
          </Form.Item>
        </Col>

        <Col span={24}>
          <Space size={12}>
            <Button
              children={t('pages.clients.clearFilter')}
              onClick={() => form.resetFields()}
            />
            <Button
              type="primary"
              children={t('pages.clients.filter')}
              onClick={() => form.submit()}
            />
          </Space>
        </Col>
      </Row>
    </Form>
  );

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection: TableRowSelection<IFarmResponse> = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const hasSelected = selectedRowKeys.length > 0;

  const items: MenuProps['items'] = [
    {
      label: 'Disparar e-mails de primeiro acesso',
      key: 'first-access',
      icon: <SendOutlined />,
      disabled: !hasSelected,
      onClick: () => {
        const keys = selectedRowKeys
          .map(key => farms.find(farm => farm.id === key))
          .map(item => item?.customer.id);

        sendNewAccess({ ids: removeDuplicates(keys!) })
          .then(() => message.success('E-mails enviados com sucesso'))
          .catch(() => message.error('Não foi possível enviar os e-mails'));
      },
    },
    {
      label: 'Adicionar contato a fazenda',
      key: 'new-customer',
      icon: <PlusOutlined />,
      onClick: () => setOpenCustomerModal(true),
    },
  ];

  const extraButton = (
    <Dropdown menu={{ items }} trigger={['click']}>
      <Button type="primary">
        <Space size={24}>
          Ações
          <DownOutlined />
        </Space>
      </Button>
    </Dropdown>
  );

  const columns: ColumnsType<IFarmResponse> = [
    {
      title: 'Nome',
      key: 'name',
      dataIndex: ['name'],
      render: renderContent,
    },
    {
      title: 'Inscrição estadual',
      key: 'stateRegistration',
      dataIndex: ['stateRegistration'],
      render: renderContent,
    },
    {
      title: 'CNPJ',
      key: 'cnpj',
      dataIndex: ['cnpj'],
      render: (_, record) => {
        if (!record.cnpj) return '---';
        const documentSize = record.cnpj.length;
        if (documentSize === 14) return Formatter.formatCNPJ(record.cnpj);
        if (documentSize === 11) return Formatter.formatCPF(record.cnpj);
        else return record.cnpj;
      },
    },
    {
      title: 'Contato',
      key: 'contact',
      dataIndex: ['customer', 'name'],
      render: renderContent,
    },
    {
      title: (
        <Tooltip
          title={'Este e-mail é do cliente/contato responsável pela fazenda!'}
        >
          E-mail
        </Tooltip>
      ),
      key: 'email',
      dataIndex: ['customer', 'email'],
      render: renderContent,
    },
    {
      title: 'Endereço',
      key: 'address',
      dataIndex: ['address'],
      render: renderContent,
    },
  ];

  useEffect(() => {
    if (userData?.organization.id) {
      fetchFarms({ orgId: userData.organization.id })
        .then(res => {
          const filteredResponse = buildSlug(res);
          setFilteredFarms(filteredResponse);
          setFarms(filteredResponse);
        })
        .catch(() => message.error('Não foi possível buscar as fazendas!'));
    }
  }, []);

  return (
    <>
      <Layout>
        <PageHeader
          title="Fazendas"
          subTitle="Veja informações das fazendas de seus clientes"
          ghost={false}
        />

        <Layout.Content style={{ margin: 24 }}>
          <Row gutter={[24, 24]}>
            <Col span={24}>
              <Card>{filter}</Card>
            </Col>

            <Col span={24}>
              <Card title="Fazendas" loading={isLoading} extra={extraButton}>
                <Table
                  size="small"
                  columns={columns}
                  dataSource={filteredFarms}
                  rowKey={farm => farm.id}
                  rowSelection={rowSelection}
                />
              </Card>
            </Col>
          </Row>
        </Layout.Content>
      </Layout>

      <NewCustomer
        setOpen={setOpenCustomerModal}
        open={openCustomerModal}
        setFetchCust={setOpenCustomerModal}
      />
    </>
  );
};

export default FarmsPage;
