import client from '../../api/urql/customClient';
import Formatter from '../../classes/Formatter';
import { IFarmResponse } from '../../pages/clients/NewCustomer.modal';
import { ILogin } from '../../utils/interfaces';
import {
  Farm,
  ICustomerFirstSignIn,
  ICustomerFirstSignInResponse,
  IGetClient,
  IGetCustomer,
  IUpdateCustomerAccount,
  IUpdateCustomerFields,
  IUpdateCustomerFirstSignInResponse,
  IUpdateCustomerOnFirstSignIn,
} from '../interfaces/Clients';
import clientQueries from '../queries/Clients';

class ClientsController {
  static login = ({
    email,
    password,
  }: ILogin): Promise<{
    token: string;
  }> =>
    new Promise((resolve, reject) =>
      client
        .mutation(clientQueries.login(), { email, password })
        .then(data => {
          resolve({
            token: data.Login.token,
          });
          resolve({ token: data.Login.token });
        })
        .catch(reject)
    );

  static getClients = (organizationId: string): Promise<IGetClient[]> =>
    new Promise((resolve, reject) =>
      client
        .query(clientQueries.getClients(), {
          where: { organization_id: organizationId },
        })
        .then(({ getClients }) => {
          const clients: IGetClient[] = getClients
            .map(customer => {
              return {
                ...customer,

                documentNumberCnpjMask: Formatter.formatCNPJ(
                  customer.documentNumberCnpj
                ),
                documentNumberCpfMask: Formatter.formatCPF(
                  customer.documentNumberCpf
                ),
                phoneNumberMask: Formatter.formatCellphone(
                  customer.phoneNumber
                ),
              };
            })
            .sort((a, b) => b.updatedAt.localeCompare(a.updatedAt));
          resolve(clients);
        })
        .catch(err => reject(err))
    );

  static getFarms = (organizationId: string): Promise<Farm[]> =>
    new Promise((resolve, reject) =>
      client
        .query(clientQueries.getFarms(), {
          where: { organization_id: organizationId },
        })
        .then(({ getFarms }) => {
          resolve(getFarms);
        })
        .catch(err => reject(err))
    );

  static getDataOnCustomerFirstLogin = (
    customer_id: string
  ): Promise<IGetCustomer> =>
    new Promise((resolve, reject) =>
      client
        .query(clientQueries.getCustomerDataOnFirstLogin(), {
          where: { id: customer_id },
        })
        .then(({ getCustomerFirstLogin }) => {
          resolve(getCustomerFirstLogin);
        })
        .catch(err => reject(err))
    );

  static getCustomer = (customer_id: string): Promise<IGetCustomer> =>
    new Promise((resolve, reject) =>
      client
        .query(clientQueries.getCustomer(), {
          where: { id: customer_id },
        })
        .then(({ getCustomer }) => {
          getCustomer.userHasOrganizations
            ? (getCustomer.organizationId =
                getCustomer.userHasOrganizations[0].organizationId)
            : undefined;
          resolve(getCustomer);
        })
        .catch(err => reject(err))
    );

  static customerFirstSignIn = (
    customerData: ICustomerFirstSignIn
  ): Promise<ICustomerFirstSignInResponse> =>
    new Promise((resolve, reject) =>
      client
        .mutation(clientQueries.customerFirstSignIn(), {
          input: {
            contract_number: customerData.contractNumber,
            email: customerData.email,
            password: customerData.password,
          },
        })
        .then(({ token }) => {
          resolve(token);
        })
        .catch(err => reject(err))
    );

  static updateCustomerFirstSignIn = ({
    customerData,
    id,
  }: {
    customerData: IUpdateCustomerOnFirstSignIn;
    id: string;
  }): Promise<IUpdateCustomerFirstSignInResponse> =>
    new Promise((resolve, reject) =>
      client
        .mutation(clientQueries.updateCustomerDataOnFirstSignIn(), {
          where: {
            id,
          },
          input: {
            id: customerData.id,
            name: customerData.name,
            document_number_cpf: customerData.documentNumberCpf,
            document_number_cnpj: customerData.documentNumberCnpj,
            trade_name: customerData.tradeName,
            company_name: customerData.companyName,
            phone_number: customerData.phoneNumber,
            email: customerData.email,
            password: customerData.password,
          },
        })
        .then(({ customerData }) => {
          resolve(customerData);
        })
        .catch(err => {
          reject(err);
        })
    );

  static updateCustomerAccount = ({
    customer,
    id,
  }: {
    customer: IUpdateCustomerAccount;
    id: string;
  }): Promise<IUpdateCustomerAccount> =>
    new Promise((resolve, reject) => {
      client
        .mutation(clientQueries.updateCustomerAccount(), {
          where: { id },
          input: {
            name: customer.name,
            phone_number: customer.phoneNumber,
            email: customer.email,
          },
        })
        .then(updateCustomer => {
          resolve(updateCustomer.customer);
        })
        .catch(err => {
          reject(err);
        });
    });

  static allowCustomerAccess = ({
    email,
    customerId,
  }: {
    email: string;
    customerId: string;
  }): Promise<{ success: boolean }> =>
    new Promise((resolve, reject) => {
      client
        .mutation(clientQueries.allowCustomerAccess(), {
          input: {
            email: email,
            customer_id: customerId,
          },
        })
        .then(({ allowCustomerAccess }) => {
          resolve(allowCustomerAccess);
        })
        .catch(err => {
          reject(err);
        });
    });

  static resetCustomerPass = ({
    ids,
  }: {
    ids: string[];
  }): Promise<Array<{ id: string; password: string }>> =>
    new Promise((resolve, reject) => {
      client
        .mutation(clientQueries.resetCustomerPass(), { input: { ids } })
        .then(({ reset }) => resolve(reset))
        .catch(err => reject(err));
    });

  static fetchOrgFarms = ({
    orgId,
  }: {
    orgId: string;
  }): Promise<Array<IFarmResponse>> =>
    new Promise((resolve, reject) => {
      client
        .query(clientQueries.fetchOrgFarms(), {
          where: {
            organization_id: orgId,
          },
        })
        .then(({ farms }) => {
          resolve(farms);
        })
        .catch(err => {
          reject(err);
        });
    });

  static createCustomer = ({
    email,
    farmId,
    name,
    orgId,
    cpf,
    phone,
  }: {
    name: string;
    email: string;
    farmId: string;
    orgId: string;
    cpf: string;
    phone: string;
  }): Promise<{ id: string; name: string; email: string }> => {
    return new Promise((resolve, reject) => {
      client
        .mutation(clientQueries.createCustomer(), {
          input: {
            name,
            email,
            farm_id: farmId,
            organization_id: orgId,
            document_number_cpf: cpf,
            phone_number: phone,
          },
        })
        .then(({ newCustomer }) => {
          resolve(newCustomer);
        })
        .catch(err => {
          reject(err);
        });
    });
  };

  static sendNewCustomerAccess = ({
    ids,
  }: {
    ids?: (string | undefined)[];
  }): Promise<{ success: boolean }> =>
    new Promise((resolve, reject) => {
      client
        .mutation(clientQueries.sendCustomerFirstAccess(), { input: { ids } })
        .then(({ newAccess }) => resolve(newAccess))
        .catch(err => reject(err));
    });

  static changeCustomerFromFarm = ({
    customerId,
    farmId,
  }: {
    farmId: string;
    customerId: string;
  }): Promise<{ customerId: string }> =>
    new Promise((resolve, reject) => {
      client
        .mutation(clientQueries.changeCustomerFromFarm(), {
          input: { customer_id: customerId, farm_id: farmId },
        })
        .then(({ updateCustomer }) => resolve(updateCustomer))
        .catch(err => reject(err));
    });

  static updateCustomer = ({
    customerData,
    id,
  }: {
    id: string;
    customerData: IUpdateCustomerFields;
  }): Promise<{ id: string }> =>
    new Promise((resolve, reject) =>
      client
        .mutation(clientQueries.updateCustomer(), {
          where: {
            id,
          },
          input: {
            company_name: customerData.companyName,
            document_number_cnpj: customerData.documentNumberCnpj,
            document_number_cpf: customerData.documentNumberCpf,
            email: customerData.email,
            name: customerData.name,
            phone_number: customerData.phoneNumber,
            trade_name: customerData.tradeName,
          },
        })
        .then(({ customerData }) => {
          resolve(customerData);
        })
        .catch(err => {
          reject(err);
        })
    );
}

export default ClientsController;
