import { QuestionCircleOutlined } from '@ant-design/icons';
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Layout,
  PageHeader,
  Row,
  Select,
  Space,
  Table,
  Tooltip,
} from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { ReactElement, useContext, useEffect, useState } from 'react';
import { TFunction, withTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import UserContext from '../../../contexts/user';
import { useRequest } from '../../../hooks/useRequest';
import AddressController from '../../../structures/controllers/Address';
import BreadCrumb from '../../../structures/controllers/Breadcrumb';
import ScheduleRulesController from '../../../structures/controllers/ScheduleRules';
import {
  IGetRules,
  IGetRulesIso,
} from '../../../structures/interfaces/ScheduleRules';
import { IOptions } from '../../../structures/interfaces/User';
import DeleteRule from './modals/deleteRule';
import {
  maximumPerCompanyMessage,
  pickUpLocationMessage,
  releaseSubmissionDateMessage,
  rulePeriodDateMessage,
  schedulingMessage,
  tsiMessage,
} from './microcopys/microCopyMessages';
import moment from 'moment';
import { scrollOptions } from '../../../utils/formOptions';

const ScheduleRules = ({ t }: { t: TFunction }): ReactElement<unknown> => {
  const { state } = useLocation();

  const [pageSize, setPageSize] = useState(10);
  const [rules, setRules] = useState<IGetRulesIso[]>();
  const [filteredRules, setFilteredRules] = useState<IGetRules[]>();
  const [seedTypeIds, setSeedTypeIds] = useState<IOptions[]>([]);
  const [pickUpLocations, setPickUpLocations] = useState<IOptions[]>([]);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [ruleId, setRuleId] = useState<string>();

  const [getRulesRequest, isGetRulesRequesting] = useRequest(
    ScheduleRulesController.getRules
  );
  const [findPickUpLocationsRequest] = useRequest(
    AddressController.findPickUpLocations
  );

  const { userData, company, filterByCreate, filterByUpdate, filterByDelete } =
    useContext(UserContext);
  const createSettingsPermission = filterByCreate?.find(
    permission => permission?.userPermissions.module === 'REGRAS'
  );
  const updateSettingsPermission = filterByUpdate?.find(
    permission => permission?.userPermissions.module === 'REGRAS'
  );
  const deleteSettingsPermission = filterByDelete?.find(
    permission => permission?.userPermissions.module === 'REGRAS'
  );

  const columnsScheduleRules: ColumnsType<IGetRules> = [
    {
      title: t('pages.scheduleRules.seedCulture'),
      dataIndex: ['seedType', 'name'],
      key: 'seedTypeId',
      sorter: (a, b) => a.seedType.name.localeCompare(b.seedType.name),
    },
    {
      title: () => (
        <>
          {t('pages.scheduleRules.pickUpLocation')}{' '}
          <Tooltip title={t(pickUpLocationMessage)}>
            <QuestionCircleOutlined />
          </Tooltip>
        </>
      ),
      dataIndex: ['pickUpLocation', 'title'],
      key: 'title',
      sorter: (a, b) =>
        a.pickUpLocation.title.localeCompare(b.pickUpLocation.title),
    },
    {
      title: () => (
        <>
          {t('pages.scheduleRules.rulePeriodDate')}{' '}
          <Tooltip title={t(rulePeriodDateMessage)}>
            <QuestionCircleOutlined />
          </Tooltip>
        </>
      ),
      render: (_, record) => {
        return (
          <Space size="middle">
            {moment(record.startDate).format('DD/MM/YYYY')}
            {'-'}
            {moment(record.endDate).format('DD/MM/YYYY')}
          </Space>
        );
      },
      sorter: {
        compare: (a, b) => {
          return moment(a.createdAt).unix() - moment(b.createdAt).unix();
        },
        multiple: 1,
      },
      sortDirections: ['descend'],
      defaultSortOrder: 'descend',
      key: 'rulePeriod',
    },
    {
      title: () => (
        <>
          {t('pages.scheduleRules.releaseSubmissionDate')}{' '}
          <Tooltip title={t(releaseSubmissionDateMessage)}>
            <QuestionCircleOutlined />
          </Tooltip>
        </>
      ),
      dataIndex: 'releaseSubmissionDate',
      render: (_, record) => {
        return (
          <Space size="middle">
            {moment(record.releaseSubmissionDate).format('DD/MM/YYYY')}
          </Space>
        );
      },
      key: 'releaseSubmissionDate',
    },
    {
      title: () => (
        <>
          {t('pages.scheduleRules.schedulingStart')}{' '}
          <Tooltip title={t(schedulingMessage)}>
            <QuestionCircleOutlined />
          </Tooltip>
        </>
      ),
      dataIndex: 'schedulingStart',
      render: (_, record) => {
        return (
          <Space size="middle">
            {moment(record.schedulingStart).format('DD/MM/YYYY')}
          </Space>
        );
      },
      key: 'schedulingStart',
    },
    {
      title: () => (
        <>
          {t('pages.scheduleRules.maximumPerCompany')}{' '}
          <Tooltip title={t(maximumPerCompanyMessage)}>
            <QuestionCircleOutlined />
          </Tooltip>
        </>
      ),
      dataIndex: 'capacityPerCompany',
      key: 'capacityPerCompany',
    },
    {
      title: () => (
        <>
          {t('pages.scheduleRules.whiteSeeds')}{' '}
          <Tooltip title={t(tsiMessage)}>
            <QuestionCircleOutlined />
          </Tooltip>
        </>
      ),
      dataIndex: 'whiteSeedSchedulingTime',
      render: value => (
        <>
          {value} {t('pages.scheduleRules.newRules.addonAfterDays')}
        </>
      ),
      key: 'whiteSeedSchedulingTime',
    },
    {
      title: () => (
        <>
          {t('pages.scheduleRules.tsiSeeds')}{' '}
          <Tooltip title={t(tsiMessage)}>
            <QuestionCircleOutlined />
          </Tooltip>
        </>
      ),
      dataIndex: 'tsiSeedSchedulingTime',
      render: value => (
        <>
          {value} {t('pages.scheduleRules.newRules.addonAfterDays')}
        </>
      ),
      key: 'tsiSeedSchedulingTime',
    },
    {
      title: t('pages.scheduleRules.actions'),
      key: 'action',
      render: (item: IGetRules, index) => (
        <Space size="middle">
          {updateSettingsPermission && (
            <Link
              to={{
                pathname: '/regras/cadastro/editar',
                state: {
                  organizationId: userData?.organization.id,
                  ruleId: item.id,
                },
              }}
            >
              {t('pages.settings.actionEdit')}
            </Link>
          )}
          {deleteSettingsPermission && (
            <a
              style={{ color: 'red' }}
              onClick={() => {
                setDeleteModalVisible(true);
                if (index) setRuleId(index.id);
              }}
            >
              {t('pages.settings.actionDelete')}
            </a>
          )}
        </Space>
      ),
    },
  ];

  const filteredColumns = columnsScheduleRules.filter(column => {
    if (!updateSettingsPermission && !deleteSettingsPermission) {
      return column.key != 'action';
    } else {
      return column;
    }
  });

  const { Search } = Input;
  const [form] = Form.useForm();

  const btnNewRule = createSettingsPermission && (
    <Link to={'/regras/cadastro/nova-regra'}>
      <Button type="primary" htmlType="submit" size="small">
        {t('pages.scheduleRules.newRuleBtn')}
      </Button>
    </Link>
  );

  const onReset = () => {
    form.resetFields();
  };

  useEffect(() => {
    if (userData) {
      findPickUpLocationsRequest({
        organizationId: userData?.organization.id,
      }).then(pickUpLocation => {
        const pickUpLocations = pickUpLocation.map(item => {
          return {
            label: item.title,
            value: item.id,
          };
        });
        setPickUpLocations(pickUpLocations);
      });
    }
  }, [company]);

  useEffect(() => {
    if (userData && deleteModalVisible === false) {
      getRulesRequest({
        organizationId: userData?.organization.id,
      }).then(rules => {
        setRules(rules);
        setFilteredRules(rules);

        const ids: IOptions[] = [];
        rules.map(rule => {
          if (!ids.find(id => id.value === rule.seedTypeId))
            ids.push({ label: rule.seedType.name, value: rule.seedTypeId });
        });
        setSeedTypeIds(ids);
      });
    }
  }, [company, state, deleteModalVisible]);

  const onFinish = ({
    search = '',
    seedName,
    pickUpLocation,
  }: {
    search?: string;
    seedName?: string;
    pickUpLocation?: string;
  }) => {
    if (rules) {
      const filters = ScheduleRulesController.filterRules;
      seedName = form.getFieldValue('culture');
      pickUpLocation = form.getFieldValue('location');

      setFilteredRules(() =>
        filters(rules, {
          search,
          seedName,
          pickUpLocation,
        })
      );
    }
  };

  const onConfirm = () => {
    if (rules) {
      const newData = [...rules];
      const index = rules.findIndex(item => ruleId === item.id);
      newData.splice(index, 1);
      setRules(newData);
    }
    setDeleteModalVisible(false);
  };

  return (
    <>
      <Layout>
        <PageHeader
          title={t('pages.scheduleRules.title')}
          subTitle={t('pages.scheduleRules.subtitle')}
          ghost={false}
          breadcrumb={<BreadCrumb />}
        />
        <Layout style={{ margin: 24 }}>
          <Card
            size="small"
            style={{ paddingRight: 12, paddingLeft: 12, marginBottom: 24 }}
          >
            <Form
              scrollToFirstError={scrollOptions}
              layout="vertical"
              form={form}
              onFinish={onFinish}
            >
              <Row gutter={[24, 0]} style={{ alignItems: 'end' }}>
                <Col xs={24} sm={24} md={12} lg={8} xl={6} xxl={5}>
                  <Form.Item name="search" label="">
                    <Search
                      enterButton
                      onSearch={() => form.submit()}
                      size="small"
                      placeholder={t('pages.scheduleRules.phInputSearch')}
                    />
                  </Form.Item>
                </Col>
                {seedTypeIds && (
                  <Col xs={24} sm={24} md={12} lg={8} xl={6} xxl={4}>
                    <Form.Item
                      name="culture"
                      label={t('pages.scheduleRules.cultureLabel')}
                    >
                      <Select
                        size="small"
                        options={seedTypeIds}
                        placeholder={t('pages.scheduleRules.phCultureFilter')}
                        allowClear
                      />
                    </Form.Item>
                  </Col>
                )}

                <Col xs={24} sm={24} md={12} lg={8} xl={6} xxl={4}>
                  <Form.Item
                    name="location"
                    label={t('pages.scheduleRules.locationLabel')}
                  >
                    <Select
                      size="small"
                      options={pickUpLocations}
                      placeholder={t('pages.scheduleRules.phPickupLocFilter')}
                      allowClear
                    />
                  </Form.Item>
                </Col>

                <Col
                  xs={24}
                  sm={24}
                  md={12}
                  lg={24}
                  xl={6}
                  xxl={11}
                  style={{ textAlign: 'right' }}
                >
                  <Form.Item label="">
                    <Button
                      style={{ marginRight: 24 }}
                      size="small"
                      onClick={onReset}
                    >
                      {t('pages.scheduleRules.clearFilter')}
                    </Button>
                    <Button size="small" type="primary" htmlType="submit">
                      {t('pages.scheduleRules.filter')}
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Card>
          <Card title={t('pages.scheduleRules.cardTitle')} extra={btnNewRule}>
            <Table
              rowKey={rule => rule.id}
              scroll={{ x: 1300 }}
              columns={filteredColumns}
              dataSource={filteredRules}
              loading={isGetRulesRequesting}
              size="small"
              pagination={{
                hideOnSinglePage: true,
                pageSize: pageSize,
                onChange(_, size) {
                  setPageSize(size);
                },
              }}
            />
          </Card>
        </Layout>
      </Layout>
      {ruleId && (
        <DeleteRule
          visible={deleteModalVisible}
          onClose={() => setDeleteModalVisible(false)}
          onConfirm={onConfirm}
          ruleId={ruleId}
        />
      )}
    </>
  );
};

export default withTranslation()(ScheduleRules);
