import { SyncOutlined } from '@ant-design/icons';
import { Button, Layout, message, Select, Space, Tooltip } from 'antd';
import { DefaultOptionType } from 'antd/lib/select';
import moment from 'moment';
import {
  CSSProperties,
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import UserContext from '../../contexts/user';
import { useRequest } from '../../hooks/useRequest';
import UserController from '../../structures/controllers/User';
import {
  IntegrationMod,
  ISettings,
} from '../../structures/interfaces/Settings';
import { getDataFromStorage, setDataToStorage } from '../../utils';
import { fetchSyncUrl } from '../../utils/sync';
import AvatarMenu from './avatarDropDown';

interface IHeader {
  collapsed: boolean;
  layoutColor?: string;
  menuKey: string;
  setCollapsed: (b: boolean) => void;
}
interface CropsType extends DefaultOptionType {
  current: boolean;
}

const modules = [
  'HOME',
  'CONTRATO',
  'CLIENTE',
  'USUARIOS',
  'CARGAS',
  'MINHA_EMPRESA',
  'scheduleRules',
  'ruleSettings',
  'tutorials',
  'reports',
];

const unhovered: CSSProperties = {
  marginRight: 12,
  color: '#fff',
};
const hovered: CSSProperties = {
  ...unhovered,
  textShadow: '0 0 1px #FFF, 0 0 2px #FFF',
};

const renderSyncAll = ({
  intMod,
  mod,
}: {
  intMod: IntegrationMod;
  mod: string;
}) => {
  const moduleOfScreen = intMod.modulesWithSync.find(aux => aux === mod);
  return moduleOfScreen || 'ALL';
};

type SyncAllType = {
  integrationMod?: IntegrationMod;
  settings?: ISettings;
  setIsHovered: Dispatch<SetStateAction<boolean>>;
  isHovered: boolean;
  t: TFunction<'translation', undefined>;
};
const syncAll = ({
  integrationMod,
  settings,
  setIsHovered,
  isHovered,
  t,
}: SyncAllType) => {
  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const syncSuccess = message.success(t('pages.sync.success.syncAllSuccess'));
  const syncError = message.error(t('pages.sync.errors.syncAllError'));

  if (integrationMod) {
    const mod = renderSyncAll({ intMod: integrationMod, mod: 'ALL' });

    if (settings?.hasSync) {
      return (
        <Button
          type="link"
          onClick={() =>
            fetchSyncUrl({
              mod,
              syncError,
              syncSuccess,
              url: settings.integrationUrl,
            })
          }
          style={isHovered ? hovered : unhovered}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <strong>
            <SyncOutlined spin={isHovered} style={{ marginRight: 5 }} />
            {t('pages.sync.labels.syncAll')}
          </strong>
        </Button>
      );
    }
  }
};

const Header = ({ collapsed, menuKey, layoutColor }: IHeader) => {
  const { t } = useTranslation();
  const { company, userData } = useContext(UserContext);
  const [cropOptions, setCropOptions] = useState<CropsType[]>();
  const [fetchCrops, isLoadingCrops] = useRequest(UserController.getCrops);
  const cropFromStorage = getDataFromStorage('crop');
  const isChangeCropAvailable = modules.includes(menuKey);

  const headerStyle: CSSProperties = {
    padding: 0,
    position: 'fixed',
    width: `calc(100% - ${collapsed ? 80 : 200}px)`,
    background: layoutColor,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'end',
    transition: `all .2s ${collapsed ? 'ease' : 'ease-in'}`,
    zIndex: 100,
  };
  const logoStyle: CSSProperties = {
    width: '150px',
    height: '100%',
    position: 'absolute',
    left: 25,
  };
  const imgStyle: CSSProperties = { maxHeight: '85%', borderRadius: '0.5rem' };

  const logo = (
    <div style={logoStyle}>
      <img src={company?.logoUrl ?? company?.logoUrl} style={imgStyle} />
    </div>
  );

  useEffect(() => {
    if (userData) {
      fetchCrops(userData.organization.id)
        .then(crops => {
          const sortedCrops = crops
            .sort((a, b) => {
              const startDateA = moment(a.startDate).clone();
              const startDateB = moment(b.startDate).clone();
              return startDateB.isAfter(startDateA) ? 1 : -1;
            })
            .map(crop => ({
              value: crop.id,
              label: crop.year,
              current: crop.current,
            }));

          if (!cropFromStorage) {
            const currCrop = sortedCrops.find(({ current }) => current);
            if (currCrop) setDataToStorage('crop', currCrop.value);
          }

          setCropOptions(sortedCrops);
        })
        .catch(() => message.error(t('headerMenu.crop.errors.cantFind')));
    }
  }, [userData]);

  return (
    <Layout.Header style={headerStyle}>
      {logo}
      <Space size={24}>
        <Tooltip
          title={isChangeCropAvailable ? '' : t(`headerMenu.crop.canNotChange`)}
        >
          <Select
            size="small"
            options={cropOptions}
            style={{ width: 150 }}
            disabled={!isChangeCropAvailable}
            loading={isLoadingCrops}
            dropdownStyle={{ textAlign: 'center' }}
            defaultValue={cropFromStorage}
          />
        </Tooltip>
        <AvatarMenu />
      </Space>
    </Layout.Header>
  );
};

export default Header;
