import { InboxOutlined } from '@ant-design/icons';
import {
  Alert,
  Button,
  Card,
  Col,
  Form,
  Input,
  Layout,
  message,
  PageHeader,
  Row,
  Select,
  Upload,
} from 'antd';
import { Footer } from 'antd/lib/layout/layout';
import { UploadFile } from 'antd/lib/upload/interface';
import { ReactElement, useContext, useEffect, useState } from 'react';
import { TFunction, withTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Formatter from '../../../classes/Formatter';
import UserContext from '../../../contexts/user';
import { useRequest } from '../../../hooks/useRequest';
import AddressController from '../../../structures/controllers/Address';
import BreadCrumb from '../../../structures/controllers/Breadcrumb';
import CompanyController from '../../../structures/controllers/Company';
import { ICityName } from '../../../structures/interfaces/Address';
import { IUploadCompanyLogoResponse } from '../../../structures/interfaces/Company/Company';
import { validateCnpj, validatePhoneNumber } from '../../../utils/inputRules';
import { UFNameOptions } from '../../../utils/UF';
import { UploadRequestOption } from 'rc-upload/lib/interface';
import HomeController from '../../../structures/controllers/Home';
import { scrollOptions } from '../../../utils/formOptions';

const { Option } = Select;

const normFile = (e: any) => {
  if (Array.isArray(e)) {
    return e;
  }
  return e && e.fileList;
};

const EditCompany = ({ t }: { t: TFunction }): ReactElement<unknown> => {
  const [form] = Form.useForm();
  const { userData, company, setCompany } = useContext(UserContext);
  const [updateOrganization, isUpdateOrganizationRequesting] = useRequest(
    CompanyController.updateOrganization
  );
  const [uploadOrganizationLogo, isUploadOrganizationLogoRequesting] =
    useRequest(CompanyController.uploadCompanyLogo);
  const history = useHistory();
  const [ufOptions, setUfOptions] = useState<string>();
  const [cityOptions, setCityOptions] = useState<ICityName[]>([]);
  const [findCitiesForStateRequest] = useRequest(
    AddressController.findCitiesByState
  );
  const [updatePendenciesRequest] = useRequest(
    HomeController.updateOrganizationPendencies
  );

  const [previewUrl, setPreviewUrl] = useState<IUploadCompanyLogoResponse>();
  const [fileList, setFileList] = useState<UploadFile[]>();
  const defaultFileUrl = company?.logoUrl;

  const info = () => {
    message.success({
      content: t('pages.editCompany.success.message'),
      className: 'custom-class',
      style: {
        marginTop: 60,
      },
    });
  };

  const setState = (selectedValue: string) => {
    setUfOptions(selectedValue);
  };

  useEffect(() => {
    if (company && company.addresses && company.contacts) {
      form.setFieldsValue({
        tradeName: company.tradeName,
        companyName: company.companyName,
        documentNumberCnpj:
          company.documentNumberCnpj === null
            ? null
            : Formatter.formatCNPJ(company.documentNumberCnpj),
        phoneNumber:
          company.contacts[0]?.phoneNumber === null
            ? null
            : Formatter.formatCellphone(company.contacts[0]?.phoneNumber),
        email:
          company.contacts[0]?.email === '---'
            ? null
            : company.contacts[0]?.email,
        zipCode: Formatter.formatZipCode(company.addresses.zipCode),
        stateShortName: company.addresses.stateShortName,
        cityName: company.addresses.cityName,
        district: company.addresses.district,
        street: company.addresses.street,
        streetNumber: company.addresses.streetNumber,
      });

      setState(company.addresses.stateShortName);
    }
  }, [company]);

  useEffect(() => {
    if (ufOptions) {
      findCitiesForStateRequest(ufOptions).then(city => {
        setCityOptions(city);
        if (
          cityOptions.find(
            city => form.getFieldValue('cityName') === city.cityName
          )
        ) {
          form.setFieldsValue({
            cityName: null,
          });
        }
      });
    }
  }, [ufOptions]);

  const onFinish = (values: any) => {
    if (userData) {
      values.zipCode = values.zipCode.replace(/[^0-9]/g, '');
      values.phoneNumber = values.phoneNumber.replace(/[^0-9]/g, '');
      values.documentNumberCnpj = values.documentNumberCnpj.replace(
        /[^0-9]/g,
        ''
      );
      updateOrganization({
        id: userData.organization.id,
        updateOrganization: {
          ...values,
          addressId: company?.addresses?.id,
          contactId: company?.contacts[0].id,
        },
      })
        .then(values => {
          if (previewUrl) {
            values.logoUrl = previewUrl.logo_url;
          }
          setCompany(values);
          info();
          history.push(`/empresas/${userData?.organization.id}`);
          updatePendenciesRequest({
            id: userData.organization.id,
            pendenciesInput: { myCompany: true },
          });
        })
        .catch(() => {
          <Alert
            message={t('pages.editCompany.errors.message')}
            description={t('pages.editCompany.errors.description')}
          />;
        });
    }
  };

  const customRequest = (options: UploadRequestOption) => {
    const { file, onSuccess, onError } = options;
    if (userData) {
      uploadOrganizationLogo({
        organizationId: userData.organization.id,
        file: file,
      })
        .then(url => {
          setPreviewUrl(url);
          onSuccess && onSuccess({ previewUrl: url });
        })
        .catch(({ message }) => {
          onError && onError({ name: '', message: t(message) });
        });
    }
  };

  useEffect(() => {
    if (defaultFileUrl && !previewUrl) {
      try {
        const url = defaultFileUrl.split('/');

        const fileName = url.pop();
        const fileId = fileName?.split('.').shift();

        if (fileName && fileId) {
          setFileList([{ uid: fileId, name: fileName, url: defaultFileUrl }]);
          setPreviewUrl({ logo_url: defaultFileUrl });
        }
      } catch {
        setFileList(undefined);
      }
    } else {
      setFileList(undefined);
    }
  }, [defaultFileUrl]);

  return (
    <>
      <Layout
        style={{
          flex: 1,
        }}
      >
        <PageHeader
          className="site-page-header"
          title={t('pages.editCompany.title')}
          breadcrumb={<BreadCrumb penultimateName={company?.tradeName} />}
          subTitle={t('pages.editCompany.subtitle')}
          ghost={false}
        />
        <Layout
          style={{
            height: '100%',
            padding: 24,
          }}
        >
          <Form
            scrollToFirstError={scrollOptions}
            onFinish={onFinish}
            layout="vertical"
            form={form}
          >
            <Card title={t('pages.editCompany.companyData')}>
              <Row gutter={24}>
                <Col xs={24} sm={24} md={12} lg={7} xl={9} xxl={9}>
                  <Form.Item
                    label={t('pages.editCompany.tradeName')}
                    name="tradeName"
                    rules={[
                      {
                        required: true,
                        message: t('pages.editCompany.rmInfoName'),
                      },
                    ]}
                  >
                    <Input
                      disabled={isUpdateOrganizationRequesting}
                      placeholder={t('pages.editCompany.phInfoName')}
                    />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={24} md={12} lg={7} xl={9} xxl={9}>
                  <Form.Item
                    label={t('pages.editCompany.companyName')}
                    name="companyName"
                    rules={[
                      {
                        required: true,
                        message: t('pages.editCompany.rmcompanyName'),
                      },
                    ]}
                  >
                    <Input
                      disabled={isUpdateOrganizationRequesting}
                      placeholder={t('pages.editCompany.phcompanyName')}
                    />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={24} md={12} lg={5} xl={5} xxl={4}>
                  <Form.Item
                    label={t('pages.editCompany.cnpj')}
                    name="documentNumberCnpj"
                    normalize={Formatter.formatCNPJ}
                    validateFirst
                    rules={[
                      {
                        required: true,
                        message: t('pages.editCompany.rmCnpj'),
                      },
                      {
                        min: 14,
                        message: t('pages.editCompany.validateCnpj'),
                      },
                      { ...validateCnpj, message: t(validateCnpj.message) },
                    ]}
                  >
                    <Input
                      disabled={isUpdateOrganizationRequesting}
                      placeholder={t('pages.editCompany.phCnpj')}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={24} style={{ marginBottom: '1rem' }}>
                <Col xs={24} sm={24} md={24} lg={12} xl={9} xxl={9}>
                  <Form.Item
                    name="logoUrl"
                    valuePropName="fileList"
                    getValueFromEvent={normFile}
                  >
                    <Upload.Dragger
                      name="file"
                      fileList={fileList}
                      maxCount={1}
                      listType="picture"
                      beforeUpload={() => {
                        setFileList(undefined);
                        return true;
                      }}
                      customRequest={customRequest}
                    >
                      <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                      </p>
                      <p className="ant-upload-text">
                        {t('pages.editCompany.drawLogo')}
                      </p>
                      <p className="ant-upload-hint">
                        {t('pages.editCompany.or')}
                      </p>
                      <p className="ant-upload-hint">
                        {t('pages.editCompany.selectFile')}
                      </p>
                    </Upload.Dragger>
                  </Form.Item>
                </Col>
              </Row>
            </Card>

            <Card title={t('pages.editCompany.contact')}>
              <Row gutter={24}>
                <Col xs={24} sm={24} md={12} lg={12} xl={6} xxl={4}>
                  <Form.Item
                    label={t('pages.editCompany.phoneNumber')}
                    name="phoneNumber"
                    normalize={Formatter.formatCellphone}
                    validateFirst
                    rules={[
                      {
                        required: true,
                        message: t('pages.editCompany.rmPhoneNumber'),
                      },
                      {
                        ...validatePhoneNumber,
                        message: t(validatePhoneNumber.message),
                      },
                    ]}
                  >
                    <Input
                      disabled={isUpdateOrganizationRequesting}
                      placeholder={t('pages.editCompany.phPhone')}
                    />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={7}>
                  <Form.Item
                    label={t('pages.editCompany.email')}
                    name="email"
                    rules={[
                      {
                        required: true,
                        message: t('pages.editCompany.rmEmail'),
                      },
                      {
                        type: 'email',
                        message: t('pages.editCompany.rmEmail'),
                      },
                    ]}
                  >
                    <Input
                      disabled={isUpdateOrganizationRequesting}
                      placeholder={t('pages.editCompany.phEmail')}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Card>

            <Card
              title={t('pages.editCompany.adress')}
              style={{ marginBottom: 84 }}
            >
              <Row gutter={24}>
                <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={3}>
                  <Form.Item
                    label={t('pages.editCompany.zipCode')}
                    name="zipCode"
                    normalize={Formatter.formatZipCode}
                    rules={[
                      {
                        required: true,
                        message: t('pages.editCompany.rmZipCode'),
                      },
                      {
                        min: 9,
                        max: 9,
                        message: t('pages.editCompany.rmMinSizeZipCode'),
                      },
                    ]}
                  >
                    <Input
                      disabled={isUpdateOrganizationRequesting}
                      placeholder={t('pages.editCompany.phZipCode')}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={3}>
                  <Form.Item
                    label={t('pages.editCompany.stateName')}
                    name="stateShortName"
                    rules={[
                      {
                        required: true,
                        message: t('pages.editCompany.rmStateName'),
                      },
                    ]}
                  >
                    <Select
                      disabled={isUpdateOrganizationRequesting}
                      placeholder={t(
                        'pages.settings.pickUpLocations.statePlaceholder'
                      )}
                      onSelect={(value: string) => setState(value)}
                    >
                      {UFNameOptions.map(uf => {
                        return (
                          <Option key={uf.value} value={uf.value}>
                            {uf.value} - {uf.label}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={3}>
                  <Form.Item
                    label={t('pages.editCompany.cityName')}
                    name="cityName"
                    rules={[
                      {
                        required: true,
                        message: t('pages.editCompany.rmCityName'),
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      disabled={isUpdateOrganizationRequesting}
                      placeholder={t(
                        'pages.settings.pickUpLocations.cityPlaceholder'
                      )}
                      optionFilterProp="children"
                    >
                      {cityOptions?.map(city => {
                        return (
                          <Option
                            key={`${city.cityName}_${city.id}`}
                            value={city.cityName}
                          >
                            {city.cityName}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>

                <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={3}>
                  <Form.Item
                    label={t('pages.editCompany.districts')}
                    name="district"
                    rules={[
                      {
                        required: true,
                        message: t('pages.editCompany.rmDistricts'),
                      },
                    ]}
                  >
                    <Input
                      disabled={isUpdateOrganizationRequesting}
                      placeholder={t('pages.editCompany.phDistrict')}
                    />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={5}>
                  <Form.Item
                    label={t('pages.editCompany.street')}
                    name="street"
                    rules={[
                      {
                        required: true,
                        message: t('pages.editCompany.rmStreet'),
                      },
                    ]}
                  >
                    <Input
                      disabled={isUpdateOrganizationRequesting}
                      placeholder={t('pages.editCompany.phStreet')}
                    />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={3}>
                  <Form.Item
                    label={t('pages.editCompany.streetNumber')}
                    name="streetNumber"
                    rules={[
                      {
                        required: true,
                        message: t('pages.editCompany.rmStreetNumber'),
                      },
                    ]}
                  >
                    <Input
                      disabled={isUpdateOrganizationRequesting}
                      placeholder={t('pages.editCompany.phStreetNumber')}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Card>
          </Form>
        </Layout>
      </Layout>
      <Footer
        style={{
          position: 'fixed',
          left: 24,
          bottom: 0,
          width: '100%',
          backgroundColor: '#FFF',
          textAlign: 'center',
          boxShadow:
            '0 -6px 16px -8px rgba(0,0,0,.08),0 -9px 28px 0 rgba(0,0,0,.05),0 -12px 48px 16px rgba(0,0,0,.03)',
        }}
      >
        <div style={{ textAlign: 'right' }}>
          <Button
            style={{ marginRight: 24 }}
            danger
            htmlType="reset"
            disabled={isUpdateOrganizationRequesting}
            onClick={() => {
              history.push(`/empresas/${userData?.organization.id}`);
            }}
          >
            {t('pages.editCompany.cancelBtn')}
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            onClick={() => {
              form.submit();
            }}
            disabled={isUploadOrganizationLogoRequesting}
            loading={isUpdateOrganizationRequesting}
          >
            {t('pages.editCompany.confirmBtn')}
          </Button>
        </div>
      </Footer>
    </>
  );
};

export default withTranslation()(EditCompany);
